.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.studentTestView {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    padding: .5rem;
    color: var(--textColor);
    animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */

}
.studentTestSectionTabsContainer {
    transform: translateY(.25rem);
    flex-direction: row;
    padding: 0 1rem;
    justify-content: flex-start;
    align-items: center;

    .studentTestSectionTabs {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: .01rem;
        overflow-x: auto;
        /* Allows horizontal scrolling */
        scroll-behavior: smooth;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
            /* for Chrome, Safari, and Opera */
        }
    }

    .tabItem,
    .left-btn,
    .right-btn {
        white-space: nowrap;
        padding: .3rem 1rem 1.5rem 1rem;
        text-decoration: none;
        font-weight: 500;
        color: var(--whiteColor);
        background-color: var(--PrimaryBkg);
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;

        &:hover {
            cursor: pointer;
            background-color: var(--PrimaryColor);
            font-weight: 600;
        }

        .icon {
            font-size: 1.5rem;
        }
    }

    .tabItem.selected {
        color: var(--whiteColor);
        background-color: var(--PrimaryColor);
        font-weight: 600;
    }

    .left-btn,
    .right-btn {
        padding: .3rem .5rem 1.5rem .5rem;
    }
}

.studentTestSectionContentsContainer {
    position: relative;
    transform: translateY(-1.1rem);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    // height: 100%;
    min-height: 40vh;
    flex-direction: column;
    // justify-content: center;
    background-color: var(--whiteColor);
    border-top: 8px solid var(--PrimaryColor);
    border-radius: .5rem;
    padding: 0 1rem 1rem 1rem;
}

.student-tests-glass{
    width: 100%;
    min-height: 50vh;
    flex-direction: column;
    // justify-content: flex-start;
    align-items: center;
    // margin-top: 1rem;
    // padding: .5rem;
    // background: var(--whiteColor);
    // border-radius: 2rem;
    gap: 1rem;
    // box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        color: var(--textColor);
        
        .testTypeSelectionDropdown {

            .css-1dimb5e-singleValue {
                font-size: 1.5rem;
                font-weight: 600;
                color: var(--textColor);
            }
        }
    }
}

.testsList {
    width: 100%;
    max-height: 80vh;
    color: var(--textColor);
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    // margin-top: 1rem;
}

.testsTable{
    position: relative;
    width: 100%;

    $table-header-bg: var(--SecondaryBkg);
    overflow-x: auto;
    overflow-y: auto;
    max-height: 100vh;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
}

.tests-table-style {
    width: 100%;
    // border-collapse: collapse;
    font-size: .85rem;
    box-shadow: 0 0 1.11rem rgba(0, 0, 0, 0.15);

    thead tr {
        background-color: var(--SecondaryBkg);
        color: var(--textColor);
        text-align: center;
        font-size: .9rem;
        vertical-align: middle;
    }

    #STARTED{
        background-color: var(--started);
    }

    #NOT-STARTED{
        background-color: var(--not-started);
    }

    #FINISHED{
        background-color: var(--finished);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .2rem;

        .retake-icon{
            font-size: .9rem;
            font-weight: 600;
            color: yellow;
        }
    }

    th,
    td {
        padding: 10px;
        border-bottom: 1px solid var(--cardBG);

        .icon {
            font-size: 1.5rem;
        }
    }

    tbody tr {
        &:hover {
            font-weight: 600;
            background-color: var(--PrimaryBkg);
            cursor: pointer;
        }

        &:nth-of-type(even) {
            background-color: var(--whiteColorDeam);

            &:hover {
                font-weight: 600;
                background-color: var(--PrimaryBkg);
            }
        }
    }

    tbody td {
        white-space: nowrap;
        text-align: center;
        /* Horizontally center the text. */
        vertical-align: middle;
        /* Vertically center the text. */
    }

    tbody td:nth-child(1) {
        width: 6rem;
    }

    tbody tr:last-of-type {
        td {
            border-bottom: 0;
        }
    }
}

.student-test-glass {
    display: grid;
    width: 100%;
    padding: 1rem 0;
    background: var(--whiteColor);
    border-radius: 2rem;
    gap: 1rem;
    grid-template-columns: 11rem auto;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}

.studentSelectTestPage{
    flex-grow: 1;
    display: grid;
    width: 100%;
    padding: 1rem 0;
    background: var(--whiteColor);
    border-radius: 2rem;
    gap: 1rem;
    grid-template-columns: 11rem auto;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

    .studentSelectTestPage-main {
        flex-direction: column;
        width: 100%;
        min-height: 50vh;
        justify-content: center;
        padding: 1rem;
        gap: 1rem;

        h3{
            color: var(--PrimaryColor);
            text-align: center;    
        }
    }
}

//MEDIA QUERIES ==================================================>
@media all and (max-width: 1000px) {}

@media all and (max-width: 800px) {
    .studentSelectTestPage{
        grid-template-columns: 3rem auto;
    }
}


@media all and (max-width: 720px) {
    .studentTestView {

    }
}

@media all and (max-width: 500px) {
    .studentTestView {
        
    }

    .studentSelectTestPage{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap:0;
        padding: 0;
    }
}
}