.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.testQuestionView {
    position: relative;
    width: 100%;
    min-height: 30vh;
    padding: 1rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    // border: 1px solid var(--SecondaryBkg);
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: .5rem;
    background-color: rgba(24, 201, 168, 0.1);

    .btn {
        margin: .5rem 0;
        color: var(--whiteColor);
        font-weight: 600;
        justify-content: space-between;
        gap: .5rem;

        .icon {
            align-self: center;
            font-size: 18px;
        }
    }

    .questionNumberSlide {
        width: 80%;
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        // padding-bottom: 1rem;
    }

    .questionNumber {
        padding: .2rem .5rem;
        border-radius: .3rem;
        background-color: var(--midnight-blue);
        color: var(--whiteColor);
        font-size: 1.1rem;
        font-weight: 700;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        text-align: start;
    }

    .questionButtons {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem .5rem;
        gap: .5rem;

        .questionIdxBtn,
        .paragraphIdxBtn {
            grid-template-columns: repeat(5, 1fr);
            justify-items: start;
            align-items: start;
            gap: .5rem;

            .idxBtn {
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 2rem;
                height: 2rem;
                background-color: var(--PrimaryColor);
                color: var(--whiteColor);
                padding: .1rem;
                border: none;
                border-radius: .5rem;
                font-size: 15px;
                font-weight: 600;
                cursor: pointer;

                &.correct-response {
                    background-color: green;
                }

                &.incorrect-response {
                    background-color: red;
                }

                &.saved-response {
                    background-color: orange;
                }
            }


            #clicked-btn {
                background-color: var(--midnight-blue);

                &.correct-response {
                    background-color: #006400;
                }

                &.incorrect-response {
                    background-color: #8B0000;
                }

                &.saved-response {
                    background-color: #FF8C00;
                }
            }
        }

        .delete-bin {
            width: 100%;
            padding: 1rem .5rem;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: .5rem;
            background-color: var(--tiger-lilly);
            color: var(--whiteColor);
            border-radius: .5rem;
            font-weight: 600;

            .icon {
                font-size: 2rem;
            }
        }
    }
}

.btns {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}


//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {}

@media all and (max-width: 1000px) {}

@media all and (max-width: 720px) {
    .testQuestionView {
        flex-direction: column-reverse;
        padding: .5rem .5rem 1rem .5rem;

        .questionNumberSlide {
            width: 100%;
        }

        .questionNumber {
            font-size: .95rem;
        }

        .questionButtons {
            width: 100%;
            justify-content: center;
            padding: .1rem;

            .questionIdxBtn,
            .paragraphIdxBtn {
                width: 100%;
                padding: 0;
                grid-template-columns: repeat(15, 1fr);
                gap: .1rem;

                .idxBtn {
                    transform: scale(0.9);
                }
            }

            .delete-bin {
                padding: .5rem;

                .icon {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

@media all and (max-width: 620px) {
    .testQuestionView {
        .questionButtons {

            .questionIdxBtn,
            .paragraphIdxBtn {
                grid-template-columns: repeat(13, 1fr);
            }
        }
    }
}

@media all and (max-width: 550px) {
    .testQuestionView {
        gap: .5rem;

        .questionNumber {
            font-size: .9rem;
        }

        .paragraph-slide,
        .question-slide{
            font-size: 1rem;
        }

        .questionButtons {

            .questionIdxBtn,
            .paragraphIdxBtn {
                width: 100%;
                padding: 0;
                grid-template-columns: repeat(10, 1fr);
                gap: .05rem;

                .idxBtn {
                    transform: scale(0.85);
                }
            }
        }
        .btn {
            margin: 0.1rem 0;
        }
    }

    .btns {
        flex-direction: column;
        gap: .2rem;
    }

    
}

@media all and (max-width: 425px) {
    .testQuestionView {
        .questionButtons {

            .questionIdxBtn,
            .paragraphIdxBtn {
                grid-template-columns: repeat(7, 1fr);
            }
        }
    }
}
}