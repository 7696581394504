.jeeta-app,
.jeeta-app *,
.customModal,
.customModal * {
    .loading-container {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.loading-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid var(--SecondaryBkg);
    border-top-color: var(--SecondaryColor);
    animation: spin 1s linear infinite;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // transform: translate(-50%, -50%)
        color: var(--SecondaryColor);
        transform-origin: center;
        animation: spinReverse 1s linear infinite;
    }
}
}