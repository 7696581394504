.jeeta-app,
.jeeta-app * {
.hero {
    position: relative;
    width: 100%;
    min-height: 450px;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--whiteColor);

    .hero-img {
        min-height: 300px;
        margin-top: 2.5rem;
        max-width: 70vw;
        max-height: 45vh;
        object-fit: contain;
        animation: on-load 1.5s ease-in-out forwards;
    }

    .hero-text {
        width: 100%;
        // position: absolute;
        // bottom: 0;
        // left: 50vw;
        // transform: translate(-50%, 25%);
        text-align: center;
        flex-direction: column;

        .hero-title {
            width: 100%;
            font-size: 3rem;
            font-weight: 800;
            color: var(--whiteColor);
            background: var(--PrimaryBkg);
            --webkit-background-clip: text;
            --webkit-text-fill-color: transparent;
        }

        .hero-description {
            width: 100%;
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--whiteColor);
            background: var(--SecondaryBkg);
        }
    }
}

.hero.noProfile {
    .hero-img {
        margin-top: 2rem;
        max-width: 90vw;
        max-height: 60vh;
        animation: on-load 1.5s ease-in-out forwards;

        // position: absolute;
        // top: 30vh;
        // left: 50vw;
        // transform: translate(-50%, -50%);
    }
}

.profile-hero {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .hero-img {
        max-width: 75vw;
        max-height: 50vh;
        object-fit: contain;
        animation: on-load 1.5s ease-in-out forwards;
    }

    .hero-text {
        width: 100%;
        flex-direction: column;
        text-align: center;
        color: var(--whiteColor);
        transform: translate(0, -30%);

        .hero-title {
            width: 100%;
            font-size: 50px;
            font-weight: 800;
            background: var(--PrimaryBkg);
        }

        .hero-description {
            width: 100%;
            font-size: 25px;
            font-weight: 500;
            background: var(--SecondaryBkg);
        }
    }
}

//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {
    .hero {

        .hero-img {
            max-width: 60vw;
            max-height: 60vh;
        }
    }

    .profile-hero {
        .hero-img {
            max-width: 80vw;
            max-height: 55vh;
        }

        .hero-text {
            transform: translate(0, -40%);

            .hero-title {
                font-size: 55px;
                padding: .2rem 0;
            }

            .hero-description {
                font-size: 30px;
                padding: .4rem 0;
            }
        }
    }
}

@media all and (max-width: 860px) {
    .hero {

        .hero-text {
            .hero-title {
                font-size: 2.5rem;
            }

            .hero-description {
                font-size: 1.3rem;
            }
        }
    }

    .hero.noProfile {
        .hero-img {
            width: 100vw;
            margin-top: 3rem;
        }
    }

    .profile-hero {

        .hero-img {
            width: 100vw;
            max-width: 100vw;
            max-height: 60vh;
        }

        .hero-text {

            .hero-title {
                font-size: 35px;
                padding: .1rem 0;
            }

            .hero-description {
                font-size: 22px;
                padding: .3rem 0;
            }
        }
    }
}

@media all and (max-width: 500px) {
    .hero {
        padding: 0;
        min-height: 0;

        .hero-img {
            // display: none;
            // margin-top: 3rem;
        }

        .hero-text {

            .hero-title {
                font-size: 1.9rem;
            }

            .hero-description {
                font-size: 1rem;
            }
        }
    }

    .profile-hero {
        padding: 0;
        min-height: 0;

        .hero-text {

            .hero-title {
                font-size: 30px;
                padding: 0;
            }

            .hero-description {
                font-size: 20px;
                padding: .2rem 0;
            }
        }
    }
}
}