.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.teacherTestView {
    position: relative;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    // .studentTestSectionContainer{
    //     position: relative;
    //     width: 100%;
    //     flex-direction: column;
    //     justify-content: flex-start;
    //     background-color: var(--whiteColor);
    //     border-top: 8px solid var(--SecondaryColor);
    //     border-radius: .5rem;
    //     padding: 1rem;

    //     .studentTestSectionTop{
    //         position: relative;
    //         width: 100%;
    //         flex-direction: column;
    //         justify-content: flex-start;

    //         h3,
    //         .testSectionTitle{
    //             width: 100%;
    //             height: 35px;
    //             box-sizing: border-box;
    //             font-size: 20px;
    //             line-height: 40px;
    //             border: none;
    //             outline: none;
    //             border-bottom: 1px solid var(--PrimaryColor);
    //         }

    //         .testSectionDescription{
    //             width: 100%;
    //             box-sizing: border-box;
    //             font-size: 15px;
    //             line-height: 40px;
    //             border: none;
    //             outline: none;
    //         }
    //     }

    //     .testSectionNumbers{
    //         flex-direction: row;
    //         justify-content: flex-start;
    //         padding: 1rem;
    //         gap: 1rem;

    //         .numbersTitle{
    //             font-size: 16px;
    //             font-weight: 600;
    //         }

    //         .numbersList{
    //             flex-direction: row;
    //             align-items: flex-start;
    //             gap: 1rem;
    //             font-size: 14px;
    //             font-weight: 600;

    //             ul{
    //                 width: 150px;
    //                 box-sizing: border-box;
    //                 font-size: 14px;
    //                 padding: .5rem;
    //                 border: 1px solid var(--PrimaryColor);
    //                 border-radius: .5rem;
    //                 outline: none;
    //             }
    //         }
    //     }

    //     .testSectionQuestions{
    //         flex-direction: row;
    //         justify-content: center;
    //         align-items: center;
    //         padding: 1rem;
    //         gap: 1rem;

    //         .btn {
    //             margin: .5rem 0;
    //             color: var(--whiteColor);
    //             font-weight: 600;
    //             justify-content: space-between;
    //             gap: .5rem;
    //             cursor: pointer;

    //             .icon {
    //                 align-self: center;
    //                 font-size: 18px;
    //             }
    //         }
    //     }

    //     .testSectionQuestionView{
    //         flex-direction: column;
    //         justify-content: flex-start;
    //         margin: 1rem 0;
    //         gap: 1rem;
    //     }
    // }    
}

//MEDIA QUERIES ==================================================>
@media all and (max-width: 1000px) {}

@media all and (max-width: 720px) {
    .teacherTestView {

        // .studentTestSectionContainer {
        //     padding: .5rem;
        // }
    }
}

@media all and (max-width: 500px) {}
}