.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.reading-material-view {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 90%;
    height: 90%;
    overflow-y: auto;
    border: none;
    background-color: var(--whiteColorDeam);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: .5rem;
    padding: 1rem;
    color: var(--textColor);

    #close {
        position: absolute;
        top: .5rem;
        right: .5rem;
        color: var(--PrimaryColor);
        align-self: center;
        font-size: 1.67rem;

        &:hover {
            color: var(--tiger-lilly);
        }
    }
}

.pdf-viewer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
}

.pdf-container {
    width: 100%;
    overflow: auto;
    flex-grow: 1;

    &::-webkit-scrollbar {
        width: .5rem;
        height: .5rem;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--SecondaryBkg);
        border-radius: .5rem;
        border: none;
    }
}

.react-pdf__Document {
    width: 100%;
    min-height: 100%;
    display: flex;
    // justify-content: center;
    align-items: center;
}

.react-pdf__Page__canvas {
    box-shadow: none;
}

.react-pdf__Page {
    width: 100%;
    display: flex;
    justify-content: center;
}

.annotation-canvas {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: auto;
    /* This will allow mouse events to pass through to the underlying document */
}

.pdf-viewer .react-pdf__Page__annotations {
    display: none;
    /* Hide annotations layer to prevent downloading */
}

.pdf-controls {
    width: 100%;
    padding: 0 2rem 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.change-page-btns,
.zoom-controls,
.annotation-controls {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    .left-btn,
    .right-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.85rem;
        /* Circle size */
        height: 1.85rem;
        /* Circle size */
        border-radius: 50%;
        /* Makes the button circular */
        border: none;
        /* Text color */
        font-size: .78rem;
        /* Adjust the font size as needed */
        background-color: var(--PrimaryColor);
        color: var(--whiteColor);

        &:hover {
            cursor: pointer;
            background-color: var(--SecondaryColor);
        }

        .icon {
            font-size: 1rem;
        }

        &.enabled {
            background-color: var(--SecondaryColor);
            color: var(--whiteColor);
        }
    }

    .create-mark-btn,
    .undo-mark-btn,
    .delete-mark-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .3rem;
        border-radius: .5rem;
        border: none;
        font-size: .85rem;
        background-color: var(--amber);
        color: var(--textColor);
        padding: .2rem .5rem;

        .icon {
            font-size: 1rem;
        }

        &:hover {
            cursor: pointer;
            background-color: var(--SecondaryColor);
            color: var(--whiteColor);
            font-weight: 600;
        }

        &.enabled {
            background-color: var(--SecondaryColor);
            color: var(--whiteColor);
            font-weight: 600;
        }
    }

    .undo-mark-btn{
        color: var(--whiteColor);
        background-color: var(--peach);
    }

    .delete-mark-btn{
        color: var(--whiteColor);
        background-color: var(--tiger-lilly);
    }
}

// .annotation-controls {
//     .left-btn {
//         background-color: var(--cardBG);
//         color: var(--PrimaryColor);

//         &.enabled {
//             background-color: var(--PrimaryColor);
//             color: var(--whiteColor);
//         }
//     }

// }

.loadingPageWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .page-loading {
        height: 100%;
    }
}

@media all and (max-width: 500px) {
    .reading-material-view{
        width: 95%;
        height: 95%;
        padding: .5rem;

        #close {
        position: absolute;
        top: .2rem;
        right: .2rem;
        color: var(--PrimaryColor);
        align-self: center;
        font-size: 1.67rem;

        &:hover {
            color: var(--tiger-lilly);
        }
    }
    }

    .pdf-controls{
        padding: 0 1.5rem 0 0;
        gap: .5rem;

        .zoom-controls,
        .change-page-btns,
        .annotation-controls{
            transform: scale(.85);
        }

        .annotation-controls{

            .undo-mark-btn,
            .create-mark-btn,
            .delete-mark-btn{
                padding: .5rem;
                border-radius: 50%;

                p{
                    display: none;
                }
            }
        }
    }
}
}