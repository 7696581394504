.jeeta-app .imageInputContainer,
.jeeta-app * .imageInputContainer, .customModal .imageInputContainer, .customModal * .imageInputContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .imageInput,
.jeeta-app * .imageInput, .customModal .imageInput, .customModal * .imageInput {
  width: 100%;
  justify-content: center;
  align-items: center;
}
.jeeta-app .imageInput .dropzone,
.jeeta-app * .imageInput .dropzone, .customModal .imageInput .dropzone, .customModal * .imageInput .dropzone {
  width: 100%;
  height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  border: 2px dashed var(--PrimaryBkg);
  border-radius: 0.25rem;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  transition: border 0.2s ease-in-out;
}
.jeeta-app .imageInput .dropzone .drag-n-drop-msg,
.jeeta-app * .imageInput .dropzone .drag-n-drop-msg, .customModal .imageInput .dropzone .drag-n-drop-msg, .customModal * .imageInput .dropzone .drag-n-drop-msg {
  font-size: 0.95rem;
  color: var(--PrimaryColor);
  white-space: auto;
  font-weight: 600;
}
.jeeta-app .imageInput .dropzone.active,
.jeeta-app * .imageInput .dropzone.active, .customModal .imageInput .dropzone.active, .customModal * .imageInput .dropzone.active {
  border-color: var(--PrimaryColor);
}
.jeeta-app .imageInput .upload-img-number,
.jeeta-app * .imageInput .upload-img-number, .customModal .imageInput .upload-img-number, .customModal * .imageInput .upload-img-number {
  width: 100%;
  padding: 0.3rem 1rem;
  text-align: center;
  font-weight: 600;
  background-color: var(--SecondaryColor);
  color: var(--whiteColor);
  font-size: 0.83rem;
}
.jeeta-app .imageInput #error,
.jeeta-app * .imageInput #error, .customModal .imageInput #error, .customModal * .imageInput #error {
  background-color: var(--tiger-lilly);
}
.jeeta-app .previewImagesContainer,
.jeeta-app * .previewImagesContainer, .customModal .previewImagesContainer, .customModal * .previewImagesContainer {
  width: 100%;
  grid-template-columns: repeat(auto-fit, 1fr);
}
.jeeta-app .previewImagesContainer .previewImages,
.jeeta-app * .previewImagesContainer .previewImages, .customModal .previewImagesContainer .previewImages, .customModal * .previewImagesContainer .previewImages {
  position: relative;
  justify-content: center;
  margin: 0.5rem;
  box-shadow: 2px 2px var(--greyText);
  background-color: var(--whiteColor);
  width: 100%;
  /* Ensure container takes up full column width */
  max-height: 40vh;
  /* Fixed height for all containers */
  overflow: hidden;
  /* To handle larger pdfs */
}
.jeeta-app .previewImagesContainer .previewImages .previewImage,
.jeeta-app * .previewImagesContainer .previewImages .previewImage, .customModal .previewImagesContainer .previewImages .previewImage, .customModal * .previewImagesContainer .previewImages .previewImage {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.jeeta-app .previewImagesContainer .previewImages .delete-btn,
.jeeta-app * .previewImagesContainer .previewImages .delete-btn, .customModal .previewImagesContainer .previewImages .delete-btn, .customModal * .previewImagesContainer .previewImages .delete-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  border: none;
  color: white;
  background-color: var(--SecondaryColor);
  display: flex;
  justify-content: center;
  padding: 0.3rem;
}
.jeeta-app .previewImagesContainer .previewImages .delete-btn .icon,
.jeeta-app * .previewImagesContainer .previewImages .delete-btn .icon, .customModal .previewImagesContainer .previewImages .delete-btn .icon, .customModal * .previewImagesContainer .previewImages .delete-btn .icon {
  font-size: 1rem;
}
.jeeta-app .previewImagesContainer .previewImages .delete-btn:hover,
.jeeta-app * .previewImagesContainer .previewImages .delete-btn:hover, .customModal .previewImagesContainer .previewImages .delete-btn:hover, .customModal * .previewImagesContainer .previewImages .delete-btn:hover {
  cursor: pointer;
  background-color: var(--tiger-lilly);
}
.jeeta-app .previewImagesContainer .previewImages p,
.jeeta-app * .previewImagesContainer .previewImages p, .customModal .previewImagesContainer .previewImages p, .customModal * .previewImagesContainer .previewImages p {
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  color: white;
  background-color: var(--PrimaryColor);
  padding: 0 0.5rem;
}
@media all and (max-width: 860px) {
  .jeeta-app .imageInputContainer .imageInput .img-add-btn,
  .jeeta-app * .imageInputContainer .imageInput .img-add-btn, .customModal .imageInputContainer .imageInput .img-add-btn, .customModal * .imageInputContainer .imageInput .img-add-btn {
    font-size: 0.89rem;
  }
  .jeeta-app .imageInputContainer .imageInput .img-add-btn .upload-img-number,
  .jeeta-app * .imageInputContainer .imageInput .img-add-btn .upload-img-number, .customModal .imageInputContainer .imageInput .img-add-btn .upload-img-number, .customModal * .imageInputContainer .imageInput .img-add-btn .upload-img-number {
    font-size: 14px;
  }
}
@media all and (max-width: 500px) {
  .jeeta-app .imageInputContainer .imageInput .dropzone,
  .jeeta-app * .imageInputContainer .imageInput .dropzone, .customModal .imageInputContainer .imageInput .dropzone, .customModal * .imageInputContainer .imageInput .dropzone {
    height: 7rem;
  }
}/*# sourceMappingURL=Image.css.map */