.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.question-card,
.paragraph-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    color: var(--textColor);
    background-color: var(--whiteColor);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: .5rem;
    padding: 35px .5rem 45px .5rem;
    gap: .5rem;
    width: 100%;
    font-size: .9rem;

    &:hover {
        cursor: pointer;
        background: var(--alice-blue);
        box-shadow: 1 4px 4px rgba(85, 85, 114, 0.549);
        transition: .3s ease;

        .delete-btn {
            display: flex;
            justify-content: center;
            border: none;
            padding: .3rem;
            border-radius: .5rem;
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: var(--tiger-lilly);
            color: white;

            .icon {
                font-size: 1.16rem;
            }
        }
    }

    .question-text,
    .paragraph-text {
        width: 100%;
        margin: .5rem;

        span {
            font-family: 'Frank Ruhl Libre', serif;
        }
    }

    .question-image,
    .paragraph-image {
        display: block;

        image {
            width: 100%;
            max-height: 30%;
            object-fit: contain;
        }
    }

    .answers {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .answer {
            flex: 1;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            transition: background-color 0.2s;

            &.correct {
                border-color: #f1e740; //#4CAF50;
                background-color: #e8f5e9; // A light green shade for correct answers

                &:hover {
                    background-color: #c8e6c9;
                }
            }

            &:hover {
                background-color: #f5f5f5;
            }
        }
    }

    .question-topics-list {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        flex-direction: row;
        gap: .3rem;
        padding: .5rem;
        overflow: auto;

        .question-topic {
            justify-content: center;
            font-size: .75rem;
            font-weight: 500;
            padding: .1rem .3rem;
            border-radius: 5px;
            background-color: var(--PrimaryColor);
            color: var(--whiteColor);
            overflow: visible;
            white-space: nowrap;

            &:hover {
                background-color: var(--SecondaryColor);
                font-weight: 600;
            }
        }

        &::-webkit-scrollbar {
            // width: 1rem;
            height: .8rem;
            /* Adjust width as needed */
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: .5rem;
            /* Adjust track background color as needed */
        }

        &::-webkit-scrollbar-thumb {
            background-color: #a7bad9;
            border-radius: .5rem;
            /* Adjust border-radius as needed */
            border: 1px solid #a7bad9;
            /* Adjust border and color as needed */
        }

        .question-tag {
            justify-content: center;
            font-size: .75rem;
            font-weight: 500;
            padding: .1rem .3rem;
            border-radius: 5px;
            background-color: var(--orange);
            color: var(--whiteColor);
            overflow: visible;
            white-space: nowrap;

            &:hover {
                background-color: var(--amber);
                font-weight: 600;
            }
        }
    }

    .question-info {
        position: absolute;
        top: 0;
        left: 0;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: .2rem;
    }

    .question-id {
        background-color: var(--PrimaryColor);
        padding: .2rem .4rem;
        border-radius: .5rem;
        color: white;
        font-size: .87rem;
        font-weight: 600;
    }

    #Physics {
        background-color: var(--Physics);

        &:hover {
            color: var(--Physics-accent);
        }
    }

    #Chemistry {
        background-color: var(--Chemistry);

        &:hover {
            color: var(--Chemistry-accent);
        }
    }

    #Mathematics {
        background-color: var(--Mathematics);

        &:hover {
            color: var(--Mathematics-accent);
        }
    }

    .question-saved {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: .2rem;
        gap: .2rem;
        
        .saved-btn{
            border: none;
            background-color: transparent;
            display: flex;
            justify-content: center;
            
            .icon {
                font-size: 1.6rem;
            }
        }
        
        #star-btn {
            color: var(--PrimaryColor);
            
        }
        #review-btn {
            padding-top: .2rem;
            color: var(--kelly-green);
        }
        
        
    }

    .question-difficulty {
        background-color: var(--SecondaryColor);
        padding: .2rem .4rem;
        border-radius: .5rem;
        color: white;
        font-size: .87rem;
        font-weight: 600;
    }

    #easy {
        background-color: var(--easy);
    }

    #medium {
        background-color: var(--medium);
    }

    #hard {
        background-color: var(--hard);
    }


    .delete-btn {
        display: none;
    }

    .answer-marks {
        justify-content: center;
        align-items: center;
        position: absolute;
        top: .2rem;
        right: .2rem;
        gap: .2rem;

        .mark {
            justify-content: center;
            align-items: center;
            gap: .1rem;

            p {
                font-size: 1rem;
                font-weight: 500;
            }

            .icon {
                font-size: 1.11rem;
            }
        }

        #correct .icon {
            color: var(--Chemistry);
        }

        #incorrect .icon {
            color: var(--tiger-lilly);
        }

        #unanswered .icon {
            color: var(--greyText);
        }
    }

    .delete-popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        padding: 1rem;
        background-color: var(--whiteColorDeam);
        border-radius: .5rem;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

        h3 {
            white-space: nowrap;
        }

        .btns {
            gap: 1rem;
        }
    }
}

.questionCardListFilter {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    z-index: 1;
    animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */

    .filterTitle {
        width: 100%;
        // padding: 1rem;
        border-radius: 1rem;
        // background: var(--gradientBkg);
        // box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
        flex-direction: column;
        gap: .5rem;

        .title {
            text-align: center;
            color: var(--SecondaryColor);
        }

        span {
            font-size: 1.5rem;
            text-align: center;
            font-weight: 600;
        }
    }

    .checkInput {
        padding: .5rem 0;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        font-weight: 600;
        font-size: .94rem;

        .unattemptedInput {
            color: var(--peach);
            justify-content: center;
            align-items: center;
            gap: .1rem;

            .icon {
            font-size: 1.16rem;
            }
        }

        .starInput {
            color: var(--PrimaryColor);
            justify-content: center;
            align-items: center;
            gap: .1rem;

            .icon {
            font-size: 1.16rem;
            }
        }

        .reviewInput {
            color: var(--kelly-green);
            justify-content: center;
            align-items: center;
            gap: .1rem;

            .icon {
            font-size: 1.16rem;
            }
        }

        .incorrectInput {
            color: var(--tiger-lilly);
            justify-content: center;
            align-items: center;
            gap: .1rem;

            .icon {
                font-size: 0.95rem;
            }
        }
    }

    .filters {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // place-items: center;
        padding: 0 .5rem;
        gap: .5rem;

        .filterSelect,
        .questionIDInput,
        .searchTextInput {
            display: flex;
            position: relative;
            width: 100%;
            flex-direction: row;
            float: left;
            justify-content: flex-start;
            align-items: center;
            padding: .5rem;
            gap: .5rem;

            .title {
                padding: .35rem .5rem;
                // min-width: 5rem;
                font-weight: 600;
                font-size: 1rem;
                white-space: nowrap;
                color: var(--textColor);
            }

            .selectionDropdown,
            .selectionDropdownMultiple {
                flex-grow: 1;

                .css-13cymwt-control{
                    font-size: .95rem;
                }
                
            }

            input {
                flex-grow: 1;
                border: hsl(0, 0%, 80%) solid 1px;
                border-radius: .3rem;
                padding: .5rem;
                font-size: .95rem;
            }
        }

        .searchTextInput{
            grid-column: span 2;
        }
    }

    .btns {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        // padding: 0 0 1rem 0;

        #filter,
        #create {
            font-size: .83rem;

            .icon {
                font-size: 1.05rem;
            }
        }
    }
}

.filter-type {
    width: 95%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;
    gap: .01rem;

    .filter{
        padding: .6rem 2rem 1.5rem 1rem;
        text-decoration: none;
        font-weight: 500;
        color: var(--whiteColor);
        background-color: var(--SecondaryColor);
        box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
        font-size: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: none;
        border-radius: 1rem;
        gap: .5rem;

        &:hover {
            cursor: pointer;
        }

        .icon {
            font-size: 1.2rem;
        }
    }

    .filter.selected {
        color: var(--whiteColor);
        background-color: var(--PrimaryColor);
        font-weight: 600;
    }
}

//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {}

@media all and (max-width: 1000px) {

    .question-card {
        font-size: .83rem;

        &:hover {
            cursor: pointer;
            background: var(--alice-blue);
            box-shadow: 1 4px 4px rgba(85, 85, 114, 0.549);
            transition: .3s ease;

            .delete-btn {
                display: flex;
                justify-content: center;
                border: none;
                padding: .3rem;
                border-radius: .5rem;
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: var(--tiger-lilly);
                color: white;

                .icon {
                    font-size: 1.16rem;
                }
            }
        }

        .question-text {
            font-size: .95rem;
        }

        .answers {
            display: flex;
            flex-wrap: wrap;
            gap: .5rem;

            .answer {
                flex: 1;
                padding: 10px;
                border: 1px solid #ddd;
                border-radius: 5px;
                transition: background-color 0.2s;

                &.correct {
                    border-color: #f1e740; //#4CAF50;
                    background-color: #e8f5e9; // A light green shade for correct answers

                    &:hover {
                        background-color: #c8e6c9;
                    }
                }

                &:hover {
                    background-color: #f5f5f5;
                }
            }
        }

        .question-difficulty,
        .question-id {
            font-size: .94rem;
        }

        .delete-btn {
            display: none;
        }

        .delete-popup {
            flex-direction: column;
            justify-content: center;
            gap: .5rem;
            padding: 1rem;
            background-color: var(--whiteColorDeam);
            border-radius: .5rem;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

            .btns {
                gap: 1rem;
            }
        }
    }
}

@media all and (max-width: 860px){
    
}

@media all and (max-width: 720px) {
    .questionCardListFilter{

        .filterTitle{
            span{
                font-size: 1.35rem;
            }
        }

        .filters {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .5rem;
        }
    }
}

@media all and (max-width: 550px) {
    .question-card {
        font-size: .8rem;

        .question-text {
            font-size: .9rem;
        }

        .question-difficulty,
        .question-id {
            font-size: .83rem;
        }

        .delete-btn {
            display: flex;
            justify-content: center;
            border: none;
            padding: .3rem;
            border-radius: .5rem;
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: var(--tiger-lilly);
            color: white;

            .icon {
                font-size: .87rem;
            }
        }

        .delete-popup {
            transform: scale(0.9);
        }
    }

    .questionCardListFilter{
        gap: .5rem;

        .filterTitle{
            span{
                font-size: 1.25rem;
            }
        }

        .filters {
            padding: .5rem .5rem 0 .5rem;

            .filterSelect,
            .questionIDInput,
            .searchTextInput {
                padding: 0;

                .title {
                    font-size: .9rem;
                }

                .selectionDropdown,
                .selectionDropdownMultiple,
                input{
                    font-size: .9rem;

                    input,
                    .css-13cymwt-control{
                        font-size: .9rem;
                    }
                }
            }
        }
        .checkInput {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 1.5rem;
            gap: 0.5rem;
        }
    }
}
}