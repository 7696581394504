.jeeta-app .testSubject,
.jeeta-app .testSubjectCreate,
.jeeta-app * .testSubject,
.jeeta-app * .testSubjectCreate,
.customModal .testSubject,
.customModal .testSubjectCreate, .customModal * .testSubject,
.customModal * .testSubjectCreate {
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  color: var(--textColor);
  gap: 0.5rem;
  animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */
}
.jeeta-app .testSubject .subjectTitle,
.jeeta-app .testSubjectCreate .subjectTitle,
.jeeta-app * .testSubject .subjectTitle,
.jeeta-app * .testSubjectCreate .subjectTitle,
.customModal .testSubject .subjectTitle,
.customModal .testSubjectCreate .subjectTitle, .customModal * .testSubject .subjectTitle,
.customModal * .testSubjectCreate .subjectTitle {
  width: 100%;
  margin: 1rem 0;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  gap: 1rem;
}
.jeeta-app .emptySubject,
.jeeta-app * .emptySubject,
.customModal .emptySubject, .customModal * .emptySubject {
  align-items: center;
  justify-content: center;
}
.jeeta-app .testSubject,
.jeeta-app * .testSubject,
.customModal .testSubject, .customModal * .testSubject {
  min-height: 50vh;
}
.jeeta-app .testSections,
.jeeta-app * .testSections,
.customModal .testSections, .customModal * .testSections {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 50vh;
  flex-direction: column;
  justify-content: center;
  color: var(--textColor);
  gap: 0.5rem;
  animation: fadeUp 1.2s ease-out forwards;
  /* Example duration and timing */
}
.jeeta-app .testSections .subjectTitle,
.jeeta-app * .testSections .subjectTitle,
.customModal .testSections .subjectTitle, .customModal * .testSections .subjectTitle {
  width: 100%;
  margin: 1rem 0;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  gap: 1rem;
}
.jeeta-app .testSubjectModal,
.jeeta-app * .testSubjectModal,
.customModal .testSubjectModal, .customModal * .testSubjectModal {
  background-color: var(--whiteColorDeam);
  position: fixed; /* make the modal appear above other content */
  top: 55%; /* centering the modal vertically */
  left: 50%; /* centering the modal horizontally */
  transform: translate(-50%, -50%); /* necessary adjustments for centering */
  z-index: 1000; /* high value to ensure modal is on top */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.jeeta-app .testSubjectModal .modalTitle,
.jeeta-app * .testSubjectModal .modalTitle,
.customModal .testSubjectModal .modalTitle, .customModal * .testSubjectModal .modalTitle {
  text-align: center;
  font-size: 1.11rem;
  font-weight: 600;
}
.jeeta-app .testSubjectModal .subjectCreateSelectionDropdown,
.jeeta-app * .testSubjectModal .subjectCreateSelectionDropdown,
.customModal .testSubjectModal .subjectCreateSelectionDropdown, .customModal * .testSubjectModal .subjectCreateSelectionDropdown {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .testSubjectModal .subjectCreateSelectionDropdown .title,
.jeeta-app * .testSubjectModal .subjectCreateSelectionDropdown .title,
.customModal .testSubjectModal .subjectCreateSelectionDropdown .title, .customModal * .testSubjectModal .subjectCreateSelectionDropdown .title {
  font-size: 0.89rem;
  font-weight: 600;
}
.jeeta-app .testSubjectModal .subjectCreateSelectionDropdown .selectionDropdown,
.jeeta-app .testSubjectModal .subjectCreateSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .testSubjectModal .subjectCreateSelectionDropdown .selectionDropdown,
.jeeta-app * .testSubjectModal .subjectCreateSelectionDropdown .selectionDropdownMultiple,
.customModal .testSubjectModal .subjectCreateSelectionDropdown .selectionDropdown,
.customModal .testSubjectModal .subjectCreateSelectionDropdown .selectionDropdownMultiple, .customModal * .testSubjectModal .subjectCreateSelectionDropdown .selectionDropdown,
.customModal * .testSubjectModal .subjectCreateSelectionDropdown .selectionDropdownMultiple {
  width: 200px;
  font-size: 0.83rem;
}
.jeeta-app .testSubjectModal .buttons,
.jeeta-app * .testSubjectModal .buttons,
.customModal .testSubjectModal .buttons, .customModal * .testSubjectModal .buttons {
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}
.jeeta-app .testSubjectModal .btn,
.jeeta-app * .testSubjectModal .btn,
.customModal .testSubjectModal .btn, .customModal * .testSubjectModal .btn {
  color: var(--whiteColor);
  font-weight: 600;
  justify-content: center;
  gap: 0.5rem;
}
.jeeta-app .testSubjectModal .btn .icon,
.jeeta-app * .testSubjectModal .btn .icon,
.customModal .testSubjectModal .btn .icon, .customModal * .testSubjectModal .btn .icon {
  align-self: center;
  font-size: 1rem;
}
.jeeta-app .testSubjectModal #close,
.jeeta-app * .testSubjectModal #close,
.customModal .testSubjectModal #close, .customModal * .testSubjectModal #close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: var(--PrimaryColor);
  align-self: center;
  font-size: 1.67rem;
}
.jeeta-app .autoTestSectionCreateContentsContainer,
.jeeta-app * .autoTestSectionCreateContentsContainer,
.customModal .autoTestSectionCreateContentsContainer, .customModal * .autoTestSectionCreateContentsContainer {
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: flex-start;
}
.jeeta-app .autoTestSectionCreateContentsContainer .autoTestSectionInfo,
.jeeta-app * .autoTestSectionCreateContentsContainer .autoTestSectionInfo,
.customModal .autoTestSectionCreateContentsContainer .autoTestSectionInfo, .customModal * .autoTestSectionCreateContentsContainer .autoTestSectionInfo {
  width: 100%;
  gap: 3rem;
}
@media all and (max-width: 720px) {
  .jeeta-app .testSubject,
  .jeeta-app * .testSubject,
  .customModal .testSubject, .customModal * .testSubject {
    padding: 0.5rem;
  }
  .jeeta-app .testSubject .subjectTitle,
  .jeeta-app * .testSubject .subjectTitle,
  .customModal .testSubject .subjectTitle, .customModal * .testSubject .subjectTitle {
    gap: 0.5rem;
  }
  .jeeta-app .testSubject .subjectTitle .subjectSelectionDropdown,
  .jeeta-app * .testSubject .subjectTitle .subjectSelectionDropdown,
  .customModal .testSubject .subjectTitle .subjectSelectionDropdown, .customModal * .testSubject .subjectTitle .subjectSelectionDropdown {
    gap: 0.5rem;
  }
  .jeeta-app .testSubject .subjectTitle .subjectSelectionDropdown .title,
  .jeeta-app * .testSubject .subjectTitle .subjectSelectionDropdown .title,
  .customModal .testSubject .subjectTitle .subjectSelectionDropdown .title, .customModal * .testSubject .subjectTitle .subjectSelectionDropdown .title {
    font-size: 1rem;
  }
  .jeeta-app .testSubject .subjectTitle .subjectSelectionDropdown .selectionDropdown,
  .jeeta-app * .testSubject .subjectTitle .subjectSelectionDropdown .selectionDropdown,
  .customModal .testSubject .subjectTitle .subjectSelectionDropdown .selectionDropdown, .customModal * .testSubject .subjectTitle .subjectSelectionDropdown .selectionDropdown {
    width: 150px;
    font-size: 0.89rem;
  }
  .jeeta-app .testSubject .subjectTitle #delete,
  .jeeta-app * .testSubject .subjectTitle #delete,
  .customModal .testSubject .subjectTitle #delete, .customModal * .testSubject .subjectTitle #delete {
    font-size: 1.67rem;
  }
  .jeeta-app .testSubject .hideIcon,
  .jeeta-app * .testSubject .hideIcon,
  .customModal .testSubject .hideIcon, .customModal * .testSubject .hideIcon {
    font-size: 1.11rem;
  }
  .jeeta-app .testSubject .deletePopup,
  .jeeta-app * .testSubject .deletePopup,
  .customModal .testSubject .deletePopup, .customModal * .testSubject .deletePopup {
    gap: 0.5rem;
  }
  .jeeta-app .testSubject .deletePopup .msg,
  .jeeta-app * .testSubject .deletePopup .msg,
  .customModal .testSubject .deletePopup .msg, .customModal * .testSubject .deletePopup .msg {
    font-size: 0.89rem;
  }
  .jeeta-app .testSubject .deletePopup .buttons,
  .jeeta-app * .testSubject .deletePopup .buttons,
  .customModal .testSubject .deletePopup .buttons, .customModal * .testSubject .deletePopup .buttons {
    gap: 0.5rem;
  }
}
@media all and (max-width: 500px) {
  .jeeta-app .testSubjectModal,
  .jeeta-app * .testSubjectModal,
  .customModal .testSubjectModal, .customModal * .testSubjectModal {
    width: 90%;
    padding: 2rem 1rem;
  }
}/*# sourceMappingURL=testSubject.css.map */