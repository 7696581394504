.home {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background-color: var(--whiteColor);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home .profileSelect {
  width: 450px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
.home .profileSelect input {
  caret-color: transparent;
}
.home .profileSelect ::-moz-placeholder {
  font-size: 15px;
  color: var(--textColor);
}
.home .profileSelect ::placeholder {
  font-size: 15px;
  color: var(--textColor);
}
.home .profileSelect span {
  font-size: 16px;
}
.home .profileSelect .react-dropdown-select-item {
  font-size: 15px;
}

@media all and (min-width: 1800px) {
  .home .profileSelect {
    width: 30vw;
    padding: 0.8rem 0.5rem;
  }
  .home .profileSelect ::-moz-placeholder {
    font-size: 18px;
  }
  .home .profileSelect ::placeholder {
    font-size: 18px;
  }
  .home .profileSelect span {
    font-size: 18px;
  }
  .home .profileSelect .react-dropdown-select-item {
    font-size: 17px;
  }
}
@media all and (max-width: 860px) {
  .home .profileSelect {
    width: 50vw;
  }
}
@media all and (max-width: 500px) {
  .home .profileSelect {
    width: 300px;
  }
  .home .profileSelect ::-moz-placeholder {
    font-size: 13.5px;
  }
  .home .profileSelect ::placeholder {
    font-size: 13.5px;
  }
  .home .profileSelect span {
    font-size: 14px;
  }
  .home .profileSelect .react-dropdown-select-item {
    font-size: 13.5px;
  }
}/*# sourceMappingURL=home.css.map */