:root {
  --fc-button-bg-color: rgba(65, 137, 230, 0.5);
  --fc-button-border-color:rgba(65, 137, 230, 0.5);
  --fc-button-active-bg-color: hsl(214, 77%, 58%);
  --fc-button-active-border-color: rgba(65, 137, 230, 0.5);
  --fc-button-hover-bg-color: hsl(169, 79%, 44%);
  --fc-button-hover-border-color: rgba(24, 201, 168, 0.5);
  --fc-bg-event-color: rgba(24, 201, 168, .3);
  --fc-bg-event-opacity: 0.9;
  --fc-event-bg-color: hsl(169, 79%, 44%);
  --fc-event-border-color: #ddd;
  --fc-today-bg-color: rgba(65, 137, 230, 0.1);
}

.jeeta-app .full-calendar,
.jeeta-app * .full-calendar {
  flex-grow: 1;
  display: flex;
  width: 100%;
  height: 100%;
  background: var(--whiteColor);
  gap: 1rem;
  grid-template-columns: 11rem auto;
  overflow: hidden;
}
.jeeta-app .full-calendar-sidebar,
.jeeta-app * .full-calendar-sidebar {
  display: flex;
  max-width: 18%;
  flex-direction: column;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 2rem;
  transition: all 300ms ease;
}
.jeeta-app .full-calendar-logo,
.jeeta-app * .full-calendar-logo {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
}
.jeeta-app .full-calendar-logo span,
.jeeta-app * .full-calendar-logo span {
  white-space: nowrap;
  color: var(--PrimaryColor);
}
.jeeta-app .full-calendar-logo span span,
.jeeta-app * .full-calendar-logo span span {
  color: var(--SecondaryColor);
}
.jeeta-app .full-calendar-logo img,
.jeeta-app * .full-calendar-logo img {
  width: 70%;
  height: auto;
}
.jeeta-app .total-events,
.jeeta-app * .total-events {
  margin: 2rem 0.5rem 1rem 0;
  display: flex;
  align-items: center;
  color: var(--whiteColor);
  background: var(--SecondaryBkg);
  gap: 0.5rem;
  height: 2.5rem;
  transition: all 300ms ease;
  border-radius: 0.7rem;
  font-size: 1.2rem;
  font-weight: 600;
  white-space: nowrap;
}
.jeeta-app .total-events::before,
.jeeta-app * .total-events::before {
  content: "";
  width: 8px;
  height: 100%;
  background: var(--SecondaryColor);
  margin-right: 0.5rem;
}
.jeeta-app .events-list,
.jeeta-app * .events-list {
  max-height: 600px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--textColor);
}
.jeeta-app .events-list .event-items,
.jeeta-app * .events-list .event-items {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  padding-left: 1rem;
}
.jeeta-app .events-list::-webkit-scrollbar,
.jeeta-app * .events-list::-webkit-scrollbar {
  width: 0.5rem;
  /* Adjust width as needed */
}
.jeeta-app .events-list::-webkit-scrollbar-track,
.jeeta-app * .events-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Adjust track background color as needed */
}
.jeeta-app .events-list::-webkit-scrollbar-thumb,
.jeeta-app * .events-list::-webkit-scrollbar-thumb {
  background-color: var(--SecondaryBkg);
  border-radius: 0.5rem;
  /* Adjust border-radius as needed */
  border: none;
  /* Adjust border and color as needed */
}
.jeeta-app .full-calendar-container,
.jeeta-app * .full-calendar-container {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  padding: 1em 0.5rem;
  overflow-y: scroll;
}
.jeeta-app .full-calendar-container .addEvent,
.jeeta-app * .full-calendar-container .addEvent {
  flex-direction: row;
}
.jeeta-app .event-info,
.jeeta-app * .event-info {
  width: 100%;
  white-space: nowrap;
  color: var(--textColor);
}
.jeeta-app .event-info::-webkit-scrollbar,
.jeeta-app * .event-info::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.jeeta-app .event-info:hover,
.jeeta-app * .event-info:hover {
  cursor: pointer;
  font-weight: 600;
}
.jeeta-app .event-info span,
.jeeta-app * .event-info span {
  display: flex;
  align-items: center;
  gap: 0.1rem;
}
.jeeta-app .event-info span .event-subject,
.jeeta-app * .event-info span .event-subject {
  text-align: center;
  margin: 0 0.3rem;
  padding: 0.1rem 0.3rem;
  border-radius: 0.3rem;
  color: var(--whiteColor);
}
.jeeta-app .event-info #Mathematics,
.jeeta-app * .event-info #Mathematics {
  background-color: var(--Mathematics);
}
.jeeta-app .event-info #Physics,
.jeeta-app * .event-info #Physics {
  background-color: var(--Physics);
}
.jeeta-app .event-info #Chemistry,
.jeeta-app * .event-info #Chemistry {
  background-color: var(--Chemistry);
}
.jeeta-app .event-info-calendar,
.jeeta-app * .event-info-calendar {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  white-space: nowrap;
  color: var(--textColor);
  font-size: 0.8rem;
  gap: 0.2rem;
}
.jeeta-app .event-info-calendar .event-time,
.jeeta-app * .event-info-calendar .event-time {
  text-align: center;
}
.jeeta-app .event-info-calendar .event-subject,
.jeeta-app * .event-info-calendar .event-subject {
  text-align: center;
  padding: 0 0.2rem;
  border-radius: 0.2rem;
  color: var(--whiteColor);
}
.jeeta-app .event-info-calendar #M,
.jeeta-app * .event-info-calendar #M {
  background-color: var(--Mathematics);
}
.jeeta-app .event-info-calendar #P,
.jeeta-app * .event-info-calendar #P {
  background-color: var(--Physics);
}
.jeeta-app .event-info-calendar #C,
.jeeta-app * .event-info-calendar #C {
  background-color: var(--Chemistry);
}
.jeeta-app .fc .fc-button :hover,
.jeeta-app .fc .fc-bg-event :hover,
.jeeta-app .fc .fc-event-main :hover,
.jeeta-app * .fc .fc-button :hover,
.jeeta-app * .fc .fc-bg-event :hover,
.jeeta-app * .fc .fc-event-main :hover {
  cursor: pointer;
}
.jeeta-app .fc .fc-toolbar-title,
.jeeta-app * .fc .fc-toolbar-title {
  text-align: center;
  color: var(--PrimaryColor);
  font-size: 1.5rem;
}
.jeeta-app .fc .fc-view-harness,
.jeeta-app * .fc .fc-view-harness {
  height: 100% !important; /* Forces the calendar's view to take up the full height of its container */
}
.jeeta-app .fc .fc-view-harness-active,
.jeeta-app * .fc .fc-view-harness-active {
  display: flex;
  flex-direction: column;
}
.jeeta-app .fc .fc-scrollgrid,
.jeeta-app * .fc .fc-scrollgrid {
  flex: 1; /* Allows the main calendar grid to flexibly fill the available space */
}
.jeeta-app .fc .fc-scroller::-webkit-scrollbar,
.jeeta-app * .fc .fc-scroller::-webkit-scrollbar {
  width: 0.5rem;
  /* Adjust width as needed */
}
.jeeta-app .fc .fc-scroller::-webkit-scrollbar-track,
.jeeta-app * .fc .fc-scroller::-webkit-scrollbar-track {
  background: transparent;
  /* Adjust track background color as needed */
}
.jeeta-app .fc .fc-scroller::-webkit-scrollbar-thumb,
.jeeta-app * .fc .fc-scroller::-webkit-scrollbar-thumb {
  background-color: var(--PrimaryBkg);
  border-radius: 0.5rem;
  /* Adjust border-radius as needed */
  border: none;
  /* Adjust border and color as needed */
}
.jeeta-app .fc .fc-timegrid-lot-label,
.jeeta-app * .fc .fc-timegrid-lot-label {
  width: auto;
  white-space: nowrap;
}
.jeeta-app .fc .fc-timegrid-slot-lane,
.jeeta-app * .fc .fc-timegrid-slot-lane {
  padding-left: auto !important; /* Adjust padding to fit new label width */
}
.jeeta-app .fc-col-header-cell,
.jeeta-app * .fc-col-header-cell {
  color: var(--textColor);
  background-color: rgba(24, 201, 168, 0.2); /* Example background color */
  border: 1px solid #ddd !important; /* Example to make the border bolder */
}
.jeeta-app .fc-col-header-cell.fc-day.fc-day-sat,
.jeeta-app .fc-col-header-cell.fc-day.fc-day-sun,
.jeeta-app * .fc-col-header-cell.fc-day.fc-day-sat,
.jeeta-app * .fc-col-header-cell.fc-day.fc-day-sun {
  background-color: rgba(255, 224, 224, 0.65);
}
@media all and (max-width: 860px) {
  .jeeta-app .timetable,
  .jeeta-app * .timetable {
    gap: 0.5rem;
  }
  .jeeta-app .full-calendar,
  .jeeta-app * .full-calendar {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0;
  }
  .jeeta-app .full-calendar .btns span,
  .jeeta-app * .full-calendar .btns span {
    display: none;
  }
  .jeeta-app .full-calendar-main,
  .jeeta-app * .full-calendar-main {
    padding: 0.5rem;
  }
  .jeeta-app .full-calendar-sidebar,
  .jeeta-app * .full-calendar-sidebar {
    width: 100%;
    max-width: none;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    gap: 0.5rem;
  }
  .jeeta-app .full-calendar-sidebar::-webkit-scrollbar,
  .jeeta-app * .full-calendar-sidebar::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .jeeta-app .full-calendar-sidebar::before,
  .jeeta-app * .full-calendar-sidebar::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 120%; /* Full width of the container */
    height: 2px; /* Thickness of the bottom line */
    background: var(--gradientBkg);
  }
  .jeeta-app .full-calendar-logo span,
  .jeeta-app * .full-calendar-logo span {
    transform: none;
  }
  .jeeta-app .full-calendar-logo img,
  .jeeta-app * .full-calendar-logo img {
    display: none;
  }
  .jeeta-app .total-events,
  .jeeta-app * .total-events {
    margin: 0;
    height: 1.8rem;
    padding: 0 0.5rem;
    border-radius: 0.5rem;
    gap: 0;
  }
  .jeeta-app .total-events::before,
  .jeeta-app * .total-events::before {
    content: "";
    width: 0; /* Full width of the container */
    height: 0; /* Thickness of the bottom line */
    margin: 0;
    background-color: transparent;
  }
  .jeeta-app .total-events .icon,
  .jeeta-app * .total-events .icon {
    font-size: 1.25rem;
  }
  .jeeta-app .events-list,
  .jeeta-app * .events-list {
    flex-direction: row;
    margin-top: 0;
    gap: 0;
    color: var(--PrimaryColor);
    overflow-x: auto;
  }
  .jeeta-app .events-list::-webkit-scrollbar,
  .jeeta-app * .events-list::-webkit-scrollbar {
    display: none;
  }
  .jeeta-app .events-list .event-items,
  .jeeta-app * .events-list .event-items {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.94rem;
    line-height: 1.2;
    padding: 0;
    gap: 0;
  }
  .jeeta-app .event-info,
  .jeeta-app * .event-info {
    width: auto;
    white-space: nowrap;
    color: var(--textColor);
    display: flex;
    flex-direction: column;
    transform: scale(0.8);
  }
}
@media all and (max-width: 550px) {
  .jeeta-app .full-calendar-logo,
  .jeeta-app * .full-calendar-logo {
    display: none;
  }
  .jeeta-app .event-info-calendar,
  .jeeta-app * .event-info-calendar {
    font-size: 0.5rem;
    gap: 0.05rem;
  }
  .jeeta-app .fc .fc-button,
  .jeeta-app * .fc .fc-button {
    padding: 0rem 0.2rem;
  }
  .jeeta-app .fc .fc-toolbar,
  .jeeta-app * .fc .fc-toolbar {
    font-size: 0.8rem;
  }
  .jeeta-app .fc .fc-toolbar-title,
  .jeeta-app * .fc .fc-toolbar-title {
    font-size: 1.2rem;
  }
  .jeeta-app .fc table,
  .jeeta-app * .fc table {
    font-size: 0.8rem;
  }
}/*# sourceMappingURL=CalendarStyles.css.map */