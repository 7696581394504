.jeeta-app .recordedVideoModal,
.jeeta-app * .recordedVideoModal,
.customModal .recordedVideoModal,
.customModal * .recordedVideoModal {
  position: absolute;
  /* make the modal appear above other content */
  top: 50vh;
  /* centering the modal vertically */
  left: 50vw;
  /* centering the modal horizontally */
  transform: translate(-50%, -50%);
  /* necessary adjustments for centering */
  z-index: 1000;
  /* high value to ensure modal is on top */
  width: 80vw;
  height: 90vh;
  /* or your desired height */
  overflow-y: auto;
  /* display scrollbar only when necessary */
  background-color: var(--whiteColorDeam);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  padding: 1.5rem 1rem;
  color: var(--textColor);
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .recordedVideoModal .modalTitle,
.jeeta-app * .recordedVideoModal .modalTitle,
.customModal .recordedVideoModal .modalTitle,
.customModal * .recordedVideoModal .modalTitle {
  padding: 0.5rem 0;
  font-size: 1.39rem;
}
.jeeta-app .recordedVideoModal .recordedVideoContent,
.jeeta-app * .recordedVideoModal .recordedVideoContent,
.customModal .recordedVideoModal .recordedVideoContent,
.customModal * .recordedVideoModal .recordedVideoContent {
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 1rem;
}
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoCheck,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoCheck,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoCheck,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoCheck {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  color: var(--PrimaryColor);
}
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoCheck .title,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoCheck .title,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoCheck .title,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoCheck .title {
  font-size: 0.89rem;
  font-weight: 600;
}
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoCheck input,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoCheck input,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoCheck input,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoCheck input {
  transform: scale(1.2);
}
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdown,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdown,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdown,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdown,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdown,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdown,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdown,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdown,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdown,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdown,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdown,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdown,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdown,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdown,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdown,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdown,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple {
  width: 100%;
  font-size: 0.83rem;
}
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric {
  max-width: 180px;
  overflow: hidden;
}
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .title,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .title,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .title,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .title,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .title,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .title,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .title,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .title,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .title,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .title,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .title,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .title,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .title,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .title,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .title,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .title {
  min-width: 5rem;
  font-size: 0.89rem;
  font-weight: 600;
}
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .text,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .text,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .text,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .text,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .text,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .text,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .text,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .text,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .text,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .text,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .text,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .text,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .text,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .text,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .text,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput .text {
  width: 100%;
  height: auto;
  font-size: 0.83rem;
  padding: 0.5rem;
  color: var(--textColor);
  border: hsl(0, 0%, 80%) solid 1px;
  border-radius: 0.3rem;
  line-height: 1.3;
}
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput #add-btn,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput #add-btn,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput #add-btn,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput #add-btn {
  display: flex;
  justify-content: center;
  border: none;
  background-color: var(--SecondaryColor);
  color: var(--whiteColor);
  padding: 0.2rem;
}
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn:hover,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn:hover,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn:hover,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput #add-btn:hover,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn:hover,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn:hover,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn:hover,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput #add-btn:hover,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn:hover,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn:hover,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn:hover,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput #add-btn:hover,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn:hover,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn:hover,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn:hover,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput #add-btn:hover {
  cursor: pointer;
  background-color: var(--PrimaryColor);
  transition: 0.3s;
}
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn .icon,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn .icon,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn .icon,
.jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTextInput #add-btn .icon,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn .icon,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn .icon,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn .icon,
.jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput #add-btn .icon,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn .icon,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn .icon,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn .icon,
.customModal .recordedVideoModal .recordedVideoContent .recordedVideoTextInput #add-btn .icon,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn .icon,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn .icon,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn .icon,
.customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTextInput #add-btn .icon {
  align-self: center;
  font-weight: 600;
  font-size: 1.22rem;
}
.jeeta-app .recordedVideoModal .btn,
.jeeta-app * .recordedVideoModal .btn,
.customModal .recordedVideoModal .btn,
.customModal * .recordedVideoModal .btn {
  color: var(--whiteColor);
  font-weight: 600;
  justify-content: center;
  gap: 0.5rem;
}
.jeeta-app .recordedVideoModal .btn .icon,
.jeeta-app * .recordedVideoModal .btn .icon,
.customModal .recordedVideoModal .btn .icon,
.customModal * .recordedVideoModal .btn .icon {
  align-self: center;
  font-size: 1rem;
}
.jeeta-app .recordedVideoModal #submit,
.jeeta-app * .recordedVideoModal #submit,
.customModal .recordedVideoModal #submit,
.customModal * .recordedVideoModal #submit {
  margin-bottom: 1rem;
}
.jeeta-app .recordedVideoModal #close,
.jeeta-app * .recordedVideoModal #close,
.customModal .recordedVideoModal #close,
.customModal * .recordedVideoModal #close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: var(--PrimaryColor);
  align-self: center;
  font-size: 1.67rem;
}
.jeeta-app .recordedVideoModal #close:hover,
.jeeta-app * .recordedVideoModal #close:hover,
.customModal .recordedVideoModal #close:hover,
.customModal * .recordedVideoModal #close:hover {
  color: var(--tiger-lilly);
}
@media all and (max-width: 860px) {
  .jeeta-app .recordedVideoModal,
  .jeeta-app * .recordedVideoModal,
  .customModal .recordedVideoModal,
  .customModal * .recordedVideoModal {
    max-width: 650px;
    /* or your desired width */
    gap: 0.5rem;
  }
  .jeeta-app .recordedVideoModal .modalTitle,
  .jeeta-app * .recordedVideoModal .modalTitle,
  .customModal .recordedVideoModal .modalTitle,
  .customModal * .recordedVideoModal .modalTitle {
    margin: 0.5rem 0;
    font-size: 23px;
  }
  .jeeta-app .recordedVideoModal .recordedVideoContent,
  .jeeta-app * .recordedVideoModal .recordedVideoContent,
  .customModal .recordedVideoModal .recordedVideoContent,
  .customModal * .recordedVideoModal .recordedVideoContent {
    gap: 1rem;
    padding: 0 0.5rem;
  }
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput {
    flex-direction: column;
    gap: 1rem;
  }
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .title,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .title,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .title,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .title,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .title,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .title,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .title,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .title {
    width: 100px;
    font-size: 0.83rem;
  }
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdown,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdown,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdown,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdown,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple {
    font-size: 0.83rem;
  }
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn .icon,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn .icon,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn .icon,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn .icon {
    font-size: 21px;
  }
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput {
    gap: 1rem;
  }
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .title,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .title,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .title,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .title,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .title,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .title,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .title,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .title,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .title,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .title,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .title,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .title,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .title,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .title,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .title,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .title {
    width: 100px;
    font-size: 0.83rem;
  }
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdown,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdown,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdown,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdown,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdown,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdown,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdown,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdown,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdown .selectionDropdownMultiple,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdown,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput .recordedVideoSelectionDropdownMultiple .selectionDropdownMultiple {
    font-size: 0.83rem;
  }
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn .icon,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn .icon,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn .icon,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn .icon,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn .icon,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn .icon,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn .icon,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn .icon {
    font-size: 21px;
  }
  .jeeta-app .recordedVideoModal .btn,
  .jeeta-app * .recordedVideoModal .btn,
  .customModal .recordedVideoModal .btn,
  .customModal * .recordedVideoModal .btn {
    color: var(--whiteColor);
    font-weight: 600;
    justify-content: center;
    gap: 0.5rem;
  }
  .jeeta-app .recordedVideoModal .btn .icon,
  .jeeta-app * .recordedVideoModal .btn .icon,
  .customModal .recordedVideoModal .btn .icon,
  .customModal * .recordedVideoModal .btn .icon {
    align-self: center;
    font-size: 1rem;
  }
  .jeeta-app .recordedVideoModal #submit,
  .jeeta-app * .recordedVideoModal #submit,
  .customModal .recordedVideoModal #submit,
  .customModal * .recordedVideoModal #submit {
    margin-bottom: 1rem;
  }
  .jeeta-app .recordedVideoModal #close,
  .jeeta-app * .recordedVideoModal #close,
  .customModal .recordedVideoModal #close,
  .customModal * .recordedVideoModal #close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--PrimaryColor);
    align-self: center;
    font-size: 1.67rem;
  }
  .jeeta-app .recordedVideoModal #close:hover,
  .jeeta-app * .recordedVideoModal #close:hover,
  .customModal .recordedVideoModal #close:hover,
  .customModal * .recordedVideoModal #close:hover {
    color: var(--tiger-lilly);
  }
}
@media all and (max-width: 500px) {
  .jeeta-app .recordedVideoModal,
  .jeeta-app * .recordedVideoModal,
  .customModal .recordedVideoModal,
  .customModal * .recordedVideoModal {
    width: 90vw;
  }
  .jeeta-app .recordedVideoModal .modalTitle,
  .jeeta-app * .recordedVideoModal .modalTitle,
  .customModal .recordedVideoModal .modalTitle,
  .customModal * .recordedVideoModal .modalTitle {
    font-size: 1.11rem;
  }
  .jeeta-app .recordedVideoModal .recordedVideoContent,
  .jeeta-app * .recordedVideoModal .recordedVideoContent,
  .customModal .recordedVideoModal .recordedVideoContent,
  .customModal * .recordedVideoModal .recordedVideoContent {
    gap: 0.5rem;
    padding: 0;
  }
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput {
    gap: 0.5rem;
  }
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn .icon,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn .icon,
  .jeeta-app .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn .icon,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn .icon,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn .icon,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn .icon,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn .icon,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn .icon,
  .customModal .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn .icon,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoInfoInput #add-btn .icon,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTopicInput #add-btn .icon,
  .customModal * .recordedVideoModal .recordedVideoContent .recordedVideoTagInput #add-btn .icon {
    font-size: 1.11rem;
  }
  .jeeta-app .recordedVideoModal .recordedVideoContent .add-recordedVideo-btns,
  .jeeta-app * .recordedVideoModal .recordedVideoContent .add-recordedVideo-btns,
  .customModal .recordedVideoModal .recordedVideoContent .add-recordedVideo-btns,
  .customModal * .recordedVideoModal .recordedVideoContent .add-recordedVideo-btns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem !important;
  }
}/*# sourceMappingURL=RecordedVideoCreate.css.map */