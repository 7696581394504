.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.slotView {
    width: 100%;
    min-height: 60vh;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    color: var(--textColor);
    animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */

    
    .btn {
        font-weight: 600;
        padding: .6rem 1.1rem !important;
    }

    #close {
        position: absolute;
        top: 0.3rem;
        right: 0.3rem;
        color: var(--PrimaryColor);
        align-self: center;

        &:hover {
            color: var(--tiger-lilly);
        }
    }

    .LecturePIN {
        margin: .5rem;
        transform: scale(1.4);
    }
}

.slotView-header{
    position: relative;
    width: 100%;
    padding: .5rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background: var(--gradientBkg);
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

    .title {
        color: var(--whiteColor);
        text-shadow: 0 2px 4px rgba(140, 140, 141, 0.3);
        text-align: center;
        gap: 1rem;
    }
}

.slotView-glass {
    flex-grow: 1;
    display: flex;
    width: 100%;
    padding: 1rem 0;
    background: var(--whiteColor);
    border-radius: 2rem;
    gap: 1rem;
    grid-template-columns: 11rem auto;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}

.slotView-main {
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 85%;
    padding: 1rem;
    gap: 2rem;
}

.slotView-topic{
    color: var(--PrimaryColor);
}

.slotLectureVideo,
.slotLectureSummary {
    padding-top: .5rem;
    position: relative;
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .videoPlayer {
        position: relative;
        width: 100%;
        height: 100%;
        // min-height: 25rem;
        // border: 2px solid var(--PrimaryBkg);
        background-color: var(--whiteColorDeam);
        border-radius: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        overflow: hidden;
        // padding: .5rem;

        .no-video-msg,
        .end-recording-msg {
            font-size: 1.2rem;
            font-weight: 600;
            color: var(--tiger-lilly);
        }

        .live-class-msg{
            font-size: 1.2rem;
            font-weight: 600;
            color: var(--PrimaryColor);
        }

        #record {
            transform: scale(1.2);

            &:hover {
                background-color: var(--tiger-lilly);
            }
        }
    }
}

.slotView-info {
    width: auto;
    display: grid;
    position: relative;
    margin-top: 1rem;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem 1.5rem;

    .slotView-infoItem,
    .slotInput,
    .slotSelectionDropdown,
    .slotSelectionDropdownMultiple {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: .5rem;
        // padding: 1rem .5rem;
        text-align: start;
        overflow: visible;

        .title {
            font-weight: 600;
            overflow: visible;
        }

        .selectionDropdown,
        .selectionDropdownMultiple {
            flex-grow: 1;
            font-size: 1rem;
        }

        .inputDate,
        .inputTime {
            flex-grow: 1;
            font-size: 1rem;
            overflow: visible;
            padding: .3rem;
            border-radius: .3rem;
            border: 1px solid hsl(0, 0%, 80%);
        }
    }

    #batches{
        grid-column: span 3;
    }
}

.btns {
    width: 100%;
    justify-content: center;
    gap: 1rem;
}

#back,
#edit {
    background-color: var(--SecondaryColor);
}

#delete {
    background-color: var(--tiger-lilly);
}

.slotScheduleEdit {
    // width: 95%;
    margin: 0 .5rem;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 1rem;
    background-color: var(--PrimaryBkg);
    border: none;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

    .scheduleEditTitle {
        color: var(--whiteColor);
        font-weight: 700;
    }

    .scheduleDates {
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .slotInput {
            padding: 0;
        }
    }

    .btns {
        width: auto;
        gap: .5rem;
    }
}

.confirmPopup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--whiteColorDeam);
    padding: 2.5rem 3rem 1rem 3rem;
    border-radius: 1rem;
    box-shadow: 0px 0px 10px rgba(189, 195, 199, 0.5);
    z-index: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .radio-group {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: .5rem;

        .radio-option {
            padding: .5rem;
            font-size: 1rem;
            font-weight: 500;
            flex-direction: row;
            align-items: center;
            gap: .2rem;
        }
    }
}

.slotPinAuthModal {
    background-color: var(--whiteColorDeam);
    padding: 2rem;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .modalTitle {
        padding: 1rem;
    }

    .pinInput {
        width: 80%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .5rem;
    }

    .lectureInfoInput {
        width: 80%;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .infoLabel {
            font-weight: 600;
        }

        .textInput {
            padding: .5rem;
            border: none;
        }
    }

    #enterPIN {
        margin-top: 1rem;
        font-weight: 600;
        transform: scale(1.1);
    }

    #close {
        position: absolute;
        top: .3rem;
        right: .3rem;
        color: var(--PrimaryColor);
        align-self: center;

        &:hover {
            color: var(--tiger-lilly);
        }
    }
}

.slotLiveDoubts {
    padding-top: .5rem;
    position: relative;
    width: 95%;
    height: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.doubtsContainer{
    position: relative;
    width: 100%;
    height: 100%;
    // border: 2px solid var(--PrimaryBkg);
    background-color: var(--whiteColorDeam);
    border-radius: 1rem;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    min-height: 25rem;

    .title{
        color: var(--textColor);
    }
    
    .no-live-doubts-msg{
        font-size: 1.2rem;
        font-weight: 600;
        color: var(--tiger-lilly);
    }
}

#doubtsRefresh{
    transform: scale(1.1);
}

.doubtsList {
    padding: .5rem 0;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    .doubtItem {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: var(--whiteColor);
        background-color: var(--PrimaryBkg);
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .doubt-text{
            color: var(--textColor);
            font-weight: 600;
        }

        .time{
            align-self: flex-end;
        }
    }

    .doubtItem.even{
        background-color: var(--SecondaryBkg);
    }
}

.doubtClusterList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.newDoubtForm {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .5rem 0;
    gap: 1rem;

    .newDoubtInput {
        width: 100%;
        font-size: 1rem;
        // flex-grow: 1;
        padding: 0.5rem;
        border-radius: 0.5rem;
        border: 1px solid var(--SecondaryColor);
    }
    .submitDoubtBtn {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        border: none;
        background-color: var(--PrimaryColor);
        color: var(--whiteColor);
        cursor: pointer;
        &:hover {
            background-color: var(--SecondaryColor);
        }
    }
}

.chapters-table-container {
    margin-top: 1.2rem;
}

.chapters-table {
    width: 100%;
    border-collapse: collapse;
}

.chapters-table th,
.chapters-table td {
    border: 1px solid #ddd;
    padding: .5rem;
    text-align: left;
}

.chapters-table th {
    background-color: #f2f2f2;
}

/* Add styles for hovering over table rows */
.chapters-table tbody tr:hover {
    background-color: #eaeaea;
    /* Light grey background on hover */
    cursor: pointer;
    /* Change cursor to indicate clickability */
}

.video-chapter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#meetingSDKElement {
    height: 100%;
    width: 100%;
    z-index: 10;
}


// .css-1vu2yqv, .css-1nwgrc8, .css-d2sft1 {
//     width: 100%;
//     height:100%
// }

// .css-ulr84r {
//     height: 70%;
// }

.MuiPopper-root {
        position: fixed !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        z-index: 9999;
        /* Ensure it stays on top */
}

.slotQuestions {
    padding-top: .5rem;
    position: relative;
    width: 95%;
    height: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
        flex-direction: column;
        align-items: center;
    }
    .slotQuestionSlider {
        position: relative;
        width: 95%;
        min-height: 13rem;
        background: var(--cardBG);
        border-radius: 1rem;
        box-shadow: 0 2px 4px rgba(140, 140, 141, .549);
        padding: 0 .5rem;
    }
}


//MEDIA QUERIES ==================================================>
@media all and (min-width: 1200px) {
    .slotView-info{
        gap: 1.5rem 3rem;
    }
}

@media all and (max-width: 1000px) {
    .slotView {

        .LecturePIN {
            transform: scale(0.95);
        }
    }

    .doubtClusterList{
        grid-template-columns: repeat(3, 1fr);
        gap: .5rem;
    }

    .chapters-table-wrapper{
        max-height: 35rem;
    }
}

@media all and (max-width: 860px) {
    .slotView {

        .LecturePIN {
            transform: scale(0.9);
        }
    }

    .slotView-main {
        padding: 1rem 0;

        .title {
            transform: scale(0.9);
        }
    }

    .doubtsContainer{
        min-height: auto;
    }

    .chapters-table-wrapper{
        max-height: 30rem;
    }

    .slotView-info {
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }
}

@media all and (max-width: 800px) {
    .slotView {
        padding: .5rem;
        gap: .5rem;
    }

    .slotView-header {
        margin: .5rem 0;
        padding: .5rem 0;
    }

    .slotView-glass {
        grid-template-columns: 3rem auto;
    }
}

@media all and (max-width: 720px) {
    .slotView {

        .LecturePIN {
            transform: scale(0.8);
        }
    }

    .slotView-glass {
        padding: 0;
        gap: 0;
        font-size: 1rem;
    }

    .slotView-info {
        width: 80%;
        grid-template-columns: repeat(2, 1fr);
        gap: .5rem 1rem;

        #batches{
            grid-column: span 2;
        }
    }

    .slotLectureVideo,
    .slotLectureSummary {

        .videoPlayer {
            min-height: 300px;

            .no-video-msg,
            .live-class-msg,
            .end-recording-msg {
                transform: scale(0.95);
            }

            #record {
                transform: scale(1.1);
            }
        }
    }

    .slotLiveDoubts{
        .doubtsList,
        .newDoubtForm{
            width: 95%;
        }
    }

    .doubtClusterList{
        grid-template-columns: repeat(2, 1fr);
    }

    .slotPinAuthModal {
        padding: 1.5rem;
    }
    .slotQuestions {
        .slotQuestionSlider {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: .5rem;
            padding: 0;
        }
    }
}

@media all and (max-width: 550px) {

    .slotView-header{
        margin: 0;
        padding: .5rem;
        font-size: .9rem;
    }

    .slotView-glass {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap:0;
        padding: 0;

        .btns{

            span{
                display: none;
            }
        }
    }

    .slotView-main {
        max-width: 100%;
        gap: .5rem;
    }

    .slotScheduleEdit {
        flex-direction: column;
        margin: .5rem 0;
        padding: .5rem 1.5rem;
        gap: .5rem;

        .scheduleDates {
            flex-direction: column;
            gap: .5rem;
        }
    }

    .slotView-info {
        width: auto;
        margin-top: .5rem;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: .5rem;

        .slotView-infoItem,
        .slotInput,
        .slotSelectionDropdown {
            padding: .1rem 0;

            .selectionDropdown,
            .selectionDropdownMultiple,
            .inputDate,
            .inputTime {
                font-size: .95rem;
            }
        }
    }

    .slotLectureVideo,
    .slotLectureSummary {
        
        .videoPlayer {

            .no-video-msg,
            .live-class-msg,
            .end-recording-msg {
                transform: scale(0.9);
            }

            #record {
                transform: scale(1);
            }
        }
    }

    .slotLiveDoubts{
        gap: .5rem;

        #doubtsRefresh{
            transform: scale(.95);
        }
    }
    
    .doubtsContainer{
        min-height: 20rem;

        .title{
            font-size: .9rem; 
        }
    }

    .doubtClusterList{
        display: flex;
        flex-direction: column;
        // gap: .5rem;
    }

    .slotPinAuthModal {
        padding: 1rem;
    }

    .vjs-control {
        width: 7% !important;
    }

    .vjs-time-control {
        width: 10% !important;
        padding: 0 !important;
    }

    .vjs-progress-control {
        min-width: 30% !important;
    }
}
}
