.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.questions,
.questionCardListModal {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0 0 0;
    width: 100%;

    .btn {
        color: var(--whiteColor);
        font-weight: 600;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        white-space: nowrap;

        .icon {
            align-self: center;
            font-size: 1rem;
        }
    }

    #back-btn {
        margin-top: 2rem;
        transform: scale(1.15);
    }
}

.questionCardList {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .message {
        margin: 1rem;
        color: var(--PrimaryColor);
    }

    .questionCards {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // grid-auto-rows: minmax(400px, auto);
        gap: 1rem;
        background: var(--cardBG);
        border-radius: 1rem;
        box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
        padding: 1rem;
        overflow: hidden;
    }

    .change-page-btns {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: .5rem;

        .left-btn,
        .right-btn,
        .page-btns {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.85rem;
            /* Circle size */
            height: 1.85rem;
            /* Circle size */
            border-radius: 50%;
            /* Makes the button circular */
            border: none;
            /* Border styling */
            color: var(--whiteColor);
            /* Text color */
            font-size: .78rem;
            /* Adjust the font size as needed */
            outline: none;

            &:hover {
                cursor: pointer;
                background-color: var(--PrimaryColor);
                /* Change background color on hover */
            }
        }

        .left-btn,
        .right-btn {
            background-color: transparent;
            color: var(--PrimaryColor);

            &:hover {
                background-color: var(--cardBG);
                color: var(--SecondaryColor);
            }
        }

        .not-current-page-btn {
            background-color: var(--SecondaryColor);
        }

        .current-page-btn {
            background-color: var(--PrimaryColor);
        }
    }
}

.questionCardListModal {
    width: 80vw;
    /* or your desired width */
    max-height: 80vh;
    /* or your desired height */
    overflow-y: auto;
    /* display scrollbar only when necessary */
    background-color: var(--whiteColorDeam);
    position: fixed;
    /* make the modal appear above other content */
    top: 50%;
    /* centering the modal vertically */
    left: 50%;
    /* centering the modal horizontally */
    transform: translate(-50%, -50%);
    /* necessary adjustments for centering */
    // z-index: 1000;
    /* high value to ensure modal is on top */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem 1rem;


    #close {
        position: absolute;
        top: 5px;
        right: 5px;
        color: var(--PrimaryColor);
        align-self: center;
        font-size: 30px;
    }
}

//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {}

@media all and (max-width: 1200px) {

    .questionCardList {
        // gap: 1rem;

        .message {
            margin: .5rem;
        }

        .questionCards {
            grid-template-columns: repeat(2, 1fr);
            // grid-auto-rows: minmax(330px, auto);
        }
    }
}

@media all and (max-width: 1000px) {

    .questions,
    .questionCardListModal {
    }

    .questionCardListModal {
        width: 90%;
    }
}

@media all and (max-width: 720px) {

    .questions,
    .questionCardListModal {

        #back-btn {
            margin-top: 1.5rem;
            transform: scale(1.05);
        }

        .questionCardList {

            .message {
                margin: .5rem;
            }

            .questionCards {
                display: flex;
                flex-direction: column;
                gap: .5rem;
                min-height: 250px;
            }

            .change-page-btns {
                gap: .3rem;

                .btn {
                    font-size: 0.72rem;
                    margin: 0;
                }

                .page-btns {
                    width: 1.22rem;
                    height: 1.22rem;
                    font-size: 0.72rem;
                }
            }
        }
    }

    .questionCardListModal {
        width: 90%;
        /* or your desired width */
        max-height: 90vh;
        /* or your desired height */
        overflow-y: auto;
        /* display scrollbar only when necessary */
        background-color: var(--whiteColorDeam);
        padding: 1.5rem 1rem;
        gap: .5rem;
    }
}

@media all and (max-width: 550px) {
    .questions {
        gap: .5rem;
        padding: .5rem;

        .btn {
            .icon {
                font-size: 15px;
            }
        }

        .questionCardList {
            // width: 100%;
            padding: .5rem;

            .questionCards {
                padding: .5rem;
                gap: .5rem;
                min-height: 6rem;
            }
        }
    }
}
}