.dashboard-sidebar {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 1rem;
    transition: all 300ms ease;
}

/* logo */
// .dashboard-bars {
//     display: none;
// }

.dashboard-logo {
    display: flex;
    flex-direction: column;
    // height: 5rem;
    font-weight: bold;
    font-size: 1.5rem;
    // gap: 1rem;
    align-items: center;
    justify-content: center;
    // height: 4%;

    span {
        color: var(--PrimaryColor);

        span {
            color: var(--SecondaryColor);
        }
    }

    img {
        width: 70%;
        height: auto;
    }
}

/* menu */
.dashboard-menu {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .menuItem {
        display: flex;
        align-items: center;
        gap: .7rem;
        height: 2.5rem;
        margin-left: 1rem;
        position: relative;
        transition: all 300ms ease;
        border-radius: 0.7rem;
        font-size: 1rem;

        span{
            white-space: nowrap;
        }

        &:hover {
            cursor: pointer;
        }

        // &:last-child {
        //     position: absolute;
        //     bottom: 2.3rem;
        //     width: 100%;
        // }
    }

    .active {
        background: var(--SecondaryBkg);
        margin-left: 0;

        &::before {
            content: "";
            width: 8px;
            height: 100%;
            background: var(--SecondaryColor);
            // margin-right: calc(1rem - 8px);
        }
    }

}


@media all and (max-width: 800px) {
    .dashboard-sidebar {
        max-width: 3rem;
        padding-top: 5rem;
    }


    .dashboard-logo {

        span{
            transform: rotate(90deg);
        }

        img{
            display: none;
        }
    }

    .dashboard-menu {
        margin-top: 5rem;
        gap: 0;

        .menuItem{
            span{
                display: none;
            }
        }
    }
}


@media all and (max-width: 550px) {

    .dashboard-sidebar {
        width: 100%;
        max-width: none;
        flex-direction: row;
        padding: .5rem 0 .5rem 1.5rem;
        gap: 1rem;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%; /* Full width of the container */
            height: 2px; /* Thickness of the bottom line */
            background: var(--gradientBkg);
        }
    }


    .dashboard-logo {

        span{
            // display: none;
            transform: none;
        }

        img{
            display: none;
        }
    }

    .dashboard-menu {
        flex-direction: row;
        margin-top: 0;
        gap: .5rem;
        color: var(--PrimaryColor);

        .menuItem{
            margin-left: 0;
            padding: .5rem;
            gap: .2rem;

            .icon{
                font-size: 1.25rem;
            }
        }

        .active {
            background: transparent;
            color: var(--SecondaryColor);
            font-weight: 500;
            // padding: 0 .5rem;
            // background-color: var(--SecondaryColor);
            
            span{
                display: block;
            }

            &::before {
                content: "";
                width: 0; /* Full width of the container */
                height: 0; /* Thickness of the bottom line */
                background-color: transparent;
            }
        }
    }
}