.jeeta-app,
.jeeta-app *,
.customModal,
.customModal * {
    .recordedVideoModal {
        position: absolute;
        /* make the modal appear above other content */
        top: 50vh;
        /* centering the modal vertically */
        left: 50vw;
        /* centering the modal horizontally */
        transform: translate(-50%, -50%);
        /* necessary adjustments for centering */
        z-index: 1000;
        /* high value to ensure modal is on top */
        width: 80vw;
        // max-width: 1000px;           /* or your desired width */
        height: 90vh;
        /* or your desired height */
        overflow-y: auto;
        /* display scrollbar only when necessary */
        background-color: var(--whiteColorDeam);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: .5rem;
        padding: 1.5rem 1rem;
        color: var(--textColor);
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        gap: 1rem;

        .modalTitle {
            padding: .5rem 0;
            font-size: 1.39rem;
        }

        .recordedVideoContent {
            position: relative;
            width: 100%;
            flex-direction: column;
            gap: 1.5rem;
            padding: 0 1rem;

            .recordedVideoCheck {
                width: 100%;
                flex-direction: row;
                justify-content: center;
                gap: .5rem;
                color: var(--PrimaryColor);

                .title {
                    font-size: 0.89rem;
                    font-weight: 600;
                }

                input {
                    transform: scale(1.2);
                }
            }

            .recordedVideoInfoInput,
            .recordedVideoTopicInput,
            .recordedVideoTagInput,
            .recordedVideoTextInput {
                width: 100%;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 1.5rem;

                .recordedVideoSelectionDropdown,
                .recordedVideoSelectionDropdownMultiple {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .5rem;

                    .selectionDropdown,
                    .selectionDropdownMultiple {
                        width: 100%;
                        font-size: 0.83rem;

                        .css-wsp0cs-MultiValueGeneric {
                            max-width: 180px;
                            overflow: hidden;
                        }
                    }
                }

                .title {
                    min-width: 5rem;
                    font-size: 0.89rem;
                    font-weight: 600;
                }

                .text {
                    width: 100%;
                    height: auto;
                    font-size: 0.83rem;
                    padding: .5rem;
                    color: var(--textColor);
                    border: hsl(0, 0%, 80%) solid 1px;
                    border-radius: .3rem;
                    line-height: 1.3;
                }

                #add-btn {
                    display: flex;
                    justify-content: center;
                    border: none;
                    background-color: var(--SecondaryColor);
                    color: var(--whiteColor);
                    padding: .2rem;

                    &:hover {
                        cursor: pointer;
                        background-color: var(--PrimaryColor);
                        transition: .3s;
                    }

                    .icon {
                        align-self: center;
                        font-weight: 600;
                        font-size: 1.22rem;
                    }
                }
            }
        }

        .btn {
            color: var(--whiteColor);
            font-weight: 600;
            justify-content: center;
            gap: .5rem;

            .icon {
                align-self: center;
                font-size: 1rem;
            }
        }

        #submit {
            margin-bottom: 1rem;
        }

        #close {
            position: absolute;
            top: 5px;
            right: 5px;
            color: var(--PrimaryColor);
            align-self: center;
            font-size: 1.67rem;

            &:hover {
                color: var(--tiger-lilly);
            }
        }
    }


    //MEDIA QUERIES ==================================================>
    @media all and (min-width: 1800px) {}

    @media all and (max-width: 860px) {
        .recordedVideoModal {
            max-width: 650px;
            /* or your desired width */
            gap: 0.5rem;

            .modalTitle {
                margin: .5rem 0;
                font-size: 23px;
            }

            .recordedVideoContent {
                gap: 1rem;
                padding: 0 .5rem;

                .recordedVideoInfoInput {
                    flex-direction: column;
                    gap: 1rem;

                    .recordedVideoSelectionDropdown,
                    .recordedVideoSelectionDropdownMultiple {

                        .title {
                            width: 100px;
                            font-size: 0.83rem;
                        }

                        .selectionDropdown,
                        .selectionDropdownMultiple {
                            font-size: 0.83rem;
                        }
                    }

                    #add-btn {
                        .icon {
                            font-size: 21px;
                        }
                    }
                }

                .recordedVideoTopicInput,
                .recordedVideoTagInput {
                    gap: 1rem;

                    .recordedVideoSelectionDropdown,
                    .recordedVideoSelectionDropdownMultiple {

                        .title {
                            width: 100px;
                            font-size: 0.83rem;
                        }

                        .selectionDropdown,
                        .selectionDropdownMultiple {
                            font-size: 0.83rem;
                        }
                    }

                    #add-btn {
                        .icon {
                            font-size: 21px;
                        }
                    }
                }
            }

            .btn {
                color: var(--whiteColor);
                font-weight: 600;
                justify-content: center;
                gap: .5rem;

                .icon {
                    align-self: center;
                    font-size: 1rem;
                }
            }

            #submit {
                margin-bottom: 1rem;
            }

            #close {
                position: absolute;
                top: 5px;
                right: 5px;
                color: var(--PrimaryColor);
                align-self: center;
                font-size: 1.67rem;

                &:hover {
                    color: var(--tiger-lilly);
                }
            }
        }
    }

    @media all and (max-width: 500px) {
        .recordedVideoModal {
            width: 90vw;

            .modalTitle {
                font-size: 1.11rem;
            }

            .recordedVideoContent {
                gap: .5rem;
                padding: 0;

                .recordedVideoInfoInput,
                .recordedVideoTopicInput,
                .recordedVideoTagInput {
                    gap: .5rem;

                    #add-btn {
                        .icon {
                            font-size: 1.11rem;
                        }
                    }
                }

                .add-recordedVideo-btns {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem !important;
                }
            }
        }
    }
}