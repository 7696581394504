.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
    .slot-card {
    width: 100%;
    min-height: 20rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: .5rem;
    // padding: .5rem;
    // gap: 1rem;
    font-size: 1rem;

    &:hover{
        cursor: pointer;
    }

    .bkg-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: .5rem;
        background-color: rgba(255, 255, 255, 0.3);
        // z-index: 0;
    }

    .bkg-cover.CURRENT {
        z-index: 0;
    }

    .bkg-cover.FINISHED {
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 2;
    }

    .classroom,
    .subject,
    .time {
        position: relative;
        text-align: center;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.15);
        padding: .1rem;
        color: var(--whiteColor);
        z-index: 1;
    }

    .date {
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--PrimaryColor);
        padding: .3rem .5rem;
        border-radius: .5rem;
        border: none;
        color: var(--whiteColor);
        // z-index: 3;
        font-size: 1rem;
        font-weight: 500;
    }

    .slot-status {
        position: absolute;
        top: 0;
        right: 0;
        padding: .3rem .5rem;
        border-radius: .5rem;
        border: none;
        color: var(--whiteColor);
        z-index: 3;
        font-size: 1rem;
        font-weight: 500;
    }

    .slot-status.FINISHED{
        background-color: var(--finished);
    }

    .slot-status.CURRENT{
        background-color: var(--started);
    }

    .subject.Mathematics {
        background-color: var(--Mathematics);
        opacity: .9;
        font-weight: 600;
    }

    .subject.Physics {
        background-color: var(--Physics);
        opacity: .9;
        font-weight: 600;
    }

    .subject.Chemistry {
        background-color: var(--Chemistry);
        opacity: .9;
        font-weight: 600;
    }

    .matchingSegment{
        width: 95%;
        max-height: 2.4rem;
        position: absolute;
        bottom: .5rem;
        left: 50%;
        transform: translateX(-50%);
        padding: .2rem;
        text-align: center;
        color: var(--textColor);
        background: var(--glass);
        border-radius: .5rem;
        font-size: .95rem;
        line-height: 1.1;
        overflow-y: scroll;
        scroll-behavior: smooth;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
            /* for Chrome, Safari, and Opera */
        }
    }
}

.slot-card.Mathematics {
    background-image: url('../../../Assets/Mathematics.jpg');
}

.slot-card.Physics {
    background-image: url('../../../Assets/Physics.jpg');
}

.slot-card.Chemistry {
    background-image: url('../../../Assets/Chemistry.jpg');
}

.slotCardList {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */

    .message {
        margin: 1rem;
        color: var(--PrimaryColor);
    }

    .slotCards {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // grid-auto-rows: minmax(400px, auto);
        gap: 1rem;
        background: var(--cardBG);
        border-radius: 1rem;
        box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
        padding: 1rem;
    }

    .change-page-btns {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: .5rem;

        .left-btn,
        .right-btn,
        .page-btns {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.85rem;
            /* Circle size */
            height: 1.85rem;
            /* Circle size */
            border-radius: 50%;
            /* Makes the button circular */
            border: none;
            /* Border styling */
            color: var(--whiteColor);
            /* Text color */
            font-size: .78rem;
            /* Adjust the font size as needed */
            outline: none;

            &:hover {
                cursor: pointer;
                background-color: var(--PrimaryColor);
                /* Change background color on hover */
            }
        }

        .left-btn,
        .right-btn {
            background-color: transparent;
            color: var(--PrimaryColor);

            &:hover {
                background-color: var(--cardBG);
                color: var(--SecondaryColor);
            }
        }

        .not-current-page-btn {
            background-color: var(--SecondaryColor);
        }

        .current-page-btn {
            background-color: var(--PrimaryColor);
        }
    }
}

.slotCardListFilter {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border-radius: 1rem;
    z-index: 1;
    animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */

    .filterTitle {
        width: 100%;
        // padding: 1rem;
        border-radius: 1rem;
        // background: var(--gradientBkg);
        // box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
        flex-direction: column;
        gap: .5rem;

        .title {
            // margin-top: 1rem;
            // font-size: 1.66rem;
            text-align: center;
            color: var(--SecondaryColor);
        }

        span {
            font-size: 1.5rem;
            text-align: center;
            font-weight: 600;
            color: var(--blackColor);
            opacity: .8;
        }
    }

    .filters {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // place-items: center;
        padding: 0 .5rem;
        gap: .5rem;

        .searchTextInput {
            display: flex;
            position: relative;
            width: 100%;
            flex-direction: row;
            float: left;
            justify-content: flex-start;
            align-items: center;
            padding: .5rem;
            gap: .5rem;

            .title {
                padding: .35rem .5rem;
                // min-width: 5rem;
                font-weight: 600;
                font-size: 1rem;
                white-space: nowrap;
                color: var(--textColor);
            }
            input {
                flex-grow: 1;
                border: hsl(0, 0%, 80%) solid 1px;
                border-radius: .3rem;
                padding: .5rem;
                font-size: .95rem;
            }
        }

        .searchTextInput {
            grid-column: span 2;
        }
    }

    .btns {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
}

//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {}

@media all and (max-width: 1200px) {
    .slot-card {
        min-height: 17.5rem;
    }

    .slotCardList{
        .slotCards {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media all and (max-width: 860px) {
    .slot-card {
        min-height: 15rem;
        font-size: .95rem;

        .matchingSegment{
            font-size: .85rem;
        }
    }
}

@media all and (max-width: 720px) {
    .slot-card {
        min-height: 12.5rem;

        .matchingSegment{
            font-size: .8rem;
        }
    }

    .slotCardListFilter{

        .filterTitle{
            span{
                font-size: 1.35rem;
            }
        }
        .filters {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .5rem;
        }
    }

    .slotCardList{

        // .slotCards{
        //     display: flex;
        //     flex-direction: column;
        //     gap: .5rem;
        //     min-height: 250px;
        // }

        .change-page-btns {
            gap: .3rem;

            .btn {
                font-size: 0.72rem;
                margin: 0;
            }

            .page-btns {
                width: 1.22rem;
                height: 1.22rem;
                font-size: 0.72rem;
            }
        }
    }
}

@media all and (max-width: 610px) {
    .slot-card {
        font-size: .9rem;
    }

    slotCardListFilter{

        .filterTitle{
            span{
                font-size: 1.3rem;
            }
        }
    }
}

@media all and (max-width: 550px) {
    .slotCardListFilter{

        .filterTitle{
            span{
                font-size: 1.2rem;
            }
        }

        .filters {
            padding: .5rem .5rem 0 .5rem;

            .searchTextInput {
                padding: 0;

                .title {
                    font-size: .9rem;
                }
                input {
                    font-size: .9rem;

                    input,
                    .css-13cymwt-control {
                        font-size: .9rem;
                    }
                }
            }
        }

        
    }

    .slotCardList{
        padding: .5rem;

        .slotCards {
            display: flex;
            flex-direction: column;
            padding: .5rem;
            gap: .5rem;
            min-height: 6rem;
        }
    }

    .slot-card{
        .date{
            font-size: .9rem;
        }
    }
}
}