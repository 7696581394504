.jeeta-app, .jeeta-app * {

.analytics {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    z-index: 200;
    /* padding: 0.5rem 3.5rem; */
    color: var(--black);
    animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */
    // background: linear-gradient(106.37deg,
    //         #ffe1bc 29.63%,
    //         #ffcfd1 51.55%,
    //         #f3c6f1 90.85%);
    // height: 100vh;
    // font-family: 'Inter', sans-serif;
}

.analytics-title {
    position: relative;
    width: 100%;
    padding: .5rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background: var(--gradientBkg);
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

    .title {
        color: var(--whiteColor);
        text-shadow: 0 2px 4px rgba(140, 140, 141, 0.3);
        text-align: center;
        gap: 1rem;
    }
}

.analysis-glass {
    flex-grow: 1;
    display: grid;
    width: 100%;
    padding: 1rem 0;
    background: var(--whiteColor);
    border-radius: 2rem;
    gap: 1rem;
    grid-template-columns: 11rem auto;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

    .analysis-main {
        flex-direction: column;
        width: 100%;
        padding: 1rem;
        gap: 1rem;
    }
}

.analysis-cardList {
    position: relative;
    width: 95%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(200px, auto);
    gap: 1rem;
    background: var(--cardBG);
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
    padding: 1rem;
    overflow: hidden;

    .lecture-card {
        width: 100%;
        min-height: 200px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: .5rem;
        // padding: .5rem;
        // gap: 1rem;
        font-size: .9rem;

        .bkg-cover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: .5rem;
            background-color: rgba(255, 255, 255, 0.3);
            // z-index: 0;
        }

        .bkg-cover.CURRENT {
            z-index: 0;
        }

        .bkg-cover.FINISHED {
            background-color: rgba(0, 0, 0, 0.4);
            z-index: 2;
        }

        .classroom,
        .subject,
        .time {
            position: relative;
            text-align: center;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.15);
            padding: .1rem;
            color: var(--whiteColor);
            z-index: 1;
        }

        .subject.Mathematics {
            background-color: var(--Mathematics);
        }

        .subject.Physics {
            background-color: var(--Physics);
        }

        .subject.Chemistry {
            background-color: var(--Chemistry);
        }
    }

    .lecture-card.Mathematics {
        background-image: url('../../Assets/Mathematics.jpg');
    }

    .lecture-card.Physics {
        background-image: url('../../Assets/Physics.jpg');
    }

    .lecture-card.Chemistry {
        background-image: url('../../Assets/Chemistry.jpg');
    }
}

.pentagon-box{
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .radarChart{
        max-width: 1000px;
        justify-content: center;
        align-items: center;
    }
}

.subtitle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .5rem;

    h2{
        color: var(--PrimaryColor);
        text-align: center;
    }
    span{
        color: var(--SecondaryColor);
    }
}

.period-buttons {
    margin: .5rem .5rem .5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: .5rem;
    font-size: .8rem;
}

.period-button {
    padding: .3rem .5rem;
    background-color: var(--PrimaryBkg);
    color: var(--whiteColor);
    border-radius: .5rem;

    &:hover {
        cursor: pointer;
        font-weight: 600;
        background-color: var(--PrimaryColor);
    }
}

.period-button.selected {
    font-weight: 600;
    background-color: var(--PrimaryColor);
}

//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {}

@media all and (max-width: 1000px) {
    .analysisPage {
        .analysis-cardList {
            grid-template-columns: repeat(2, 1fr);
        }

    }

    // .analysis-glass {
    //     // grid-template-columns: 10% 50% auto;
    //     overflow-y: scroll;
    // }
}

@media all and (max-width: 860px) {

    .analysis-glass{
        grid-template-columns: 3rem auto;
    }

    .analysisPage {

        .dashboard-header {
            padding: 1rem 0;

            .title {
                transform: scale(0.9);
            }
        }

        .analysis-cardList {
            display: flex;
            flex-direction: column;
            gap: .5rem;

            .lecture-card {
                width: 100%;
                min-height: 180px;
                font-size: .85rem;
            }
        }
    }
}

@media all and (max-width: 720px) {
    .analysisPage {
        gap: .5rem;
        padding: .5rem;

        .LecturePIN {
            transform: scale(0.8);
        }
    }

    .analytics-title {
            padding: .5rem;

            .title {
            font-size: .95rem;
        }
        }

        .analysis-cardList {
            display: flex;
            flex-direction: column;
            gap: .5rem;

            .lecture-card {
                width: 100%;
                min-height: 150px;
                font-size: .75rem;
            }
        }

    .analysis-glass {
        grid-template-columns: 3rem auto;
        // display: flex;
        // flex-direction: column;
        // width: 100%;
        // padding: 1rem 0;
        // background: var(--whiteColor);
        // border-radius: 2rem;
        // gap: 1rem;
        // grid-template-columns: 11rem auto;
        // overflow: hidden;
        // box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
    }
}

@media all and (max-width: 550px) {
    .analysis-glass{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;
        padding: 0;
    }
}
}