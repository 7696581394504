.jeeta-app,
.jeeta-app *,
.customModal,
.customModal * {
    .loginForm {
    width: 800px;
    max-width: 80vw;
    height: max-content;
    min-height: 180px;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 0;

    .loginInfoInput {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        font-size: 1rem;

        .inputTitle {
            width: 1.5rem;
            text-align: center;
            font-weight: 500;
        }

        .idInput,
        .pwInput {
            align-items: center;
            width: 350px;
            height: 40px;
            padding: 0 1rem;
            background: var(--inputColor);
            border-radius: 3rem;

            input {
                background: none;
                border: none;
                outline: none;
                width: 100%;
                font-size: .9rem;
                font-weight: 500;

                &::placeholder {
                    font-size: .9rem;
                }
            }

            .icon {
                font-size: 1.3rem;
                color: var(--PrimaryColor) !important;
                margin-left: 10px;
            }
        }
    }

    .remember-forgot {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .2rem;

        .remember-checkbox,
        .forgot-btn {
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            font-weight: 400;
            gap: .3rem;

            input {
                cursor: pointer;
                accent-color: var(--tiger-lilly);
            }
        }

        .forgot-btn {
            padding-left: 1.5rem;
            color: var(--tiger-lilly);
            font-weight: 600;
            font-size: .9rem;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }

            .icon {
                font-size: 1.2rem;
            }
        }
    }

    .btn {
        width: max-content;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: .6rem;
        border-radius: 3rem;
        border: none;
        margin: .5rem 0;
        padding: .6rem 2rem;
        background-color: var(--gradientColor);
        color: var(--whiteColor);

        &:hover {
            cursor: pointer;
            background-color: var(--PrimaryColor);
        }

        .icon {
            font-size: 1.2rem;
            font-weight: 500;
        }

        span {
            font-size: 1rem;
            font-weight: 500;
        }
    }
}

.resetPasswordModal {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    z-index: 1000;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    background-color: var(--whiteColorDeam);
    padding: 2rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    .text-box,
    .input-box {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .5rem;
    }

    .email-input {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        font-size: 1rem;
        padding-bottom: .5rem;

        label {
            width: max-content;
            font-weight: 600;
        }

        input {
            width: 300px;
            padding: .2rem .5rem;
            border: none;
            border-radius: .3rem;
        }
    }

    .password-reset-message {
        text-align: center;
        color: var(--textColor);
    }

    #close {
        position: absolute;
        top: .3rem;
        right: .3rem;
        color: var(--PrimaryColor);
        align-self: center;

        &:hover {
            color: var(--tiger-lilly);
        }
    }
}


//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {}

@media all and (max-width: 860px) {
    .loginForm {
        width: 80vw;

        .loginInfoInput,
        .remember-forgot {
            transform: scale(0.95);
        }
    }
}

@media all and (max-width: 500px) {
    .loginForm {
        gap: .5rem;

        .loginInfoInput,
        .remember-forgot {
            transform: scale(0.9);
        }
    }
}
}