.jeeta-app,
.jeeta-app * {
.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: .5rem;
    font-size: 0.8rem;
    background: var(--bodyColor);
    color: var(--SecondaryColor);
    animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */
}

.footer-title {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    // gap: .5rem;
    margin-top: 1rem;
    
    span {
        font-size: 1.1rem;
        font-weight: 400;
        color: var(--SecondaryColor);
    }

    h2 {
        font-size: 1.66rem;
        font-style: italic;
        color: var(--PrimaryColor);
    }
}

.footerDiv {
    position: relative;
    width: 100%;
    // z-index: 1;
    // padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--SecondaryColor);
    font-size: 0.8rem;

    p {
        font-size: .78rem;
    }
}

//MEDIA QUERIES ==================================================>

@media all and (min-width: 1800px) {}

@media all and (max-width: 860px) {
    .footer {

        .contactDiv {
            margin-top: .5rem;
            // flex-direction: column;
            // justify
            gap: 1rem;

            .contactText {
                small {
                    font-size: .78rem;
                }

                h2 {
                    font-size: 1.38rem;
                }
            }

            .contactInput {
                width: 90%;
                gap: .5rem;
                font-size: .83rem;

                input {
                    width: 310px;
                }
            }
        }

        .footerContent {
            width: 95%;
            padding: 1rem;

            .footerCard {
                padding: 1.5rem 1rem 3.5rem 1rem;
                gap: .5rem;

                .footerIntro {
                    row-gap: .3rem;

                    .logoDiv {
                        font-size: .87rem;

                        .icon {
                            font-size: 1.27rem;
                        }
                    }

                    .footerParagraph {
                        font-size: .78rem;
                    }

                }
            }
        }
    }
}

@media all and (max-width: 500px) {
    .footer {
        padding: .5rem .5rem .1rem .5rem;
        gap: .5rem;
    }

    .footer-title{
        transform: scale(.9);
    }

    .footer-feedback{
        width: 95%;
        padding: 1rem .5rem .5rem .5rem;
        gap: .5rem;

        .footer-question {
            font-size: .95rem;
        }

        .footer-answer{
            .answer-input{
                font-size: .9rem;
            }
        }
    }

    .footerDiv {
        font-size: 0.68rem;
    }
}

@media screen and (max-width: 450px) {
    .footerDiv {
        flex-direction: column;
    }
}
}