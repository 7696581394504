.jeeta-app,
.jeeta-app * {
    .doubt-cluster-card {
    background-color: var(--PrimaryBkg);
    border-radius: 1rem;
    padding: 1rem;
    // margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: .3rem;

    &:hover{
        background-color: var(--SecondaryBkg);
        cursor: pointer;
    }

    .doubt-cluster-text {
        // position: fixed;
        text-align: center;
        font-weight: 600;
        font-size: 1.3rem;
        color: var(--whiteColor);
    }

    // Initial state is not expanded
    &.not-expanded {
        justify-content: center;
        max-height: 3rem;
        overflow: hidden;
    }

    // Expanded state
    &.expanded {
        background-color: var(--SecondaryBkg);
        justify-content: flex-start; 
        max-height: 18rem;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: thin;

        &:hover{
            background-color: var(--PrimaryBkg);
        }

        .doubt-cluster-text{
            margin-bottom: .5rem;
        }
    }
}

.doubt-card {
    background: var(--glass);
    border: none;
    border-radius: .5rem;
    padding: .5rem; 

    .doubt-text {
        font-size: .9rem;
        color: var(--textColor);
    }
}

//MEDIA QUERIES ==================================================>
@media all and (min-width: 1200px) {

}

@media all and (max-width: 1000px) {

}

@media all and (max-width: 860px) {
    .doubt-cluster-card {
        .doubt-cluster-text {
            // position: fixed;
            text-align: center;
            font-weight: 600;
            font-size: 1.1rem;
            color: var(--whiteColor);
        }
    }
}


@media all and (max-width: 720px) {

}

@media all and (max-width: 500px) {
    .doubt-cluster-card {
        padding: .5rem;

        .doubt-cluster-text {
            font-size: 1.1rem;
        }
    }
}
}

