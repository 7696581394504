.jeeta-app,
.jeeta-app *,
.customModal,
.customModal * {
.pdfInputContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;

}

.pdfInput {
    width: 100%;
    justify-content: center;
    align-items: center;

    .dropzone {
        width: 100%;
        height: 6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: .5rem;
        border: 2px dashed var(--PrimaryBkg);
        border-radius: .25rem;
        padding: 1rem;
        text-align: center;
        cursor: pointer;
        transition: border 0.2s ease-in-out;

        .drag-n-drop-msg {
            font-size: .95rem;
            color: var(--PrimaryColor);
            white-space: auto;
            font-weight: 600;
        }
    }

    .dropzone.active {
        border-color: var(--PrimaryColor);
    }

    .upload-pdf-number {
        width: 100%;
        padding: .3rem 1rem;
        text-align: center;
        font-weight: 600;
        background-color: var(--SecondaryColor);
        color: var(--whiteColor);
        font-size: 0.83rem;
    }

    #error {
        background-color: var(--tiger-lilly);
    }
}


.previewPdfsContainer {
    width: 100%;
    grid-template-columns: repeat(auto-fit, 1fr);
    // grid-auto-rows: minmax(200px, auto);

    .previewPdfs {
        position: relative;
        justify-content: center;
        margin: .5rem;
        box-shadow: 2px 2px var(--greyText);
        background-color: var(--whiteColor);
        width: 100%;
        /* Ensure container takes up full column width */
        max-height: 40vh;
        /* Fixed height for all containers */
        overflow: hidden;
        /* To handle larger pdfs */

        .previewPdf {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }

        .delete-btn {
            position: absolute;
            bottom: 0;
            right: 0;
            width: max-content;
            height: max-content;
            border: none;
            color: white;
            background-color: var(--SecondaryColor);
            display: flex;
            justify-content: center;
            padding: .3rem;

            .icon {
                font-size: 1rem;
            }

            &:hover {
                cursor: pointer;
                background-color: var(--tiger-lilly);
            }
        }

        p {
            position: absolute;
            top: 0;
            left: 0;
            border: none;
            color: white;
            background-color: var(--PrimaryColor);
            padding: 0 .5rem;
        }
    }
}



@media all and (min-width: 1800px) {}

@media all and (max-width: 860px) {

    .pdfInputContainer {

        .pdfInput {

            .pdf-add-btn {
                font-size: 0.89rem;

                .upload-pdf-number {
                    font-size: 14px;
                }
            }
        }
    }
}

@media all and (max-width: 500px) {

    .pdfInputContainer {
        .pdfInput {
            .dropzone {
                height: 7rem;
            }
        }
    }

}
}