.jeeta-app .dashboard,
.jeeta-app * .dashboard {
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  color: var(--textColor);
  animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */
}
.jeeta-app .dashboard .LecturePIN,
.jeeta-app * .dashboard .LecturePIN {
  position: absolute;
  top: 0.8rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.jeeta-app .dashboard .LecturePIN h3,
.jeeta-app * .dashboard .LecturePIN h3 {
  color: var(--textColor);
}
.jeeta-app .dashboard .LecturePIN .PIN-container,
.jeeta-app * .dashboard .LecturePIN .PIN-container {
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
}
.jeeta-app .dashboard .LecturePIN .PIN-container .number-box,
.jeeta-app * .dashboard .LecturePIN .PIN-container .number-box {
  width: 1.5rem;
  background-color: var(--SecondaryColor);
  border: none;
  border-radius: 0.3rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  font-size: 1.2rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--whiteColor);
}
.jeeta-app .dashboard-header,
.jeeta-app * .dashboard-header {
  position: relative;
  width: 100%;
  padding: 0.5rem 1rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: var(--gradientBkg);
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}
.jeeta-app .dashboard-header .title,
.jeeta-app * .dashboard-header .title {
  color: var(--whiteColor);
  text-shadow: 0 2px 4px rgba(140, 140, 141, 0.3);
  text-align: center;
}
.jeeta-app .dashboard-glass,
.jeeta-app * .dashboard-glass {
  display: grid;
  width: 100%;
  padding: 1rem 0;
  background: var(--whiteColor);
  border-radius: 2rem;
  gap: 1rem;
  grid-template-columns: 11rem auto;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}
.jeeta-app .dashboard-main,
.jeeta-app * .dashboard-main {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
}
.jeeta-app .dashboard-main .title,
.jeeta-app * .dashboard-main .title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: var(--SecondaryColor);
  padding: 0.5rem;
  text-align: center;
}
.jeeta-app .dashboard-main .title span,
.jeeta-app * .dashboard-main .title span {
  color: var(--SecondaryColor);
}
.jeeta-app .dashboard-main .title .breakImg,
.jeeta-app * .dashboard-main .title .breakImg {
  max-width: 450px;
}
.jeeta-app .dashboard-main .recommendation-strategy,
.jeeta-app * .dashboard-main .recommendation-strategy {
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--whiteColorDeam);
}
.jeeta-app .dashboard-main .recommendation-strategy .recommendation-strategy-btns,
.jeeta-app * .dashboard-main .recommendation-strategy .recommendation-strategy-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .dashboard-main .recommendation-strategy .recommendation-strategy-btns .recommendation-strategy-btn,
.jeeta-app * .dashboard-main .recommendation-strategy .recommendation-strategy-btns .recommendation-strategy-btn {
  padding: 0.2rem 0.5rem;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--PrimaryBkg);
  color: var(--whiteColor);
}
.jeeta-app .dashboard-main .recommendation-strategy .recommendation-strategy-btns .recommendation-strategy-btn:hover,
.jeeta-app * .dashboard-main .recommendation-strategy .recommendation-strategy-btns .recommendation-strategy-btn:hover {
  background-color: var(--PrimaryColor);
  font-weight: 600;
  cursor: pointer;
}
.jeeta-app .dashboard-main .recommendation-strategy .recommendation-strategy-btns .recommendation-strategy-btn.active,
.jeeta-app * .dashboard-main .recommendation-strategy .recommendation-strategy-btns .recommendation-strategy-btn.active {
  background-color: var(--PrimaryColor);
  font-weight: 600;
  cursor: pointer;
}
.jeeta-app .dashboard-cardList,
.jeeta-app * .dashboard-cardList {
  position: relative;
  width: 95%;
  min-height: 236px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(200px, auto);
  gap: 1rem;
  background: var(--cardBG);
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  padding: 1rem;
}
@media all and (max-width: 1000px) {
  .jeeta-app .dashboard .dashboard-cardList,
  .jeeta-app * .dashboard .dashboard-cardList {
    grid-template-columns: repeat(2, 1fr);
  }
  .jeeta-app .dashboard .LecturePIN,
  .jeeta-app * .dashboard .LecturePIN {
    transform: scale(0.95);
  }
}
@media all and (max-width: 860px) {
  .jeeta-app .dashboard,
  .jeeta-app * .dashboard {
    gap: 0.5rem;
  }
  .jeeta-app .dashboard .LecturePIN,
  .jeeta-app * .dashboard .LecturePIN {
    transform: scale(0.9);
  }
  .jeeta-app .dashboard-cardList .lecture-card,
  .jeeta-app * .dashboard-cardList .lecture-card {
    width: 100%;
    min-height: 180px;
    font-size: 0.85rem;
  }
  .jeeta-app .dashboard-main .recommendation-strategy,
  .jeeta-app * .dashboard-main .recommendation-strategy {
    flex-direction: column;
  }
}
@media all and (max-width: 800px) {
  .jeeta-app .dashboard,
  .jeeta-app * .dashboard {
    padding: 0.5rem;
  }
  .jeeta-app .dashboard .LecturePIN,
  .jeeta-app * .dashboard .LecturePIN {
    transform: scale(0.8);
  }
  .jeeta-app .dashboard-header,
  .jeeta-app * .dashboard-header {
    margin: 0.5rem 0;
    padding: 0.5rem 0;
  }
  .jeeta-app .dashboard-glass,
  .jeeta-app * .dashboard-glass {
    grid-template-columns: 3rem auto;
  }
  .jeeta-app .dashboard-main .title,
  .jeeta-app * .dashboard-main .title {
    font-size: 1.2rem;
  }
}
@media all and (max-width: 720px) {
  .jeeta-app .dashboard-cardList,
  .jeeta-app * .dashboard-cardList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .jeeta-app .dashboard-cardList .lecture-card,
  .jeeta-app * .dashboard-cardList .lecture-card {
    width: 100%;
    min-height: 150px;
    font-size: 0.75rem;
  }
}
@media all and (max-width: 550px) {
  .jeeta-app .dashboard-header,
  .jeeta-app * .dashboard-header {
    margin: 0;
    padding: 0.5rem;
    font-size: 0.9rem;
  }
  .jeeta-app .dashboard-glass,
  .jeeta-app * .dashboard-glass {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0;
  }
  .jeeta-app .dashboard-main .title,
  .jeeta-app * .dashboard-main .title {
    padding: 0;
    font-size: 1rem;
  }
  .jeeta-app .dashboard-main .recommendation-strategy .recommendation-strategy-btns,
  .jeeta-app * .dashboard-main .recommendation-strategy .recommendation-strategy-btns {
    flex-direction: column;
  }
  .jeeta-app .dashboard-main .recommendation-strategy .recommendation-strategy-btns .recommendation-strategy-btn,
  .jeeta-app * .dashboard-main .recommendation-strategy .recommendation-strategy-btns .recommendation-strategy-btn {
    width: 135px;
  }
  .jeeta-app .dashboard-cardList,
  .jeeta-app * .dashboard-cardList {
    width: 100%;
    padding: 0.5rem;
    gap: 0.5rem;
  }
}/*# sourceMappingURL=dashboard.css.map */