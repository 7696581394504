.jeeta-app .testModal,
.jeeta-app * .testModal,
.customModal .testModal, .customModal * .testModal {
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  /* necessary adjustments for centering */
  z-index: 1000;
  /* high value to ensure modal is on top */
  width: 80vw;
  /* or your desired width */
  overflow-y: auto;
  /* display scrollbar only when necessary */
  background-color: var(--whiteColorDeam);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  padding: 1rem 3rem;
  color: var(--textColor);
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .testModal .modalTitle,
.jeeta-app * .testModal .modalTitle,
.customModal .testModal .modalTitle, .customModal * .testModal .modalTitle {
  margin: 1rem 0;
  padding: 1rem 0;
  font-size: 1.4rem;
}
.jeeta-app .testModal .testContent,
.jeeta-app * .testModal .testContent,
.customModal .testModal .testContent, .customModal * .testModal .testContent {
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 1rem;
}
.jeeta-app .testModal .testContent .testInfoInput,
.jeeta-app .testModal .testContent .testSelectionDropdown,
.jeeta-app .testModal .testContent .testSelectionDropdownMultiple,
.jeeta-app * .testModal .testContent .testInfoInput,
.jeeta-app * .testModal .testContent .testSelectionDropdown,
.jeeta-app * .testModal .testContent .testSelectionDropdownMultiple,
.customModal .testModal .testContent .testInfoInput,
.customModal .testModal .testContent .testSelectionDropdown,
.customModal .testModal .testContent .testSelectionDropdownMultiple, .customModal * .testModal .testContent .testInfoInput,
.customModal * .testModal .testContent .testSelectionDropdown,
.customModal * .testModal .testContent .testSelectionDropdownMultiple {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .testModal .testContent .testInfoInput .title,
.jeeta-app .testModal .testContent .testSelectionDropdown .title,
.jeeta-app .testModal .testContent .testSelectionDropdownMultiple .title,
.jeeta-app * .testModal .testContent .testInfoInput .title,
.jeeta-app * .testModal .testContent .testSelectionDropdown .title,
.jeeta-app * .testModal .testContent .testSelectionDropdownMultiple .title,
.customModal .testModal .testContent .testInfoInput .title,
.customModal .testModal .testContent .testSelectionDropdown .title,
.customModal .testModal .testContent .testSelectionDropdownMultiple .title, .customModal * .testModal .testContent .testInfoInput .title,
.customModal * .testModal .testContent .testSelectionDropdown .title,
.customModal * .testModal .testContent .testSelectionDropdownMultiple .title {
  padding: 0.35rem 0.5rem;
  font-size: 0.87rem;
  font-weight: 600;
}
.jeeta-app .testModal .testContent .testInfoInput .selectionDropdown,
.jeeta-app .testModal .testContent .testInfoInput .selectionDropdownMultiple,
.jeeta-app .testModal .testContent .testSelectionDropdown .selectionDropdown,
.jeeta-app .testModal .testContent .testSelectionDropdown .selectionDropdownMultiple,
.jeeta-app .testModal .testContent .testSelectionDropdownMultiple .selectionDropdown,
.jeeta-app .testModal .testContent .testSelectionDropdownMultiple .selectionDropdownMultiple,
.jeeta-app * .testModal .testContent .testInfoInput .selectionDropdown,
.jeeta-app * .testModal .testContent .testInfoInput .selectionDropdownMultiple,
.jeeta-app * .testModal .testContent .testSelectionDropdown .selectionDropdown,
.jeeta-app * .testModal .testContent .testSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .testModal .testContent .testSelectionDropdownMultiple .selectionDropdown,
.jeeta-app * .testModal .testContent .testSelectionDropdownMultiple .selectionDropdownMultiple,
.customModal .testModal .testContent .testInfoInput .selectionDropdown,
.customModal .testModal .testContent .testInfoInput .selectionDropdownMultiple,
.customModal .testModal .testContent .testSelectionDropdown .selectionDropdown,
.customModal .testModal .testContent .testSelectionDropdown .selectionDropdownMultiple,
.customModal .testModal .testContent .testSelectionDropdownMultiple .selectionDropdown,
.customModal .testModal .testContent .testSelectionDropdownMultiple .selectionDropdownMultiple, .customModal * .testModal .testContent .testInfoInput .selectionDropdown,
.customModal * .testModal .testContent .testInfoInput .selectionDropdownMultiple,
.customModal * .testModal .testContent .testSelectionDropdown .selectionDropdown,
.customModal * .testModal .testContent .testSelectionDropdown .selectionDropdownMultiple,
.customModal * .testModal .testContent .testSelectionDropdownMultiple .selectionDropdown,
.customModal * .testModal .testContent .testSelectionDropdownMultiple .selectionDropdownMultiple {
  flex-grow: 1;
  font-size: 0.83rem;
}
.jeeta-app .testModal .testContent .testInfoInput .text,
.jeeta-app .testModal .testContent .testInfoInput .number,
.jeeta-app .testModal .testContent .testInfoInput .date,
.jeeta-app .testModal .testContent .testSelectionDropdown .text,
.jeeta-app .testModal .testContent .testSelectionDropdown .number,
.jeeta-app .testModal .testContent .testSelectionDropdown .date,
.jeeta-app .testModal .testContent .testSelectionDropdownMultiple .text,
.jeeta-app .testModal .testContent .testSelectionDropdownMultiple .number,
.jeeta-app .testModal .testContent .testSelectionDropdownMultiple .date,
.jeeta-app * .testModal .testContent .testInfoInput .text,
.jeeta-app * .testModal .testContent .testInfoInput .number,
.jeeta-app * .testModal .testContent .testInfoInput .date,
.jeeta-app * .testModal .testContent .testSelectionDropdown .text,
.jeeta-app * .testModal .testContent .testSelectionDropdown .number,
.jeeta-app * .testModal .testContent .testSelectionDropdown .date,
.jeeta-app * .testModal .testContent .testSelectionDropdownMultiple .text,
.jeeta-app * .testModal .testContent .testSelectionDropdownMultiple .number,
.jeeta-app * .testModal .testContent .testSelectionDropdownMultiple .date,
.customModal .testModal .testContent .testInfoInput .text,
.customModal .testModal .testContent .testInfoInput .number,
.customModal .testModal .testContent .testInfoInput .date,
.customModal .testModal .testContent .testSelectionDropdown .text,
.customModal .testModal .testContent .testSelectionDropdown .number,
.customModal .testModal .testContent .testSelectionDropdown .date,
.customModal .testModal .testContent .testSelectionDropdownMultiple .text,
.customModal .testModal .testContent .testSelectionDropdownMultiple .number,
.customModal .testModal .testContent .testSelectionDropdownMultiple .date, .customModal * .testModal .testContent .testInfoInput .text,
.customModal * .testModal .testContent .testInfoInput .number,
.customModal * .testModal .testContent .testInfoInput .date,
.customModal * .testModal .testContent .testSelectionDropdown .text,
.customModal * .testModal .testContent .testSelectionDropdown .number,
.customModal * .testModal .testContent .testSelectionDropdown .date,
.customModal * .testModal .testContent .testSelectionDropdownMultiple .text,
.customModal * .testModal .testContent .testSelectionDropdownMultiple .number,
.customModal * .testModal .testContent .testSelectionDropdownMultiple .date {
  flex-grow: 1;
  font-size: 0.83rem;
  padding: 0.5rem;
  color: var(--textColor);
  border: hsl(0, 0%, 80%) solid 1px;
  border-radius: 0.3rem;
}
.jeeta-app .testModal .testContent .testInfoInput input[type=range]::-webkit-slider-runnable-track,
.jeeta-app .testModal .testContent .testSelectionDropdown input[type=range]::-webkit-slider-runnable-track,
.jeeta-app .testModal .testContent .testSelectionDropdownMultiple input[type=range]::-webkit-slider-runnable-track,
.jeeta-app * .testModal .testContent .testInfoInput input[type=range]::-webkit-slider-runnable-track,
.jeeta-app * .testModal .testContent .testSelectionDropdown input[type=range]::-webkit-slider-runnable-track,
.jeeta-app * .testModal .testContent .testSelectionDropdownMultiple input[type=range]::-webkit-slider-runnable-track,
.customModal .testModal .testContent .testInfoInput input[type=range]::-webkit-slider-runnable-track,
.customModal .testModal .testContent .testSelectionDropdown input[type=range]::-webkit-slider-runnable-track,
.customModal .testModal .testContent .testSelectionDropdownMultiple input[type=range]::-webkit-slider-runnable-track, .customModal * .testModal .testContent .testInfoInput input[type=range]::-webkit-slider-runnable-track,
.customModal * .testModal .testContent .testSelectionDropdown input[type=range]::-webkit-slider-runnable-track,
.customModal * .testModal .testContent .testSelectionDropdownMultiple input[type=range]::-webkit-slider-runnable-track {
  cursor: pointer;
  animate: 0.2s;
  border-radius: 0.5rem;
}
.jeeta-app .testModal .testContent .testInfoInput .sliderValue,
.jeeta-app .testModal .testContent .testSelectionDropdown .sliderValue,
.jeeta-app .testModal .testContent .testSelectionDropdownMultiple .sliderValue,
.jeeta-app * .testModal .testContent .testInfoInput .sliderValue,
.jeeta-app * .testModal .testContent .testSelectionDropdown .sliderValue,
.jeeta-app * .testModal .testContent .testSelectionDropdownMultiple .sliderValue,
.customModal .testModal .testContent .testInfoInput .sliderValue,
.customModal .testModal .testContent .testSelectionDropdown .sliderValue,
.customModal .testModal .testContent .testSelectionDropdownMultiple .sliderValue, .customModal * .testModal .testContent .testInfoInput .sliderValue,
.customModal * .testModal .testContent .testSelectionDropdown .sliderValue,
.customModal * .testModal .testContent .testSelectionDropdownMultiple .sliderValue {
  font-size: 0.83rem;
  background-color: var(--whiteColor);
  padding: 0.2rem 0.5rem;
  color: var(--textColor);
  border: hsl(0, 0%, 80%) solid 1px;
  border-radius: 0.3rem;
}
.jeeta-app .testModal .testContent .testInfoInput .sliderRange,
.jeeta-app .testModal .testContent .testSelectionDropdown .sliderRange,
.jeeta-app .testModal .testContent .testSelectionDropdownMultiple .sliderRange,
.jeeta-app * .testModal .testContent .testInfoInput .sliderRange,
.jeeta-app * .testModal .testContent .testSelectionDropdown .sliderRange,
.jeeta-app * .testModal .testContent .testSelectionDropdownMultiple .sliderRange,
.customModal .testModal .testContent .testInfoInput .sliderRange,
.customModal .testModal .testContent .testSelectionDropdown .sliderRange,
.customModal .testModal .testContent .testSelectionDropdownMultiple .sliderRange, .customModal * .testModal .testContent .testInfoInput .sliderRange,
.customModal * .testModal .testContent .testSelectionDropdown .sliderRange,
.customModal * .testModal .testContent .testSelectionDropdownMultiple .sliderRange {
  font-size: 0.8rem;
  color: var(--textColor);
}
.jeeta-app .testModal .testContent .batchGradeSelectDropdown,
.jeeta-app * .testModal .testContent .batchGradeSelectDropdown,
.customModal .testModal .testContent .batchGradeSelectDropdown, .customModal * .testModal .testContent .batchGradeSelectDropdown {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.jeeta-app .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown,
.jeeta-app .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown,
.jeeta-app * .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown,
.jeeta-app * .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown,
.customModal .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown,
.customModal .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown, .customModal * .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown,
.customModal * .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown .title,
.jeeta-app .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown .title,
.jeeta-app * .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown .title,
.jeeta-app * .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown .title,
.customModal .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown .title,
.customModal .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown .title, .customModal * .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown .title,
.customModal * .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown .title {
  font-size: 0.87rem;
  font-weight: 600;
}
.jeeta-app .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown,
.jeeta-app .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple,
.jeeta-app .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown,
.jeeta-app .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown,
.jeeta-app * .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown,
.jeeta-app * .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple,
.customModal .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown,
.customModal .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple,
.customModal .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown,
.customModal .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple, .customModal * .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown,
.customModal * .testModal .testContent .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple,
.customModal * .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown,
.customModal * .testModal .testContent .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple {
  width: 100%;
  font-size: 0.83rem;
}
.jeeta-app .testModal .btn,
.jeeta-app * .testModal .btn,
.customModal .testModal .btn, .customModal * .testModal .btn {
  margin-top: 1rem;
  color: var(--whiteColor);
  font-weight: 600;
  justify-content: center;
  gap: 0.5rem;
}
.jeeta-app .testModal .btn .icon,
.jeeta-app * .testModal .btn .icon,
.customModal .testModal .btn .icon, .customModal * .testModal .btn .icon {
  align-self: center;
  font-size: 1rem;
}
.jeeta-app .testModal #submit,
.jeeta-app * .testModal #submit,
.customModal .testModal #submit, .customModal * .testModal #submit {
  margin-bottom: 1rem;
}
.jeeta-app .testModal #close,
.jeeta-app * .testModal #close,
.customModal .testModal #close, .customModal * .testModal #close {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  color: var(--PrimaryColor);
  align-self: center;
  font-size: 1.8rem;
}
.jeeta-app .testModal #close:hover,
.jeeta-app * .testModal #close:hover,
.customModal .testModal #close:hover, .customModal * .testModal #close:hover {
  color: var(--tiger-lilly);
}
.jeeta-app .autoTestSectionCreate,
.jeeta-app * .autoTestSectionCreate,
.customModal .autoTestSectionCreate, .customModal * .autoTestSectionCreate {
  z-index: 1001;
  min-width: 800px;
  background-color: rgba(65, 137, 230, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transform: scale(0.9);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.jeeta-app .automatedTestCheck,
.jeeta-app * .automatedTestCheck,
.customModal .automatedTestCheck, .customModal * .automatedTestCheck {
  position: relative;
  padding: 3rem 1rem 0rem 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--glass);
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  font-size: 0.87rem !important;
  z-index: 1000;
}
.jeeta-app .automatedTestCheck .automatedTestCheckHeader,
.jeeta-app * .automatedTestCheck .automatedTestCheckHeader,
.customModal .automatedTestCheck .automatedTestCheckHeader, .customModal * .automatedTestCheck .automatedTestCheckHeader {
  position: absolute;
  width: 100%;
  height: 2.2rem;
  top: 0;
  left: 0;
  overflow: hidden;
}
.jeeta-app .automatedTestCheck .automatedTestCheckHeader .checkBox,
.jeeta-app * .automatedTestCheck .automatedTestCheckHeader .checkBox,
.customModal .automatedTestCheck .automatedTestCheckHeader .checkBox, .customModal * .automatedTestCheck .automatedTestCheckHeader .checkBox {
  width: 100%;
  padding: 0.5rem 1rem 1rem 1rem;
  border-radius: 0.5rem;
  background-color: var(--blackColor);
  color: var(--whiteColor);
  width: 100%;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .automatedTestCheck .automatedTestCheckHeader .checkBox .title,
.jeeta-app * .automatedTestCheck .automatedTestCheckHeader .checkBox .title,
.customModal .automatedTestCheck .automatedTestCheckHeader .checkBox .title, .customModal * .automatedTestCheck .automatedTestCheckHeader .checkBox .title {
  font-weight: 600;
}
.jeeta-app .automatedTestCheck .automatedTestCheckHeader .checkBox input[type=checkbox],
.jeeta-app * .automatedTestCheck .automatedTestCheckHeader .checkBox input[type=checkbox],
.customModal .automatedTestCheck .automatedTestCheckHeader .checkBox input[type=checkbox], .customModal * .automatedTestCheck .automatedTestCheckHeader .checkBox input[type=checkbox] {
  -moz-appearance: none;
       appearance: none; /* Remove default checkbox styling */
  -webkit-appearance: none; /* For Safari */
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  background-color: white; /* Initial background color */
  border: 2px solid var(--orange); /* Border color */
  border-radius: 4px; /* Optional: rounded corners */
  position: relative;
}
.jeeta-app .automatedTestCheck .automatedTestCheckHeader .checkBox input[type=checkbox]:checked,
.jeeta-app * .automatedTestCheck .automatedTestCheckHeader .checkBox input[type=checkbox]:checked,
.customModal .automatedTestCheck .automatedTestCheckHeader .checkBox input[type=checkbox]:checked, .customModal * .automatedTestCheck .automatedTestCheckHeader .checkBox input[type=checkbox]:checked {
  background-color: var(--orange); /* Background color when checked */
  border: 2px solid var(--orange); /* Border color when checked */
}
.jeeta-app .automatedTestCheck .automatedTestCheckHeader .checkBox input[type=checkbox]:checked::before,
.jeeta-app * .automatedTestCheck .automatedTestCheckHeader .checkBox input[type=checkbox]:checked::before,
.customModal .automatedTestCheck .automatedTestCheckHeader .checkBox input[type=checkbox]:checked::before, .customModal * .automatedTestCheck .automatedTestCheckHeader .checkBox input[type=checkbox]:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: white;
  transform: translate(-50%, -50%);
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 20%, 80% 0%, 45% 75%);
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 20%, 80% 0%, 45% 75%);
}
.jeeta-app .automatedTestCheck .syllabusCheck,
.jeeta-app .automatedTestCheck .syllabusFilter,
.jeeta-app .automatedTestCheck .testInfoInput,
.jeeta-app * .automatedTestCheck .syllabusCheck,
.jeeta-app * .automatedTestCheck .syllabusFilter,
.jeeta-app * .automatedTestCheck .testInfoInput,
.customModal .automatedTestCheck .syllabusCheck,
.customModal .automatedTestCheck .syllabusFilter,
.customModal .automatedTestCheck .testInfoInput, .customModal * .automatedTestCheck .syllabusCheck,
.customModal * .automatedTestCheck .syllabusFilter,
.customModal * .automatedTestCheck .testInfoInput {
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--whiteColor);
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .automatedTestCheck .syllabusCheck .title,
.jeeta-app .automatedTestCheck .syllabusFilter .title,
.jeeta-app .automatedTestCheck .testInfoInput .title,
.jeeta-app * .automatedTestCheck .syllabusCheck .title,
.jeeta-app * .automatedTestCheck .syllabusFilter .title,
.jeeta-app * .automatedTestCheck .testInfoInput .title,
.customModal .automatedTestCheck .syllabusCheck .title,
.customModal .automatedTestCheck .syllabusFilter .title,
.customModal .automatedTestCheck .testInfoInput .title, .customModal * .automatedTestCheck .syllabusCheck .title,
.customModal * .automatedTestCheck .syllabusFilter .title,
.customModal * .automatedTestCheck .testInfoInput .title {
  font-weight: 600;
}
.jeeta-app .automatedTestCheck .syllabusCheck input,
.jeeta-app .automatedTestCheck .syllabusFilter input,
.jeeta-app .automatedTestCheck .testInfoInput input,
.jeeta-app * .automatedTestCheck .syllabusCheck input,
.jeeta-app * .automatedTestCheck .syllabusFilter input,
.jeeta-app * .automatedTestCheck .testInfoInput input,
.customModal .automatedTestCheck .syllabusCheck input,
.customModal .automatedTestCheck .syllabusFilter input,
.customModal .automatedTestCheck .testInfoInput input, .customModal * .automatedTestCheck .syllabusCheck input,
.customModal * .automatedTestCheck .syllabusFilter input,
.customModal * .automatedTestCheck .testInfoInput input {
  cursor: pointer;
}
.jeeta-app .automatedTestCheck .syllabusFilter,
.jeeta-app * .automatedTestCheck .syllabusFilter,
.customModal .automatedTestCheck .syllabusFilter, .customModal * .automatedTestCheck .syllabusFilter {
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--PrimaryBkg);
  transform: scale(0.9);
  z-index: 1001;
}
.jeeta-app .automatedTestCheck .syllabusFilter .syllabus-filter .filter .filter-label,
.jeeta-app * .automatedTestCheck .syllabusFilter .syllabus-filter .filter .filter-label,
.customModal .automatedTestCheck .syllabusFilter .syllabus-filter .filter .filter-label, .customModal * .automatedTestCheck .syllabusFilter .syllabus-filter .filter .filter-label {
  font-size: 0.87rem;
}
@media all and (max-width: 720px) {
  .jeeta-app .testModal,
  .jeeta-app * .testModal,
  .customModal .testModal, .customModal * .testModal {
    width: 90vw;
    padding: 0.5rem;
  }
}
@media all and (max-width: 550px) {
  .jeeta-app .testModal,
  .jeeta-app * .testModal,
  .customModal .testModal, .customModal * .testModal {
    width: 95vw;
  }
}/*# sourceMappingURL=testModal.css.map */