.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.questionSlider {
    position: relative;
    width: 100%;
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.slide-wrapper {
    width: 100%;
    min-height: 400px;
    // overflow-y: auto;
    position: relative;
    background-color: var(--whiteColor);
    border-radius: 1rem;
    border: 3px solid var(--PrimaryBkg);
    padding: 1rem 4rem 3rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform ease-out 500ms;
}

.slider-controls {
    .slider-btns {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .prev-btn,
        .next-tbn {
            justify-content: center;
            border-radius: 50%;
            border: none;
            background-color: var(--whiteColor);
            color: var(--PrimaryColor);
            transition: background-color 0.3s;

            &:hover {
                cursor: pointer;
                background-color: var(--whiteColorDeam);
                color: var(--SecondaryColor);
            }

            .icon {
                width: 3rem;
                height: 3rem;
            }
        }
    }

    /* Positioning for the whole dots wrapper */
    .dots-wrapper {
        position: absolute;
        width: 100%;
        bottom: 1rem;
        right: 0;
        left: 0;

        /* Container styles */
        .dots-container {
            align-items: center;
            justify-content: center;
            gap: 5px;

            /* Base styling for the dot */
            .dot {
                transition: all 0.3s ease;
                width: 10px;
                height: 10px;
                background-color: var(--PrimaryColor);
                border-radius: 50%;
                opacity: 0.5;
                /* Default state with reduced opacity */
            }

            /* Additional styling for the active dot */
            .dot-active {
                padding: 2px;
                opacity: 1;
                /* Full opacity for the active dot */
            }
        }
    }
}

#check-answer {
    margin-bottom: 1rem;
}

.check-answer-btns {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.paragraph-slide,
.question-slide {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-size: 1.1rem;

    .paragraph-questions {
        width: 100%;
        height: auto;
        flex-direction: column;
        justify-content: flex-start;
        gap: .3rem;
        padding: 0 1rem;
    }

    .question-info {
        position: relative;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        padding: .5rem;
        gap: .5rem;
        .question-saved {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: .2rem;
            gap: .2rem;
    
            .saved-btn {
                border: none;
                background-color: transparent;
                display: flex;
                justify-content: center;
    
                .icon {
                    font-size: 1.6rem;
                }
            }
    
            #star-btn {
                color: var(--tiger-lilly);
    
            }
    
            #review-btn {
                padding-top: .2rem;
                color: var(--kelly-green);
            }
        }
    }

    .question-difficulty {
        background-color: var(--SecondaryColor);
        padding: .2rem .4rem;
        border-radius: .5rem;
        color: white;
        font-size: .94rem;
        font-weight: 600;
    }

    

    #easy {
        background-color: var(--easy);
    }

    #medium {
        background-color: var(--medium);
    }

    #hard {
        background-color: var(--hard);
    }

    .question-id {
        background-color: var(--PrimaryColor);
        padding: .2rem .4rem;
        border-radius: .5rem;
        color: white;
        font-size: .94rem;
        font-weight: 600;
    }

    #Physics {
        background-color: var(--Physics);
    }

    #Chemistry {
        background-color: var(--Chemistry);
    }

    #Mathematics {
        background-color: var(--Mathematics);
    }

    .question-text,
    .paragraph-text {
        width: 100%;
        padding: .5rem;
        flex-direction: row;
        align-items: flex-start;
        font-family: 'Frank Ruhl Libre', serif;

        span {
            font-family: 'Frank Ruhl Libre', serif;
            line-height: 1.4;
        }

        .question-index {
            margin-top: 3px;
            margin-right: 5px;
            transform: scale(1.2);
            font-weight: 600;
        }
    }

    
    .question-image,
    .paragraph-images {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        gap: .5rem;

        .img {
            max-width: 100%;
            max-height: 50vh;
            height: auto;
            object-fit: contain;
        }
    }

    .question-image-only {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;

        .wide {
            max-width: 100%;
            max-height: 50vh;
            height: auto;
            object-fit: contain;
        }

        .long{
            max-width: 80%;
            max-height: 50vh;
            height: auto;
            object-fit: contain;
        }
    }

    .answers {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        padding: 0 1rem;

        .answer-container {
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 1.05rem;

            .option {
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: .5rem;
                font-family: 'Frank Ruhl Libre', serif;

                .answer-check {
                    margin-top: 2px;
                    transform: scale(1.5);
                }

                .answer-label {
                    font-weight: 700;
                    font-family: 'Frank Ruhl Libre', serif;
                }

                span {
                    font-family: 'Frank Ruhl Libre', serif;
                }
            }

            .subjective {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: .5rem;

                span, input {
                    width: 100%;
                    font-family: 'Frank Ruhl Libre', serif;
                }

                .subjective-input{
                    padding: .2rem .5rem;
                }

                .subjective-given-answer{
                    font-weight: 600;
                }
            }
        }

        

        &.correct {
            border-color: green;
        }
    }

    

    .delete-btn {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: var(--whiteColor);
        color: var(--SecondaryColor);
        border: none;
        font-size: .87rem;
        padding: .3rem 1rem;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        gap: .5rem;

        .icon {
            align-self: center;
            font-size: 1.11rem;
        }

        &:hover {
            cursor: pointer;
            color: var(--tiger-lilly);
        }
    }

    .edit-btn {
        position: absolute;
        right: 0;
        background-color: var(--whiteColor);
        color: var(--PrimaryColor);
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            align-self: center;
            font-size: 1.66rem;
        }

        &:hover {
            cursor: pointer;
            color: var(--SecondaryColor);
        }
    }

    .delete-popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        flex-direction: column;
        justify-content: center;
        gap: .5rem;
        padding: 1rem;
        background-color: var(--whiteColorDeam);
        border-radius: .5rem;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        z-index: 1;

        .btns {
            gap: 1rem;
        }
    }

    .answer-marks {
        justify-content: center;
        align-items: center;
        gap: .2rem;

        .mark {
            justify-content: center;
            align-items: center;
            gap: .1rem;

            p {
                font-size: 1.27rem;
                font-weight: 500;
            }

            .icon {
                font-size: 1.55rem;
            }
        }

        #correct .icon {
            color: var(--Chemistry);
        }

        #incorrect .icon {
            color: var(--tiger-lilly);
        }

        #unanswered .icon {
            color: var(--greyText);
        }
    }
}

.solution {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    gap: .1rem;
    background-color: rgba(24, 201, 168, 0.2);
    border-radius: .3rem;
    padding: .5rem;
    // margin-bottom: 1rem;

    .solution-btn {
        background-color: transparent;
        color: var(--PrimaryColor);
        border: none;
        font-size: .87rem;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        gap: .5rem;

        .icon {
            align-self: center;
            font-size: 1.11rem;
        }

        &:hover,
        &:active {
            cursor: pointer;
            border: 2px solid var(--SecondaryColor);
        }
    }

    .solution-step{
        background: var(--glass);
        color: var(--textColor);
        font-weight: 600;
        font-size: .9rem;
        padding: .3rem .5rem;
        border-radius: .3rem;
    }

    .solution-text {
        font-family: 'Frank Ruhl Libre', serif;
        font-size: .9rem;
        overflow-x: auto;
        padding: .5rem;

        &::-webkit-scrollbar {
            height: .4rem;
        }

        span {
            font-family: 'Frank Ruhl Libre', serif;
            line-height: 1.4;
        }
    }    
}


.msg {
    text-align: center;
    color: var(--PrimaryColor);
    font-size: 1rem;

}

#correct-answer {
    background-color: greenyellow;
    font-weight: 600;
}

#wrongAnswer {
    color: var(--tiger-lilly);
}

#correctAnswer {
    color: var(--PrimaryColor);
}

.answerResponse {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {}

@media all and (min-width: 1000px) {}

@media all and (max-width: 720px) {
    .questionSlider {
        padding: 1rem .5rem;

        .slide-wrapper {
            padding: 1rem 3rem 3rem 3rem;
        }

        
    }
}

@media all and (max-width: 500px) {
    .questionSlider {
        padding: .5rem;

        .slide-wrapper {
            padding: .5rem .5rem 3rem .5rem;

            .msg {
                font-size: .83rem;
            }

            .slider-controls {
                .slider-btns {
                    display: none;
                }
            }
        }
    }
    .check-answer-btns {
        flex-direction: column;
        gap: .5rem;
    }
}
}