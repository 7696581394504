.jeeta-app,
.jeeta-app * {
  /* radial bar */
  /* sideDetail*/
}
.jeeta-app .CompactCard,
.jeeta-app * .CompactCard {
  display: flex;
  flex: 1;
  gap: 2rem;
  border-radius: 0.7rem;
  color: white;
  padding: 1rem 2rem;
  position: relative;
}
.jeeta-app .CompactCard:hover,
.jeeta-app * .CompactCard:hover {
  cursor: pointer;
}
.jeeta-app #Questions,
.jeeta-app * #Questions {
  background: linear-gradient(180deg, #bb67ff 0%, #c484f3 100%);
}
.jeeta-app #Questions:hover,
.jeeta-app * #Questions:hover {
  box-shadow: 0px 10px 20px 0px #e0c6f5;
}
.jeeta-app #Lectures,
.jeeta-app * #Lectures {
  background: linear-gradient(180deg, #FF919D 0%, #FC929D 100%);
}
.jeeta-app #Lectures:hover,
.jeeta-app * #Lectures:hover {
  box-shadow: 0px 10px 20px 0px #FDC0C7;
}
.jeeta-app #Tests,
.jeeta-app * #Tests {
  background: linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255, 202, 113) -46.42%);
}
.jeeta-app #Tests:hover,
.jeeta-app * #Tests:hover {
  box-shadow: 0px 10px 20px 0px #F9D59B;
}
.jeeta-app #Doubts,
.jeeta-app * #Doubts {
  background: linear-gradient(180deg, #FF919D 0%, #FC929D 100%);
}
.jeeta-app #Doubts:hover,
.jeeta-app * #Doubts:hover {
  box-shadow: 0px 10px 20px 0px rgb(178, 223, 185);
}
.jeeta-app .cardInfo,
.jeeta-app * .cardInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .cardInfo .value,
.jeeta-app * .cardInfo .value {
  font-size: 1.8rem;
  font-weight: 600;
}
.jeeta-app .cardInfo .title,
.jeeta-app * .cardInfo .title {
  font-size: 1.2rem;
  font-weight: 500;
}
.jeeta-app .radialBar,
.jeeta-app * .radialBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.jeeta-app .radialBar span,
.jeeta-app * .radialBar span {
  font-size: 1rem;
  font-weight: bold;
}
.jeeta-app .CircularProgressbar,
.jeeta-app * .CircularProgressbar {
  width: 5rem !important;
  overflow: visible;
}
.jeeta-app .CircularProgressbar-path,
.jeeta-app * .CircularProgressbar-path {
  stroke: white !important;
  stroke-width: 0.66rem !important;
  filter: drop-shadow(2px 4px 6px white);
}
.jeeta-app .CircularProgressbar-trail,
.jeeta-app * .CircularProgressbar-trail {
  display: none;
}
.jeeta-app .CircularProgressbar-text,
.jeeta-app * .CircularProgressbar-text {
  fill: white !important;
  font-size: 1.2rem !important;
}
.jeeta-app .detail,
.jeeta-app * .detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.jeeta-app .detail span:nth-child(2),
.jeeta-app * .detail span:nth-child(2) {
  font-size: 1.25rem;
  font-weight: bold;
}
.jeeta-app .detail span:nth-child(3),
.jeeta-app * .detail span:nth-child(3) {
  font-size: 0.66rem;
}
@media screen and (max-width: 780px) {
  .jeeta-app .CompactCard,
  .jeeta-app * .CompactCard {
    padding: 1rem;
  }
}
@media screen and (max-width: 720px) {
  .jeeta-app .CompactCard,
  .jeeta-app * .CompactCard {
    padding: 1rem 2rem;
    width: 100%;
  }
}/*# sourceMappingURL=Card.css.map */