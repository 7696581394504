.jeeta-app,
.jeeta-app * {
    .Cards {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.cardContainer {
    flex: 1;
    max-width: 450px;
}

//MEDIA QUERIES ==================================================>

@media all and (max-width: 1200px) {
    .Cards{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Creates 2 columns */
        grid-gap: 1rem;
    }
}

@media all and (max-width: 720px) {
    .Cards {
        width: 100%;
        display: flex;
        flex-direction: column;
        // gap: .5rem;
    }

    .cardContainer{
        min-width: 75%;
    }
}

@media all and (max-width: 550px) {
    .cardContainer{
        min-width: 90%;
    }
}
}