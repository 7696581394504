.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
  /* Add styles for hovering over table rows */
}
.jeeta-app .slotView,
.jeeta-app * .slotView,
.customModal .slotView, .customModal * .slotView {
  width: 100%;
  min-height: 60vh;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  color: var(--textColor);
  animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */
}
.jeeta-app .slotView .btn,
.jeeta-app * .slotView .btn,
.customModal .slotView .btn, .customModal * .slotView .btn {
  font-weight: 600;
  padding: 0.6rem 1.1rem !important;
}
.jeeta-app .slotView #close,
.jeeta-app * .slotView #close,
.customModal .slotView #close, .customModal * .slotView #close {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  color: var(--PrimaryColor);
  align-self: center;
}
.jeeta-app .slotView #close:hover,
.jeeta-app * .slotView #close:hover,
.customModal .slotView #close:hover, .customModal * .slotView #close:hover {
  color: var(--tiger-lilly);
}
.jeeta-app .slotView .LecturePIN,
.jeeta-app * .slotView .LecturePIN,
.customModal .slotView .LecturePIN, .customModal * .slotView .LecturePIN {
  margin: 0.5rem;
  transform: scale(1.4);
}
.jeeta-app .slotView-header,
.jeeta-app * .slotView-header,
.customModal .slotView-header, .customModal * .slotView-header {
  position: relative;
  width: 100%;
  padding: 0.5rem 1rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: var(--gradientBkg);
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}
.jeeta-app .slotView-header .title,
.jeeta-app * .slotView-header .title,
.customModal .slotView-header .title, .customModal * .slotView-header .title {
  color: var(--whiteColor);
  text-shadow: 0 2px 4px rgba(140, 140, 141, 0.3);
  text-align: center;
  gap: 1rem;
}
.jeeta-app .slotView-glass,
.jeeta-app * .slotView-glass,
.customModal .slotView-glass, .customModal * .slotView-glass {
  flex-grow: 1;
  display: flex;
  width: 100%;
  padding: 1rem 0;
  background: var(--whiteColor);
  border-radius: 2rem;
  gap: 1rem;
  grid-template-columns: 11rem auto;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}
.jeeta-app .slotView-main,
.jeeta-app * .slotView-main,
.customModal .slotView-main, .customModal * .slotView-main {
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 85%;
  padding: 1rem;
  gap: 2rem;
}
.jeeta-app .slotView-topic,
.jeeta-app * .slotView-topic,
.customModal .slotView-topic, .customModal * .slotView-topic {
  color: var(--PrimaryColor);
}
.jeeta-app .slotLectureVideo,
.jeeta-app .slotLectureSummary,
.jeeta-app * .slotLectureVideo,
.jeeta-app * .slotLectureSummary,
.customModal .slotLectureVideo,
.customModal .slotLectureSummary, .customModal * .slotLectureVideo,
.customModal * .slotLectureSummary {
  padding-top: 0.5rem;
  position: relative;
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .slotLectureVideo .videoPlayer,
.jeeta-app .slotLectureSummary .videoPlayer,
.jeeta-app * .slotLectureVideo .videoPlayer,
.jeeta-app * .slotLectureSummary .videoPlayer,
.customModal .slotLectureVideo .videoPlayer,
.customModal .slotLectureSummary .videoPlayer, .customModal * .slotLectureVideo .videoPlayer,
.customModal * .slotLectureSummary .videoPlayer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--whiteColorDeam);
  border-radius: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  overflow: hidden;
}
.jeeta-app .slotLectureVideo .videoPlayer .no-video-msg,
.jeeta-app .slotLectureVideo .videoPlayer .end-recording-msg,
.jeeta-app .slotLectureSummary .videoPlayer .no-video-msg,
.jeeta-app .slotLectureSummary .videoPlayer .end-recording-msg,
.jeeta-app * .slotLectureVideo .videoPlayer .no-video-msg,
.jeeta-app * .slotLectureVideo .videoPlayer .end-recording-msg,
.jeeta-app * .slotLectureSummary .videoPlayer .no-video-msg,
.jeeta-app * .slotLectureSummary .videoPlayer .end-recording-msg,
.customModal .slotLectureVideo .videoPlayer .no-video-msg,
.customModal .slotLectureVideo .videoPlayer .end-recording-msg,
.customModal .slotLectureSummary .videoPlayer .no-video-msg,
.customModal .slotLectureSummary .videoPlayer .end-recording-msg, .customModal * .slotLectureVideo .videoPlayer .no-video-msg,
.customModal * .slotLectureVideo .videoPlayer .end-recording-msg,
.customModal * .slotLectureSummary .videoPlayer .no-video-msg,
.customModal * .slotLectureSummary .videoPlayer .end-recording-msg {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--tiger-lilly);
}
.jeeta-app .slotLectureVideo .videoPlayer .live-class-msg,
.jeeta-app .slotLectureSummary .videoPlayer .live-class-msg,
.jeeta-app * .slotLectureVideo .videoPlayer .live-class-msg,
.jeeta-app * .slotLectureSummary .videoPlayer .live-class-msg,
.customModal .slotLectureVideo .videoPlayer .live-class-msg,
.customModal .slotLectureSummary .videoPlayer .live-class-msg, .customModal * .slotLectureVideo .videoPlayer .live-class-msg,
.customModal * .slotLectureSummary .videoPlayer .live-class-msg {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--PrimaryColor);
}
.jeeta-app .slotLectureVideo .videoPlayer #record,
.jeeta-app .slotLectureSummary .videoPlayer #record,
.jeeta-app * .slotLectureVideo .videoPlayer #record,
.jeeta-app * .slotLectureSummary .videoPlayer #record,
.customModal .slotLectureVideo .videoPlayer #record,
.customModal .slotLectureSummary .videoPlayer #record, .customModal * .slotLectureVideo .videoPlayer #record,
.customModal * .slotLectureSummary .videoPlayer #record {
  transform: scale(1.2);
}
.jeeta-app .slotLectureVideo .videoPlayer #record:hover,
.jeeta-app .slotLectureSummary .videoPlayer #record:hover,
.jeeta-app * .slotLectureVideo .videoPlayer #record:hover,
.jeeta-app * .slotLectureSummary .videoPlayer #record:hover,
.customModal .slotLectureVideo .videoPlayer #record:hover,
.customModal .slotLectureSummary .videoPlayer #record:hover, .customModal * .slotLectureVideo .videoPlayer #record:hover,
.customModal * .slotLectureSummary .videoPlayer #record:hover {
  background-color: var(--tiger-lilly);
}
.jeeta-app .slotView-info,
.jeeta-app * .slotView-info,
.customModal .slotView-info, .customModal * .slotView-info {
  width: auto;
  display: grid;
  position: relative;
  margin-top: 1rem;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem 1.5rem;
}
.jeeta-app .slotView-info .slotView-infoItem,
.jeeta-app .slotView-info .slotInput,
.jeeta-app .slotView-info .slotSelectionDropdown,
.jeeta-app .slotView-info .slotSelectionDropdownMultiple,
.jeeta-app * .slotView-info .slotView-infoItem,
.jeeta-app * .slotView-info .slotInput,
.jeeta-app * .slotView-info .slotSelectionDropdown,
.jeeta-app * .slotView-info .slotSelectionDropdownMultiple,
.customModal .slotView-info .slotView-infoItem,
.customModal .slotView-info .slotInput,
.customModal .slotView-info .slotSelectionDropdown,
.customModal .slotView-info .slotSelectionDropdownMultiple, .customModal * .slotView-info .slotView-infoItem,
.customModal * .slotView-info .slotInput,
.customModal * .slotView-info .slotSelectionDropdown,
.customModal * .slotView-info .slotSelectionDropdownMultiple {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  text-align: start;
  overflow: visible;
}
.jeeta-app .slotView-info .slotView-infoItem .title,
.jeeta-app .slotView-info .slotInput .title,
.jeeta-app .slotView-info .slotSelectionDropdown .title,
.jeeta-app .slotView-info .slotSelectionDropdownMultiple .title,
.jeeta-app * .slotView-info .slotView-infoItem .title,
.jeeta-app * .slotView-info .slotInput .title,
.jeeta-app * .slotView-info .slotSelectionDropdown .title,
.jeeta-app * .slotView-info .slotSelectionDropdownMultiple .title,
.customModal .slotView-info .slotView-infoItem .title,
.customModal .slotView-info .slotInput .title,
.customModal .slotView-info .slotSelectionDropdown .title,
.customModal .slotView-info .slotSelectionDropdownMultiple .title, .customModal * .slotView-info .slotView-infoItem .title,
.customModal * .slotView-info .slotInput .title,
.customModal * .slotView-info .slotSelectionDropdown .title,
.customModal * .slotView-info .slotSelectionDropdownMultiple .title {
  font-weight: 600;
  overflow: visible;
}
.jeeta-app .slotView-info .slotView-infoItem .selectionDropdown,
.jeeta-app .slotView-info .slotView-infoItem .selectionDropdownMultiple,
.jeeta-app .slotView-info .slotInput .selectionDropdown,
.jeeta-app .slotView-info .slotInput .selectionDropdownMultiple,
.jeeta-app .slotView-info .slotSelectionDropdown .selectionDropdown,
.jeeta-app .slotView-info .slotSelectionDropdown .selectionDropdownMultiple,
.jeeta-app .slotView-info .slotSelectionDropdownMultiple .selectionDropdown,
.jeeta-app .slotView-info .slotSelectionDropdownMultiple .selectionDropdownMultiple,
.jeeta-app * .slotView-info .slotView-infoItem .selectionDropdown,
.jeeta-app * .slotView-info .slotView-infoItem .selectionDropdownMultiple,
.jeeta-app * .slotView-info .slotInput .selectionDropdown,
.jeeta-app * .slotView-info .slotInput .selectionDropdownMultiple,
.jeeta-app * .slotView-info .slotSelectionDropdown .selectionDropdown,
.jeeta-app * .slotView-info .slotSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .slotView-info .slotSelectionDropdownMultiple .selectionDropdown,
.jeeta-app * .slotView-info .slotSelectionDropdownMultiple .selectionDropdownMultiple,
.customModal .slotView-info .slotView-infoItem .selectionDropdown,
.customModal .slotView-info .slotView-infoItem .selectionDropdownMultiple,
.customModal .slotView-info .slotInput .selectionDropdown,
.customModal .slotView-info .slotInput .selectionDropdownMultiple,
.customModal .slotView-info .slotSelectionDropdown .selectionDropdown,
.customModal .slotView-info .slotSelectionDropdown .selectionDropdownMultiple,
.customModal .slotView-info .slotSelectionDropdownMultiple .selectionDropdown,
.customModal .slotView-info .slotSelectionDropdownMultiple .selectionDropdownMultiple, .customModal * .slotView-info .slotView-infoItem .selectionDropdown,
.customModal * .slotView-info .slotView-infoItem .selectionDropdownMultiple,
.customModal * .slotView-info .slotInput .selectionDropdown,
.customModal * .slotView-info .slotInput .selectionDropdownMultiple,
.customModal * .slotView-info .slotSelectionDropdown .selectionDropdown,
.customModal * .slotView-info .slotSelectionDropdown .selectionDropdownMultiple,
.customModal * .slotView-info .slotSelectionDropdownMultiple .selectionDropdown,
.customModal * .slotView-info .slotSelectionDropdownMultiple .selectionDropdownMultiple {
  flex-grow: 1;
  font-size: 1rem;
}
.jeeta-app .slotView-info .slotView-infoItem .inputDate,
.jeeta-app .slotView-info .slotView-infoItem .inputTime,
.jeeta-app .slotView-info .slotInput .inputDate,
.jeeta-app .slotView-info .slotInput .inputTime,
.jeeta-app .slotView-info .slotSelectionDropdown .inputDate,
.jeeta-app .slotView-info .slotSelectionDropdown .inputTime,
.jeeta-app .slotView-info .slotSelectionDropdownMultiple .inputDate,
.jeeta-app .slotView-info .slotSelectionDropdownMultiple .inputTime,
.jeeta-app * .slotView-info .slotView-infoItem .inputDate,
.jeeta-app * .slotView-info .slotView-infoItem .inputTime,
.jeeta-app * .slotView-info .slotInput .inputDate,
.jeeta-app * .slotView-info .slotInput .inputTime,
.jeeta-app * .slotView-info .slotSelectionDropdown .inputDate,
.jeeta-app * .slotView-info .slotSelectionDropdown .inputTime,
.jeeta-app * .slotView-info .slotSelectionDropdownMultiple .inputDate,
.jeeta-app * .slotView-info .slotSelectionDropdownMultiple .inputTime,
.customModal .slotView-info .slotView-infoItem .inputDate,
.customModal .slotView-info .slotView-infoItem .inputTime,
.customModal .slotView-info .slotInput .inputDate,
.customModal .slotView-info .slotInput .inputTime,
.customModal .slotView-info .slotSelectionDropdown .inputDate,
.customModal .slotView-info .slotSelectionDropdown .inputTime,
.customModal .slotView-info .slotSelectionDropdownMultiple .inputDate,
.customModal .slotView-info .slotSelectionDropdownMultiple .inputTime, .customModal * .slotView-info .slotView-infoItem .inputDate,
.customModal * .slotView-info .slotView-infoItem .inputTime,
.customModal * .slotView-info .slotInput .inputDate,
.customModal * .slotView-info .slotInput .inputTime,
.customModal * .slotView-info .slotSelectionDropdown .inputDate,
.customModal * .slotView-info .slotSelectionDropdown .inputTime,
.customModal * .slotView-info .slotSelectionDropdownMultiple .inputDate,
.customModal * .slotView-info .slotSelectionDropdownMultiple .inputTime {
  flex-grow: 1;
  font-size: 1rem;
  overflow: visible;
  padding: 0.3rem;
  border-radius: 0.3rem;
  border: 1px solid hsl(0, 0%, 80%);
}
.jeeta-app .slotView-info #batches,
.jeeta-app * .slotView-info #batches,
.customModal .slotView-info #batches, .customModal * .slotView-info #batches {
  grid-column: span 3;
}
.jeeta-app .btns,
.jeeta-app * .btns,
.customModal .btns, .customModal * .btns {
  width: 100%;
  justify-content: center;
  gap: 1rem;
}
.jeeta-app #back,
.jeeta-app #edit,
.jeeta-app * #back,
.jeeta-app * #edit,
.customModal #back,
.customModal #edit, .customModal * #back,
.customModal * #edit {
  background-color: var(--SecondaryColor);
}
.jeeta-app #delete,
.jeeta-app * #delete,
.customModal #delete, .customModal * #delete {
  background-color: var(--tiger-lilly);
}
.jeeta-app .slotScheduleEdit,
.jeeta-app * .slotScheduleEdit,
.customModal .slotScheduleEdit, .customModal * .slotScheduleEdit {
  margin: 0 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
  background-color: var(--PrimaryBkg);
  border: none;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}
.jeeta-app .slotScheduleEdit .scheduleEditTitle,
.jeeta-app * .slotScheduleEdit .scheduleEditTitle,
.customModal .slotScheduleEdit .scheduleEditTitle, .customModal * .slotScheduleEdit .scheduleEditTitle {
  color: var(--whiteColor);
  font-weight: 700;
}
.jeeta-app .slotScheduleEdit .scheduleDates,
.jeeta-app * .slotScheduleEdit .scheduleDates,
.customModal .slotScheduleEdit .scheduleDates, .customModal * .slotScheduleEdit .scheduleDates {
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .slotScheduleEdit .scheduleDates .slotInput,
.jeeta-app * .slotScheduleEdit .scheduleDates .slotInput,
.customModal .slotScheduleEdit .scheduleDates .slotInput, .customModal * .slotScheduleEdit .scheduleDates .slotInput {
  padding: 0;
}
.jeeta-app .slotScheduleEdit .btns,
.jeeta-app * .slotScheduleEdit .btns,
.customModal .slotScheduleEdit .btns, .customModal * .slotScheduleEdit .btns {
  width: auto;
  gap: 0.5rem;
}
.jeeta-app .confirmPopup,
.jeeta-app * .confirmPopup,
.customModal .confirmPopup, .customModal * .confirmPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--whiteColorDeam);
  padding: 2.5rem 3rem 1rem 3rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(189, 195, 199, 0.5);
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .confirmPopup .radio-group,
.jeeta-app * .confirmPopup .radio-group,
.customModal .confirmPopup .radio-group, .customModal * .confirmPopup .radio-group {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
}
.jeeta-app .confirmPopup .radio-group .radio-option,
.jeeta-app * .confirmPopup .radio-group .radio-option,
.customModal .confirmPopup .radio-group .radio-option, .customModal * .confirmPopup .radio-group .radio-option {
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;
}
.jeeta-app .slotPinAuthModal,
.jeeta-app * .slotPinAuthModal,
.customModal .slotPinAuthModal, .customModal * .slotPinAuthModal {
  background-color: var(--whiteColorDeam);
  padding: 2rem;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .slotPinAuthModal .modalTitle,
.jeeta-app * .slotPinAuthModal .modalTitle,
.customModal .slotPinAuthModal .modalTitle, .customModal * .slotPinAuthModal .modalTitle {
  padding: 1rem;
}
.jeeta-app .slotPinAuthModal .pinInput,
.jeeta-app * .slotPinAuthModal .pinInput,
.customModal .slotPinAuthModal .pinInput, .customModal * .slotPinAuthModal .pinInput {
  width: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .slotPinAuthModal .lectureInfoInput,
.jeeta-app * .slotPinAuthModal .lectureInfoInput,
.customModal .slotPinAuthModal .lectureInfoInput, .customModal * .slotPinAuthModal .lectureInfoInput {
  width: 80%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .slotPinAuthModal .lectureInfoInput .infoLabel,
.jeeta-app * .slotPinAuthModal .lectureInfoInput .infoLabel,
.customModal .slotPinAuthModal .lectureInfoInput .infoLabel, .customModal * .slotPinAuthModal .lectureInfoInput .infoLabel {
  font-weight: 600;
}
.jeeta-app .slotPinAuthModal .lectureInfoInput .textInput,
.jeeta-app * .slotPinAuthModal .lectureInfoInput .textInput,
.customModal .slotPinAuthModal .lectureInfoInput .textInput, .customModal * .slotPinAuthModal .lectureInfoInput .textInput {
  padding: 0.5rem;
  border: none;
}
.jeeta-app .slotPinAuthModal #enterPIN,
.jeeta-app * .slotPinAuthModal #enterPIN,
.customModal .slotPinAuthModal #enterPIN, .customModal * .slotPinAuthModal #enterPIN {
  margin-top: 1rem;
  font-weight: 600;
  transform: scale(1.1);
}
.jeeta-app .slotPinAuthModal #close,
.jeeta-app * .slotPinAuthModal #close,
.customModal .slotPinAuthModal #close, .customModal * .slotPinAuthModal #close {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  color: var(--PrimaryColor);
  align-self: center;
}
.jeeta-app .slotPinAuthModal #close:hover,
.jeeta-app * .slotPinAuthModal #close:hover,
.customModal .slotPinAuthModal #close:hover, .customModal * .slotPinAuthModal #close:hover {
  color: var(--tiger-lilly);
}
.jeeta-app .slotLiveDoubts,
.jeeta-app * .slotLiveDoubts,
.customModal .slotLiveDoubts, .customModal * .slotLiveDoubts {
  padding-top: 0.5rem;
  position: relative;
  width: 95%;
  height: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.jeeta-app .doubtsContainer,
.jeeta-app * .doubtsContainer,
.customModal .doubtsContainer, .customModal * .doubtsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--whiteColorDeam);
  border-radius: 1rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  min-height: 25rem;
}
.jeeta-app .doubtsContainer .title,
.jeeta-app * .doubtsContainer .title,
.customModal .doubtsContainer .title, .customModal * .doubtsContainer .title {
  color: var(--textColor);
}
.jeeta-app .doubtsContainer .no-live-doubts-msg,
.jeeta-app * .doubtsContainer .no-live-doubts-msg,
.customModal .doubtsContainer .no-live-doubts-msg, .customModal * .doubtsContainer .no-live-doubts-msg {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--tiger-lilly);
}
.jeeta-app #doubtsRefresh,
.jeeta-app * #doubtsRefresh,
.customModal #doubtsRefresh, .customModal * #doubtsRefresh {
  transform: scale(1.1);
}
.jeeta-app .doubtsList,
.jeeta-app * .doubtsList,
.customModal .doubtsList, .customModal * .doubtsList {
  padding: 0.5rem 0;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .doubtsList .doubtItem,
.jeeta-app * .doubtsList .doubtItem,
.customModal .doubtsList .doubtItem, .customModal * .doubtsList .doubtItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--whiteColor);
  background-color: var(--PrimaryBkg);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.jeeta-app .doubtsList .doubtItem .doubt-text,
.jeeta-app * .doubtsList .doubtItem .doubt-text,
.customModal .doubtsList .doubtItem .doubt-text, .customModal * .doubtsList .doubtItem .doubt-text {
  color: var(--textColor);
  font-weight: 600;
}
.jeeta-app .doubtsList .doubtItem .time,
.jeeta-app * .doubtsList .doubtItem .time,
.customModal .doubtsList .doubtItem .time, .customModal * .doubtsList .doubtItem .time {
  align-self: flex-end;
}
.jeeta-app .doubtsList .doubtItem.even,
.jeeta-app * .doubtsList .doubtItem.even,
.customModal .doubtsList .doubtItem.even, .customModal * .doubtsList .doubtItem.even {
  background-color: var(--SecondaryBkg);
}
.jeeta-app .doubtClusterList,
.jeeta-app * .doubtClusterList,
.customModal .doubtClusterList, .customModal * .doubtClusterList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.jeeta-app .newDoubtForm,
.jeeta-app * .newDoubtForm,
.customModal .newDoubtForm, .customModal * .newDoubtForm {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  gap: 1rem;
}
.jeeta-app .newDoubtForm .newDoubtInput,
.jeeta-app * .newDoubtForm .newDoubtInput,
.customModal .newDoubtForm .newDoubtInput, .customModal * .newDoubtForm .newDoubtInput {
  width: 100%;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--SecondaryColor);
}
.jeeta-app .newDoubtForm .submitDoubtBtn,
.jeeta-app * .newDoubtForm .submitDoubtBtn,
.customModal .newDoubtForm .submitDoubtBtn, .customModal * .newDoubtForm .submitDoubtBtn {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
  cursor: pointer;
}
.jeeta-app .newDoubtForm .submitDoubtBtn:hover,
.jeeta-app * .newDoubtForm .submitDoubtBtn:hover,
.customModal .newDoubtForm .submitDoubtBtn:hover, .customModal * .newDoubtForm .submitDoubtBtn:hover {
  background-color: var(--SecondaryColor);
}
.jeeta-app .chapters-table-container,
.jeeta-app * .chapters-table-container,
.customModal .chapters-table-container, .customModal * .chapters-table-container {
  margin-top: 1.2rem;
}
.jeeta-app .chapters-table,
.jeeta-app * .chapters-table,
.customModal .chapters-table, .customModal * .chapters-table {
  width: 100%;
  border-collapse: collapse;
}
.jeeta-app .chapters-table th,
.jeeta-app .chapters-table td,
.jeeta-app * .chapters-table th,
.jeeta-app * .chapters-table td,
.customModal .chapters-table th,
.customModal .chapters-table td, .customModal * .chapters-table th,
.customModal * .chapters-table td {
  border: 1px solid #ddd;
  padding: 0.5rem;
  text-align: left;
}
.jeeta-app .chapters-table th,
.jeeta-app * .chapters-table th,
.customModal .chapters-table th, .customModal * .chapters-table th {
  background-color: #f2f2f2;
}
.jeeta-app .chapters-table tbody tr:hover,
.jeeta-app * .chapters-table tbody tr:hover,
.customModal .chapters-table tbody tr:hover, .customModal * .chapters-table tbody tr:hover {
  background-color: #eaeaea;
  /* Light grey background on hover */
  cursor: pointer;
  /* Change cursor to indicate clickability */
}
.jeeta-app .video-chapter,
.jeeta-app * .video-chapter,
.customModal .video-chapter, .customModal * .video-chapter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.jeeta-app #meetingSDKElement,
.jeeta-app * #meetingSDKElement,
.customModal #meetingSDKElement, .customModal * #meetingSDKElement {
  height: 100%;
  width: 100%;
  z-index: 10;
}
.jeeta-app .MuiPopper-root,
.jeeta-app * .MuiPopper-root,
.customModal .MuiPopper-root, .customModal * .MuiPopper-root {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 9999;
  /* Ensure it stays on top */
}
.jeeta-app .slotQuestions,
.jeeta-app * .slotQuestions,
.customModal .slotQuestions, .customModal * .slotQuestions {
  padding-top: 0.5rem;
  position: relative;
  width: 95%;
  height: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.jeeta-app .slotQuestions .title,
.jeeta-app * .slotQuestions .title,
.customModal .slotQuestions .title, .customModal * .slotQuestions .title {
  flex-direction: column;
  align-items: center;
}
.jeeta-app .slotQuestions .slotQuestionSlider,
.jeeta-app * .slotQuestions .slotQuestionSlider,
.customModal .slotQuestions .slotQuestionSlider, .customModal * .slotQuestions .slotQuestionSlider {
  position: relative;
  width: 95%;
  min-height: 13rem;
  background: var(--cardBG);
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  padding: 0 0.5rem;
}
@media all and (min-width: 1200px) {
  .jeeta-app .slotView-info,
  .jeeta-app * .slotView-info,
  .customModal .slotView-info, .customModal * .slotView-info {
    gap: 1.5rem 3rem;
  }
}
@media all and (max-width: 1000px) {
  .jeeta-app .slotView .LecturePIN,
  .jeeta-app * .slotView .LecturePIN,
  .customModal .slotView .LecturePIN, .customModal * .slotView .LecturePIN {
    transform: scale(0.95);
  }
  .jeeta-app .doubtClusterList,
  .jeeta-app * .doubtClusterList,
  .customModal .doubtClusterList, .customModal * .doubtClusterList {
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }
  .jeeta-app .chapters-table-wrapper,
  .jeeta-app * .chapters-table-wrapper,
  .customModal .chapters-table-wrapper, .customModal * .chapters-table-wrapper {
    max-height: 35rem;
  }
}
@media all and (max-width: 860px) {
  .jeeta-app .slotView .LecturePIN,
  .jeeta-app * .slotView .LecturePIN,
  .customModal .slotView .LecturePIN, .customModal * .slotView .LecturePIN {
    transform: scale(0.9);
  }
  .jeeta-app .slotView-main,
  .jeeta-app * .slotView-main,
  .customModal .slotView-main, .customModal * .slotView-main {
    padding: 1rem 0;
  }
  .jeeta-app .slotView-main .title,
  .jeeta-app * .slotView-main .title,
  .customModal .slotView-main .title, .customModal * .slotView-main .title {
    transform: scale(0.9);
  }
  .jeeta-app .doubtsContainer,
  .jeeta-app * .doubtsContainer,
  .customModal .doubtsContainer, .customModal * .doubtsContainer {
    min-height: auto;
  }
  .jeeta-app .chapters-table-wrapper,
  .jeeta-app * .chapters-table-wrapper,
  .customModal .chapters-table-wrapper, .customModal * .chapters-table-wrapper {
    max-height: 30rem;
  }
  .jeeta-app .slotView-info,
  .jeeta-app * .slotView-info,
  .customModal .slotView-info, .customModal * .slotView-info {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
}
@media all and (max-width: 800px) {
  .jeeta-app .slotView,
  .jeeta-app * .slotView,
  .customModal .slotView, .customModal * .slotView {
    padding: 0.5rem;
    gap: 0.5rem;
  }
  .jeeta-app .slotView-header,
  .jeeta-app * .slotView-header,
  .customModal .slotView-header, .customModal * .slotView-header {
    margin: 0.5rem 0;
    padding: 0.5rem 0;
  }
  .jeeta-app .slotView-glass,
  .jeeta-app * .slotView-glass,
  .customModal .slotView-glass, .customModal * .slotView-glass {
    grid-template-columns: 3rem auto;
  }
}
@media all and (max-width: 720px) {
  .jeeta-app .slotView .LecturePIN,
  .jeeta-app * .slotView .LecturePIN,
  .customModal .slotView .LecturePIN, .customModal * .slotView .LecturePIN {
    transform: scale(0.8);
  }
  .jeeta-app .slotView-glass,
  .jeeta-app * .slotView-glass,
  .customModal .slotView-glass, .customModal * .slotView-glass {
    padding: 0;
    gap: 0;
    font-size: 1rem;
  }
  .jeeta-app .slotView-info,
  .jeeta-app * .slotView-info,
  .customModal .slotView-info, .customModal * .slotView-info {
    width: 80%;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem 1rem;
  }
  .jeeta-app .slotView-info #batches,
  .jeeta-app * .slotView-info #batches,
  .customModal .slotView-info #batches, .customModal * .slotView-info #batches {
    grid-column: span 2;
  }
  .jeeta-app .slotLectureVideo .videoPlayer,
  .jeeta-app .slotLectureSummary .videoPlayer,
  .jeeta-app * .slotLectureVideo .videoPlayer,
  .jeeta-app * .slotLectureSummary .videoPlayer,
  .customModal .slotLectureVideo .videoPlayer,
  .customModal .slotLectureSummary .videoPlayer, .customModal * .slotLectureVideo .videoPlayer,
  .customModal * .slotLectureSummary .videoPlayer {
    min-height: 300px;
  }
  .jeeta-app .slotLectureVideo .videoPlayer .no-video-msg,
  .jeeta-app .slotLectureVideo .videoPlayer .live-class-msg,
  .jeeta-app .slotLectureVideo .videoPlayer .end-recording-msg,
  .jeeta-app .slotLectureSummary .videoPlayer .no-video-msg,
  .jeeta-app .slotLectureSummary .videoPlayer .live-class-msg,
  .jeeta-app .slotLectureSummary .videoPlayer .end-recording-msg,
  .jeeta-app * .slotLectureVideo .videoPlayer .no-video-msg,
  .jeeta-app * .slotLectureVideo .videoPlayer .live-class-msg,
  .jeeta-app * .slotLectureVideo .videoPlayer .end-recording-msg,
  .jeeta-app * .slotLectureSummary .videoPlayer .no-video-msg,
  .jeeta-app * .slotLectureSummary .videoPlayer .live-class-msg,
  .jeeta-app * .slotLectureSummary .videoPlayer .end-recording-msg,
  .customModal .slotLectureVideo .videoPlayer .no-video-msg,
  .customModal .slotLectureVideo .videoPlayer .live-class-msg,
  .customModal .slotLectureVideo .videoPlayer .end-recording-msg,
  .customModal .slotLectureSummary .videoPlayer .no-video-msg,
  .customModal .slotLectureSummary .videoPlayer .live-class-msg,
  .customModal .slotLectureSummary .videoPlayer .end-recording-msg, .customModal * .slotLectureVideo .videoPlayer .no-video-msg,
  .customModal * .slotLectureVideo .videoPlayer .live-class-msg,
  .customModal * .slotLectureVideo .videoPlayer .end-recording-msg,
  .customModal * .slotLectureSummary .videoPlayer .no-video-msg,
  .customModal * .slotLectureSummary .videoPlayer .live-class-msg,
  .customModal * .slotLectureSummary .videoPlayer .end-recording-msg {
    transform: scale(0.95);
  }
  .jeeta-app .slotLectureVideo .videoPlayer #record,
  .jeeta-app .slotLectureSummary .videoPlayer #record,
  .jeeta-app * .slotLectureVideo .videoPlayer #record,
  .jeeta-app * .slotLectureSummary .videoPlayer #record,
  .customModal .slotLectureVideo .videoPlayer #record,
  .customModal .slotLectureSummary .videoPlayer #record, .customModal * .slotLectureVideo .videoPlayer #record,
  .customModal * .slotLectureSummary .videoPlayer #record {
    transform: scale(1.1);
  }
  .jeeta-app .slotLiveDoubts .doubtsList,
  .jeeta-app .slotLiveDoubts .newDoubtForm,
  .jeeta-app * .slotLiveDoubts .doubtsList,
  .jeeta-app * .slotLiveDoubts .newDoubtForm,
  .customModal .slotLiveDoubts .doubtsList,
  .customModal .slotLiveDoubts .newDoubtForm, .customModal * .slotLiveDoubts .doubtsList,
  .customModal * .slotLiveDoubts .newDoubtForm {
    width: 95%;
  }
  .jeeta-app .doubtClusterList,
  .jeeta-app * .doubtClusterList,
  .customModal .doubtClusterList, .customModal * .doubtClusterList {
    grid-template-columns: repeat(2, 1fr);
  }
  .jeeta-app .slotPinAuthModal,
  .jeeta-app * .slotPinAuthModal,
  .customModal .slotPinAuthModal, .customModal * .slotPinAuthModal {
    padding: 1.5rem;
  }
  .jeeta-app .slotQuestions .slotQuestionSlider,
  .jeeta-app * .slotQuestions .slotQuestionSlider,
  .customModal .slotQuestions .slotQuestionSlider, .customModal * .slotQuestions .slotQuestionSlider {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0;
  }
}
@media all and (max-width: 550px) {
  .jeeta-app .slotView-header,
  .jeeta-app * .slotView-header,
  .customModal .slotView-header, .customModal * .slotView-header {
    margin: 0;
    padding: 0.5rem;
    font-size: 0.9rem;
  }
  .jeeta-app .slotView-glass,
  .jeeta-app * .slotView-glass,
  .customModal .slotView-glass, .customModal * .slotView-glass {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0;
  }
  .jeeta-app .slotView-glass .btns span,
  .jeeta-app * .slotView-glass .btns span,
  .customModal .slotView-glass .btns span, .customModal * .slotView-glass .btns span {
    display: none;
  }
  .jeeta-app .slotView-main,
  .jeeta-app * .slotView-main,
  .customModal .slotView-main, .customModal * .slotView-main {
    max-width: 100%;
    gap: 0.5rem;
  }
  .jeeta-app .slotScheduleEdit,
  .jeeta-app * .slotScheduleEdit,
  .customModal .slotScheduleEdit, .customModal * .slotScheduleEdit {
    flex-direction: column;
    margin: 0.5rem 0;
    padding: 0.5rem 1.5rem;
    gap: 0.5rem;
  }
  .jeeta-app .slotScheduleEdit .scheduleDates,
  .jeeta-app * .slotScheduleEdit .scheduleDates,
  .customModal .slotScheduleEdit .scheduleDates, .customModal * .slotScheduleEdit .scheduleDates {
    flex-direction: column;
    gap: 0.5rem;
  }
  .jeeta-app .slotView-info,
  .jeeta-app * .slotView-info,
  .customModal .slotView-info, .customModal * .slotView-info {
    width: auto;
    margin-top: 0.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .jeeta-app .slotView-info .slotView-infoItem,
  .jeeta-app .slotView-info .slotInput,
  .jeeta-app .slotView-info .slotSelectionDropdown,
  .jeeta-app * .slotView-info .slotView-infoItem,
  .jeeta-app * .slotView-info .slotInput,
  .jeeta-app * .slotView-info .slotSelectionDropdown,
  .customModal .slotView-info .slotView-infoItem,
  .customModal .slotView-info .slotInput,
  .customModal .slotView-info .slotSelectionDropdown, .customModal * .slotView-info .slotView-infoItem,
  .customModal * .slotView-info .slotInput,
  .customModal * .slotView-info .slotSelectionDropdown {
    padding: 0.1rem 0;
  }
  .jeeta-app .slotView-info .slotView-infoItem .selectionDropdown,
  .jeeta-app .slotView-info .slotView-infoItem .selectionDropdownMultiple,
  .jeeta-app .slotView-info .slotView-infoItem .inputDate,
  .jeeta-app .slotView-info .slotView-infoItem .inputTime,
  .jeeta-app .slotView-info .slotInput .selectionDropdown,
  .jeeta-app .slotView-info .slotInput .selectionDropdownMultiple,
  .jeeta-app .slotView-info .slotInput .inputDate,
  .jeeta-app .slotView-info .slotInput .inputTime,
  .jeeta-app .slotView-info .slotSelectionDropdown .selectionDropdown,
  .jeeta-app .slotView-info .slotSelectionDropdown .selectionDropdownMultiple,
  .jeeta-app .slotView-info .slotSelectionDropdown .inputDate,
  .jeeta-app .slotView-info .slotSelectionDropdown .inputTime,
  .jeeta-app * .slotView-info .slotView-infoItem .selectionDropdown,
  .jeeta-app * .slotView-info .slotView-infoItem .selectionDropdownMultiple,
  .jeeta-app * .slotView-info .slotView-infoItem .inputDate,
  .jeeta-app * .slotView-info .slotView-infoItem .inputTime,
  .jeeta-app * .slotView-info .slotInput .selectionDropdown,
  .jeeta-app * .slotView-info .slotInput .selectionDropdownMultiple,
  .jeeta-app * .slotView-info .slotInput .inputDate,
  .jeeta-app * .slotView-info .slotInput .inputTime,
  .jeeta-app * .slotView-info .slotSelectionDropdown .selectionDropdown,
  .jeeta-app * .slotView-info .slotSelectionDropdown .selectionDropdownMultiple,
  .jeeta-app * .slotView-info .slotSelectionDropdown .inputDate,
  .jeeta-app * .slotView-info .slotSelectionDropdown .inputTime,
  .customModal .slotView-info .slotView-infoItem .selectionDropdown,
  .customModal .slotView-info .slotView-infoItem .selectionDropdownMultiple,
  .customModal .slotView-info .slotView-infoItem .inputDate,
  .customModal .slotView-info .slotView-infoItem .inputTime,
  .customModal .slotView-info .slotInput .selectionDropdown,
  .customModal .slotView-info .slotInput .selectionDropdownMultiple,
  .customModal .slotView-info .slotInput .inputDate,
  .customModal .slotView-info .slotInput .inputTime,
  .customModal .slotView-info .slotSelectionDropdown .selectionDropdown,
  .customModal .slotView-info .slotSelectionDropdown .selectionDropdownMultiple,
  .customModal .slotView-info .slotSelectionDropdown .inputDate,
  .customModal .slotView-info .slotSelectionDropdown .inputTime, .customModal * .slotView-info .slotView-infoItem .selectionDropdown,
  .customModal * .slotView-info .slotView-infoItem .selectionDropdownMultiple,
  .customModal * .slotView-info .slotView-infoItem .inputDate,
  .customModal * .slotView-info .slotView-infoItem .inputTime,
  .customModal * .slotView-info .slotInput .selectionDropdown,
  .customModal * .slotView-info .slotInput .selectionDropdownMultiple,
  .customModal * .slotView-info .slotInput .inputDate,
  .customModal * .slotView-info .slotInput .inputTime,
  .customModal * .slotView-info .slotSelectionDropdown .selectionDropdown,
  .customModal * .slotView-info .slotSelectionDropdown .selectionDropdownMultiple,
  .customModal * .slotView-info .slotSelectionDropdown .inputDate,
  .customModal * .slotView-info .slotSelectionDropdown .inputTime {
    font-size: 0.95rem;
  }
  .jeeta-app .slotLectureVideo .videoPlayer .no-video-msg,
  .jeeta-app .slotLectureVideo .videoPlayer .live-class-msg,
  .jeeta-app .slotLectureVideo .videoPlayer .end-recording-msg,
  .jeeta-app .slotLectureSummary .videoPlayer .no-video-msg,
  .jeeta-app .slotLectureSummary .videoPlayer .live-class-msg,
  .jeeta-app .slotLectureSummary .videoPlayer .end-recording-msg,
  .jeeta-app * .slotLectureVideo .videoPlayer .no-video-msg,
  .jeeta-app * .slotLectureVideo .videoPlayer .live-class-msg,
  .jeeta-app * .slotLectureVideo .videoPlayer .end-recording-msg,
  .jeeta-app * .slotLectureSummary .videoPlayer .no-video-msg,
  .jeeta-app * .slotLectureSummary .videoPlayer .live-class-msg,
  .jeeta-app * .slotLectureSummary .videoPlayer .end-recording-msg,
  .customModal .slotLectureVideo .videoPlayer .no-video-msg,
  .customModal .slotLectureVideo .videoPlayer .live-class-msg,
  .customModal .slotLectureVideo .videoPlayer .end-recording-msg,
  .customModal .slotLectureSummary .videoPlayer .no-video-msg,
  .customModal .slotLectureSummary .videoPlayer .live-class-msg,
  .customModal .slotLectureSummary .videoPlayer .end-recording-msg, .customModal * .slotLectureVideo .videoPlayer .no-video-msg,
  .customModal * .slotLectureVideo .videoPlayer .live-class-msg,
  .customModal * .slotLectureVideo .videoPlayer .end-recording-msg,
  .customModal * .slotLectureSummary .videoPlayer .no-video-msg,
  .customModal * .slotLectureSummary .videoPlayer .live-class-msg,
  .customModal * .slotLectureSummary .videoPlayer .end-recording-msg {
    transform: scale(0.9);
  }
  .jeeta-app .slotLectureVideo .videoPlayer #record,
  .jeeta-app .slotLectureSummary .videoPlayer #record,
  .jeeta-app * .slotLectureVideo .videoPlayer #record,
  .jeeta-app * .slotLectureSummary .videoPlayer #record,
  .customModal .slotLectureVideo .videoPlayer #record,
  .customModal .slotLectureSummary .videoPlayer #record, .customModal * .slotLectureVideo .videoPlayer #record,
  .customModal * .slotLectureSummary .videoPlayer #record {
    transform: scale(1);
  }
  .jeeta-app .slotLiveDoubts,
  .jeeta-app * .slotLiveDoubts,
  .customModal .slotLiveDoubts, .customModal * .slotLiveDoubts {
    gap: 0.5rem;
  }
  .jeeta-app .slotLiveDoubts #doubtsRefresh,
  .jeeta-app * .slotLiveDoubts #doubtsRefresh,
  .customModal .slotLiveDoubts #doubtsRefresh, .customModal * .slotLiveDoubts #doubtsRefresh {
    transform: scale(0.95);
  }
  .jeeta-app .doubtsContainer,
  .jeeta-app * .doubtsContainer,
  .customModal .doubtsContainer, .customModal * .doubtsContainer {
    min-height: 20rem;
  }
  .jeeta-app .doubtsContainer .title,
  .jeeta-app * .doubtsContainer .title,
  .customModal .doubtsContainer .title, .customModal * .doubtsContainer .title {
    font-size: 0.9rem;
  }
  .jeeta-app .doubtClusterList,
  .jeeta-app * .doubtClusterList,
  .customModal .doubtClusterList, .customModal * .doubtClusterList {
    display: flex;
    flex-direction: column;
  }
  .jeeta-app .slotPinAuthModal,
  .jeeta-app * .slotPinAuthModal,
  .customModal .slotPinAuthModal, .customModal * .slotPinAuthModal {
    padding: 1rem;
  }
  .jeeta-app .vjs-control,
  .jeeta-app * .vjs-control,
  .customModal .vjs-control, .customModal * .vjs-control {
    width: 7% !important;
  }
  .jeeta-app .vjs-time-control,
  .jeeta-app * .vjs-time-control,
  .customModal .vjs-time-control, .customModal * .vjs-time-control {
    width: 10% !important;
    padding: 0 !important;
  }
  .jeeta-app .vjs-progress-control,
  .jeeta-app * .vjs-progress-control,
  .customModal .vjs-progress-control, .customModal * .vjs-progress-control {
    min-width: 30% !important;
  }
}/*# sourceMappingURL=slotview.css.map */