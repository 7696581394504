.jeeta-app .feedback,
.jeeta-app * .feedback,
.customModal .feedback, .customModal * .feedback {
  position: absolute;
  width: 45rem;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  background-color: var(--whiteColorDeam);
  border: none;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  padding: 3rem 2rem 1rem 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .feedback-title,
.jeeta-app * .feedback-title,
.customModal .feedback-title, .customModal * .feedback-title {
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  color: var(--PrimaryColor);
  font-size: 1.5rem;
  font-weight: 600;
  white-space: nowrap;
}
.jeeta-app .feedback-title .icon,
.jeeta-app * .feedback-title .icon,
.customModal .feedback-title .icon, .customModal * .feedback-title .icon {
  margin-bottom: 1px;
  font-size: 1.66rem;
}
.jeeta-app .feedback-container,
.jeeta-app * .feedback-container,
.customModal .feedback-container, .customModal * .feedback-container {
  position: relative;
  width: 100%;
  margin: 0.5rem 1rem;
  padding: 1rem;
  gap: 1rem;
  border-radius: 1rem;
  background: var(--glass);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.jeeta-app .feedback-container .feedback-question,
.jeeta-app * .feedback-container .feedback-question,
.customModal .feedback-container .feedback-question, .customModal * .feedback-container .feedback-question {
  text-align: center;
  color: var(--textColor);
  font-size: 1.1rem;
  font-weight: 500;
}
.jeeta-app .feedback-container .feedback-answer,
.jeeta-app * .feedback-container .feedback-answer,
.customModal .feedback-container .feedback-answer, .customModal * .feedback-container .feedback-answer {
  flex-direction: column;
  width: 95%;
  gap: 1rem;
}
.jeeta-app .feedback-container .feedback-answer .answer-input,
.jeeta-app * .feedback-container .feedback-answer .answer-input,
.customModal .feedback-container .feedback-answer .answer-input, .customModal * .feedback-container .feedback-answer .answer-input {
  width: 100%;
  border-radius: 0.5rem;
  border: none;
  font-size: 0.95rem;
  padding: 0.5rem;
}
.jeeta-app #close,
.jeeta-app * #close,
.customModal #close, .customModal * #close {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  color: var(--PrimaryColor);
  align-self: center;
}
.jeeta-app #close:hover,
.jeeta-app * #close:hover,
.customModal #close:hover, .customModal * #close:hover {
  color: var(--tiger-lilly);
}
@media all and (max-width: 800px) {
  .jeeta-app .feedback,
  .jeeta-app * .feedback,
  .customModal .feedback, .customModal * .feedback {
    width: 90%;
    padding: 2rem 1rem 1rem 1rem;
    gap: 0.5rem;
  }
}
@media all and (max-width: 550px) {
  .jeeta-app .feedback,
  .jeeta-app * .feedback,
  .customModal .feedback, .customModal * .feedback {
    padding: 2rem 0.5rem 1rem 0.5rem;
  }
  .jeeta-app .feedback-title,
  .jeeta-app * .feedback-title,
  .customModal .feedback-title, .customModal * .feedback-title {
    font-size: 1.25rem;
  }
  .jeeta-app .feedback-title .icon,
  .jeeta-app * .feedback-title .icon,
  .customModal .feedback-title .icon, .customModal * .feedback-title .icon {
    font-size: 1.45rem;
  }
  .jeeta-app .feedback-container,
  .jeeta-app * .feedback-container,
  .customModal .feedback-container, .customModal * .feedback-container {
    margin: 0.5rem 0;
    padding: 0.5rem;
    gap: 0.5rem;
  }
  .jeeta-app .feedback-container .feedback-question,
  .jeeta-app * .feedback-container .feedback-question,
  .customModal .feedback-container .feedback-question, .customModal * .feedback-container .feedback-question {
    font-size: 1rem;
  }
  .jeeta-app .feedback-container .feedback-answer,
  .jeeta-app * .feedback-container .feedback-answer,
  .customModal .feedback-container .feedback-answer, .customModal * .feedback-container .feedback-answer {
    gap: 0.5rem;
  }
  .jeeta-app .feedback-container .feedback-answer .answer-input,
  .jeeta-app * .feedback-container .feedback-answer .answer-input,
  .customModal .feedback-container .feedback-answer .answer-input, .customModal * .feedback-container .feedback-answer .answer-input {
    font-size: 0.88rem;
  }
  .jeeta-app #close,
  .jeeta-app * #close,
  .customModal #close, .customModal * #close {
    transform: scale(0.9);
  }
}
@media all and (max-width: 400px) {
  .jeeta-app .feedback-title,
  .jeeta-app * .feedback-title,
  .customModal .feedback-title, .customModal * .feedback-title {
    font-size: 1.15rem;
  }
  .jeeta-app .feedback-title .icon,
  .jeeta-app * .feedback-title .icon,
  .customModal .feedback-title .icon, .customModal * .feedback-title .icon {
    font-size: 1.35rem;
  }
  .jeeta-app #close,
  .jeeta-app * #close,
  .customModal #close, .customModal * #close {
    transform: scale(0.85);
  }
}/*# sourceMappingURL=Feedback.css.map */