.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.testSubject,
.testSubjectCreate{
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    // background-color: var(--glass);
    color: var(--textColor);
    gap: .5rem;
    animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */

    .subjectTitle{
        width: 100%;
        margin: 1rem 0;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 600;
        gap: 1rem;
    }
}

.emptySubject {
    align-items: center;
    justify-content: center;
}

.testSubject {
    min-height: 50vh;
}

.testSections {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 50vh;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    // background-color: var(--glass);
    color: var(--textColor);
    gap: .5rem;
    animation: fadeUp 1.2s ease-out forwards;
    /* Example duration and timing */

    .subjectTitle {
        width: 100%;
        margin: 1rem 0;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 600;
        gap: 1rem;
    }
}

.testSubjectModal{
    background-color: var(--whiteColorDeam);
    position: fixed;        /* make the modal appear above other content */
    top: 55%;               /* centering the modal vertically */
    left: 50%;              /* centering the modal horizontally */
    transform: translate(-50%, -50%);  /* necessary adjustments for centering */
    z-index: 1000;          /* high value to ensure modal is on top */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    .modalTitle{
        text-align: center;
        font-size: 1.11rem;
        font-weight: 600;
    }

    .subjectCreateSelectionDropdown {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .title {
            font-size: .89rem;
            font-weight: 600;
        }

        .selectionDropdown,
        .selectionDropdownMultiple {
            width: 200px;
            font-size: .83rem;
        }
    }
    
    .buttons {
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
    }

    .btn {
        color: var(--whiteColor);
        font-weight: 600;
        justify-content: center;
        gap: .5rem;
    
        .icon {
            align-self: center;
            font-size: 1rem;
        }
    }

    #close {
        position: absolute;
        top: .5rem;
        right: .5rem;
        color: var(--PrimaryColor);
        align-self: center;
        font-size: 1.67rem;
    }
}

.autoTestSectionCreateContentsContainer{
    width: 100%;
    flex-direction: column;
    gap: .5rem;
    justify-content: flex-start;
    align-items: flex-start;

    .autoTestSectionInfo{
        width: 100%;
        gap: 3rem;
    }
}

//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px){
}

@media all and (max-width: 1000px){
    
}

@media all and (max-width: 720px){
    .testSubject{
        padding: .5rem;

        .subjectTitle{
            gap: .5rem;

            .subjectSelectionDropdown{
                gap: .5rem;

                .title{
                    font-size: 1rem;
                }
    
                .selectionDropdown{
                    width: 150px;
                    font-size: .89rem;
                }
            }
    
            #delete {
                font-size: 1.67rem;
            }
        }

        .hideIcon {
            font-size: 1.11rem;
        }

        .deletePopup {
            gap: .5rem;

            .msg{
                font-size: .89rem;
            }

            .buttons{
                gap: .5rem;
            }
        }
    }
}

@media all and (max-width: 500px){
    .testSubjectModal{
        width: 90%;
        padding: 2rem 1rem;
    }
}
}

