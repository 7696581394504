.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {

.feedback {
    position: absolute;
    width: 45rem;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    // width: max-content;
    background-color: var(--whiteColorDeam);
    border: none;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
    padding: 3rem 2rem 1rem 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.feedback-title {
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: .5rem;
    color: var(--PrimaryColor);
    font-size: 1.5rem;
    font-weight: 600;
    white-space: nowrap;

    .icon {
        margin-bottom: 1px;
        font-size: 1.66rem;
    }
}

.feedback-container{
    position: relative;
    width: 100%;
    margin: .5rem 1rem;
    padding: 1rem;
    gap: 1rem;
    border-radius: 1rem;
    background: var(--glass);
    // opacity: 0.5 !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // z-index: 2;

    .feedback-question {
        text-align: center;
        color: var(--textColor);
        font-size: 1.1rem;
        font-weight: 500;
    }

    .feedback-answer{
        flex-direction: column;
        width: 95%;
        gap: 1rem;

        .answer-input{
            width: 100%;
            border-radius: .5rem;
            border: none;
            font-size: .95rem;
            padding: .5rem;
        }
    }
}

#close {
    position: absolute;
    top: .3rem;
    right: .3rem;
    color: var(--PrimaryColor);
    align-self: center;

    &:hover {
        color: var(--tiger-lilly);
    }
}

//MEDIA QUERIES ==================================================>

@media all and (min-width: 1800px) {}

@media all and (max-width: 800px) {
    .feedback{
        width: 90%;
        padding: 2rem 1rem 1rem 1rem;
        gap: .5rem;
    }

}

@media all and (max-width: 550px) {
    .feedback{
        padding: 2rem .5rem 1rem .5rem;
    }

    .feedback-title {
        font-size: 1.25rem;

        .icon {
            font-size: 1.45rem;
        }
    }

    .feedback-container{
        margin: .5rem 0;
        padding: .5rem;
        gap: .5rem;

        .feedback-question {
            font-size: 1rem;
        }

        .feedback-answer{
            gap: .5rem;

            .answer-input{
                font-size: .88rem;
            }
        }
    }

    #close{
        transform: scale(.9);
    }
}

@media all and (max-width: 400px) {

    .feedback-title {
        font-size: 1.15rem;

        .icon {
            font-size: 1.35rem;
        }
    }

     #close{
        transform: scale(.85);
    }
}
}