.jeeta-app,
.jeeta-app * {
.forward-timer{
    position: relative;
    // width: 100%;
    padding: .2rem .4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--SecondaryBkg);
    color: var(--whiteColor);
    border-radius: .5rem;
    font-size: .94rem;
    font-weight: 600;
    gap: .2rem;

    &:hover{
        cursor: pointer;
        background-color: var(--SecondaryColor);
    }

    .icon{
        font-size: 1.1rem;
    }
}

.forward-timer.visible{
    background-color: var(--SecondaryColor);

    &:hover{
        background-color: var(--SecondaryBkg);
    }
}


.timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.timer-container.on-top{
    background: var(--whiteColor);
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    position: fixed;
    top: 4rem;
    right: .5rem;
    z-index: 10;
}

.timer {
    position: relative;
    width: 8rem;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress-ring {
    width: 100%;
    height: 100%; /* Sets the SVG size to 10rem by 10rem */
    display: flex; /* Ensure it's flex to align child svg */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
}

.progress-ring__circle {
    stroke: var(--easy);
    stroke-width: 1rem;
    fill: transparent;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.progress-ring__circle.half {
    stroke: var(--medium);
}

.progress-ring__circle.little {
    stroke: var(--hard);
}

.timer-label {
    position: absolute;
    font-size: 1.2em;
    font-weight: 600;
    color: var(--textColor);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%; /* Centers the label text in the SVG */
}

.timer-label-input {
    position: absolute;
    font-size: .9rem;
    color: var(--PrimaryColor);
    margin-top: 10%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .2rem;

    .inputTime{
        width: 5.3rem;
        padding: .1rem .3rem;
        border: 1px solid var(--cardBG);
        border-radius: .3rem;
    }

}

.controls button {
    font-size: 1em;
    padding: .2rem .5rem;
    font-weight: 600;
    // margin: 5px;
    background-color: var(--peach);
    color: white;
    border: none;
    border-radius: .5rem;

    &:hover {
        cursor: pointer;
        background-color: var(--tiger-lilly);
        font-weight: 600;
    }
}

@media all and (max-width: 550px) {
    .timer-container {
        transform: scale(.9);
    }
}
}