:root{
    --fc-button-bg-color: rgba(65, 137, 230, 0.5);
    --fc-button-border-color:rgba(65, 137, 230, 0.5);
    --fc-button-active-bg-color:  hsl(214, 77%, 58%);
    --fc-button-active-border-color: rgba(65, 137, 230, 0.5);
    --fc-button-hover-bg-color: hsl(169, 79%, 44%);
    --fc-button-hover-border-color: rgba(24, 201, 168, 0.5);
    --fc-bg-event-color: rgba(24, 201, 168, .3);
    --fc-bg-event-opacity: 0.9;
    --fc-event-bg-color: hsl(169, 79%, 44%);
    --fc-event-border-color: #ddd;
    --fc-today-bg-color: rgba(65, 137, 230, 0.1);
}
.jeeta-app,
.jeeta-app * {
.full-calendar {
    flex-grow: 1;
    display: flex;
    width: 100%;
    height: 100%;
    // padding: 1rem 0;
    background: var(--whiteColor);
    // border-radius: 2rem;
    gap: 1rem;
    grid-template-columns: 11rem auto;
    overflow: hidden;
    // box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
    // font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    // font-size: .83rem;
}

.full-calendar-sidebar{
    display: flex;
    max-width: 18%;
    flex-direction: column;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 2rem;
    transition: all 300ms ease;
}

.full-calendar-logo {
    display: flex;
    flex-direction: column;
    // height: 5rem;
    font-weight: bold;
    font-size: 1.5rem;
    // gap: 1rem;
    align-items: center;
    justify-content: center;
    // height: 4%;

    span {
        white-space: nowrap;
        color: var(--PrimaryColor);

        span {
            color: var(--SecondaryColor);
        }
    }

    img {
        width: 70%;
        height: auto;
    }
}

.total-events {
    margin: 2rem .5rem 1rem 0;
    display: flex;
    align-items: center;
    color: var(--whiteColor);
    background: var(--SecondaryBkg);
    gap: .5rem;
    height: 2.5rem;
    transition: all 300ms ease;
    border-radius: 0.7rem;
    font-size: 1.2rem;
    font-weight: 600;
    white-space: nowrap;

    &::before {
        content: "";
        width: 8px;
        height: 100%;
        background: var(--SecondaryColor);
        margin-right: .5rem;
    }
}

.events-list {
    max-height: 600px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
    color: var(--textColor);

    .event-items {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: .5rem;
        font-size: 1rem;
        line-height: 1.5;
        padding-left: 1rem;
    }

    &::-webkit-scrollbar {
        width: .5rem;
        /* Adjust width as needed */
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        /* Adjust track background color as needed */
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--SecondaryBkg);
        border-radius: .5rem;
    /* Adjust border-radius as needed */
    border: none;
    /* Adjust border and color as needed */
    }
}

.full-calendar-container {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    padding: 1em .5rem;
    overflow-y: scroll;

    .addEvent {
        flex-direction: row;
    }
}

.event-info {
    width: 100%;
    white-space: nowrap;
    color: var(--textColor);

    &::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }

    &:hover {
        cursor: pointer;
        font-weight: 600;
    }
    
    .event-date {
        // padding-right: .3rem;
        // color: var(--PrimaryColor);
    }

    span {
        display: flex;
        align-items: center;
        gap: .1rem;
        .event-subject {
            // display: flex;
            // justify-content: center;
            text-align: center;
            margin: 0 .3rem;
            padding: .1rem .3rem;
            border-radius: .3rem;
            color: var(--whiteColor);
            // font-weight: 600;
        }
    }
    
    
    #Mathematics{
        background-color: var(--Mathematics);
    }

    #Physics{
        background-color: var(--Physics);
    }

    #Chemistry{
        background-color: var(--Chemistry);
    }
    
    .event-teacher{
        // color: var(--PrimaryColor);
    }
}

.event-info-calendar {
    display: flex;
    justify-content: center;
    // padding-left: .2rem;
    align-items: flex-start;
    width: 100%;
    white-space: nowrap;
    color: var(--textColor);
    font-size: .8rem;
    gap: .2rem;
    
    .event-time{
        text-align: center;
    }

    .event-subject {
        text-align: center;
        padding: 0 .2rem;
        border-radius: .2rem;
        color: var(--whiteColor);
    }
    #M{
        background-color: var(--Mathematics);
    }

    #P{
        background-color: var(--Physics);
    }

    #C{
        background-color: var(--Chemistry);
    }
    
    .event-teacher{
        // color: var(--PrimaryColor);
    }
}

// full-calendar customization

.fc{

    .fc-button,
    .fc-bg-event,
    .fc-event-main{
        :hover {
            cursor: pointer;
        }
    }

    .fc-toolbar-title {
        text-align: center;
        color: var(--PrimaryColor);
        font-size: 1.5rem;
    }

    .fc-view-harness {
        height: 100% !important; /* Forces the calendar's view to take up the full height of its container */
    }

    .fc-view-harness-active {
        display: flex;
        flex-direction: column;
    }

    .fc-scrollgrid {
        flex: 1; /* Allows the main calendar grid to flexibly fill the available space */
    }

    .fc-scroller::-webkit-scrollbar {
        width: .5rem;
        /* Adjust width as needed */
    }

    .fc-scroller::-webkit-scrollbar-track {
        background: transparent;
        /* Adjust track background color as needed */
    }

    .fc-scroller::-webkit-scrollbar-thumb {
        background-color: var(--PrimaryBkg);
        border-radius: .5rem;
        /* Adjust border-radius as needed */
        border: none;
        /* Adjust border and color as needed */
    }

    .fc-timegrid-lot-label{
        width: auto;
        white-space: nowrap; 
    }

    .fc-timegrid-slot-lane {
        padding-left: auto !important; /* Adjust padding to fit new label width */
    }
}
// .fc .fc-button-primary:not(:disabled) .fc-button-active,
// .fc .fc-button-primary:not(:disabled):active .fc .fc-button-primary:not(:disabled).fc-button-active,
// .fc .fc-button-primary:not(:disabled):active {
//     background-color: var(--PrimaryColor);
//     // border-color: var(--fc-button-active-border-color);
//     color: var(--whiteColor);
// }

// .fc .fc-view,
// .fc-button {
//     z-index: 0 !important;
// }

// .fc {
//     /* the calendar root */
//     // z-index: 1 !important;
//     margin: 0 auto;

//     :active {
//         z-index: 1 !important;
//     }

    
    
// }



.fc-col-header-cell {
    color: var(--textColor);
    background-color: rgba(24, 201, 168, 0.2);/* Example background color */
    border: 1px solid #ddd !important;/* Example to make the border bolder */
}
.fc-col-header-cell.fc-day.fc-day-sat,
.fc-col-header-cell.fc-day.fc-day-sun{
    background-color: rgba(255,224,224, .65);
}

//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {}

@media all and (max-width: 1000px) {
    // .full-calendar {

    //     .full-calendar-sidebar {
    //         width: 250px;

    //         .events-list {

    //             h2 {
    //                 font-size: 20px;
    //             }

    //             .event-items {

    //                 .event-info {
    //                     font-size: .8rem;
    //                 }
    //             }
    //         }
    //     }
    // }

    // .fc {
    //     .fc-toolbar-chunk {
    //         transform: scale(0.95);
    //     }
    // }
}

@media all and (max-width: 860px) {

    .timetable {
        // padding: .5rem;
        gap: .5rem;
    }

    .full-calendar { 
        width: 100%;
        display: flex;
        flex-direction: column;
        gap:0;
        padding: 0;

        .btns{

            span{
                display: none;
            }
        }
    }

    .full-calendar-main{
        padding: .5rem;
    }

    .full-calendar-sidebar{
        width: 100%;
        max-width: none;
        flex-direction: row;
        align-items: center;
        padding: .5rem .5rem .5rem 1rem;
        gap: .5rem;
        
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 120%; /* Full width of the container */
            height: 2px; /* Thickness of the bottom line */
            background: var(--gradientBkg);
        }
    }

    .full-calendar-logo {

        span{
            // display: none;
            transform: none;
        }

        img{
            display: none;
        }
    }

    .total-events {
        margin: 0;
        height: 1.8rem;
        padding: 0 .5rem;
        border-radius: .5rem;
        gap: 0;

        // span{
        //     display: none;
        // }

        &::before {
            content: "";
            width: 0; /* Full width of the container */
            height: 0; /* Thickness of the bottom line */
            margin: 0;
            background-color: transparent;
        }

        .icon{
            font-size: 1.25rem;
        }
    }

    .events-list {
        flex-direction: row;
        margin-top: 0;
        gap: 0;
        color: var(--PrimaryColor);
        overflow-x: auto;
        
        &::-webkit-scrollbar {
            display: none;
        }

        .event-items {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: .94rem;
            line-height: 1.2;
            padding: 0;
            gap: 0;
        }
    }

    .event-info {
        width: auto;
        white-space: nowrap;
        color: var(--textColor);
        display: flex;
        flex-direction: column;
        transform: scale(.8);
    }
}

@media all and (max-width: 550px){

    .full-calendar-logo{
        display: none;
    }

    .event-info-calendar {
        font-size: .5rem;
        gap: 0.05rem;
    }

    .fc {

        .fc-button{
            padding: 0rem .2rem;
        }

        .fc-toolbar{
            font-size: .8rem;
        }

        .fc-toolbar-title{
            font-size: 1.2rem;
        }

        table{
            font-size: .8rem;
        }

        

    //     .fc-col-header {
    //         font-size: 14px;
    //     }

    //     colgroup {
    //         col {
    //             width: 42px !important;
    //         }
    //     }

    //     .fc-timegrid-axis,
    //     .fc-timegrid-slot-label {
    //         transform: scale(0.9);
    //     }

    //     .fc-timegrid-axis-cushion {
    //         max-width: 40px;
    //     }
    }
}}