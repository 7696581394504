.jeeta-app,
.jeeta-app * {
    .timer-btn {
    padding: .2rem 5rem;
    font-size: .9rem;
    font-weight: 600;
    color: var(--PrimaryColor);
    background-color: var(--glass);
    border: none;
    border-radius: .3rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:disabled {
        background-color: var(--glass);
        color: var(--tiger-lilly);
        cursor: not-allowed;
    }
}
}

