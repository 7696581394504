.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
    .questionModal{
    position: absolute;        /* make the modal appear above other content */
    top: 50vh;               /* centering the modal vertically */
    left: 50vw;              /* centering the modal horizontally */
    transform: translate(-50%, -50%);  /* necessary adjustments for centering */
    z-index: 1000;  /* high value to ensure modal is on top */
    width: 80vw;
    // max-width: 1000px;           /* or your desired width */
    height: 90vh;      /* or your desired height */
    overflow-y: auto;       /* display scrollbar only when necessary */
    background-color: var(--whiteColorDeam);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: .5rem;
    padding: 1.5rem 1rem;
    color: var(--textColor);
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    gap: 1rem;

    .modalTitle {
        padding: .5rem 0;
        font-size: 1.39rem;
    }

    .questionContent{
        position: relative;
        width: 100%;
        flex-direction: column;
        gap: 1.5rem;
        padding: 0 1rem;

        .questionCheck{
            width: 100%;
            flex-direction: row;
            justify-content: center;
            gap: .5rem;
            color: var(--PrimaryColor);

            .title{
                font-size: 0.89rem;
                font-weight: 600;
            }

            input{
                transform: scale(1.2);
            }
        }

        .questionInfoInput, .questionTopicInput, .questionTagInput, .questionDifficultyInput{
            width: 100%;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 1.5rem;

            .difficulty-btns{
                width: 100%;
                justify-content: space-between;
                gap: 1rem;

                .difficulty-btn{
                    flex-grow: 1;
                    justify-content: center;
                    padding: .3rem .5rem;
                    border: none;
                    border-radius: .25rem;
                    background-color: var(--PrimaryBkg);
                    color: var(--whiteColor);

                    &:hover{
                        cursor: pointer;
                        background-color: var(--PrimaryColor);
                        font-weight: 600;
                    }
                }

                .difficulty-btn.selected{
                    background-color: var(--PrimaryColor);
                    font-weight: 600;
                }
            }

            .questionSelectionDropdown, .questionSelectionDropdownMultiple{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .5rem;

                .selectionDropdown, .selectionDropdownMultiple{
                    width: 100%;
                    font-size: 0.83rem;

                    .css-wsp0cs-MultiValueGeneric {
                        max-width: 180px;
                        overflow: hidden;
                    }
                }
            }

            .title{
                min-width: 5rem;
                font-size: 0.89rem;
                font-weight: 600;
            }

            #add-btn {
                display: flex;
                justify-content: center;
                border: none;
                background-color: var(--SecondaryColor);
                color: var(--whiteColor);
                padding: .2rem;

                &:hover {
                    cursor: pointer;
                    background-color: var(--PrimaryColor);
                    transition: .3s;
                }

                .icon {
                    align-self: center;
                    font-weight: 600;
                    font-size: 1.22rem;
                }
            }
        }

        .singleChoice,
        .multiChoice,
        .paragraph,
        .matchTheColumn,
        .numerical {
            width: 100%;
            flex-direction: column;
            justify-content: center;
            gap: 1.5rem;

            .questionTextInput,
            .questionRadioInput,
            .questionCheckboxInput,
            .questionSelectionDropdown,
            .questionSelectionDropdownMultiple{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .5rem;

                .title{
                    min-width: 5rem;
                    font-size: 0.89rem;
                    font-weight: 600;

                    .icon {
                        color: var(--textColor);
                        font-size: 0.89rem;
                    }
                }
            
                .text{
                    width: 100%;
                    height: auto;
                    font-size: 0.83rem;
                    padding: .5rem;
                    color: var(--textColor);
                    border: hsl(0, 0%, 80%) solid 1px;
                    border-radius: .3rem;
                    line-height: 1.3;
                }

                .selectionDropdown, .selectionDropdownMultiple{
                    width: 25%;
                    font-size: 0.83rem;

                    .css-wsp0cs-MultiValueGeneric {
                        max-width: 180px;
                        overflow: hidden;
                    }
                }
            }

            .subQuestion{
                width: 100%;
                position: relative;
                flex-direction: column;
                gap: .5rem;
                // border: hsl(0, 0%, 80%) solid 1px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
                padding: 2.5rem 1rem .5rem 1rem;

                .delete-btn {
                    position: absolute;
                    top: 0;
                    right: 0;
                    border: none;
                    color: white;
                    background-color: var(--SecondaryColor);
                    display: flex;
                    justify-content: center;
                    padding: .2rem;

                    .icon{
                        font-size: 1.39rem;
                    }

                    &:hover{
                        cursor: pointer;
                        background-color: var(--tiger-lilly);
                    }
                }
    
                p {
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: none;
                    color: white;
                    font-size: 1.11rem;
                    background-color: var(--PrimaryColor);
                    padding: 0 .5rem;
                }
            }

            .remove-btn{
                justify-content: center;
                    gap: .5rem;
                    border: none;
                    background-color: var(--tiger-lilly);
                    color: var(--whiteColor);
                    padding: .5rem;
                    font-size: 0.83rem;
            }

            .add-question-btns{
                // width: 100%;
                grid-template-columns: repeat(3, 1fr);
                gap: 1rem;

                .add-btn {
                    width: 100%;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    gap: .5rem;
                    border: none;
                    border-radius: .25rem;
                    background-color: var(--SecondaryColor);
                    color: var(--whiteColor);
                    padding: .5rem;
                    font-size: 0.83rem;
    
                    &:hover {
                        cursor: pointer;
                        background-color: var(--PrimaryColor);
                        transition: .3s;
                    }
    
                    .icon {
                        align-self: center;
                        font-weight: 600;
                        font-size: 1.22rem;
                    }
                }
            }
        }
    }

    .btn {
        color: var(--whiteColor);
        font-weight: 600;
        justify-content: center;
        gap: .5rem;
        
        .icon {
            align-self: center;
            font-size: 1rem;
        }
    }
            
    #submit {
        margin-bottom: 1rem;
    }

    #close {
        position: absolute;
        top: 5px;
        right: 5px;
        color: var(--PrimaryColor);
        align-self: center;
        font-size: 1.67rem;

        &:hover{
            color: var(--tiger-lilly);
        }
    }
}

.radioInput, .checkboxInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: .3rem;

    .radioOption, .checkboxOption {
        flex-grow: 1;  /* Distribute space equally */
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
            font-size: 0.83rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: .1rem;
            padding: .3rem;
            flex-grow: 1;
    
            .optionInput {
                font-size: 0.83rem;
                padding: .3rem;
                border-radius: .3rem;
                border: none;
                color: var(--textColor);
                width: 100%;
                flex-grow: 1;
            }
        }
    }
}


//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px){
}

@media all and (max-width: 860px){
    .questionModal{
        max-width: 650px;           /* or your desired width */
        gap: 0.5rem;
    
        .modalTitle {
            margin: .5rem 0;
            font-size: 23px;
        }
    
        .questionContent{
            gap: 1rem;
            padding: 0 .5rem;
    
            .questionInfoInput{
                flex-direction: column;
                gap: 1rem;
    
                .questionSelectionDropdown, .questionSelectionDropdownMultiple{

                    .title{
                        width: 100px;
                        font-size: 0.83rem;
                    }
    
                    .selectionDropdown, .selectionDropdownMultiple{
                        font-size: 0.83rem;
                    }
                }
    
                #add-btn {    
                    .icon {
                        font-size: 21px;
                    }
                }
            }

            .questionTopicInput, .questionTagInput{
                gap: 1rem;
    
                .questionSelectionDropdown, .questionSelectionDropdownMultiple{

                    .title{
                        width: 100px;
                        font-size: 0.83rem;
                    }
    
                    .selectionDropdown, .selectionDropdownMultiple{
                        font-size: 0.83rem;
                    }
                }
    
                #add-btn {    
                    .icon {
                        font-size: 21px;
                    }
                }
            }
    
            .singleChoice,
            .multiChoice,
            .paragraph,
            .matchTheColumn,
            .numerical {
                gap: 1rem;
    
                .questionTextInput,
                .questionSelectionDropdown,
                .questionSelectionDropdownMultiple{
    
                    .title{
                        width: 100px;
                        font-size: 0.83rem;
    
                        .icon {
                            font-size: 0.83rem;
                        }
                    }
                
                    .text{
                        font-size: 0.8rem;
                    }
    
                    .selectionDropdown, .selectionDropdownMultiple{
                        font-size: 0.83rem;
                    }

                }
    
                .questionRadioInput,
                .questionCheckboxInput{
                    flex-direction: row;
                    align-items: center;

                    .title{
                        width: 100px;
                        font-size: 0.83rem;
    
                        .icon {
                            font-size: 0.83rem;
                        }
                    }
                }
    
                .subQuestion{
                    padding: 2.5rem 1rem .5rem 1rem;
    
                    .delete-btn {
                        .icon{
                            font-size: 1.39rem;
                        }
                    }
        
                    p {
                        font-size: 1.11rem;
                    }
                }
    
                .add-question-btns{
    
                    .add-btn {
                        font-size: 0.8rem;
        
                        .icon {
                            font-size: 1.11rem;
                        }
                    }
                }
            }
        }
    
        .btn {
            color: var(--whiteColor);
            font-weight: 600;
            justify-content: center;
            gap: .5rem;
            
            .icon {
                align-self: center;
                font-size: 1rem;
            }
        }
                
        #submit {
            margin-bottom: 1rem;
        }
    
        #close {
            position: absolute;
            top: 5px;
            right: 5px;
            color: var(--PrimaryColor);
            align-self: center;
            font-size: 1.67rem;
    
            &:hover{
                color: var(--tiger-lilly);
            }
        }
    }

    .radioInput, .checkboxInput {
        flex-direction: column;
        // justify-content: flex-start;
        align-items: flex-start;
        // grid-template-columns: repeat(2, 1fr);
        gap: .3rem;
    }
}

@media all and (max-width: 500px){
    .questionModal{
        width: 90vw;
    
        .modalTitle {
            font-size: 1.11rem;
        }
    
        .questionContent{
            gap: .5rem;
            padding: 0;
    
            .questionInfoInput, .questionTopicInput, .questionTagInput{
                gap: .5rem;
    
                #add-btn {    
                    .icon {
                        font-size: 1.11rem;
                    }
                }
            }

            .singleChoice,
            .multiChoice,
            .paragraph,
            .matchTheColumn,
            .numerical {
                gap: .5rem;
            }


            .questionDifficultyInput{
                .difficulty-btns{
                    flex-direction: column;
                    gap: .5rem;
                }
            }

            .add-question-btns{
                display: flex;
                flex-direction: column;
                gap: .5rem !important;
            }
        }
    }

    .radioInput, .checkboxInput {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: .3rem;

        .radioOption{
            width: 100%;
            justify-content: flex-start;
        }
    }
}
}