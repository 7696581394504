.jeeta-app,
.jeeta-app * {
.CompactCard {
    display: flex;
    flex: 1;
    // justify-content: center;
    gap: 2rem;
    border-radius: 0.7rem;
    color: white;
    padding: 1rem 2rem;
    position: relative;

    &:hover {
        cursor: pointer;
    }
}

#Questions{
    background: linear-gradient(180deg, #bb67ff 0%, #c484f3 100%);

    &:hover {
        box-shadow: 0px 10px 20px 0px #e0c6f5;
    }
}
#Lectures{
    background: linear-gradient(180deg, #FF919D 0%, #FC929D 100%);

    &:hover {
        box-shadow: 0px 10px 20px 0px #FDC0C7;
    }
}

#Tests{
    background: linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%);
    
    &:hover {
        box-shadow: 0px 10px 20px 0px #F9D59B;
    }
}
        
#Doubts{
    background: linear-gradient(180deg, #FF919D 0%, #FC929D 100%);
    
    &:hover {
        box-shadow: 0px 10px 20px 0px rgb(178, 223, 185);
    }
}

.cardInfo{
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    .value{
        font-size: 1.8rem;
        font-weight: 600;
    }

    .title{
        font-size: 1.2rem;
        font-weight: 500;
    }
}

/* radial bar */
.radialBar {
    // flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // gap: 1rem;

    span {
        font-size: 1rem;
        font-weight: bold;
    }
}

.CircularProgressbar {
    width: 5rem !important;
    overflow: visible;
}

.CircularProgressbar-path {
    stroke: white !important;
    stroke-width: .66rem !important;
    filter: drop-shadow(2px 4px 6px white);
}

.CircularProgressbar-trail {
    display: none;
}

.CircularProgressbar-text {
    fill: white !important;
    font-size: 1.2rem !important;
}

/* sideDetail*/
.detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    span:nth-child(2) {
        font-size: 1.25rem;
        font-weight: bold;
    }

    span:nth-child(3) {
        font-size: .66rem;
    }
}

@media screen and (max-width: 780px) {
    .CompactCard{
        padding: 1rem;
    }
}

@media screen and (max-width: 720px) {
    .CompactCard{
        padding: 1rem 2rem;
        width: 100%;
    }
}
}