.jeeta-app, .jeeta-app *, .customModal, .customModal * {

.batches,
.batchView {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  color: var(--textColor);
  animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */


  .btn {
    color: var(--whiteColor);
    font-weight: 600;
    justify-content: space-between;
    gap: .5rem;
    font-size: 0.88rem;

    .icon {
      align-self: center;
      font-size: 1.11rem;
    }
  }

  #manage {
    margin-bottom: 2rem;
  }
}

.create-btn {
  border: none;
  color: var(--PrimaryColor);
  background: transparent;

  &:hover {
    cursor: pointer;
  }

  .icon {
    align-self: center;
    font-size: 2rem;
  }
}

.batches-header {
  position: relative;
  width: 100%;
  padding: .5rem 1rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: var(--gradientBkg);
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

  .title {
    color: var(--whiteColor);
    text-shadow: 0 2px 4px rgba(140, 140, 141, 0.3);
    text-align: center;
  }
}

.batch-filter {
  width: 95%;
  display: flex;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
  z-index: 5;
    // background: var(--cardBG);
    // border-radius: 1rem;
    // box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549)
}

.batchGradeSelectDropdown {
  display: flex;
  justify-content: center;
  gap: 2rem;
  
  .gradeSelectionDropdown,
  .batchSelectionDropdown {
    width: 15rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;

    .title {
      font-size: 0.88rem;
      font-weight: 600;
    }

    .selectionDropdown,
    .selectionDropdownMultiple {
      flex-grow: 1;
      font-size: .83rem;

      .title{
        padding: .35rem .5rem;
      }

      .css-wsp0cs-MultiValueGeneric {
        max-width: 180px;
        overflow: hidden;
      }
    }
  }
}

.batch-glass{
  display: grid;
  width: 100%;
  padding: 1rem;
  background: var(--whiteColor);
  border-radius: 2rem;
  gap: 1rem;
  grid-template-columns: 11rem auto;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}

.timeTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 500px;
  // padding: 1rem 0;
  background: var(--whiteColor);
  border-radius: 2rem;
  // gap: 1rem;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    margin: 2rem .5rem 0 .5rem;
    text-align: center;
    color: var(--SecondaryColor);
  }
  
  .bkgImg{
    width: 25rem;
  }
}

#full-calendar-wrapper{
  min-height: 80vh;
}

.batchModal {
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1000;
  min-width: 600px;
  max-width: 90vw;
  max-height: 90vh;
  overflow-y: auto;
  background-color: var(--whiteColorDeam);
  padding: 2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  .batchCreate,
  .batchScheduleCreate,
  .batchScheduleModify {
    width: 100%;
    color: var(--textColor);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    .modalTitle {
      margin: 1rem 0;
      font-size: 1.38rem;
      text-align: center;
    }

    .batchInfoInput {
      width: 80%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1.5rem;

      .textInput,
      .numberInput,
      .gradeSelectionDropdown,
      .batchSelectionDropdown {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .5rem;

        .title {
          min-width: 5rem;
          font-size: .87rem;
          font-weight: 600;
        }

        input {
          flex-grow: 1;
          border: none;
          border-radius: .3rem;
          padding: .3rem .5rem;
          font-size: .87rem;
        }

        .selectionDropdown,
        .selectionDropdownMultiple {
          width: 100%;
          font-size: .83rem;

          .css-13cymwt-control {
            border: none;
            padding: .2rem;
          }

          .css-wsp0cs-MultiValueGeneric {
            max-width: 180px;
            overflow: hidden;
          }
        }
      }

      .timeDateInput {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;

        .dateInput,
        .timeInput {
          width: 100%;
          display: flex;
          align-items: center;
          gap: .5rem;

          .title {
            min-width: 5rem;
            font-size: 0.88rem;
            font-weight: 600;
          }

          input {
            flex-grow: 1;
            max-width: 180px;
            border: none;
            border-radius: .3rem;
            padding: .5rem;
            font-size: 0.88rem;
          }
        }
      }
    }

    .btn {
      margin-top: 1rem;
      color: var(--whiteColor);
      font-weight: 600;
      justify-content: space-between;
      gap: .5rem;

      .icon {
        align-self: center;
        font-size: 1rem;
      }
    }
  }

  #close {
    position: absolute;
    top: .3rem;
    right: .3rem;
    color: var(--PrimaryColor);
    align-self: center;
    font-size: 1.66rem;
  }
}

//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {}

@media all and (max-width: 1000px) {
  .batches {

    // .batchFilter {

    //   .filterTitle {
    //     gap: .5rem;

    //     .title {
    //       margin-top: .5rem;
    //       // font-size: 1.5rem;
    //     }

    //     span {
    //       // font-size: 1.05rem;
    //     }
    //   }

    //   .filters {
    //     gap: .5rem;

    //     .batchGradeSelectDropdown {
    //       gap: 1.5rem;

    //       .gradeSelectionDropdown,
    //       .batchSelectionDropdown {
    //         width: 250px;

    //         .title {
    //           font-size: 0.88rem;
    //         }

    //         .selectionDropdown,
    //         .selectionDropdownMultiple {
    //           font-size: .83rem;
    //         }
    //       }
    //     }

    //     .create-btn {

    //       .icon {
    //         font-size: 1,85rem;
    //       }
    //     }
    //   }
    // }
  }
}

@media all and (max-width: 860px) {
  .batches,
  .batchView {
    gap: .5rem;

    .LecturePIN {
        transform: scale(0.9);
    }
  }
}

@media all and (max-width: 800px){
  .batches,
  .batchView {
    padding: .5rem;

    .LecturePIN {
        transform: scale(0.8);
    }
  }

  .batches-header {
      margin: .5rem 0;
      padding: .5rem 0;
  }
}

@media all and (max-width: 720px) {
  .batches,
  .batchView {

    .batchFilter {

      .filterTitle {
        padding: .5rem;

        .title {
          margin-top: 0;
        }
      }

      .filters {
        padding: .5rem;

        .create-btn {

          .icon {
            font-size: 1.66rem;
          }
        }
      }
    }
  }

  .batchGradeSelectDropdown {
      gap: 1rem;
  }

  .batchModal {
    min-width: 0;
    width: 80vw;
    gap: .5rem;
    padding: .5rem;

    .batchCreate,
    .batchScheduleCreate,
    .batchScheduleModify {
      transform: scale(0.9);

      .batchInfoInput {
        gap: 1rem;

        .timeDateInput {
          flex-direction: column;
        }
      }
    }
  }
}

@media all and (max-width: 610px) {
  .batchGradeSelectDropdown {
      flex-direction: column;
  }
}

@media all and (max-width: 550px) {
  .batches,
  .batchView {

    .batches-header{
      margin: 0;
      padding: .5rem;
      font-size: .9rem;
    }

    .batchFilter {
      // margin: .5rem 0;
      padding: .5rem 0;
      gap: 1em;

      .filterTitle {

        .title {
          font-size: 1.27rem;
        }

        span {
          font-size: 0.88rem;
        }
      }

      .filters {
        .create-btn {
          margin-top: 3.3rem;

          .icon {
            align-self: center;
            font-size: 1.66rem;
          }
        }
      }
    }
  }

  .timeTable{
    padding: .5rem .1rem;

    .bkgImg{
        width: 90%;
    }
  }

  .batchModal {
    width: 90%;
    gap: .5rem;
    padding: .5rem;

    .batchCreate,
    .batchScheduleCreate,
    .batchScheduleModify {
      gap: 1rem;

      .modalTitle {
        margin: .5rem 0;
      }
    }
  }
}
}