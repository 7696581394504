.jeeta-app .studentTestView,
.jeeta-app * .studentTestView,
.customModal .studentTestView, .customModal * .studentTestView {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  color: var(--textColor);
  animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */
}
.jeeta-app .studentTestSectionTabsContainer,
.jeeta-app * .studentTestSectionTabsContainer,
.customModal .studentTestSectionTabsContainer, .customModal * .studentTestSectionTabsContainer {
  transform: translateY(0.25rem);
  flex-direction: row;
  padding: 0 1rem;
  justify-content: flex-start;
  align-items: center;
}
.jeeta-app .studentTestSectionTabsContainer .studentTestSectionTabs,
.jeeta-app * .studentTestSectionTabsContainer .studentTestSectionTabs,
.customModal .studentTestSectionTabsContainer .studentTestSectionTabs, .customModal * .studentTestSectionTabsContainer .studentTestSectionTabs {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.01rem;
  overflow-x: auto;
  /* Allows horizontal scrolling */
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.jeeta-app .studentTestSectionTabsContainer .studentTestSectionTabs::-webkit-scrollbar,
.jeeta-app * .studentTestSectionTabsContainer .studentTestSectionTabs::-webkit-scrollbar,
.customModal .studentTestSectionTabsContainer .studentTestSectionTabs::-webkit-scrollbar, .customModal * .studentTestSectionTabsContainer .studentTestSectionTabs::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}
.jeeta-app .studentTestSectionTabsContainer .tabItem,
.jeeta-app .studentTestSectionTabsContainer .left-btn,
.jeeta-app .studentTestSectionTabsContainer .right-btn,
.jeeta-app * .studentTestSectionTabsContainer .tabItem,
.jeeta-app * .studentTestSectionTabsContainer .left-btn,
.jeeta-app * .studentTestSectionTabsContainer .right-btn,
.customModal .studentTestSectionTabsContainer .tabItem,
.customModal .studentTestSectionTabsContainer .left-btn,
.customModal .studentTestSectionTabsContainer .right-btn, .customModal * .studentTestSectionTabsContainer .tabItem,
.customModal * .studentTestSectionTabsContainer .left-btn,
.customModal * .studentTestSectionTabsContainer .right-btn {
  white-space: nowrap;
  padding: 0.3rem 1rem 1.5rem 1rem;
  text-decoration: none;
  font-weight: 500;
  color: var(--whiteColor);
  background-color: var(--PrimaryBkg);
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.jeeta-app .studentTestSectionTabsContainer .tabItem:hover,
.jeeta-app .studentTestSectionTabsContainer .left-btn:hover,
.jeeta-app .studentTestSectionTabsContainer .right-btn:hover,
.jeeta-app * .studentTestSectionTabsContainer .tabItem:hover,
.jeeta-app * .studentTestSectionTabsContainer .left-btn:hover,
.jeeta-app * .studentTestSectionTabsContainer .right-btn:hover,
.customModal .studentTestSectionTabsContainer .tabItem:hover,
.customModal .studentTestSectionTabsContainer .left-btn:hover,
.customModal .studentTestSectionTabsContainer .right-btn:hover, .customModal * .studentTestSectionTabsContainer .tabItem:hover,
.customModal * .studentTestSectionTabsContainer .left-btn:hover,
.customModal * .studentTestSectionTabsContainer .right-btn:hover {
  cursor: pointer;
  background-color: var(--PrimaryColor);
  font-weight: 600;
}
.jeeta-app .studentTestSectionTabsContainer .tabItem .icon,
.jeeta-app .studentTestSectionTabsContainer .left-btn .icon,
.jeeta-app .studentTestSectionTabsContainer .right-btn .icon,
.jeeta-app * .studentTestSectionTabsContainer .tabItem .icon,
.jeeta-app * .studentTestSectionTabsContainer .left-btn .icon,
.jeeta-app * .studentTestSectionTabsContainer .right-btn .icon,
.customModal .studentTestSectionTabsContainer .tabItem .icon,
.customModal .studentTestSectionTabsContainer .left-btn .icon,
.customModal .studentTestSectionTabsContainer .right-btn .icon, .customModal * .studentTestSectionTabsContainer .tabItem .icon,
.customModal * .studentTestSectionTabsContainer .left-btn .icon,
.customModal * .studentTestSectionTabsContainer .right-btn .icon {
  font-size: 1.5rem;
}
.jeeta-app .studentTestSectionTabsContainer .tabItem.selected,
.jeeta-app * .studentTestSectionTabsContainer .tabItem.selected,
.customModal .studentTestSectionTabsContainer .tabItem.selected, .customModal * .studentTestSectionTabsContainer .tabItem.selected {
  color: var(--whiteColor);
  background-color: var(--PrimaryColor);
  font-weight: 600;
}
.jeeta-app .studentTestSectionTabsContainer .left-btn,
.jeeta-app .studentTestSectionTabsContainer .right-btn,
.jeeta-app * .studentTestSectionTabsContainer .left-btn,
.jeeta-app * .studentTestSectionTabsContainer .right-btn,
.customModal .studentTestSectionTabsContainer .left-btn,
.customModal .studentTestSectionTabsContainer .right-btn, .customModal * .studentTestSectionTabsContainer .left-btn,
.customModal * .studentTestSectionTabsContainer .right-btn {
  padding: 0.3rem 0.5rem 1.5rem 0.5rem;
}
.jeeta-app .studentTestSectionContentsContainer,
.jeeta-app * .studentTestSectionContentsContainer,
.customModal .studentTestSectionContentsContainer, .customModal * .studentTestSectionContentsContainer {
  position: relative;
  transform: translateY(-1.1rem);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  min-height: 40vh;
  flex-direction: column;
  background-color: var(--whiteColor);
  border-top: 8px solid var(--PrimaryColor);
  border-radius: 0.5rem;
  padding: 0 1rem 1rem 1rem;
}
.jeeta-app .student-tests-glass,
.jeeta-app * .student-tests-glass,
.customModal .student-tests-glass, .customModal * .student-tests-glass {
  width: 100%;
  min-height: 50vh;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .student-tests-glass .title,
.jeeta-app * .student-tests-glass .title,
.customModal .student-tests-glass .title, .customModal * .student-tests-glass .title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--textColor);
}
.jeeta-app .student-tests-glass .title .testTypeSelectionDropdown .css-1dimb5e-singleValue,
.jeeta-app * .student-tests-glass .title .testTypeSelectionDropdown .css-1dimb5e-singleValue,
.customModal .student-tests-glass .title .testTypeSelectionDropdown .css-1dimb5e-singleValue, .customModal * .student-tests-glass .title .testTypeSelectionDropdown .css-1dimb5e-singleValue {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--textColor);
}
.jeeta-app .testsList,
.jeeta-app * .testsList,
.customModal .testsList, .customModal * .testsList {
  width: 100%;
  max-height: 80vh;
  color: var(--textColor);
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .testsTable,
.jeeta-app * .testsTable,
.customModal .testsTable, .customModal * .testsTable {
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 100vh;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}
.jeeta-app .tests-table-style,
.jeeta-app * .tests-table-style,
.customModal .tests-table-style, .customModal * .tests-table-style {
  width: 100%;
  font-size: 0.85rem;
  box-shadow: 0 0 1.11rem rgba(0, 0, 0, 0.15);
}
.jeeta-app .tests-table-style thead tr,
.jeeta-app * .tests-table-style thead tr,
.customModal .tests-table-style thead tr, .customModal * .tests-table-style thead tr {
  background-color: var(--SecondaryBkg);
  color: var(--textColor);
  text-align: center;
  font-size: 0.9rem;
  vertical-align: middle;
}
.jeeta-app .tests-table-style #STARTED,
.jeeta-app * .tests-table-style #STARTED,
.customModal .tests-table-style #STARTED, .customModal * .tests-table-style #STARTED {
  background-color: var(--started);
}
.jeeta-app .tests-table-style #NOT-STARTED,
.jeeta-app * .tests-table-style #NOT-STARTED,
.customModal .tests-table-style #NOT-STARTED, .customModal * .tests-table-style #NOT-STARTED {
  background-color: var(--not-started);
}
.jeeta-app .tests-table-style #FINISHED,
.jeeta-app * .tests-table-style #FINISHED,
.customModal .tests-table-style #FINISHED, .customModal * .tests-table-style #FINISHED {
  background-color: var(--finished);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}
.jeeta-app .tests-table-style #FINISHED .retake-icon,
.jeeta-app * .tests-table-style #FINISHED .retake-icon,
.customModal .tests-table-style #FINISHED .retake-icon, .customModal * .tests-table-style #FINISHED .retake-icon {
  font-size: 0.9rem;
  font-weight: 600;
  color: yellow;
}
.jeeta-app .tests-table-style th,
.jeeta-app .tests-table-style td,
.jeeta-app * .tests-table-style th,
.jeeta-app * .tests-table-style td,
.customModal .tests-table-style th,
.customModal .tests-table-style td, .customModal * .tests-table-style th,
.customModal * .tests-table-style td {
  padding: 10px;
  border-bottom: 1px solid var(--cardBG);
}
.jeeta-app .tests-table-style th .icon,
.jeeta-app .tests-table-style td .icon,
.jeeta-app * .tests-table-style th .icon,
.jeeta-app * .tests-table-style td .icon,
.customModal .tests-table-style th .icon,
.customModal .tests-table-style td .icon, .customModal * .tests-table-style th .icon,
.customModal * .tests-table-style td .icon {
  font-size: 1.5rem;
}
.jeeta-app .tests-table-style tbody tr:hover,
.jeeta-app * .tests-table-style tbody tr:hover,
.customModal .tests-table-style tbody tr:hover, .customModal * .tests-table-style tbody tr:hover {
  font-weight: 600;
  background-color: var(--PrimaryBkg);
  cursor: pointer;
}
.jeeta-app .tests-table-style tbody tr:nth-of-type(even),
.jeeta-app * .tests-table-style tbody tr:nth-of-type(even),
.customModal .tests-table-style tbody tr:nth-of-type(even), .customModal * .tests-table-style tbody tr:nth-of-type(even) {
  background-color: var(--whiteColorDeam);
}
.jeeta-app .tests-table-style tbody tr:nth-of-type(even):hover,
.jeeta-app * .tests-table-style tbody tr:nth-of-type(even):hover,
.customModal .tests-table-style tbody tr:nth-of-type(even):hover, .customModal * .tests-table-style tbody tr:nth-of-type(even):hover {
  font-weight: 600;
  background-color: var(--PrimaryBkg);
}
.jeeta-app .tests-table-style tbody td,
.jeeta-app * .tests-table-style tbody td,
.customModal .tests-table-style tbody td, .customModal * .tests-table-style tbody td {
  white-space: nowrap;
  text-align: center;
  /* Horizontally center the text. */
  vertical-align: middle;
  /* Vertically center the text. */
}
.jeeta-app .tests-table-style tbody td:nth-child(1),
.jeeta-app * .tests-table-style tbody td:nth-child(1),
.customModal .tests-table-style tbody td:nth-child(1), .customModal * .tests-table-style tbody td:nth-child(1) {
  width: 6rem;
}
.jeeta-app .tests-table-style tbody tr:last-of-type td,
.jeeta-app * .tests-table-style tbody tr:last-of-type td,
.customModal .tests-table-style tbody tr:last-of-type td, .customModal * .tests-table-style tbody tr:last-of-type td {
  border-bottom: 0;
}
.jeeta-app .student-test-glass,
.jeeta-app * .student-test-glass,
.customModal .student-test-glass, .customModal * .student-test-glass {
  display: grid;
  width: 100%;
  padding: 1rem 0;
  background: var(--whiteColor);
  border-radius: 2rem;
  gap: 1rem;
  grid-template-columns: 11rem auto;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}
.jeeta-app .studentSelectTestPage,
.jeeta-app * .studentSelectTestPage,
.customModal .studentSelectTestPage, .customModal * .studentSelectTestPage {
  flex-grow: 1;
  display: grid;
  width: 100%;
  padding: 1rem 0;
  background: var(--whiteColor);
  border-radius: 2rem;
  gap: 1rem;
  grid-template-columns: 11rem auto;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}
.jeeta-app .studentSelectTestPage .studentSelectTestPage-main,
.jeeta-app * .studentSelectTestPage .studentSelectTestPage-main,
.customModal .studentSelectTestPage .studentSelectTestPage-main, .customModal * .studentSelectTestPage .studentSelectTestPage-main {
  flex-direction: column;
  width: 100%;
  min-height: 50vh;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
}
.jeeta-app .studentSelectTestPage .studentSelectTestPage-main h3,
.jeeta-app * .studentSelectTestPage .studentSelectTestPage-main h3,
.customModal .studentSelectTestPage .studentSelectTestPage-main h3, .customModal * .studentSelectTestPage .studentSelectTestPage-main h3 {
  color: var(--PrimaryColor);
  text-align: center;
}
@media all and (max-width: 800px) {
  .jeeta-app .studentSelectTestPage,
  .jeeta-app * .studentSelectTestPage,
  .customModal .studentSelectTestPage, .customModal * .studentSelectTestPage {
    grid-template-columns: 3rem auto;
  }
}
@media all and (max-width: 500px) {
  .jeeta-app .studentSelectTestPage,
  .jeeta-app * .studentSelectTestPage,
  .customModal .studentSelectTestPage, .customModal * .studentSelectTestPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0;
  }
}/*# sourceMappingURL=studentTest.css.map */