// .jeeta-app,
// .jeeta-app *,
// .customModal, .customModal * {
//     #zoom-iframe-total {
//         position: relative;
//         width: 100vw;
//         height: 100vh;
//         background-color: white;
//         overflow: hidden; /* Prevent overflow unless in fullscreen */
// }

.drawer-toggle-button{
    position: absolute;
    top: 50%;
    right: 0;
    // transform: translateY(-50%);
    width: 2rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 4000;
}

// .zoom-iframe-container{
//     width: 100%;
//     height: 100%;
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: center;
//     overflow: auto !important;

//     &::-webkit-scrollbar {
//         width: 1rem;
//     }

//     &::-webkit-scrollbar-thumb {
//         border-radius: 1rem;
//         background: var(--PrimaryBkg);
//         transition: all .5s ease-in-out;
//     }

//     &::-webkit-scrollbar-thumb:hover {
//         background: var(--PrimaryColor);
//     }
// }

// .zoom-iframe {
//     width: 99vw;
//     min-height: 99vh;
//     flex-grow: 1;
//     white-space: nowrap;
// }

// .overlay-btns {
//     flex-direction: row;
//     // justify-content: space-between;
//     gap: .3rem;
//     top: 5px;
//     left: 5px;
//     position: absolute;
//     .full-screen{
//     }
// }

// .zoom-doubts {
//     width: 100%;
//     height: max-content;
//     justify-content: center;
//     padding-bottom: .5rem;
// }

// .doubtsContainer {
//     min-height: 0;
// }

// /* Adjust fullscreen handling */
// #zoom-iframe-total:-webkit-full-screen,
// #zoom-iframe-total:-moz-full-screen,
// #zoom-iframe-total:-ms-fullscreen,
// #zoom-iframe-total:fullscreen {
//     overflow: auto !important;

//     &::-webkit-scrollbar {
//         width: 1rem;
//     }

//     &::-webkit-scrollbar-thumb {
//         border-radius: 1rem;
//         background: var(--PrimaryBkg);
//         transition: all .5s ease-in-out;
//     }

//     &::-webkit-scrollbar-thumb:hover {
//         background: var(--PrimaryColor);
//     }
// }

// body:fullscreen {
//     overflow: auto !important;
// }

// body:-ms-fullscreen {
//     overflow: auto !important;
// }

// body:-webkit-full-screen {
//     overflow: auto !important;
// }

// body:-moz-full-screen {
//     overflow: auto !important;
// }

// }
