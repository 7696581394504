.jeeta-app .forward-timer,
.jeeta-app * .forward-timer {
  position: relative;
  padding: 0.2rem 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--SecondaryBkg);
  color: var(--whiteColor);
  border-radius: 0.5rem;
  font-size: 0.94rem;
  font-weight: 600;
  gap: 0.2rem;
}
.jeeta-app .forward-timer:hover,
.jeeta-app * .forward-timer:hover {
  cursor: pointer;
  background-color: var(--SecondaryColor);
}
.jeeta-app .forward-timer .icon,
.jeeta-app * .forward-timer .icon {
  font-size: 1.1rem;
}
.jeeta-app .forward-timer.visible,
.jeeta-app * .forward-timer.visible {
  background-color: var(--SecondaryColor);
}
.jeeta-app .forward-timer.visible:hover,
.jeeta-app * .forward-timer.visible:hover {
  background-color: var(--SecondaryBkg);
}
.jeeta-app .timer-container,
.jeeta-app * .timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.jeeta-app .timer-container.on-top,
.jeeta-app * .timer-container.on-top {
  background: var(--whiteColor);
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  position: fixed;
  top: 4rem;
  right: 0.5rem;
  z-index: 10;
}
.jeeta-app .timer,
.jeeta-app * .timer {
  position: relative;
  width: 8rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jeeta-app .progress-ring,
.jeeta-app * .progress-ring {
  width: 100%;
  height: 100%; /* Sets the SVG size to 10rem by 10rem */
  display: flex; /* Ensure it's flex to align child svg */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}
.jeeta-app .progress-ring__circle,
.jeeta-app * .progress-ring__circle {
  stroke: var(--easy);
  stroke-width: 1rem;
  fill: transparent;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.jeeta-app .progress-ring__circle.half,
.jeeta-app * .progress-ring__circle.half {
  stroke: var(--medium);
}
.jeeta-app .progress-ring__circle.little,
.jeeta-app * .progress-ring__circle.little {
  stroke: var(--hard);
}
.jeeta-app .timer-label,
.jeeta-app * .timer-label {
  position: absolute;
  font-size: 1.2em;
  font-weight: 600;
  color: var(--textColor);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%; /* Centers the label text in the SVG */
}
.jeeta-app .timer-label-input,
.jeeta-app * .timer-label-input {
  position: absolute;
  font-size: 0.9rem;
  color: var(--PrimaryColor);
  margin-top: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}
.jeeta-app .timer-label-input .inputTime,
.jeeta-app * .timer-label-input .inputTime {
  width: 5.3rem;
  padding: 0.1rem 0.3rem;
  border: 1px solid var(--cardBG);
  border-radius: 0.3rem;
}
.jeeta-app .controls button,
.jeeta-app * .controls button {
  font-size: 1em;
  padding: 0.2rem 0.5rem;
  font-weight: 600;
  background-color: var(--peach);
  color: white;
  border: none;
  border-radius: 0.5rem;
}
.jeeta-app .controls button:hover,
.jeeta-app * .controls button:hover {
  cursor: pointer;
  background-color: var(--tiger-lilly);
  font-weight: 600;
}
@media all and (max-width: 550px) {
  .jeeta-app .timer-container,
  .jeeta-app * .timer-container {
    transform: scale(0.9);
  }
}/*# sourceMappingURL=Timer.css.map */