.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.profile {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem .5rem;
    color: var(--textColor);
    animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */

    .userPhoto {
        width: 30%;
        max-width: 350px;
        height: 100%;
        padding: .5rem;
        object-fit: contain;
    }

    .userProfile {
        width: 60%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding: 1rem .5rem;

        .userInfo {
            width: 100%;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: .5rem;

            .infoLabel {
                text-align: left;
                width: 30%;
                font-size: 16px;
                font-weight: 600;
            }

            .infoContent {
                position: relative;
                flex-direction: row;
                width: 60%;
                background-color: var(--whiteColor);
                border-radius: .5rem;
                font-size: 15px;
                padding: .5rem 1rem;

                span {
                    margin-right: 25px;
                    overflow: auto;

                    /* Hide scrollbar for Chrome, Safari, and newer versions of Opera*/
                    &::-webkit-scrollbar {
                        display: none;
                    }

                    /* Hide scrollbar for IE, Edge, and Firefox */
                    -ms-overflow-style: none;
                    /* IE and Edge */
                    scrollbar-width: none;
                    /* Firefox */
                }

                .icon {
                    position: absolute;
                    right: 0;
                    margin: 0 1rem;
                    font-size: 20px;
                    color: var(--PrimaryColor);

                    &:hover {
                        color: var(--SecondaryColor);
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.profileBtns {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 4rem;

    .btn {
        color: var(--whiteColor);
        font-weight: 600;
        justify-content: center;
        align-items: center;
        gap: .5rem;

        .icon {
            align-self: center;
            font-size: 18px;
        }
    }
}

.profileModal {
    width: 650px;
    /* or your desired width */
    // height: 600px;      /* or your desired height */
    overflow-y: auto;
    /* display scrollbar only when necessary */
    background-color: var(--whiteColorDeam);
    padding: 10px;
    position: fixed;
    /* make the modal appear above other content */
    top: 55%;
    /* centering the modal vertically */
    left: 50%;
    /* centering the modal horizontally */
    transform: translate(-50%, -50%);
    /* necessary adjustments for centering */
    z-index: 1000;
    /* high value to ensure modal is on top */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

    .register-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        gap: 1.5rem;

        .registerTitle {
            h3 {
                margin: .5rem 0;
            }
        }

        .inputBox {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.5rem;

            .contactInput {
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: .5rem;

                .inputLabel {
                    width: 130px;
                    font-weight: 600;
                    font-size: 16px;
                }

                input {
                    width: 250px;
                    padding: .4rem .5rem;
                    border: none;
                    border-radius: .5rem;
                    font-size: 14px;
                }

                .icon {
                    margin: .5rem;
                    font-size: 20px;
                    color: var(--PrimaryColor);
                }
            }
        }

        #close {
            position: absolute;
            top: 5px;
            right: 5px;
            color: var(--PrimaryColor);
            align-self: center;
            font-size: 30px;
        }
    }
}

.profilePopup {
    position: absolute;
    top: 35px;
    right: 15px;
    width: max-content;
    background-color: var(--whiteColor);
    border: none;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .profileMessage {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: .3rem;
        color: var(--textColor);

        .icon {
            color: var(--PrimaryColor);
            align-self: center;
            font-size: 25px;
        }
    }

    .profileBtns {
        margin-top: 0;
        gap: .5rem;
    }

    .btn {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 85px;
        padding: .5rem 1rem;
        border: none;
        border-radius: 2rem;
        color: var(--whiteColor);
        font-size: .9rem;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        margin: 0;
    }
}

.report-feedback{
    background-color: var(--orange);
    font-size: .9rem;
    font-weight: 600;
    color: var(--whiteColor);
    border: none;
    border-radius: 2rem;
    padding: .5rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    .icon{
        font-size: 1.2rem;
    }

    &:hover{
        cursor: pointer;
        background-color: var(--pink);
    }
}

.LecturePIN {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    font-size: 1rem;

    span {
        font-weight: 500;
    }

    .PIN-container {
        justify-content: center;
        align-items: center;
        gap: .3rem;

        .number-box {
            width: 23px;
            background-color: var(--PrimaryColor);
            border: none;
            border-radius: .3rem;
            box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
            font-weight: 600;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: var(--whiteColor);
        }
    }
}

//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {}

@media all and (max-width: 860px) {
    .profile {
        flex-direction: column;
        gap: 1rem;

        .userPhoto {
            width: 30%;
        }

        .userProfile {
            width: 100%;
            justify-content: center;

            .userInfo {
                width: 100%;
                justify-content: center;

                .infoLabel {
                    width: 20%;
                }

                .infoContent {
                    width: 50%;

                    span {
                        margin-right: 25px;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 500px) {
    .profile {
        gap: .5rem;

        .userPhoto {
            width: 50%;
        }

        .userProfile {
            padding: .5rem 0;

            .userInfo {

                .infoLabel {
                    width: 30%;
                    font-size: 14.5px;
                }

                .infoContent {
                    width: 60%;
                    font-size: 14px;
                    padding: .3rem .5rem;

                    span {
                        margin-right: 22px;
                    }

                    .icon {
                        margin: 0 .5rem;
                        font-size: 18px;
                    }
                }
            }

            .userProfileBtns {
                gap: 1.5rem;
            }
        }
    }
}
}