.jeeta-app .recorded-video-card,
.jeeta-app * .recorded-video-card,
.customModal .recorded-video-card,
.customModal * .recorded-video-card {
  width: 100%;
  min-height: 20rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  font-size: 1rem;
}
.jeeta-app .recorded-video-card:hover,
.jeeta-app * .recorded-video-card:hover,
.customModal .recorded-video-card:hover,
.customModal * .recorded-video-card:hover {
  cursor: pointer;
}
.jeeta-app .recorded-video-card .bkg-cover,
.jeeta-app * .recorded-video-card .bkg-cover,
.customModal .recorded-video-card .bkg-cover,
.customModal * .recorded-video-card .bkg-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.3);
}
.jeeta-app .recorded-video-card .bkg-cover.APPROVED,
.jeeta-app * .recorded-video-card .bkg-cover.APPROVED,
.customModal .recorded-video-card .bkg-cover.APPROVED,
.customModal * .recorded-video-card .bkg-cover.APPROVED {
  z-index: 0;
}
.jeeta-app .recorded-video-card .bkg-cover.DENIED,
.jeeta-app * .recorded-video-card .bkg-cover.DENIED,
.customModal .recorded-video-card .bkg-cover.DENIED,
.customModal * .recorded-video-card .bkg-cover.DENIED {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}
.jeeta-app .recorded-video-card .material,
.jeeta-app .recorded-video-card .subject,
.jeeta-app * .recorded-video-card .material,
.jeeta-app * .recorded-video-card .subject,
.customModal .recorded-video-card .material,
.customModal .recorded-video-card .subject,
.customModal * .recorded-video-card .material,
.customModal * .recorded-video-card .subject {
  position: relative;
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  padding: 0.1rem;
  color: var(--whiteColor);
  z-index: 1;
}
.jeeta-app .recorded-video-card .material::first-letter,
.jeeta-app * .recorded-video-card .material::first-letter,
.customModal .recorded-video-card .material::first-letter,
.customModal * .recorded-video-card .material::first-letter {
  text-transform: uppercase;
}
.jeeta-app .recorded-video-card .subject.Mathematics,
.jeeta-app * .recorded-video-card .subject.Mathematics,
.customModal .recorded-video-card .subject.Mathematics,
.customModal * .recorded-video-card .subject.Mathematics {
  background-color: var(--Mathematics);
  opacity: 0.9;
  font-weight: 600;
}
.jeeta-app .recorded-video-card .subject.Physics,
.jeeta-app * .recorded-video-card .subject.Physics,
.customModal .recorded-video-card .subject.Physics,
.customModal * .recorded-video-card .subject.Physics {
  background-color: var(--Physics);
  opacity: 0.9;
  font-weight: 600;
}
.jeeta-app .recorded-video-card .subject.Chemistry,
.jeeta-app * .recorded-video-card .subject.Chemistry,
.customModal .recorded-video-card .subject.Chemistry,
.customModal * .recorded-video-card .subject.Chemistry {
  background-color: var(--Chemistry);
  opacity: 0.9;
  font-weight: 600;
}
.jeeta-app .recorded-video-card .approval-status,
.jeeta-app * .recorded-video-card .approval-status,
.customModal .recorded-video-card .approval-status,
.customModal * .recorded-video-card .approval-status {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 3;
  font-weight: 700;
  color: var(--whiteColor);
}
.jeeta-app .recorded-video-card .approval-status .icon,
.jeeta-app * .recorded-video-card .approval-status .icon,
.customModal .recorded-video-card .approval-status .icon,
.customModal * .recorded-video-card .approval-status .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.jeeta-app .recorded-video-card #APPROVED,
.jeeta-app * .recorded-video-card #APPROVED,
.customModal .recorded-video-card #APPROVED,
.customModal * .recorded-video-card #APPROVED {
  color: var(--PrimaryColor);
}
.jeeta-app .recorded-video-card #DENIED,
.jeeta-app * .recorded-video-card #DENIED,
.customModal .recorded-video-card #DENIED,
.customModal * .recorded-video-card #DENIED {
  color: var(--tiger-lilly);
}
.jeeta-app .recorded-video-card.Mathematics,
.jeeta-app * .recorded-video-card.Mathematics,
.customModal .recorded-video-card.Mathematics,
.customModal * .recorded-video-card.Mathematics {
  background-image: url("../../../Assets/Mathematics.jpg");
}
.jeeta-app .recorded-video-card.Physics,
.jeeta-app * .recorded-video-card.Physics,
.customModal .recorded-video-card.Physics,
.customModal * .recorded-video-card.Physics {
  background-image: url("../../../Assets/Physics.jpg");
}
.jeeta-app .recorded-video-card.Chemistry,
.jeeta-app * .recorded-video-card.Chemistry,
.customModal .recorded-video-card.Chemistry,
.customModal * .recorded-video-card.Chemistry {
  background-image: url("../../../Assets/Chemistry.jpg");
}
.jeeta-app .recordedVideoCardList,
.jeeta-app * .recordedVideoCardList,
.customModal .recordedVideoCardList,
.customModal * .recordedVideoCardList {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  animation: fadeUp 1.2s ease-out forwards;
  /* Example duration and timing */
}
.jeeta-app .recordedVideoCardList .message,
.jeeta-app * .recordedVideoCardList .message,
.customModal .recordedVideoCardList .message,
.customModal * .recordedVideoCardList .message {
  margin: 1rem;
  color: var(--PrimaryColor);
}
.jeeta-app .recordedVideoCardList .recordedVideoCards,
.jeeta-app * .recordedVideoCardList .recordedVideoCards,
.customModal .recordedVideoCardList .recordedVideoCards,
.customModal * .recordedVideoCardList .recordedVideoCards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  background: var(--cardBG);
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  padding: 1rem;
  overflow: hidden;
}
.jeeta-app .recordedVideoCardList .change-page-btns,
.jeeta-app * .recordedVideoCardList .change-page-btns,
.customModal .recordedVideoCardList .change-page-btns,
.customModal * .recordedVideoCardList .change-page-btns {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .recordedVideoCardList .change-page-btns .left-btn,
.jeeta-app .recordedVideoCardList .change-page-btns .right-btn,
.jeeta-app .recordedVideoCardList .change-page-btns .page-btns,
.jeeta-app * .recordedVideoCardList .change-page-btns .left-btn,
.jeeta-app * .recordedVideoCardList .change-page-btns .right-btn,
.jeeta-app * .recordedVideoCardList .change-page-btns .page-btns,
.customModal .recordedVideoCardList .change-page-btns .left-btn,
.customModal .recordedVideoCardList .change-page-btns .right-btn,
.customModal .recordedVideoCardList .change-page-btns .page-btns,
.customModal * .recordedVideoCardList .change-page-btns .left-btn,
.customModal * .recordedVideoCardList .change-page-btns .right-btn,
.customModal * .recordedVideoCardList .change-page-btns .page-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.85rem;
  /* Circle size */
  height: 1.85rem;
  /* Circle size */
  border-radius: 50%;
  /* Makes the button circular */
  border: none;
  /* Border styling */
  color: var(--whiteColor);
  /* Text color */
  font-size: 0.78rem;
  /* Adjust the font size as needed */
  outline: none;
}
.jeeta-app .recordedVideoCardList .change-page-btns .left-btn:hover,
.jeeta-app .recordedVideoCardList .change-page-btns .right-btn:hover,
.jeeta-app .recordedVideoCardList .change-page-btns .page-btns:hover,
.jeeta-app * .recordedVideoCardList .change-page-btns .left-btn:hover,
.jeeta-app * .recordedVideoCardList .change-page-btns .right-btn:hover,
.jeeta-app * .recordedVideoCardList .change-page-btns .page-btns:hover,
.customModal .recordedVideoCardList .change-page-btns .left-btn:hover,
.customModal .recordedVideoCardList .change-page-btns .right-btn:hover,
.customModal .recordedVideoCardList .change-page-btns .page-btns:hover,
.customModal * .recordedVideoCardList .change-page-btns .left-btn:hover,
.customModal * .recordedVideoCardList .change-page-btns .right-btn:hover,
.customModal * .recordedVideoCardList .change-page-btns .page-btns:hover {
  cursor: pointer;
  background-color: var(--PrimaryColor);
  /* Change background color on hover */
}
.jeeta-app .recordedVideoCardList .change-page-btns .left-btn,
.jeeta-app .recordedVideoCardList .change-page-btns .right-btn,
.jeeta-app * .recordedVideoCardList .change-page-btns .left-btn,
.jeeta-app * .recordedVideoCardList .change-page-btns .right-btn,
.customModal .recordedVideoCardList .change-page-btns .left-btn,
.customModal .recordedVideoCardList .change-page-btns .right-btn,
.customModal * .recordedVideoCardList .change-page-btns .left-btn,
.customModal * .recordedVideoCardList .change-page-btns .right-btn {
  background-color: transparent;
  color: var(--PrimaryColor);
}
.jeeta-app .recordedVideoCardList .change-page-btns .left-btn:hover,
.jeeta-app .recordedVideoCardList .change-page-btns .right-btn:hover,
.jeeta-app * .recordedVideoCardList .change-page-btns .left-btn:hover,
.jeeta-app * .recordedVideoCardList .change-page-btns .right-btn:hover,
.customModal .recordedVideoCardList .change-page-btns .left-btn:hover,
.customModal .recordedVideoCardList .change-page-btns .right-btn:hover,
.customModal * .recordedVideoCardList .change-page-btns .left-btn:hover,
.customModal * .recordedVideoCardList .change-page-btns .right-btn:hover {
  background-color: var(--cardBG);
  color: var(--SecondaryColor);
}
.jeeta-app .recordedVideoCardList .change-page-btns .not-current-page-btn,
.jeeta-app * .recordedVideoCardList .change-page-btns .not-current-page-btn,
.customModal .recordedVideoCardList .change-page-btns .not-current-page-btn,
.customModal * .recordedVideoCardList .change-page-btns .not-current-page-btn {
  background-color: var(--SecondaryColor);
}
.jeeta-app .recordedVideoCardList .change-page-btns .current-page-btn,
.jeeta-app * .recordedVideoCardList .change-page-btns .current-page-btn,
.customModal .recordedVideoCardList .change-page-btns .current-page-btn,
.customModal * .recordedVideoCardList .change-page-btns .current-page-btn {
  background-color: var(--PrimaryColor);
}
.jeeta-app .recordedVideoCardListFilter,
.jeeta-app * .recordedVideoCardListFilter,
.customModal .recordedVideoCardListFilter,
.customModal * .recordedVideoCardListFilter {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  z-index: 1;
  animation: fadeUp 1.2s ease-out forwards;
}
.jeeta-app .recordedVideoCardListFilter .filterTitle,
.jeeta-app * .recordedVideoCardListFilter .filterTitle,
.customModal .recordedVideoCardListFilter .filterTitle,
.customModal * .recordedVideoCardListFilter .filterTitle {
  width: 100%;
  border-radius: 1rem;
  flex-direction: column;
  gap: 0.5rem;
}
.jeeta-app .recordedVideoCardListFilter .filterTitle .title,
.jeeta-app * .recordedVideoCardListFilter .filterTitle .title,
.customModal .recordedVideoCardListFilter .filterTitle .title,
.customModal * .recordedVideoCardListFilter .filterTitle .title {
  text-align: center;
  color: var(--SecondaryColor);
}
.jeeta-app .recordedVideoCardListFilter .filterTitle span,
.jeeta-app * .recordedVideoCardListFilter .filterTitle span,
.customModal .recordedVideoCardListFilter .filterTitle span,
.customModal * .recordedVideoCardListFilter .filterTitle span {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
}
.jeeta-app .recordedVideoCardListFilter .checkInput,
.jeeta-app * .recordedVideoCardListFilter .checkInput,
.customModal .recordedVideoCardListFilter .checkInput,
.customModal * .recordedVideoCardListFilter .checkInput {
  padding: 0.5rem 0;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  font-weight: 600;
  font-size: 0.94rem;
}
.jeeta-app .recordedVideoCardListFilter .checkInput .unattemptedInput,
.jeeta-app * .recordedVideoCardListFilter .checkInput .unattemptedInput,
.customModal .recordedVideoCardListFilter .checkInput .unattemptedInput,
.customModal * .recordedVideoCardListFilter .checkInput .unattemptedInput {
  color: var(--peach);
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}
.jeeta-app .recordedVideoCardListFilter .checkInput .unattemptedInput .icon,
.jeeta-app * .recordedVideoCardListFilter .checkInput .unattemptedInput .icon,
.customModal .recordedVideoCardListFilter .checkInput .unattemptedInput .icon,
.customModal * .recordedVideoCardListFilter .checkInput .unattemptedInput .icon {
  font-size: 1.16rem;
}
.jeeta-app .recordedVideoCardListFilter .checkInput .starInput,
.jeeta-app * .recordedVideoCardListFilter .checkInput .starInput,
.customModal .recordedVideoCardListFilter .checkInput .starInput,
.customModal * .recordedVideoCardListFilter .checkInput .starInput {
  color: var(--tiger-lilly);
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}
.jeeta-app .recordedVideoCardListFilter .checkInput .starInput .icon,
.jeeta-app * .recordedVideoCardListFilter .checkInput .starInput .icon,
.customModal .recordedVideoCardListFilter .checkInput .starInput .icon,
.customModal * .recordedVideoCardListFilter .checkInput .starInput .icon {
  font-size: 1.16rem;
}
.jeeta-app .recordedVideoCardListFilter .checkInput .reviewInput,
.jeeta-app * .recordedVideoCardListFilter .checkInput .reviewInput,
.customModal .recordedVideoCardListFilter .checkInput .reviewInput,
.customModal * .recordedVideoCardListFilter .checkInput .reviewInput {
  color: var(--kelly-green);
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}
.jeeta-app .recordedVideoCardListFilter .checkInput .reviewInput .icon,
.jeeta-app * .recordedVideoCardListFilter .checkInput .reviewInput .icon,
.customModal .recordedVideoCardListFilter .checkInput .reviewInput .icon,
.customModal * .recordedVideoCardListFilter .checkInput .reviewInput .icon {
  font-size: 1.16rem;
}
.jeeta-app .recordedVideoCardListFilter .filters,
.jeeta-app * .recordedVideoCardListFilter .filters,
.customModal .recordedVideoCardListFilter .filters,
.customModal * .recordedVideoCardListFilter .filters {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 0.5rem;
  gap: 0.5rem;
}
.jeeta-app .recordedVideoCardListFilter .filters .filterSelect,
.jeeta-app .recordedVideoCardListFilter .filters .searchTextInput,
.jeeta-app * .recordedVideoCardListFilter .filters .filterSelect,
.jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput,
.customModal .recordedVideoCardListFilter .filters .filterSelect,
.customModal .recordedVideoCardListFilter .filters .searchTextInput,
.customModal * .recordedVideoCardListFilter .filters .filterSelect,
.customModal * .recordedVideoCardListFilter .filters .searchTextInput {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  float: left;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
}
.jeeta-app .recordedVideoCardListFilter .filters .filterSelect .title,
.jeeta-app .recordedVideoCardListFilter .filters .searchTextInput .title,
.jeeta-app * .recordedVideoCardListFilter .filters .filterSelect .title,
.jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput .title,
.customModal .recordedVideoCardListFilter .filters .filterSelect .title,
.customModal .recordedVideoCardListFilter .filters .searchTextInput .title,
.customModal * .recordedVideoCardListFilter .filters .filterSelect .title,
.customModal * .recordedVideoCardListFilter .filters .searchTextInput .title {
  padding: 0.35rem 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;
  color: var(--textColor);
}
.jeeta-app .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown,
.jeeta-app .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple,
.jeeta-app .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown,
.jeeta-app .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
.jeeta-app * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown,
.jeeta-app * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple,
.jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown,
.jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
.customModal .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown,
.customModal .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple,
.customModal .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown,
.customModal .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
.customModal * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown,
.customModal * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple,
.customModal * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown,
.customModal * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple {
  flex-grow: 1;
}
.jeeta-app .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
.jeeta-app .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
.jeeta-app .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
.jeeta-app * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
.jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
.customModal .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
.customModal .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
.customModal .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
.customModal .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
.customModal * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
.customModal * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
.customModal * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
.customModal * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control {
  font-size: 0.95rem;
}
.jeeta-app .recordedVideoCardListFilter .filters .filterSelect input,
.jeeta-app .recordedVideoCardListFilter .filters .searchTextInput input,
.jeeta-app * .recordedVideoCardListFilter .filters .filterSelect input,
.jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput input,
.customModal .recordedVideoCardListFilter .filters .filterSelect input,
.customModal .recordedVideoCardListFilter .filters .searchTextInput input,
.customModal * .recordedVideoCardListFilter .filters .filterSelect input,
.customModal * .recordedVideoCardListFilter .filters .searchTextInput input {
  flex-grow: 1;
  border: hsl(0, 0%, 80%) solid 1px;
  border-radius: 0.3rem;
  padding: 0.5rem;
  font-size: 0.95rem;
}
.jeeta-app .recordedVideoCardListFilter .filters .searchTextInput,
.jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput,
.customModal .recordedVideoCardListFilter .filters .searchTextInput,
.customModal * .recordedVideoCardListFilter .filters .searchTextInput {
  grid-column: span 2;
}
.jeeta-app .recordedVideoCardListFilter .btns,
.jeeta-app * .recordedVideoCardListFilter .btns,
.customModal .recordedVideoCardListFilter .btns,
.customModal * .recordedVideoCardListFilter .btns {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .recordedVideoCardListFilter .btns #filter,
.jeeta-app .recordedVideoCardListFilter .btns #create,
.jeeta-app * .recordedVideoCardListFilter .btns #filter,
.jeeta-app * .recordedVideoCardListFilter .btns #create,
.customModal .recordedVideoCardListFilter .btns #filter,
.customModal .recordedVideoCardListFilter .btns #create,
.customModal * .recordedVideoCardListFilter .btns #filter,
.customModal * .recordedVideoCardListFilter .btns #create {
  font-size: 0.83rem;
}
.jeeta-app .recordedVideoCardListFilter .btns #filter .icon,
.jeeta-app .recordedVideoCardListFilter .btns #create .icon,
.jeeta-app * .recordedVideoCardListFilter .btns #filter .icon,
.jeeta-app * .recordedVideoCardListFilter .btns #create .icon,
.customModal .recordedVideoCardListFilter .btns #filter .icon,
.customModal .recordedVideoCardListFilter .btns #create .icon,
.customModal * .recordedVideoCardListFilter .btns #filter .icon,
.customModal * .recordedVideoCardListFilter .btns #create .icon {
  font-size: 1.05rem;
}
@media all and (max-width: 1200px) {
  .jeeta-app .recorded-video-card,
  .jeeta-app * .recorded-video-card,
  .customModal .recorded-video-card,
  .customModal * .recorded-video-card {
    min-height: 17.5rem;
  }
  .jeeta-app .recordedVideoCardList .recordedVideoCards,
  .jeeta-app * .recordedVideoCardList .recordedVideoCards,
  .customModal .recordedVideoCardList .recordedVideoCards,
  .customModal * .recordedVideoCardList .recordedVideoCards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media all and (max-width: 860px) {
  .jeeta-app .recorded-video-card,
  .jeeta-app * .recorded-video-card,
  .customModal .recorded-video-card,
  .customModal * .recorded-video-card {
    min-height: 15rem;
    font-size: 0.95rem;
  }
}
@media all and (max-width: 720px) {
  .jeeta-app .recorded-video-card,
  .jeeta-app * .recorded-video-card,
  .customModal .recorded-video-card,
  .customModal * .recorded-video-card {
    min-height: 12.5rem;
  }
  .jeeta-app .recordedVideoCardListFilter .filterTitle span,
  .jeeta-app * .recordedVideoCardListFilter .filterTitle span,
  .customModal .recordedVideoCardListFilter .filterTitle span,
  .customModal * .recordedVideoCardListFilter .filterTitle span {
    font-size: 1.35rem;
  }
  .jeeta-app .recordedVideoCardListFilter .filters,
  .jeeta-app * .recordedVideoCardListFilter .filters,
  .customModal .recordedVideoCardListFilter .filters,
  .customModal * .recordedVideoCardListFilter .filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  .jeeta-app .recordedVideoCardList .change-page-btns,
  .jeeta-app * .recordedVideoCardList .change-page-btns,
  .customModal .recordedVideoCardList .change-page-btns,
  .customModal * .recordedVideoCardList .change-page-btns {
    gap: 0.3rem;
  }
  .jeeta-app .recordedVideoCardList .change-page-btns .btn,
  .jeeta-app * .recordedVideoCardList .change-page-btns .btn,
  .customModal .recordedVideoCardList .change-page-btns .btn,
  .customModal * .recordedVideoCardList .change-page-btns .btn {
    font-size: 0.72rem;
    margin: 0;
  }
  .jeeta-app .recordedVideoCardList .change-page-btns .page-btns,
  .jeeta-app * .recordedVideoCardList .change-page-btns .page-btns,
  .customModal .recordedVideoCardList .change-page-btns .page-btns,
  .customModal * .recordedVideoCardList .change-page-btns .page-btns {
    width: 1.22rem;
    height: 1.22rem;
    font-size: 0.72rem;
  }
}
@media all and (max-width: 610px) {
  .jeeta-app .recorded-video-card,
  .jeeta-app * .recorded-video-card,
  .customModal .recorded-video-card,
  .customModal * .recorded-video-card {
    font-size: 0.9rem;
  }
  .jeeta-app recordedVideoCardListFilter .filterTitle span,
  .jeeta-app * recordedVideoCardListFilter .filterTitle span,
  .customModal recordedVideoCardListFilter .filterTitle span,
  .customModal * recordedVideoCardListFilter .filterTitle span {
    font-size: 1.3rem;
  }
}
@media all and (max-width: 550px) {
  .jeeta-app .recordedVideoCardListFilter,
  .jeeta-app * .recordedVideoCardListFilter,
  .customModal .recordedVideoCardListFilter,
  .customModal * .recordedVideoCardListFilter {
    gap: 0.5rem;
  }
  .jeeta-app .recordedVideoCardListFilter .filterTitle span,
  .jeeta-app * .recordedVideoCardListFilter .filterTitle span,
  .customModal .recordedVideoCardListFilter .filterTitle span,
  .customModal * .recordedVideoCardListFilter .filterTitle span {
    font-size: 1.25rem;
  }
  .jeeta-app .recordedVideoCardListFilter .filters,
  .jeeta-app * .recordedVideoCardListFilter .filters,
  .customModal .recordedVideoCardListFilter .filters,
  .customModal * .recordedVideoCardListFilter .filters {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
  .jeeta-app .recordedVideoCardListFilter .filters .filterSelect,
  .jeeta-app .recordedVideoCardListFilter .filters .searchTextInput,
  .jeeta-app * .recordedVideoCardListFilter .filters .filterSelect,
  .jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput,
  .customModal .recordedVideoCardListFilter .filters .filterSelect,
  .customModal .recordedVideoCardListFilter .filters .searchTextInput,
  .customModal * .recordedVideoCardListFilter .filters .filterSelect,
  .customModal * .recordedVideoCardListFilter .filters .searchTextInput {
    padding: 0;
  }
  .jeeta-app .recordedVideoCardListFilter .filters .filterSelect .title,
  .jeeta-app .recordedVideoCardListFilter .filters .searchTextInput .title,
  .jeeta-app * .recordedVideoCardListFilter .filters .filterSelect .title,
  .jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput .title,
  .customModal .recordedVideoCardListFilter .filters .filterSelect .title,
  .customModal .recordedVideoCardListFilter .filters .searchTextInput .title,
  .customModal * .recordedVideoCardListFilter .filters .filterSelect .title,
  .customModal * .recordedVideoCardListFilter .filters .searchTextInput .title {
    font-size: 0.9rem;
  }
  .jeeta-app .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown,
  .jeeta-app .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple,
  .jeeta-app .recordedVideoCardListFilter .filters .filterSelect input,
  .jeeta-app .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown,
  .jeeta-app .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
  .jeeta-app .recordedVideoCardListFilter .filters .searchTextInput input,
  .jeeta-app * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown,
  .jeeta-app * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple,
  .jeeta-app * .recordedVideoCardListFilter .filters .filterSelect input,
  .jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown,
  .jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
  .jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput input,
  .customModal .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown,
  .customModal .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple,
  .customModal .recordedVideoCardListFilter .filters .filterSelect input,
  .customModal .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown,
  .customModal .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
  .customModal .recordedVideoCardListFilter .filters .searchTextInput input,
  .customModal * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown,
  .customModal * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple,
  .customModal * .recordedVideoCardListFilter .filters .filterSelect input,
  .customModal * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown,
  .customModal * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple,
  .customModal * .recordedVideoCardListFilter .filters .searchTextInput input {
    font-size: 0.9rem;
  }
  .jeeta-app .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown input,
  .jeeta-app .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
  .jeeta-app .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple input,
  .jeeta-app .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
  .jeeta-app .recordedVideoCardListFilter .filters .filterSelect input input,
  .jeeta-app .recordedVideoCardListFilter .filters .filterSelect input .css-13cymwt-control,
  .jeeta-app .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown input,
  .jeeta-app .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
  .jeeta-app .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple input,
  .jeeta-app .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
  .jeeta-app .recordedVideoCardListFilter .filters .searchTextInput input input,
  .jeeta-app .recordedVideoCardListFilter .filters .searchTextInput input .css-13cymwt-control,
  .jeeta-app * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown input,
  .jeeta-app * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
  .jeeta-app * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple input,
  .jeeta-app * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
  .jeeta-app * .recordedVideoCardListFilter .filters .filterSelect input input,
  .jeeta-app * .recordedVideoCardListFilter .filters .filterSelect input .css-13cymwt-control,
  .jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown input,
  .jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
  .jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple input,
  .jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
  .jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput input input,
  .jeeta-app * .recordedVideoCardListFilter .filters .searchTextInput input .css-13cymwt-control,
  .customModal .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown input,
  .customModal .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
  .customModal .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple input,
  .customModal .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
  .customModal .recordedVideoCardListFilter .filters .filterSelect input input,
  .customModal .recordedVideoCardListFilter .filters .filterSelect input .css-13cymwt-control,
  .customModal .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown input,
  .customModal .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
  .customModal .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple input,
  .customModal .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
  .customModal .recordedVideoCardListFilter .filters .searchTextInput input input,
  .customModal .recordedVideoCardListFilter .filters .searchTextInput input .css-13cymwt-control,
  .customModal * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown input,
  .customModal * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdown .css-13cymwt-control,
  .customModal * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple input,
  .customModal * .recordedVideoCardListFilter .filters .filterSelect .selectionDropdownMultiple .css-13cymwt-control,
  .customModal * .recordedVideoCardListFilter .filters .filterSelect input input,
  .customModal * .recordedVideoCardListFilter .filters .filterSelect input .css-13cymwt-control,
  .customModal * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown input,
  .customModal * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdown .css-13cymwt-control,
  .customModal * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple input,
  .customModal * .recordedVideoCardListFilter .filters .searchTextInput .selectionDropdownMultiple .css-13cymwt-control,
  .customModal * .recordedVideoCardListFilter .filters .searchTextInput input input,
  .customModal * .recordedVideoCardListFilter .filters .searchTextInput input .css-13cymwt-control {
    font-size: 0.9rem;
  }
  .jeeta-app .recordedVideoCardListFilter .btns,
  .jeeta-app * .recordedVideoCardListFilter .btns,
  .customModal .recordedVideoCardListFilter .btns,
  .customModal * .recordedVideoCardListFilter .btns {
    flex-direction: column;
    gap: 0.5rem;
  }
  .jeeta-app .recordedVideoCardListFilter .checkInput,
  .jeeta-app * .recordedVideoCardListFilter .checkInput,
  .customModal .recordedVideoCardListFilter .checkInput,
  .customModal * .recordedVideoCardListFilter .checkInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1.5rem;
    gap: 0.5rem;
  }
  .jeeta-app .recordedVideoCardList,
  .jeeta-app * .recordedVideoCardList,
  .customModal .recordedVideoCardList,
  .customModal * .recordedVideoCardList {
    padding: 0.5rem;
  }
  .jeeta-app .recordedVideoCardList .recordedVideoCards,
  .jeeta-app * .recordedVideoCardList .recordedVideoCards,
  .customModal .recordedVideoCardList .recordedVideoCards,
  .customModal * .recordedVideoCardList .recordedVideoCards {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    gap: 0.5rem;
    min-height: 6rem;
  }
  .jeeta-app .recorded-video-card .date,
  .jeeta-app * .recorded-video-card .date,
  .customModal .recorded-video-card .date,
  .customModal * .recorded-video-card .date {
    font-size: 0.9rem;
  }
}/*# sourceMappingURL=RecordedVideoCard.css.map */