.jeeta-app .profile,
.jeeta-app * .profile,
.customModal .profile, .customModal * .profile {
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0.5rem;
  color: var(--textColor);
  animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */
}
.jeeta-app .profile .userPhoto,
.jeeta-app * .profile .userPhoto,
.customModal .profile .userPhoto, .customModal * .profile .userPhoto {
  width: 30%;
  max-width: 350px;
  height: 100%;
  padding: 0.5rem;
  -o-object-fit: contain;
     object-fit: contain;
}
.jeeta-app .profile .userProfile,
.jeeta-app * .profile .userProfile,
.customModal .profile .userProfile, .customModal * .profile .userProfile {
  width: 60%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0.5rem;
}
.jeeta-app .profile .userProfile .userInfo,
.jeeta-app * .profile .userProfile .userInfo,
.customModal .profile .userProfile .userInfo, .customModal * .profile .userProfile .userInfo {
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .profile .userProfile .userInfo .infoLabel,
.jeeta-app * .profile .userProfile .userInfo .infoLabel,
.customModal .profile .userProfile .userInfo .infoLabel, .customModal * .profile .userProfile .userInfo .infoLabel {
  text-align: left;
  width: 30%;
  font-size: 16px;
  font-weight: 600;
}
.jeeta-app .profile .userProfile .userInfo .infoContent,
.jeeta-app * .profile .userProfile .userInfo .infoContent,
.customModal .profile .userProfile .userInfo .infoContent, .customModal * .profile .userProfile .userInfo .infoContent {
  position: relative;
  flex-direction: row;
  width: 60%;
  background-color: var(--whiteColor);
  border-radius: 0.5rem;
  font-size: 15px;
  padding: 0.5rem 1rem;
}
.jeeta-app .profile .userProfile .userInfo .infoContent span,
.jeeta-app * .profile .userProfile .userInfo .infoContent span,
.customModal .profile .userProfile .userInfo .infoContent span, .customModal * .profile .userProfile .userInfo .infoContent span {
  margin-right: 25px;
  overflow: auto;
  /* Hide scrollbar for Chrome, Safari, and newer versions of Opera*/
  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.jeeta-app .profile .userProfile .userInfo .infoContent span::-webkit-scrollbar,
.jeeta-app * .profile .userProfile .userInfo .infoContent span::-webkit-scrollbar,
.customModal .profile .userProfile .userInfo .infoContent span::-webkit-scrollbar, .customModal * .profile .userProfile .userInfo .infoContent span::-webkit-scrollbar {
  display: none;
}
.jeeta-app .profile .userProfile .userInfo .infoContent .icon,
.jeeta-app * .profile .userProfile .userInfo .infoContent .icon,
.customModal .profile .userProfile .userInfo .infoContent .icon, .customModal * .profile .userProfile .userInfo .infoContent .icon {
  position: absolute;
  right: 0;
  margin: 0 1rem;
  font-size: 20px;
  color: var(--PrimaryColor);
}
.jeeta-app .profile .userProfile .userInfo .infoContent .icon:hover,
.jeeta-app * .profile .userProfile .userInfo .infoContent .icon:hover,
.customModal .profile .userProfile .userInfo .infoContent .icon:hover, .customModal * .profile .userProfile .userInfo .infoContent .icon:hover {
  color: var(--SecondaryColor);
  cursor: pointer;
}
.jeeta-app .profileBtns,
.jeeta-app * .profileBtns,
.customModal .profileBtns, .customModal * .profileBtns {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 4rem;
}
.jeeta-app .profileBtns .btn,
.jeeta-app * .profileBtns .btn,
.customModal .profileBtns .btn, .customModal * .profileBtns .btn {
  color: var(--whiteColor);
  font-weight: 600;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .profileBtns .btn .icon,
.jeeta-app * .profileBtns .btn .icon,
.customModal .profileBtns .btn .icon, .customModal * .profileBtns .btn .icon {
  align-self: center;
  font-size: 18px;
}
.jeeta-app .profileModal,
.jeeta-app * .profileModal,
.customModal .profileModal, .customModal * .profileModal {
  width: 650px;
  /* or your desired width */
  overflow-y: auto;
  /* display scrollbar only when necessary */
  background-color: var(--whiteColorDeam);
  padding: 10px;
  position: fixed;
  /* make the modal appear above other content */
  top: 55%;
  /* centering the modal vertically */
  left: 50%;
  /* centering the modal horizontally */
  transform: translate(-50%, -50%);
  /* necessary adjustments for centering */
  z-index: 1000;
  /* high value to ensure modal is on top */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.jeeta-app .profileModal .register-container,
.jeeta-app * .profileModal .register-container,
.customModal .profileModal .register-container, .customModal * .profileModal .register-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  gap: 1.5rem;
}
.jeeta-app .profileModal .register-container .registerTitle h3,
.jeeta-app * .profileModal .register-container .registerTitle h3,
.customModal .profileModal .register-container .registerTitle h3, .customModal * .profileModal .register-container .registerTitle h3 {
  margin: 0.5rem 0;
}
.jeeta-app .profileModal .register-container .inputBox,
.jeeta-app * .profileModal .register-container .inputBox,
.customModal .profileModal .register-container .inputBox, .customModal * .profileModal .register-container .inputBox {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.jeeta-app .profileModal .register-container .inputBox .contactInput,
.jeeta-app * .profileModal .register-container .inputBox .contactInput,
.customModal .profileModal .register-container .inputBox .contactInput, .customModal * .profileModal .register-container .inputBox .contactInput {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .profileModal .register-container .inputBox .contactInput .inputLabel,
.jeeta-app * .profileModal .register-container .inputBox .contactInput .inputLabel,
.customModal .profileModal .register-container .inputBox .contactInput .inputLabel, .customModal * .profileModal .register-container .inputBox .contactInput .inputLabel {
  width: 130px;
  font-weight: 600;
  font-size: 16px;
}
.jeeta-app .profileModal .register-container .inputBox .contactInput input,
.jeeta-app * .profileModal .register-container .inputBox .contactInput input,
.customModal .profileModal .register-container .inputBox .contactInput input, .customModal * .profileModal .register-container .inputBox .contactInput input {
  width: 250px;
  padding: 0.4rem 0.5rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 14px;
}
.jeeta-app .profileModal .register-container .inputBox .contactInput .icon,
.jeeta-app * .profileModal .register-container .inputBox .contactInput .icon,
.customModal .profileModal .register-container .inputBox .contactInput .icon, .customModal * .profileModal .register-container .inputBox .contactInput .icon {
  margin: 0.5rem;
  font-size: 20px;
  color: var(--PrimaryColor);
}
.jeeta-app .profileModal .register-container #close,
.jeeta-app * .profileModal .register-container #close,
.customModal .profileModal .register-container #close, .customModal * .profileModal .register-container #close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: var(--PrimaryColor);
  align-self: center;
  font-size: 30px;
}
.jeeta-app .profilePopup,
.jeeta-app * .profilePopup,
.customModal .profilePopup, .customModal * .profilePopup {
  position: absolute;
  top: 35px;
  right: 15px;
  width: -moz-max-content;
  width: max-content;
  background-color: var(--whiteColor);
  border: none;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .profilePopup .profileMessage,
.jeeta-app * .profilePopup .profileMessage,
.customModal .profilePopup .profileMessage, .customModal * .profilePopup .profileMessage {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  color: var(--textColor);
}
.jeeta-app .profilePopup .profileMessage .icon,
.jeeta-app * .profilePopup .profileMessage .icon,
.customModal .profilePopup .profileMessage .icon, .customModal * .profilePopup .profileMessage .icon {
  color: var(--PrimaryColor);
  align-self: center;
  font-size: 25px;
}
.jeeta-app .profilePopup .profileBtns,
.jeeta-app * .profilePopup .profileBtns,
.customModal .profilePopup .profileBtns, .customModal * .profilePopup .profileBtns {
  margin-top: 0;
  gap: 0.5rem;
}
.jeeta-app .profilePopup .btn,
.jeeta-app * .profilePopup .btn,
.customModal .profilePopup .btn, .customModal * .profilePopup .btn {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 85px;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 2rem;
  color: var(--whiteColor);
  font-size: 0.9rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
}
.jeeta-app .report-feedback,
.jeeta-app * .report-feedback,
.customModal .report-feedback, .customModal * .report-feedback {
  background-color: var(--orange);
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--whiteColor);
  border: none;
  border-radius: 2rem;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .report-feedback .icon,
.jeeta-app * .report-feedback .icon,
.customModal .report-feedback .icon, .customModal * .report-feedback .icon {
  font-size: 1.2rem;
}
.jeeta-app .report-feedback:hover,
.jeeta-app * .report-feedback:hover,
.customModal .report-feedback:hover, .customModal * .report-feedback:hover {
  cursor: pointer;
  background-color: var(--pink);
}
.jeeta-app .LecturePIN,
.jeeta-app * .LecturePIN,
.customModal .LecturePIN, .customModal * .LecturePIN {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}
.jeeta-app .LecturePIN span,
.jeeta-app * .LecturePIN span,
.customModal .LecturePIN span, .customModal * .LecturePIN span {
  font-weight: 500;
}
.jeeta-app .LecturePIN .PIN-container,
.jeeta-app * .LecturePIN .PIN-container,
.customModal .LecturePIN .PIN-container, .customModal * .LecturePIN .PIN-container {
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
}
.jeeta-app .LecturePIN .PIN-container .number-box,
.jeeta-app * .LecturePIN .PIN-container .number-box,
.customModal .LecturePIN .PIN-container .number-box, .customModal * .LecturePIN .PIN-container .number-box {
  width: 23px;
  background-color: var(--PrimaryColor);
  border: none;
  border-radius: 0.3rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  font-weight: 600;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--whiteColor);
}
@media all and (max-width: 860px) {
  .jeeta-app .profile,
  .jeeta-app * .profile,
  .customModal .profile, .customModal * .profile {
    flex-direction: column;
    gap: 1rem;
  }
  .jeeta-app .profile .userPhoto,
  .jeeta-app * .profile .userPhoto,
  .customModal .profile .userPhoto, .customModal * .profile .userPhoto {
    width: 30%;
  }
  .jeeta-app .profile .userProfile,
  .jeeta-app * .profile .userProfile,
  .customModal .profile .userProfile, .customModal * .profile .userProfile {
    width: 100%;
    justify-content: center;
  }
  .jeeta-app .profile .userProfile .userInfo,
  .jeeta-app * .profile .userProfile .userInfo,
  .customModal .profile .userProfile .userInfo, .customModal * .profile .userProfile .userInfo {
    width: 100%;
    justify-content: center;
  }
  .jeeta-app .profile .userProfile .userInfo .infoLabel,
  .jeeta-app * .profile .userProfile .userInfo .infoLabel,
  .customModal .profile .userProfile .userInfo .infoLabel, .customModal * .profile .userProfile .userInfo .infoLabel {
    width: 20%;
  }
  .jeeta-app .profile .userProfile .userInfo .infoContent,
  .jeeta-app * .profile .userProfile .userInfo .infoContent,
  .customModal .profile .userProfile .userInfo .infoContent, .customModal * .profile .userProfile .userInfo .infoContent {
    width: 50%;
  }
  .jeeta-app .profile .userProfile .userInfo .infoContent span,
  .jeeta-app * .profile .userProfile .userInfo .infoContent span,
  .customModal .profile .userProfile .userInfo .infoContent span, .customModal * .profile .userProfile .userInfo .infoContent span {
    margin-right: 25px;
  }
}
@media all and (max-width: 500px) {
  .jeeta-app .profile,
  .jeeta-app * .profile,
  .customModal .profile, .customModal * .profile {
    gap: 0.5rem;
  }
  .jeeta-app .profile .userPhoto,
  .jeeta-app * .profile .userPhoto,
  .customModal .profile .userPhoto, .customModal * .profile .userPhoto {
    width: 50%;
  }
  .jeeta-app .profile .userProfile,
  .jeeta-app * .profile .userProfile,
  .customModal .profile .userProfile, .customModal * .profile .userProfile {
    padding: 0.5rem 0;
  }
  .jeeta-app .profile .userProfile .userInfo .infoLabel,
  .jeeta-app * .profile .userProfile .userInfo .infoLabel,
  .customModal .profile .userProfile .userInfo .infoLabel, .customModal * .profile .userProfile .userInfo .infoLabel {
    width: 30%;
    font-size: 14.5px;
  }
  .jeeta-app .profile .userProfile .userInfo .infoContent,
  .jeeta-app * .profile .userProfile .userInfo .infoContent,
  .customModal .profile .userProfile .userInfo .infoContent, .customModal * .profile .userProfile .userInfo .infoContent {
    width: 60%;
    font-size: 14px;
    padding: 0.3rem 0.5rem;
  }
  .jeeta-app .profile .userProfile .userInfo .infoContent span,
  .jeeta-app * .profile .userProfile .userInfo .infoContent span,
  .customModal .profile .userProfile .userInfo .infoContent span, .customModal * .profile .userProfile .userInfo .infoContent span {
    margin-right: 22px;
  }
  .jeeta-app .profile .userProfile .userInfo .infoContent .icon,
  .jeeta-app * .profile .userProfile .userInfo .infoContent .icon,
  .customModal .profile .userProfile .userInfo .infoContent .icon, .customModal * .profile .userProfile .userInfo .infoContent .icon {
    margin: 0 0.5rem;
    font-size: 18px;
  }
  .jeeta-app .profile .userProfile .userProfileBtns,
  .jeeta-app * .profile .userProfile .userProfileBtns,
  .customModal .profile .userProfile .userProfileBtns, .customModal * .profile .userProfile .userProfileBtns {
    gap: 1.5rem;
  }
}/*# sourceMappingURL=profile.css.map */