.jeeta-app,
.jeeta-app * {
    .dashboard {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    color: var(--textColor);
    animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */

    .LecturePIN {
        position: absolute;
        top: .8rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;

        h3 {
            color: var(--textColor);
        }

        .PIN-container {
            justify-content: center;
            align-items: center;
            gap: .3rem;

            .number-box {
                width: 1.5rem;
                background-color: var(--SecondaryColor);
                border: none;
                border-radius: .3rem;
                box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
                font-size: 1.2rem;
                font-weight: 600;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: var(--whiteColor);
            }
        }
    }
}

.dashboard-header {
    position: relative;
    width: 100%;
    padding: .5rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background: var(--gradientBkg);
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

    .title {
        color: var(--whiteColor);
        text-shadow: 0 2px 4px rgba(140, 140, 141, 0.3);
        text-align: center;
    }
}

.dashboard-glass {
    display: grid;
    width: 100%;
    padding: 1rem 0;
    background: var(--whiteColor);
    border-radius: 2rem;
    gap: 1rem;
    grid-template-columns: 11rem auto;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}

.dashboard-main {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem;
    gap: .5rem;

    .title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        color: var(--SecondaryColor);
        padding: .5rem;
        text-align: center;

        span{
            color: var(--SecondaryColor);
        }

        .breakImg{
            max-width: 450px;
        }
    }

    .recommendation-strategy{
        width: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .5rem;
        gap: .5rem;
        border: none;
        border-radius: .5rem;
        background-color: var(--whiteColorDeam);

        .recommendation-strategy-btns{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: .5rem;

            .recommendation-strategy-btn{
                padding: .2rem .5rem;
                border: none;
                border-radius: .5rem;
                background-color: var(--PrimaryBkg);
                color: var(--whiteColor);

                &:hover{
                    background-color: var(--PrimaryColor);
                    font-weight: 600;
                    cursor: pointer;
                }
            }

            .recommendation-strategy-btn.active{
                background-color: var(--PrimaryColor);
                font-weight: 600;
                cursor: pointer;
            }
        }
    }
    
}

.dashboard-cardList {
    position: relative;
    width: 95%;
    min-height: 236px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(200px, auto);
    gap: 1rem;
    background: var(--cardBG);
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
    padding: 1rem;
    // overflow: hidden;
}


//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {}

@media all and (max-width: 1000px) {
    .dashboard {
        .dashboard-cardList {
            grid-template-columns: repeat(2, 1fr);
        }

        .LecturePIN {
            transform: scale(0.95);
        }
    }
}

@media all and (max-width: 860px) {
    .dashboard {
        gap: .5rem;

        .LecturePIN {
            transform: scale(0.9);
        }
    }

    .dashboard-cardList {

        .lecture-card {
            width: 100%;
            min-height: 180px;
            font-size: .85rem;
        }
    }

    .dashboard-main{
        .recommendation-strategy{
            flex-direction: column;
        }
    }
}

@media all and (max-width: 800px) {
    .dashboard {
        padding: .5rem;

        .LecturePIN {
            transform: scale(0.8);
        }
    }

    .dashboard-header {
        margin: .5rem 0;
        padding: .5rem 0;
    }

    .dashboard-glass {
        grid-template-columns: 3rem auto;
    }

    .dashboard-main{

        .title{
            font-size: 1.2rem;
        }
    }
}

@media all and (max-width: 720px) {
    .dashboard-cardList {
        display: flex;
        flex-direction: column;
        gap: .5rem;

        .lecture-card {
            width: 100%;
            min-height: 150px;
            font-size: .75rem;
        }
    }
}

@media all and (max-width: 550px) {

    .dashboard-header{
        margin: 0;
        padding: .5rem;
        font-size: .9rem;
    }

    .dashboard-glass{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap:0;
        padding: 0;
    }

    .dashboard-main{

        .title{
            padding: 0;
            font-size: 1rem;
        }

        .recommendation-strategy{
            .recommendation-strategy-btns{
                flex-direction: column;

                .recommendation-strategy-btn{
                    width: 135px;
                }
            }
        }
    }

    .dashboard-cardList {
        width: 100%;
        padding: .5rem;
        gap: .5rem;
    }
}
}