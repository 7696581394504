.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.tagModal {
    position: relative;
    /* make the modal appear above other content */
    top: 50%;
    /* centering the modal vertically */
    left: 50%;
    /* centering the modal horizontally */
    transform: translate(-50%, -50%);
    /* necessary adjustments for centering */
    z-index: 1010;
    /* high value to ensure modal is on top */
    width: 500px;
    // overflow-y: auto;       /* display scrollbar only when necessary */
    background-color: var(--whiteColorDeam);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: .5rem;
    padding: 1.5rem 1rem;
    color: var(--textColor);

    .tagCreate {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .tagTitle {
            align-items: center;
            margin: 1rem 0;
            font-size: 25px;
        }

        .tagInfoInput {
            width: 80%;
            flex-direction: column;
            justify-content: flex-start;
            gap: 1rem;
            .tagTypeSelectionDropdown {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .5rem;
            
                .title {
                    font-size: 16px;
                    font-weight: 600;
            
                    .icon {
                        color: var(--textColor);
                        font-size: 16px;
                    }
                }
            
                .text {
                    width: 100%;
                    height: auto;
                    font-size: 15px;
                    padding: .2rem .5rem;
                    color: var(--textColor);
                    border: hsl(0, 0%, 80%) solid 1px;
                    border-radius: .3rem;
                    line-height: 1.3;
                }
            
                .selectionDropdown,
                .selectionDropdownMultiple {
                    width: 50%;
                    font-size: 15px;
            
                    .css-wsp0cs-MultiValueGeneric {
                        max-width: 180px;
                        overflow: hidden;
                    }
                }
            }
            .textInput,
            .checkBoxInput {
                width: 100%;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: .5rem;

                .title {
                    width: 90px;
                    font-size: 16px;
                    font-weight: 600;
                }

                input {
                    flex-grow: 1;
                    border: hsl(0, 0%, 80%) solid 1px;
                    border-radius: .3rem;
                    padding: .3rem .7rem;
                    font-size: 15px;
                }
                
            }
        }

        .checkBoxInput {
            .checkBox {
                justify-self: flex-start;
            }
        }

        .btn {
            margin-top: 1rem;
            color: var(--whiteColor);
            font-weight: 600;
            justify-content: space-between;
            gap: .5rem;

            .icon {
                align-self: center;
                font-size: 18px;
            }
        }

        #close {
            position: absolute;
            top: 5px;
            right: 5px;
            color: var(--PrimaryColor);
            align-self: center;
            font-size: 30px;

            &:hover {
                color: var(--tiger-lilly);
            }
        }
    }
}
}