.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
    .reading-material-card {
    width: 100%;
    min-height: 20rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    background-image: url('../../../Assets/ReadingMaterials.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: .5rem;
    // padding: .5rem;
    // gap: 1rem;
    font-size: 1rem;

    &:hover {
        cursor: pointer;
    }

    .bkg-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: .5rem;
        background-color: rgba(255, 255, 255, 0.3);
        // z-index: 0;
    }

    .material,
    .subject {
        position: relative;
        text-align: center;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.15);
        padding: .1rem;
        color: var(--whiteColor);
        z-index: 1;
    }

    .material::first-letter {
        text-transform: uppercase;
    }

    .subject.Mathematics {
        background-color: var(--Mathematics);
        opacity: .9;
        font-weight: 600;
    }

    .subject.Physics {
        background-color: var(--Physics);
        opacity: .9;
        font-weight: 600;
    }

    .subject.Chemistry {
        background-color: var(--Chemistry);
        opacity: .9;
        font-weight: 600;
    }
}

.reading-material-card.module {
    background-image: url('../../../Assets/Modules.jpg');
}

.reading-material-card.book {
    background-image: url('../../../Assets/Books.jpg');
}

.reading-material-card.note {
    background-image: url('../../../Assets/Notebooks.jpg');
}

.readingMaterialCardList {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    animation: fadeUp 1.2s ease-out forwards;
    /* Example duration and timing */

    .message {
        margin: 1rem;
        color: var(--PrimaryColor);
    }

    .readingMaterialCards {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // grid-auto-rows: minmax(400px, auto);
        gap: 1rem;
        background: var(--cardBG);
        border-radius: 1rem;
        box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
        padding: 1rem;
        overflow: hidden;
    }

    .change-page-btns {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: .5rem;

        .left-btn,
        .right-btn,
        .page-btns {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.85rem;
            /* Circle size */
            height: 1.85rem;
            /* Circle size */
            border-radius: 50%;
            /* Makes the button circular */
            border: none;
            /* Border styling */
            color: var(--whiteColor);
            /* Text color */
            font-size: .78rem;
            /* Adjust the font size as needed */
            outline: none;

            &:hover {
                cursor: pointer;
                background-color: var(--PrimaryColor);
                /* Change background color on hover */
            }
        }

        .left-btn,
        .right-btn {
            background-color: transparent;
            color: var(--PrimaryColor);

            &:hover {
                background-color: var(--cardBG);
                color: var(--SecondaryColor);
            }
        }

        .not-current-page-btn {
            background-color: var(--SecondaryColor);
        }

        .current-page-btn {
            background-color: var(--PrimaryColor);
        }
    }
}

.readingMaterialCardListFilter {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    z-index: 1;
    animation: fadeUp 1.2s ease-out forwards;

    .filterTitle {
        width: 100%;
        // padding: 1rem;
        border-radius: 1rem;
        // background: var(--gradientBkg);
        // box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
        flex-direction: column;
        gap: .5rem;

        .title {
            text-align: center;
            color: var(--SecondaryColor);
        }

        span {
            font-size: 1.5rem;
            text-align: center;
            font-weight: 600;
        }
    }

    .checkInput {
        padding: .5rem 0;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        font-weight: 600;
        font-size: .94rem;

        .unattemptedInput {
            color: var(--peach);
            justify-content: center;
            align-items: center;
            gap: .1rem;

            .icon {
            font-size: 1.16rem;
            }
        }

        .starInput {
            color: var(--tiger-lilly);
            justify-content: center;
            align-items: center;
            gap: .1rem;

            .icon {
            font-size: 1.16rem;
            }
        }

        .reviewInput {
            color: var(--kelly-green);
            justify-content: center;
            align-items: center;
            gap: .1rem;

            .icon {
            font-size: 1.16rem;
            }
        }
    }

    .filters {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // place-items: center;
        padding: 0 .5rem;
        gap: .5rem;

        .filterSelect,
        .searchTextInput {
            display: flex;
            position: relative;
            width: 100%;
            flex-direction: row;
            float: left;
            justify-content: flex-start;
            align-items: center;
            padding: .5rem;
            gap: .5rem;

            .title {
                padding: .35rem .5rem;
                // min-width: 5rem;
                font-weight: 600;
                font-size: 1rem;
                white-space: nowrap;
                color: var(--textColor);
            }

            .selectionDropdown,
            .selectionDropdownMultiple {
                flex-grow: 1;

                .css-13cymwt-control {
                    font-size: .95rem;
                }

            }

            input {
                flex-grow: 1;
                border: hsl(0, 0%, 80%) solid 1px;
                border-radius: .3rem;
                padding: .5rem;
                font-size: .95rem;
            }
        }

        .searchTextInput {
            grid-column: span 2;
        }
    }

    .btns {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        // padding: 0 0 1rem 0;

        #filter,
        #create {
            font-size: .83rem;

            .icon {
                font-size: 1.05rem;
            }
        }
    }
}


//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {}

@media all and (max-width: 1200px) {
    .reading-material-card {
        min-height: 17.5rem;
    }

    .readingMaterialCardList {
        .readingMaterialCards {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media all and (max-width: 860px) {
    .reading-material-card {
        min-height: 15rem;
        font-size: .95rem;
    }
}

@media all and (max-width: 720px) {
    .reading-material-card {
        min-height: 12.5rem;
    }

    .readingMaterialCardListFilter {

        .filterTitle {
            span {
                font-size: 1.35rem;
            }
        }
        .filters {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .5rem;
        }
    }

    .readingMaterialCardList {

        // .readingMaterialCards{
        //     display: flex;
        //     flex-direction: column;
        //     gap: .5rem;
        //     min-height: 250px;
        // }

        .change-page-btns {
            gap: .3rem;

            .btn {
                font-size: 0.72rem;
                margin: 0;
            }

            .page-btns {
                width: 1.22rem;
                height: 1.22rem;
                font-size: 0.72rem;
            }
        }
    }

    
}

@media all and (max-width: 610px) {
    .reading-material-card {
        font-size: .9rem;
    }

    readingMaterialCardListFilter {

        .filterTitle {
            span {
                font-size: 1.3rem;
            }
        }
    }
}

@media all and (max-width: 550px) {
    .readingMaterialCardListFilter {
        gap: .5rem;

        .filterTitle {
            span {
                font-size: 1.25rem;
            }
        }
        .filters {
            padding: .5rem .5rem 0 .5rem;

            .filterSelect,
            .searchTextInput {
                padding: 0;

                .title {
                    font-size: .9rem;
                }

                .selectionDropdown,
                .selectionDropdownMultiple,
                input {
                    font-size: .9rem;

                    input,
                    .css-13cymwt-control {
                        font-size: .9rem;
                    }
                }
            }
        }

        .checkInput {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 1.5rem;
            gap: 0.5rem;
        }
    }

    .readingMaterialCardList {
        padding: .5rem;

        .readingMaterialCards {
            display: flex;
            flex-direction: column;
            padding: .5rem;
            gap: .5rem;
            min-height: 6rem;
        }
    }

    .reading-material-card {
        .date {
            font-size: .9rem;
        }
    }

    
}
}