.jeeta-app .hero,
.jeeta-app * .hero {
  position: relative;
  width: 100%;
  min-height: 450px;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteColor);
}
.jeeta-app .hero .hero-img,
.jeeta-app * .hero .hero-img {
  min-height: 300px;
  margin-top: 2.5rem;
  max-width: 70vw;
  max-height: 45vh;
  -o-object-fit: contain;
     object-fit: contain;
  animation: on-load 1.5s ease-in-out forwards;
}
.jeeta-app .hero .hero-text,
.jeeta-app * .hero .hero-text {
  width: 100%;
  text-align: center;
  flex-direction: column;
}
.jeeta-app .hero .hero-text .hero-title,
.jeeta-app * .hero .hero-text .hero-title {
  width: 100%;
  font-size: 3rem;
  font-weight: 800;
  color: var(--whiteColor);
  background: var(--PrimaryBkg);
  --webkit-background-clip: text;
  --webkit-text-fill-color: transparent;
}
.jeeta-app .hero .hero-text .hero-description,
.jeeta-app * .hero .hero-text .hero-description {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--whiteColor);
  background: var(--SecondaryBkg);
}
.jeeta-app .hero.noProfile .hero-img,
.jeeta-app * .hero.noProfile .hero-img {
  margin-top: 2rem;
  max-width: 90vw;
  max-height: 60vh;
  animation: on-load 1.5s ease-in-out forwards;
}
.jeeta-app .profile-hero,
.jeeta-app * .profile-hero {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.jeeta-app .profile-hero .hero-img,
.jeeta-app * .profile-hero .hero-img {
  max-width: 75vw;
  max-height: 50vh;
  -o-object-fit: contain;
     object-fit: contain;
  animation: on-load 1.5s ease-in-out forwards;
}
.jeeta-app .profile-hero .hero-text,
.jeeta-app * .profile-hero .hero-text {
  width: 100%;
  flex-direction: column;
  text-align: center;
  color: var(--whiteColor);
  transform: translate(0, -30%);
}
.jeeta-app .profile-hero .hero-text .hero-title,
.jeeta-app * .profile-hero .hero-text .hero-title {
  width: 100%;
  font-size: 50px;
  font-weight: 800;
  background: var(--PrimaryBkg);
}
.jeeta-app .profile-hero .hero-text .hero-description,
.jeeta-app * .profile-hero .hero-text .hero-description {
  width: 100%;
  font-size: 25px;
  font-weight: 500;
  background: var(--SecondaryBkg);
}
@media all and (min-width: 1800px) {
  .jeeta-app .hero .hero-img,
  .jeeta-app * .hero .hero-img {
    max-width: 60vw;
    max-height: 60vh;
  }
  .jeeta-app .profile-hero .hero-img,
  .jeeta-app * .profile-hero .hero-img {
    max-width: 80vw;
    max-height: 55vh;
  }
  .jeeta-app .profile-hero .hero-text,
  .jeeta-app * .profile-hero .hero-text {
    transform: translate(0, -40%);
  }
  .jeeta-app .profile-hero .hero-text .hero-title,
  .jeeta-app * .profile-hero .hero-text .hero-title {
    font-size: 55px;
    padding: 0.2rem 0;
  }
  .jeeta-app .profile-hero .hero-text .hero-description,
  .jeeta-app * .profile-hero .hero-text .hero-description {
    font-size: 30px;
    padding: 0.4rem 0;
  }
}
@media all and (max-width: 860px) {
  .jeeta-app .hero .hero-text .hero-title,
  .jeeta-app * .hero .hero-text .hero-title {
    font-size: 2.5rem;
  }
  .jeeta-app .hero .hero-text .hero-description,
  .jeeta-app * .hero .hero-text .hero-description {
    font-size: 1.3rem;
  }
  .jeeta-app .hero.noProfile .hero-img,
  .jeeta-app * .hero.noProfile .hero-img {
    width: 100vw;
    margin-top: 3rem;
  }
  .jeeta-app .profile-hero .hero-img,
  .jeeta-app * .profile-hero .hero-img {
    width: 100vw;
    max-width: 100vw;
    max-height: 60vh;
  }
  .jeeta-app .profile-hero .hero-text .hero-title,
  .jeeta-app * .profile-hero .hero-text .hero-title {
    font-size: 35px;
    padding: 0.1rem 0;
  }
  .jeeta-app .profile-hero .hero-text .hero-description,
  .jeeta-app * .profile-hero .hero-text .hero-description {
    font-size: 22px;
    padding: 0.3rem 0;
  }
}
@media all and (max-width: 500px) {
  .jeeta-app .hero,
  .jeeta-app * .hero {
    padding: 0;
    min-height: 0;
  }
  .jeeta-app .hero .hero-text .hero-title,
  .jeeta-app * .hero .hero-text .hero-title {
    font-size: 1.9rem;
  }
  .jeeta-app .hero .hero-text .hero-description,
  .jeeta-app * .hero .hero-text .hero-description {
    font-size: 1rem;
  }
  .jeeta-app .profile-hero,
  .jeeta-app * .profile-hero {
    padding: 0;
    min-height: 0;
  }
  .jeeta-app .profile-hero .hero-text .hero-title,
  .jeeta-app * .profile-hero .hero-text .hero-title {
    font-size: 30px;
    padding: 0;
  }
  .jeeta-app .profile-hero .hero-text .hero-description,
  .jeeta-app * .profile-hero .hero-text .hero-description {
    font-size: 20px;
    padding: 0.2rem 0;
  }
}/*# sourceMappingURL=hero.css.map */