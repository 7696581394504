.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
  /* menu */
}
.jeeta-app .syllabus-sidebar,
.jeeta-app * .syllabus-sidebar,
.customModal .syllabus-sidebar, .customModal * .syllabus-sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 1rem;
  transition: all 300ms ease;
}
.jeeta-app .syllabus-logo,
.jeeta-app * .syllabus-logo,
.customModal .syllabus-logo, .customModal * .syllabus-logo {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
}
.jeeta-app .syllabus-logo span,
.jeeta-app * .syllabus-logo span,
.customModal .syllabus-logo span, .customModal * .syllabus-logo span {
  color: var(--PrimaryColor);
}
.jeeta-app .syllabus-logo span span,
.jeeta-app * .syllabus-logo span span,
.customModal .syllabus-logo span span, .customModal * .syllabus-logo span span {
  color: var(--SecondaryColor);
}
.jeeta-app .syllabus-logo img,
.jeeta-app * .syllabus-logo img,
.customModal .syllabus-logo img, .customModal * .syllabus-logo img {
  width: 70%;
  height: auto;
}
.jeeta-app .syllabus-menu,
.jeeta-app * .syllabus-menu,
.customModal .syllabus-menu, .customModal * .syllabus-menu {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: var(--textColor);
}
.jeeta-app .syllabus-menu .menuItem,
.jeeta-app * .syllabus-menu .menuItem,
.customModal .syllabus-menu .menuItem, .customModal * .syllabus-menu .menuItem {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  height: 2.5rem;
  margin-left: 1rem;
  position: relative;
  transition: all 300ms ease;
  border-radius: 0.7rem;
  font-size: 1rem;
}
.jeeta-app .syllabus-menu .menuItem:hover,
.jeeta-app * .syllabus-menu .menuItem:hover,
.customModal .syllabus-menu .menuItem:hover, .customModal * .syllabus-menu .menuItem:hover {
  cursor: pointer;
  font-weight: 600;
  color: var(--SecondaryColor);
}
.jeeta-app .syllabus-menu .menuItem .icon,
.jeeta-app * .syllabus-menu .menuItem .icon,
.customModal .syllabus-menu .menuItem .icon, .customModal * .syllabus-menu .menuItem .icon {
  font-size: 1.5rem;
}
.jeeta-app .syllabus-menu .active,
.jeeta-app * .syllabus-menu .active,
.customModal .syllabus-menu .active, .customModal * .syllabus-menu .active {
  background: var(--SecondaryBkg);
  margin-left: 0;
}
.jeeta-app .syllabus-menu .active::before,
.jeeta-app * .syllabus-menu .active::before,
.customModal .syllabus-menu .active::before, .customModal * .syllabus-menu .active::before {
  content: "";
  width: 8px;
  height: 100%;
  background: var(--SecondaryColor);
}
@media all and (max-width: 800px) {
  .jeeta-app .syllabus-sidebar,
  .jeeta-app * .syllabus-sidebar,
  .customModal .syllabus-sidebar, .customModal * .syllabus-sidebar {
    max-width: 3rem;
    padding-top: 5rem;
  }
  .jeeta-app .syllabus-logo span,
  .jeeta-app * .syllabus-logo span,
  .customModal .syllabus-logo span, .customModal * .syllabus-logo span {
    transform: rotate(90deg);
  }
  .jeeta-app .syllabus-logo img,
  .jeeta-app * .syllabus-logo img,
  .customModal .syllabus-logo img, .customModal * .syllabus-logo img {
    display: none;
  }
  .jeeta-app .syllabus-menu,
  .jeeta-app * .syllabus-menu,
  .customModal .syllabus-menu, .customModal * .syllabus-menu {
    margin-top: 5rem;
    gap: 0;
  }
  .jeeta-app .syllabus-menu .menuItem span,
  .jeeta-app * .syllabus-menu .menuItem span,
  .customModal .syllabus-menu .menuItem span, .customModal * .syllabus-menu .menuItem span {
    display: none;
  }
}
@media all and (max-width: 550px) {
  .jeeta-app .syllabus-sidebar,
  .jeeta-app * .syllabus-sidebar,
  .customModal .syllabus-sidebar, .customModal * .syllabus-sidebar {
    width: 100%;
    max-width: none;
    flex-direction: row;
    padding: 0.5rem 0 0.5rem 1.5rem;
    gap: 1rem;
  }
  .jeeta-app .syllabus-sidebar::before,
  .jeeta-app * .syllabus-sidebar::before,
  .customModal .syllabus-sidebar::before, .customModal * .syllabus-sidebar::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; /* Full width of the container */
    height: 2px; /* Thickness of the bottom line */
    background: var(--gradientBkg);
  }
  .jeeta-app .syllabus-logo span,
  .jeeta-app * .syllabus-logo span,
  .customModal .syllabus-logo span, .customModal * .syllabus-logo span {
    transform: none;
  }
  .jeeta-app .syllabus-logo img,
  .jeeta-app * .syllabus-logo img,
  .customModal .syllabus-logo img, .customModal * .syllabus-logo img {
    display: none;
  }
  .jeeta-app .syllabus-menu,
  .jeeta-app * .syllabus-menu,
  .customModal .syllabus-menu, .customModal * .syllabus-menu {
    flex-direction: row;
    margin-top: 0;
    gap: 0.5rem;
    color: var(--PrimaryColor);
    overflow-x: auto;
  }
  .jeeta-app .syllabus-menu::-webkit-scrollbar,
  .jeeta-app * .syllabus-menu::-webkit-scrollbar,
  .customModal .syllabus-menu::-webkit-scrollbar, .customModal * .syllabus-menu::-webkit-scrollbar {
    display: none;
  }
  .jeeta-app .syllabus-menu .menuItem,
  .jeeta-app * .syllabus-menu .menuItem,
  .customModal .syllabus-menu .menuItem, .customModal * .syllabus-menu .menuItem {
    margin-left: 0;
    padding: 0.5rem;
    gap: 0.2rem;
  }
  .jeeta-app .syllabus-menu .menuItem .icon,
  .jeeta-app * .syllabus-menu .menuItem .icon,
  .customModal .syllabus-menu .menuItem .icon, .customModal * .syllabus-menu .menuItem .icon {
    font-size: 1.25rem;
  }
  .jeeta-app .syllabus-menu .active,
  .jeeta-app * .syllabus-menu .active,
  .customModal .syllabus-menu .active, .customModal * .syllabus-menu .active {
    background: transparent;
    color: var(--SecondaryColor);
    font-weight: 500;
  }
  .jeeta-app .syllabus-menu .active span,
  .jeeta-app * .syllabus-menu .active span,
  .customModal .syllabus-menu .active span, .customModal * .syllabus-menu .active span {
    display: block;
  }
  .jeeta-app .syllabus-menu .active::before,
  .jeeta-app * .syllabus-menu .active::before,
  .customModal .syllabus-menu .active::before, .customModal * .syllabus-menu .active::before {
    content: "";
    width: 0; /* Full width of the container */
    height: 0; /* Thickness of the bottom line */
    background-color: transparent;
  }
}/*# sourceMappingURL=SyllabusSidebar.css.map */