
.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.testReport {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    $table-border-color: var(--cardBG);
    $table-header-bg: #f7f7f7;


    .testReportTitle {
        padding: .5rem 0;
        font-size: 1.7rem;
    }

    .testReportButtons {
        width: 100%;
        justify-content: space-between;
        padding: 0 1rem;
    }

    .report-container {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--cardBG);
        border-radius: 1rem;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        padding: 1rem;
        gap: .5rem;

        .report-title {
            flex-direction: row;
            gap: 2rem;
            background-color: transparent;
            margin-bottom: 2rem;
        }

        .report-items {
            width: 100%;
            padding-left: 1rem;
            gap: 1px;

            .report-item {
                padding: .2rem .7rem 1rem .7rem;
                font-size: .9rem;
                font-weight: 500;
                border: none;
                border-radius: .5rem;
                color: var(--whiteColor);
                background-color: var(--SecondaryColor);
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

                &:hover,
                &:active {
                    font-weight: 600;
                    cursor: pointer;
                    background-color: var(--PrimaryColor);
                }
            }

            .report-item.selected {
                background-color: var(--PrimaryColor);
            }
        }
    }

    .report-table-wrapper {
        width: 100%;
        max-height: 80vh;
        overflow-x: auto;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: thin;
        transform: translateY(-1.25rem);
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    .report-table {
        width: 100%;
        table-layout: auto;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

        thead tr {
            background-color: var(--PrimaryBkg);
            color: var(--textColor);
            text-align: center;
            vertical-align: middle;
        }

        th,
        tr,
        td {
            text-align: center;
            vertical-align: middle;
            padding: .5rem;
            border-bottom: 1px solid $table-border-color;
        }

        td {
            .detail-response-row {
                align-items: center;
                justify-content: center;
            }
        }

        tbody tr {

            &:hover {
                background-color: var(--whiteColor);
            }

            &:nth-of-type(even) {
                background-color: var(--whiteColorDeam);

                &:hover {
                    background-color: var(--whiteColor);
                }
            }
        }

        tbody td {

            .table-icon {
                padding-top: .1rem;
                font-size: 1.5rem;
                font-weight: 800;
            }

            #correct {
                color: var(--not-started);
            }

            #incorrect {
                color: var(--hard);
            }
        }
    }

    .statistics-section {
        position: relative;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        transform: translateY(-1.25rem);
        background-color: var(--whiteColor);
        border-radius: 1rem;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        padding: 1rem;
        

        .statistics-marks {
            flex-direction: row;
            gap: 2rem;
        }

        .statistics-buttons{
            position: fixed;
            top: 1rem;
            z-index: 2000;
            width: 100%;
            padding: 0 1rem;
            display: flex;
            justify-content: space-between;

            .statistics-sort-buttons,
            .statistics-view-buttons{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: .5rem;
            
                .statistics-button{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: .1rem .5rem;
                    border: none;
                    border-radius: .3rem;
                    background-color: var(--SecondaryColor);
                    color: var(--whiteColor);
                    cursor: pointer;
                }

                .statistics-button.active{
                    background-color: var(--PrimaryColor);
                    font-weight: 600;
                }
            }
        }
    }

    #close {
        position: absolute;
        top: .5rem;
        right: .5rem;
        color: var(--PrimaryColor);
        align-self: center;
        font-size: 1.8rem;
    }
}

.question-wise-charts,
.student-wise-charts{
    padding-top: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto; /* Makes the div scrollable in the x-direction */
    overflow-y: hidden; /* Hides the overflow in the y-direction */

    &::-webkit-scrollbar {
        width: .5rem;
        height: .5rem;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--SecondaryBkg);
        border-radius: .5rem;
        border: none;
    }
}

.question-wise-charts-legends,
.student-wise-charts-legends{
    position: fixed;
    top: 3.5rem;
    z-index: 2000;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .question-wise-charts-legend,
    .student-wise-charts-legend{
        display: flex;
        align-items: center;
        gap: .3rem;

        .circle-legend{
            width: .8rem;
            height: .8rem;
            border: 1px solid;
            // border-radius: 50%;
        }

        #correct{
            background-color:rgba(75, 192, 192, 0.2);
            border-color: rgba(75, 192, 192, 1);
        }

        #incorrect{
            background-color:rgba(255, 99, 132, 0.2);
            border-color: rgba(255, 99, 132, 1);
        }

        #partial-correct{
            background-color:rgba(255, 206, 86, 0.2);
            border-color: rgba(255, 206, 86, 1);
        }

        #no-response{
            background-color:rgba(54, 162, 235, 0.2);
            border-color: rgba(54, 162, 235, 1);
        }

        .legend-text{
            font-size: .8rem;
        }
    }
}

.question-wise-table,
.student-wise-table{
    padding-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: .85rem;
    // box-shadow: 0 0 1.11rem rgba(0, 0, 0, 0.15);

    thead tr {
        background-color: var(--whiteColorDeam);
        color: var(--textColor);
        text-align: center;
        font-size: .9rem;
        /* Horizontally center the text. */
        vertical-align: middle;
        /* Vertically center the text. */
        // padding: 10px 0;
    }

    td:nth-child(2),
    th:nth-child(2){
        background-color: rgba(75, 192, 192, 0.2);
    }

    td:nth-child(3),
    th:nth-child(3){
        background-color: rgba(255, 99, 132, 0.2);
    }

    td:nth-child(4),
    th:nth-child(4){
        background-color: rgba(255, 206, 86, 0.2);
    }

    td:nth-child(5),
    th:nth-child(5){
        background-color: rgba(54, 162, 235, 0.2);
    }

    th,
    td {
        padding: 10px;
        border-bottom: 1px solid var(--cardBG);
    }

    tbody tr {
        &:hover {
            font-weight: 600;
            background-color: var(--PrimaryBkg);
        }
    }

    tbody td {
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
    }

    tbody tr:last-of-type {
        td {
            border-bottom: 0;
        }
    }

    td:nth-child(1){
        font-weight: 600;
    }
    // td:nth-child(5),
    // td:nth-child(6),
    // td:nth-child(7) {
    //     .icon {
    //         padding-top: 3px;
    //         font-size: 1rem;
    //     }

    //     text-align: center;
    //     /* Horizontally center the text. */
    //     vertical-align: middle;
    //     /* Vertically center the text. */
    // }
}

.testReportModal {
    width: 80%;
    height: 80%;
    max-width: 100%;
    max-height: 80%;
    overflow-y: auto;
    background-color: var(--whiteColorDeam);
    padding: 1.5rem 1rem;
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.chart-container{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 .5rem;
    position: relative;
    overflow-x: auto !important; /* Makes the div scrollable in the x-direction */
    overflow-y: hidden !important; /* Hides the overflow in the y-direction */

    &::-webkit-scrollbar {
        width: .5rem;
        height: .5rem;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--SecondaryBkg);
        border-radius: .5rem;
        border: none;
    }
}

#general-statistics{
    canvas{
        width: auto !important;
        min-width: 50% !important;
        max-width: 100% !important;
        height: auto !important;
        max-height: 500px !important;
        margin: auto !important;
    }
}

.chart-wrapper {
    width: 500px; /* Adjust based on your layout needs */
    height: 300px; /* Fixed height for each chart */
    margin-bottom: 20px; /* Space between charts */
}

.chart-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {}

@media all and (max-width: 1000px) {}

@media all and (max-width: 720px) {
    .testReport {

        .testReportTitle {
            padding-top: 0;
        }

        .testReportButtons {
            padding: 0;

            .btn {
                margin-top: 0;
            }
        }

        .report-container {
            font-size: .9rem;

            .report-title {
                margin-bottom: 1.5rem;
            }

            .report-items {

                .report-item {
                    font-size: .85rem;
                }
            }
            .statistics-section {
                .statistics-marks {
                    gap: 1rem;
                }
            }
        }
    }
}

@media all and (max-width: 500px) {
    .testReport {

        .report-container {

            .report-title {
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: .5rem;
            }
            .statistics-section {
                .statistics-marks {
                    flex-direction: column;
                    gap: .5rem;
                }
            }
        }
    }
}
}