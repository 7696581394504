.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
    .testSection {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 40vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--glass);
    color: var(--textColor);
    gap: .5rem;
    padding: 0 1rem 1rem 1rem;
}

.testSectionTop {
    position: relative;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;

    h3,
    .testSectionTitle {
        width: 100%;
        height: 35px;
        box-sizing: border-box;
        font-size: 1.11rem;
        line-height: 40px;
        border: none;
        outline: none;
        border-bottom: 1px solid var(--PrimaryColor);
    }

    .testSectionDescription {
        width: 100%;
        box-sizing: border-box;
        font-size: .83rem;
        line-height: 40px;
        border: none;
        outline: none;
    }

    .edit-delete-section {
        position: absolute;
        top: 2px;
        right: 5px;
        color: var(--PrimaryColor);
        align-self: center;
        font-size: 1.67rem;
        .delete-section, .edit-section {
            &:hover {
                color: var(--tiger-lilly);
            }
        }
    }
}

.testSectionNumbers {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 1rem;

    .numbersTitle {
        font-size: 1rem;
        font-weight: 600;
    }

    .numbersInput {
        justify-content: flex-start;
        align-items: center;
        gap: .5rem;

        label {
            color: var(--PrimaryColor);
            font-size: .87rem;
            font-style: italic;
            font-weight: 600;
        }

        .testMarks {
            width: 50px;
            font-size: .77rem;
            padding: .2rem 0 .2rem .5rem;
            border: 1px solid var(--PrimaryColor);
        }
    }
}

.testQuestionAddBtns {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    gap: 1rem;
}

.testInfo {
    flex-direction: column;
    width: 100%;
    padding: .5rem 1rem;
    gap: .5rem;

    .instructions {
        width: 100%;
        font-size: 1rem;
        line-height: 1.5;

        li {
            font-family: 'Frank Ruhl Libre', serif;
            list-style-type: disc;
        }
    }

    .testMarksList {
        font-size: .94rem;
        margin-left: 1rem;
        line-height: 1.5;

        .numbersInput {
            font-family: 'Frank Ruhl Libre', serif;
            justify-content: flex-start;
            align-items: center;
            gap: .5rem;

            label {
                width: 7rem;
                font-size: .87rem;
                font-style: italic;
                font-weight: 600;
            }

            .testMarks {
                width: 2.2rem;
                font-size: .77rem;
                padding: .2rem 0 .2rem .5rem;
                border: 1px solid var(--PrimaryColor);
            }
        }
    }
}

.testSectionModal{
    background-color: var(--whiteColorDeam);
    position: fixed;        /* make the modal appear above other content */
    top: 55%;               /* centering the modal vertically */
    left: 50%;              /* centering the modal horizontally */
    transform: translate(-50%, -50%);  /* necessary adjustments for centering */
    z-index: 1000;          /* high value to ensure modal is on top */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    .modalTitle{
        text-align: center;
        font-size: 1.11rem;
        font-weight: 600;
    }

    .subjectCreateSelectionDropdown {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .title {
            font-size: .89rem;
            font-weight: 600;
        }

        .selectionDropdown,
        .selectionDropdownMultiple {
            width: 200px;
            font-size: .83rem;
        }
    }
    
    .buttons {
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
    }

    .btn {
        color: var(--whiteColor);
        font-weight: 600;
        justify-content: center;
        gap: .5rem;
    
        .icon {
            align-self: center;
            font-size: 1rem;
        }
    }

    #close {
        position: absolute;
        top: .5rem;
        right: .5rem;
        color: var(--PrimaryColor);
        align-self: center;
        font-size: 1.67rem;
    }
}


//MEDIA QUERIES ==================================================>
@media all and (max-width: 1000px) {}

@media all and (max-width: 720px) {
    .testSection {
        .testSectionContainer {
            padding: .5rem;
        }
    }
}

@media all and (max-width: 550px) {
    .testSection {
    }

    
.testSectionNumbers {
    flex-direction: column;
    // justify-content: center;
    align-items: flex-start;
    // padding: 1rem;
    gap: .5rem;

    .numbersTitle {
        font-size: 1rem;
        font-weight: 600;
    }

    .numbersInput {
        justify-content: flex-start;
        align-items: center;
        gap: .5rem;

        label {
            color: var(--PrimaryColor);
            font-size: .87rem;
            font-style: italic;
            font-weight: 600;
        }

        .testMarks {
            width: 50px;
            font-size: .77rem;
            padding: .2rem 0 .2rem .5rem;
            border: 1px solid var(--PrimaryColor);
        }
    }
}
        
    .testInfo {
        padding: .5rem ;

        .instructions {
            font-size: .9rem;
        }

        .testMarksList {
            font-size: .9rem;
            margin-left: 0rem;

            .numbersInput {
                align-items: flex-start;

                label {
                    width: auto;
                    white-space: nowrap;
                }
            }
        }
    }

    .testQuestionAddBtns {
        flex-direction: column;
        gap: .5rem;

        .btn {
            margin: 0;
        }
    }
}
}