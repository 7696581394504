.jeeta-app .tests,
.jeeta-app * .tests,
.customModal .tests, .customModal * .tests {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  color: var(--textColor);
  animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */
}
.jeeta-app .singleTestPage,
.jeeta-app * .singleTestPage,
.customModal .singleTestPage, .customModal * .singleTestPage {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}
.jeeta-app .singleTestPage .tests-glass,
.jeeta-app * .singleTestPage .tests-glass,
.customModal .singleTestPage .tests-glass, .customModal * .singleTestPage .tests-glass {
  height: 100%;
  border-radius: 0 !important;
  overflow-y: auto;
}
.jeeta-app .tests-header,
.jeeta-app * .tests-header,
.customModal .tests-header, .customModal * .tests-header {
  position: relative;
  width: 100%;
  padding: 0.5rem 1rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: var(--gradientBkg);
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}
.jeeta-app .tests-header .title,
.jeeta-app * .tests-header .title,
.customModal .tests-header .title, .customModal * .tests-header .title {
  color: var(--whiteColor);
  text-shadow: 0 2px 4px rgba(140, 140, 141, 0.3);
  text-align: center;
}
.jeeta-app .tests-filter,
.jeeta-app * .tests-filter,
.customModal .tests-filter, .customModal * .tests-filter {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  z-index: 5;
}
.jeeta-app .tests-filter .filterTitle,
.jeeta-app * .tests-filter .filterTitle,
.customModal .tests-filter .filterTitle, .customModal * .tests-filter .filterTitle {
  width: 100%;
  border-radius: 1rem;
  flex-direction: column;
  gap: 0.5rem;
}
.jeeta-app .tests-filter .filterTitle .title,
.jeeta-app * .tests-filter .filterTitle .title,
.customModal .tests-filter .filterTitle .title, .customModal * .tests-filter .filterTitle .title {
  text-align: center;
  color: var(--SecondaryColor);
}
.jeeta-app .tests-filter .filterTitle span,
.jeeta-app * .tests-filter .filterTitle span,
.customModal .tests-filter .filterTitle span, .customModal * .tests-filter .filterTitle span {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  color: var(--blackColor);
  opacity: 0.8;
}
.jeeta-app .tests-filter .filters,
.jeeta-app * .tests-filter .filters,
.customModal .tests-filter .filters, .customModal * .tests-filter .filters {
  width: 100%;
  margin: 0.5rem 0;
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  gap: 2rem;
}
.jeeta-app .tests-filter .filters .filterSelect,
.jeeta-app .tests-filter .filters .searchTextInput,
.jeeta-app .tests-filter .filters .testSelectionDropdown,
.jeeta-app * .tests-filter .filters .filterSelect,
.jeeta-app * .tests-filter .filters .searchTextInput,
.jeeta-app * .tests-filter .filters .testSelectionDropdown,
.customModal .tests-filter .filters .filterSelect,
.customModal .tests-filter .filters .searchTextInput,
.customModal .tests-filter .filters .testSelectionDropdown, .customModal * .tests-filter .filters .filterSelect,
.customModal * .tests-filter .filters .searchTextInput,
.customModal * .tests-filter .filters .testSelectionDropdown {
  flex: 1;
  min-width: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .tests-filter .filters .filterSelect .title,
.jeeta-app .tests-filter .filters .searchTextInput .title,
.jeeta-app .tests-filter .filters .testSelectionDropdown .title,
.jeeta-app * .tests-filter .filters .filterSelect .title,
.jeeta-app * .tests-filter .filters .searchTextInput .title,
.jeeta-app * .tests-filter .filters .testSelectionDropdown .title,
.customModal .tests-filter .filters .filterSelect .title,
.customModal .tests-filter .filters .searchTextInput .title,
.customModal .tests-filter .filters .testSelectionDropdown .title, .customModal * .tests-filter .filters .filterSelect .title,
.customModal * .tests-filter .filters .searchTextInput .title,
.customModal * .tests-filter .filters .testSelectionDropdown .title {
  font-weight: 600;
  font-size: 0.85rem;
  color: var(--textColor);
}
.jeeta-app .tests-filter .filters .filterSelect .selectionDropdown,
.jeeta-app .tests-filter .filters .filterSelect .selectionDropdownMultiple,
.jeeta-app .tests-filter .filters .searchTextInput .selectionDropdown,
.jeeta-app .tests-filter .filters .searchTextInput .selectionDropdownMultiple,
.jeeta-app .tests-filter .filters .testSelectionDropdown .selectionDropdown,
.jeeta-app .tests-filter .filters .testSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .tests-filter .filters .filterSelect .selectionDropdown,
.jeeta-app * .tests-filter .filters .filterSelect .selectionDropdownMultiple,
.jeeta-app * .tests-filter .filters .searchTextInput .selectionDropdown,
.jeeta-app * .tests-filter .filters .searchTextInput .selectionDropdownMultiple,
.jeeta-app * .tests-filter .filters .testSelectionDropdown .selectionDropdown,
.jeeta-app * .tests-filter .filters .testSelectionDropdown .selectionDropdownMultiple,
.customModal .tests-filter .filters .filterSelect .selectionDropdown,
.customModal .tests-filter .filters .filterSelect .selectionDropdownMultiple,
.customModal .tests-filter .filters .searchTextInput .selectionDropdown,
.customModal .tests-filter .filters .searchTextInput .selectionDropdownMultiple,
.customModal .tests-filter .filters .testSelectionDropdown .selectionDropdown,
.customModal .tests-filter .filters .testSelectionDropdown .selectionDropdownMultiple, .customModal * .tests-filter .filters .filterSelect .selectionDropdown,
.customModal * .tests-filter .filters .filterSelect .selectionDropdownMultiple,
.customModal * .tests-filter .filters .searchTextInput .selectionDropdown,
.customModal * .tests-filter .filters .searchTextInput .selectionDropdownMultiple,
.customModal * .tests-filter .filters .testSelectionDropdown .selectionDropdown,
.customModal * .tests-filter .filters .testSelectionDropdown .selectionDropdownMultiple {
  flex-grow: 1;
  font-size: 0.87rem;
}
.jeeta-app .tests-filter .filters .filterSelect .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .tests-filter .filters .filterSelect .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .tests-filter .filters .searchTextInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .tests-filter .filters .searchTextInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .tests-filter .filters .testSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .tests-filter .filters .testSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .tests-filter .filters .filterSelect .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .tests-filter .filters .filterSelect .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .tests-filter .filters .searchTextInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .tests-filter .filters .searchTextInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .tests-filter .filters .testSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .tests-filter .filters .testSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .tests-filter .filters .filterSelect .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .tests-filter .filters .filterSelect .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .tests-filter .filters .searchTextInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .tests-filter .filters .searchTextInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .tests-filter .filters .testSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .tests-filter .filters .testSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric, .customModal * .tests-filter .filters .filterSelect .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .tests-filter .filters .filterSelect .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .tests-filter .filters .searchTextInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .tests-filter .filters .searchTextInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .tests-filter .filters .testSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .tests-filter .filters .testSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric {
  max-width: 180px;
  overflow: hidden;
}
.jeeta-app .tests-filter .filters .filterSelect input,
.jeeta-app .tests-filter .filters .searchTextInput input,
.jeeta-app .tests-filter .filters .testSelectionDropdown input,
.jeeta-app * .tests-filter .filters .filterSelect input,
.jeeta-app * .tests-filter .filters .searchTextInput input,
.jeeta-app * .tests-filter .filters .testSelectionDropdown input,
.customModal .tests-filter .filters .filterSelect input,
.customModal .tests-filter .filters .searchTextInput input,
.customModal .tests-filter .filters .testSelectionDropdown input, .customModal * .tests-filter .filters .filterSelect input,
.customModal * .tests-filter .filters .searchTextInput input,
.customModal * .tests-filter .filters .testSelectionDropdown input {
  width: 100%;
  border: hsl(0, 0%, 80%) solid 1px;
  border-radius: 4px;
  padding: 0.3rem 0.5rem;
  font-size: 0.83rem;
}
.jeeta-app .tests-glass,
.jeeta-app * .tests-glass,
.customModal .tests-glass, .customModal * .tests-glass {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 40vh;
  padding: 1.5rem 1rem 0.5rem 1rem;
  background: var(--whiteColor);
  border-radius: 2rem;
  gap: 0.5rem;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}
.jeeta-app .tests-glass .title,
.jeeta-app * .tests-glass .title,
.customModal .tests-glass .title, .customModal * .tests-glass .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  text-align: center;
  color: var(--SecondaryColor);
}
.jeeta-app .tests-glass .bkgImg,
.jeeta-app * .tests-glass .bkgImg,
.customModal .tests-glass .bkgImg, .customModal * .tests-glass .bkgImg {
  width: 25rem;
}
.jeeta-app #NTA-tests-glass,
.jeeta-app * #NTA-tests-glass,
.customModal #NTA-tests-glass, .customModal * #NTA-tests-glass {
  height: 100vh !important;
  box-sizing: border-box;
  justify-content: center;
  border-radius: 0 !important;
  padding: 0;
  gap: 0;
  overflow: hidden;
}
.jeeta-app .btn,
.jeeta-app * .btn,
.customModal .btn, .customModal * .btn {
  color: var(--whiteColor);
  font-weight: 600;
  justify-content: space-between;
  gap: 0.5rem;
}
.jeeta-app .btn .icon,
.jeeta-app * .btn .icon,
.customModal .btn .icon, .customModal * .btn .icon {
  align-self: center;
  font-size: 1rem;
}
.jeeta-app #startTest,
.jeeta-app * #startTest,
.customModal #startTest, .customModal * #startTest {
  background-color: var(--tiger-lilly);
}
.jeeta-app .testReport-btn,
.jeeta-app * .testReport-btn,
.customModal .testReport-btn, .customModal * .testReport-btn {
  position: absolute;
  top: 0;
  right: 0;
  justify-content: center;
}
.jeeta-app .testReport-btn .report-btn,
.jeeta-app * .testReport-btn .report-btn,
.customModal .testReport-btn .report-btn, .customModal * .testReport-btn .report-btn {
  background-color: var(--finished);
  color: var(--whiteColor);
  border: none;
  border-radius: 1rem;
  font-size: 0.87rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .testReport-btn .report-btn .icon,
.jeeta-app * .testReport-btn .report-btn .icon,
.customModal .testReport-btn .report-btn .icon, .customModal * .testReport-btn .report-btn .icon {
  align-self: center;
  font-size: 1.11rem;
}
.jeeta-app .testReport-btn .report-btn:hover,
.jeeta-app * .testReport-btn .report-btn:hover,
.customModal .testReport-btn .report-btn:hover, .customModal * .testReport-btn .report-btn:hover {
  cursor: pointer;
  background-color: var(--SecondaryColor);
}
.jeeta-app .test-refresh,
.jeeta-app * .test-refresh,
.customModal .test-refresh, .customModal * .test-refresh {
  position: absolute;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
}
.jeeta-app .test-refresh .msg,
.jeeta-app * .test-refresh .msg,
.customModal .test-refresh .msg, .customModal * .test-refresh .msg {
  background-color: var(--started);
  color: var(--whiteColor);
  border: none;
  border-radius: 1rem;
  font-size: 0.87rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .test-refresh .msg .icon,
.jeeta-app * .test-refresh .msg .icon,
.customModal .test-refresh .msg .icon, .customModal * .test-refresh .msg .icon {
  font-size: 1rem;
  font-weight: 600;
}
.jeeta-app .test-refresh:hover,
.jeeta-app * .test-refresh:hover,
.customModal .test-refresh:hover, .customModal * .test-refresh:hover {
  cursor: pointer;
}
.jeeta-app .test-status,
.jeeta-app * .test-status,
.customModal .test-status, .customModal * .test-status {
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}
.jeeta-app .test-status .msg,
.jeeta-app * .test-status .msg,
.customModal .test-status .msg, .customModal * .test-status .msg {
  color: var(--whiteColor);
  border: none;
  border-radius: 1rem;
  font-size: 0.87rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.jeeta-app .test-status #started,
.jeeta-app * .test-status #started,
.customModal .test-status #started, .customModal * .test-status #started {
  background-color: var(--started);
}
.jeeta-app .test-status #finished,
.jeeta-app * .test-status #finished,
.customModal .test-status #finished, .customModal * .test-status #finished {
  background-color: var(--finished);
}
.jeeta-app .test-status #not-started,
.jeeta-app * .test-status #not-started,
.customModal .test-status #not-started, .customModal * .test-status #not-started {
  background-color: var(--not-started);
}
.jeeta-app .testInfo-edit-btn,
.jeeta-app * .testInfo-edit-btn,
.customModal .testInfo-edit-btn, .customModal * .testInfo-edit-btn {
  position: absolute;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
}
.jeeta-app .testInfo-edit-btn .msg,
.jeeta-app * .testInfo-edit-btn .msg,
.customModal .testInfo-edit-btn .msg, .customModal * .testInfo-edit-btn .msg {
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
  border: none;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 0.87rem;
}
.jeeta-app .testInfo-edit-btn .msg .icon,
.jeeta-app * .testInfo-edit-btn .msg .icon,
.customModal .testInfo-edit-btn .msg .icon, .customModal * .testInfo-edit-btn .msg .icon {
  font-size: 1.05rem;
  font-weight: 600;
}
.jeeta-app .testInfo-edit-btn:hover,
.jeeta-app * .testInfo-edit-btn:hover,
.customModal .testInfo-edit-btn:hover, .customModal * .testInfo-edit-btn:hover {
  cursor: pointer;
}
.jeeta-app .testInfo-edit-btn:hover .msg,
.jeeta-app * .testInfo-edit-btn:hover .msg,
.customModal .testInfo-edit-btn:hover .msg, .customModal * .testInfo-edit-btn:hover .msg {
  background-color: var(--SecondaryColor);
}
.jeeta-app .testEdit,
.jeeta-app * .testEdit,
.customModal .testEdit, .customModal * .testEdit {
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
}
.jeeta-app .testEdit .testSubjectTabsContainer,
.jeeta-app .testEdit .testSubjectCreateTabsContainer,
.jeeta-app * .testEdit .testSubjectTabsContainer,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer,
.customModal .testEdit .testSubjectTabsContainer,
.customModal .testEdit .testSubjectCreateTabsContainer, .customModal * .testEdit .testSubjectTabsContainer,
.customModal * .testEdit .testSubjectCreateTabsContainer {
  transform: translateY(0.5rem);
  flex-direction: row;
  padding: 0 1rem;
  justify-content: flex-start;
  align-items: center;
}
.jeeta-app .testEdit .testSubjectTabsContainer .testSubjectTabs,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .testSubjectTabs,
.jeeta-app * .testEdit .testSubjectTabsContainer .testSubjectTabs,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .testSubjectTabs,
.customModal .testEdit .testSubjectTabsContainer .testSubjectTabs,
.customModal .testEdit .testSubjectCreateTabsContainer .testSubjectTabs, .customModal * .testEdit .testSubjectTabsContainer .testSubjectTabs,
.customModal * .testEdit .testSubjectCreateTabsContainer .testSubjectTabs {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.01rem;
  overflow-x: auto;
  /* Allows horizontal scrolling */
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.jeeta-app .testEdit .testSubjectTabsContainer .testSubjectTabs::-webkit-scrollbar,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .testSubjectTabs::-webkit-scrollbar,
.jeeta-app * .testEdit .testSubjectTabsContainer .testSubjectTabs::-webkit-scrollbar,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .testSubjectTabs::-webkit-scrollbar,
.customModal .testEdit .testSubjectTabsContainer .testSubjectTabs::-webkit-scrollbar,
.customModal .testEdit .testSubjectCreateTabsContainer .testSubjectTabs::-webkit-scrollbar, .customModal * .testEdit .testSubjectTabsContainer .testSubjectTabs::-webkit-scrollbar,
.customModal * .testEdit .testSubjectCreateTabsContainer .testSubjectTabs::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}
.jeeta-app .testEdit .testSubjectTabsContainer .tabItem,
.jeeta-app .testEdit .testSubjectTabsContainer .left-btn,
.jeeta-app .testEdit .testSubjectTabsContainer .right-btn,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .tabItem,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .left-btn,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .right-btn,
.jeeta-app * .testEdit .testSubjectTabsContainer .tabItem,
.jeeta-app * .testEdit .testSubjectTabsContainer .left-btn,
.jeeta-app * .testEdit .testSubjectTabsContainer .right-btn,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .tabItem,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .left-btn,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .right-btn,
.customModal .testEdit .testSubjectTabsContainer .tabItem,
.customModal .testEdit .testSubjectTabsContainer .left-btn,
.customModal .testEdit .testSubjectTabsContainer .right-btn,
.customModal .testEdit .testSubjectCreateTabsContainer .tabItem,
.customModal .testEdit .testSubjectCreateTabsContainer .left-btn,
.customModal .testEdit .testSubjectCreateTabsContainer .right-btn, .customModal * .testEdit .testSubjectTabsContainer .tabItem,
.customModal * .testEdit .testSubjectTabsContainer .left-btn,
.customModal * .testEdit .testSubjectTabsContainer .right-btn,
.customModal * .testEdit .testSubjectCreateTabsContainer .tabItem,
.customModal * .testEdit .testSubjectCreateTabsContainer .left-btn,
.customModal * .testEdit .testSubjectCreateTabsContainer .right-btn {
  white-space: nowrap;
  padding: 0.3rem 1rem 1.5rem 1rem;
  text-decoration: none;
  font-weight: 500;
  color: var(--whiteColor);
  background-color: var(--SecondaryBkg);
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 0.5rem;
}
.jeeta-app .testEdit .testSubjectTabsContainer .tabItem:hover,
.jeeta-app .testEdit .testSubjectTabsContainer .left-btn:hover,
.jeeta-app .testEdit .testSubjectTabsContainer .right-btn:hover,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .tabItem:hover,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .left-btn:hover,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .right-btn:hover,
.jeeta-app * .testEdit .testSubjectTabsContainer .tabItem:hover,
.jeeta-app * .testEdit .testSubjectTabsContainer .left-btn:hover,
.jeeta-app * .testEdit .testSubjectTabsContainer .right-btn:hover,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .tabItem:hover,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .left-btn:hover,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .right-btn:hover,
.customModal .testEdit .testSubjectTabsContainer .tabItem:hover,
.customModal .testEdit .testSubjectTabsContainer .left-btn:hover,
.customModal .testEdit .testSubjectTabsContainer .right-btn:hover,
.customModal .testEdit .testSubjectCreateTabsContainer .tabItem:hover,
.customModal .testEdit .testSubjectCreateTabsContainer .left-btn:hover,
.customModal .testEdit .testSubjectCreateTabsContainer .right-btn:hover, .customModal * .testEdit .testSubjectTabsContainer .tabItem:hover,
.customModal * .testEdit .testSubjectTabsContainer .left-btn:hover,
.customModal * .testEdit .testSubjectTabsContainer .right-btn:hover,
.customModal * .testEdit .testSubjectCreateTabsContainer .tabItem:hover,
.customModal * .testEdit .testSubjectCreateTabsContainer .left-btn:hover,
.customModal * .testEdit .testSubjectCreateTabsContainer .right-btn:hover {
  cursor: pointer;
  background-color: var(--SecondaryColor);
  font-weight: 600;
}
.jeeta-app .testEdit .testSubjectTabsContainer .tabItem .icon,
.jeeta-app .testEdit .testSubjectTabsContainer .left-btn .icon,
.jeeta-app .testEdit .testSubjectTabsContainer .right-btn .icon,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .tabItem .icon,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .left-btn .icon,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .right-btn .icon,
.jeeta-app * .testEdit .testSubjectTabsContainer .tabItem .icon,
.jeeta-app * .testEdit .testSubjectTabsContainer .left-btn .icon,
.jeeta-app * .testEdit .testSubjectTabsContainer .right-btn .icon,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .tabItem .icon,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .left-btn .icon,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .right-btn .icon,
.customModal .testEdit .testSubjectTabsContainer .tabItem .icon,
.customModal .testEdit .testSubjectTabsContainer .left-btn .icon,
.customModal .testEdit .testSubjectTabsContainer .right-btn .icon,
.customModal .testEdit .testSubjectCreateTabsContainer .tabItem .icon,
.customModal .testEdit .testSubjectCreateTabsContainer .left-btn .icon,
.customModal .testEdit .testSubjectCreateTabsContainer .right-btn .icon, .customModal * .testEdit .testSubjectTabsContainer .tabItem .icon,
.customModal * .testEdit .testSubjectTabsContainer .left-btn .icon,
.customModal * .testEdit .testSubjectTabsContainer .right-btn .icon,
.customModal * .testEdit .testSubjectCreateTabsContainer .tabItem .icon,
.customModal * .testEdit .testSubjectCreateTabsContainer .left-btn .icon,
.customModal * .testEdit .testSubjectCreateTabsContainer .right-btn .icon {
  font-size: 1.5rem;
}
.jeeta-app .testEdit .testSubjectTabsContainer .tabItem .icon.delete-subject:hover,
.jeeta-app .testEdit .testSubjectTabsContainer .tabItem .icon.add-subject:hover,
.jeeta-app .testEdit .testSubjectTabsContainer .left-btn .icon.delete-subject:hover,
.jeeta-app .testEdit .testSubjectTabsContainer .left-btn .icon.add-subject:hover,
.jeeta-app .testEdit .testSubjectTabsContainer .right-btn .icon.delete-subject:hover,
.jeeta-app .testEdit .testSubjectTabsContainer .right-btn .icon.add-subject:hover,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .tabItem .icon.delete-subject:hover,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .tabItem .icon.add-subject:hover,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .left-btn .icon.delete-subject:hover,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .left-btn .icon.add-subject:hover,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .right-btn .icon.delete-subject:hover,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .right-btn .icon.add-subject:hover,
.jeeta-app * .testEdit .testSubjectTabsContainer .tabItem .icon.delete-subject:hover,
.jeeta-app * .testEdit .testSubjectTabsContainer .tabItem .icon.add-subject:hover,
.jeeta-app * .testEdit .testSubjectTabsContainer .left-btn .icon.delete-subject:hover,
.jeeta-app * .testEdit .testSubjectTabsContainer .left-btn .icon.add-subject:hover,
.jeeta-app * .testEdit .testSubjectTabsContainer .right-btn .icon.delete-subject:hover,
.jeeta-app * .testEdit .testSubjectTabsContainer .right-btn .icon.add-subject:hover,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .tabItem .icon.delete-subject:hover,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .tabItem .icon.add-subject:hover,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .left-btn .icon.delete-subject:hover,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .left-btn .icon.add-subject:hover,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .right-btn .icon.delete-subject:hover,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .right-btn .icon.add-subject:hover,
.customModal .testEdit .testSubjectTabsContainer .tabItem .icon.delete-subject:hover,
.customModal .testEdit .testSubjectTabsContainer .tabItem .icon.add-subject:hover,
.customModal .testEdit .testSubjectTabsContainer .left-btn .icon.delete-subject:hover,
.customModal .testEdit .testSubjectTabsContainer .left-btn .icon.add-subject:hover,
.customModal .testEdit .testSubjectTabsContainer .right-btn .icon.delete-subject:hover,
.customModal .testEdit .testSubjectTabsContainer .right-btn .icon.add-subject:hover,
.customModal .testEdit .testSubjectCreateTabsContainer .tabItem .icon.delete-subject:hover,
.customModal .testEdit .testSubjectCreateTabsContainer .tabItem .icon.add-subject:hover,
.customModal .testEdit .testSubjectCreateTabsContainer .left-btn .icon.delete-subject:hover,
.customModal .testEdit .testSubjectCreateTabsContainer .left-btn .icon.add-subject:hover,
.customModal .testEdit .testSubjectCreateTabsContainer .right-btn .icon.delete-subject:hover,
.customModal .testEdit .testSubjectCreateTabsContainer .right-btn .icon.add-subject:hover, .customModal * .testEdit .testSubjectTabsContainer .tabItem .icon.delete-subject:hover,
.customModal * .testEdit .testSubjectTabsContainer .tabItem .icon.add-subject:hover,
.customModal * .testEdit .testSubjectTabsContainer .left-btn .icon.delete-subject:hover,
.customModal * .testEdit .testSubjectTabsContainer .left-btn .icon.add-subject:hover,
.customModal * .testEdit .testSubjectTabsContainer .right-btn .icon.delete-subject:hover,
.customModal * .testEdit .testSubjectTabsContainer .right-btn .icon.add-subject:hover,
.customModal * .testEdit .testSubjectCreateTabsContainer .tabItem .icon.delete-subject:hover,
.customModal * .testEdit .testSubjectCreateTabsContainer .tabItem .icon.add-subject:hover,
.customModal * .testEdit .testSubjectCreateTabsContainer .left-btn .icon.delete-subject:hover,
.customModal * .testEdit .testSubjectCreateTabsContainer .left-btn .icon.add-subject:hover,
.customModal * .testEdit .testSubjectCreateTabsContainer .right-btn .icon.delete-subject:hover,
.customModal * .testEdit .testSubjectCreateTabsContainer .right-btn .icon.add-subject:hover {
  color: var(--tiger-lilly);
}
.jeeta-app .testEdit .testSubjectTabsContainer .tabItem.selected,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .tabItem.selected,
.jeeta-app * .testEdit .testSubjectTabsContainer .tabItem.selected,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .tabItem.selected,
.customModal .testEdit .testSubjectTabsContainer .tabItem.selected,
.customModal .testEdit .testSubjectCreateTabsContainer .tabItem.selected, .customModal * .testEdit .testSubjectTabsContainer .tabItem.selected,
.customModal * .testEdit .testSubjectCreateTabsContainer .tabItem.selected {
  color: var(--whiteColor);
  background-color: var(--SecondaryColor);
  font-weight: 600;
}
.jeeta-app .testEdit .testSubjectTabsContainer .left-btn,
.jeeta-app .testEdit .testSubjectTabsContainer .right-btn,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .left-btn,
.jeeta-app .testEdit .testSubjectCreateTabsContainer .right-btn,
.jeeta-app * .testEdit .testSubjectTabsContainer .left-btn,
.jeeta-app * .testEdit .testSubjectTabsContainer .right-btn,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .left-btn,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer .right-btn,
.customModal .testEdit .testSubjectTabsContainer .left-btn,
.customModal .testEdit .testSubjectTabsContainer .right-btn,
.customModal .testEdit .testSubjectCreateTabsContainer .left-btn,
.customModal .testEdit .testSubjectCreateTabsContainer .right-btn, .customModal * .testEdit .testSubjectTabsContainer .left-btn,
.customModal * .testEdit .testSubjectTabsContainer .right-btn,
.customModal * .testEdit .testSubjectCreateTabsContainer .left-btn,
.customModal * .testEdit .testSubjectCreateTabsContainer .right-btn {
  padding: 0.3rem 0.5rem 1.5rem 0.5rem;
}
.jeeta-app .testEdit .testSubjectCreateTabsContainer,
.jeeta-app * .testEdit .testSubjectCreateTabsContainer,
.customModal .testEdit .testSubjectCreateTabsContainer, .customModal * .testEdit .testSubjectCreateTabsContainer {
  transform: translateY(0);
}
.jeeta-app .testEdit .testSectionTabsContainer,
.jeeta-app .testEdit .testSectionCreateTabsContainer,
.jeeta-app * .testEdit .testSectionTabsContainer,
.jeeta-app * .testEdit .testSectionCreateTabsContainer,
.customModal .testEdit .testSectionTabsContainer,
.customModal .testEdit .testSectionCreateTabsContainer, .customModal * .testEdit .testSectionTabsContainer,
.customModal * .testEdit .testSectionCreateTabsContainer {
  transform: translateY(0.5rem);
  flex-direction: row;
  padding: 0 1rem;
  justify-content: flex-start;
  align-items: center;
}
.jeeta-app .testEdit .testSectionTabsContainer .testSectionTabs,
.jeeta-app .testEdit .testSectionCreateTabsContainer .testSectionTabs,
.jeeta-app * .testEdit .testSectionTabsContainer .testSectionTabs,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .testSectionTabs,
.customModal .testEdit .testSectionTabsContainer .testSectionTabs,
.customModal .testEdit .testSectionCreateTabsContainer .testSectionTabs, .customModal * .testEdit .testSectionTabsContainer .testSectionTabs,
.customModal * .testEdit .testSectionCreateTabsContainer .testSectionTabs {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.01rem;
  overflow-x: auto;
  /* Allows horizontal scrolling */
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.jeeta-app .testEdit .testSectionTabsContainer .testSectionTabs::-webkit-scrollbar,
.jeeta-app .testEdit .testSectionCreateTabsContainer .testSectionTabs::-webkit-scrollbar,
.jeeta-app * .testEdit .testSectionTabsContainer .testSectionTabs::-webkit-scrollbar,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .testSectionTabs::-webkit-scrollbar,
.customModal .testEdit .testSectionTabsContainer .testSectionTabs::-webkit-scrollbar,
.customModal .testEdit .testSectionCreateTabsContainer .testSectionTabs::-webkit-scrollbar, .customModal * .testEdit .testSectionTabsContainer .testSectionTabs::-webkit-scrollbar,
.customModal * .testEdit .testSectionCreateTabsContainer .testSectionTabs::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}
.jeeta-app .testEdit .testSectionTabsContainer .tabItem,
.jeeta-app .testEdit .testSectionTabsContainer .left-btn,
.jeeta-app .testEdit .testSectionTabsContainer .right-btn,
.jeeta-app .testEdit .testSectionCreateTabsContainer .tabItem,
.jeeta-app .testEdit .testSectionCreateTabsContainer .left-btn,
.jeeta-app .testEdit .testSectionCreateTabsContainer .right-btn,
.jeeta-app * .testEdit .testSectionTabsContainer .tabItem,
.jeeta-app * .testEdit .testSectionTabsContainer .left-btn,
.jeeta-app * .testEdit .testSectionTabsContainer .right-btn,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .tabItem,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .left-btn,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .right-btn,
.customModal .testEdit .testSectionTabsContainer .tabItem,
.customModal .testEdit .testSectionTabsContainer .left-btn,
.customModal .testEdit .testSectionTabsContainer .right-btn,
.customModal .testEdit .testSectionCreateTabsContainer .tabItem,
.customModal .testEdit .testSectionCreateTabsContainer .left-btn,
.customModal .testEdit .testSectionCreateTabsContainer .right-btn, .customModal * .testEdit .testSectionTabsContainer .tabItem,
.customModal * .testEdit .testSectionTabsContainer .left-btn,
.customModal * .testEdit .testSectionTabsContainer .right-btn,
.customModal * .testEdit .testSectionCreateTabsContainer .tabItem,
.customModal * .testEdit .testSectionCreateTabsContainer .left-btn,
.customModal * .testEdit .testSectionCreateTabsContainer .right-btn {
  white-space: nowrap;
  padding: 0.3rem 1rem 1.5rem 1rem;
  text-decoration: none;
  font-weight: 500;
  color: var(--whiteColor);
  background-color: var(--PrimaryBkg);
  font-size: 0.95rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 0.5rem;
}
.jeeta-app .testEdit .testSectionTabsContainer .tabItem:hover,
.jeeta-app .testEdit .testSectionTabsContainer .left-btn:hover,
.jeeta-app .testEdit .testSectionTabsContainer .right-btn:hover,
.jeeta-app .testEdit .testSectionCreateTabsContainer .tabItem:hover,
.jeeta-app .testEdit .testSectionCreateTabsContainer .left-btn:hover,
.jeeta-app .testEdit .testSectionCreateTabsContainer .right-btn:hover,
.jeeta-app * .testEdit .testSectionTabsContainer .tabItem:hover,
.jeeta-app * .testEdit .testSectionTabsContainer .left-btn:hover,
.jeeta-app * .testEdit .testSectionTabsContainer .right-btn:hover,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .tabItem:hover,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .left-btn:hover,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .right-btn:hover,
.customModal .testEdit .testSectionTabsContainer .tabItem:hover,
.customModal .testEdit .testSectionTabsContainer .left-btn:hover,
.customModal .testEdit .testSectionTabsContainer .right-btn:hover,
.customModal .testEdit .testSectionCreateTabsContainer .tabItem:hover,
.customModal .testEdit .testSectionCreateTabsContainer .left-btn:hover,
.customModal .testEdit .testSectionCreateTabsContainer .right-btn:hover, .customModal * .testEdit .testSectionTabsContainer .tabItem:hover,
.customModal * .testEdit .testSectionTabsContainer .left-btn:hover,
.customModal * .testEdit .testSectionTabsContainer .right-btn:hover,
.customModal * .testEdit .testSectionCreateTabsContainer .tabItem:hover,
.customModal * .testEdit .testSectionCreateTabsContainer .left-btn:hover,
.customModal * .testEdit .testSectionCreateTabsContainer .right-btn:hover {
  cursor: pointer;
  background-color: var(--PrimaryColor);
  font-weight: 600;
}
.jeeta-app .testEdit .testSectionTabsContainer .tabItem .icon,
.jeeta-app .testEdit .testSectionTabsContainer .left-btn .icon,
.jeeta-app .testEdit .testSectionTabsContainer .right-btn .icon,
.jeeta-app .testEdit .testSectionCreateTabsContainer .tabItem .icon,
.jeeta-app .testEdit .testSectionCreateTabsContainer .left-btn .icon,
.jeeta-app .testEdit .testSectionCreateTabsContainer .right-btn .icon,
.jeeta-app * .testEdit .testSectionTabsContainer .tabItem .icon,
.jeeta-app * .testEdit .testSectionTabsContainer .left-btn .icon,
.jeeta-app * .testEdit .testSectionTabsContainer .right-btn .icon,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .tabItem .icon,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .left-btn .icon,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .right-btn .icon,
.customModal .testEdit .testSectionTabsContainer .tabItem .icon,
.customModal .testEdit .testSectionTabsContainer .left-btn .icon,
.customModal .testEdit .testSectionTabsContainer .right-btn .icon,
.customModal .testEdit .testSectionCreateTabsContainer .tabItem .icon,
.customModal .testEdit .testSectionCreateTabsContainer .left-btn .icon,
.customModal .testEdit .testSectionCreateTabsContainer .right-btn .icon, .customModal * .testEdit .testSectionTabsContainer .tabItem .icon,
.customModal * .testEdit .testSectionTabsContainer .left-btn .icon,
.customModal * .testEdit .testSectionTabsContainer .right-btn .icon,
.customModal * .testEdit .testSectionCreateTabsContainer .tabItem .icon,
.customModal * .testEdit .testSectionCreateTabsContainer .left-btn .icon,
.customModal * .testEdit .testSectionCreateTabsContainer .right-btn .icon {
  font-size: 1.425rem;
}
.jeeta-app .testEdit .testSectionTabsContainer .tabItem .icon.delete-section:hover,
.jeeta-app .testEdit .testSectionTabsContainer .tabItem .icon.add-section:hover,
.jeeta-app .testEdit .testSectionTabsContainer .left-btn .icon.delete-section:hover,
.jeeta-app .testEdit .testSectionTabsContainer .left-btn .icon.add-section:hover,
.jeeta-app .testEdit .testSectionTabsContainer .right-btn .icon.delete-section:hover,
.jeeta-app .testEdit .testSectionTabsContainer .right-btn .icon.add-section:hover,
.jeeta-app .testEdit .testSectionCreateTabsContainer .tabItem .icon.delete-section:hover,
.jeeta-app .testEdit .testSectionCreateTabsContainer .tabItem .icon.add-section:hover,
.jeeta-app .testEdit .testSectionCreateTabsContainer .left-btn .icon.delete-section:hover,
.jeeta-app .testEdit .testSectionCreateTabsContainer .left-btn .icon.add-section:hover,
.jeeta-app .testEdit .testSectionCreateTabsContainer .right-btn .icon.delete-section:hover,
.jeeta-app .testEdit .testSectionCreateTabsContainer .right-btn .icon.add-section:hover,
.jeeta-app * .testEdit .testSectionTabsContainer .tabItem .icon.delete-section:hover,
.jeeta-app * .testEdit .testSectionTabsContainer .tabItem .icon.add-section:hover,
.jeeta-app * .testEdit .testSectionTabsContainer .left-btn .icon.delete-section:hover,
.jeeta-app * .testEdit .testSectionTabsContainer .left-btn .icon.add-section:hover,
.jeeta-app * .testEdit .testSectionTabsContainer .right-btn .icon.delete-section:hover,
.jeeta-app * .testEdit .testSectionTabsContainer .right-btn .icon.add-section:hover,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .tabItem .icon.delete-section:hover,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .tabItem .icon.add-section:hover,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .left-btn .icon.delete-section:hover,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .left-btn .icon.add-section:hover,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .right-btn .icon.delete-section:hover,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .right-btn .icon.add-section:hover,
.customModal .testEdit .testSectionTabsContainer .tabItem .icon.delete-section:hover,
.customModal .testEdit .testSectionTabsContainer .tabItem .icon.add-section:hover,
.customModal .testEdit .testSectionTabsContainer .left-btn .icon.delete-section:hover,
.customModal .testEdit .testSectionTabsContainer .left-btn .icon.add-section:hover,
.customModal .testEdit .testSectionTabsContainer .right-btn .icon.delete-section:hover,
.customModal .testEdit .testSectionTabsContainer .right-btn .icon.add-section:hover,
.customModal .testEdit .testSectionCreateTabsContainer .tabItem .icon.delete-section:hover,
.customModal .testEdit .testSectionCreateTabsContainer .tabItem .icon.add-section:hover,
.customModal .testEdit .testSectionCreateTabsContainer .left-btn .icon.delete-section:hover,
.customModal .testEdit .testSectionCreateTabsContainer .left-btn .icon.add-section:hover,
.customModal .testEdit .testSectionCreateTabsContainer .right-btn .icon.delete-section:hover,
.customModal .testEdit .testSectionCreateTabsContainer .right-btn .icon.add-section:hover, .customModal * .testEdit .testSectionTabsContainer .tabItem .icon.delete-section:hover,
.customModal * .testEdit .testSectionTabsContainer .tabItem .icon.add-section:hover,
.customModal * .testEdit .testSectionTabsContainer .left-btn .icon.delete-section:hover,
.customModal * .testEdit .testSectionTabsContainer .left-btn .icon.add-section:hover,
.customModal * .testEdit .testSectionTabsContainer .right-btn .icon.delete-section:hover,
.customModal * .testEdit .testSectionTabsContainer .right-btn .icon.add-section:hover,
.customModal * .testEdit .testSectionCreateTabsContainer .tabItem .icon.delete-section:hover,
.customModal * .testEdit .testSectionCreateTabsContainer .tabItem .icon.add-section:hover,
.customModal * .testEdit .testSectionCreateTabsContainer .left-btn .icon.delete-section:hover,
.customModal * .testEdit .testSectionCreateTabsContainer .left-btn .icon.add-section:hover,
.customModal * .testEdit .testSectionCreateTabsContainer .right-btn .icon.delete-section:hover,
.customModal * .testEdit .testSectionCreateTabsContainer .right-btn .icon.add-section:hover {
  color: var(--tiger-lilly);
}
.jeeta-app .testEdit .testSectionTabsContainer .tabItem.selected,
.jeeta-app .testEdit .testSectionCreateTabsContainer .tabItem.selected,
.jeeta-app * .testEdit .testSectionTabsContainer .tabItem.selected,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .tabItem.selected,
.customModal .testEdit .testSectionTabsContainer .tabItem.selected,
.customModal .testEdit .testSectionCreateTabsContainer .tabItem.selected, .customModal * .testEdit .testSectionTabsContainer .tabItem.selected,
.customModal * .testEdit .testSectionCreateTabsContainer .tabItem.selected {
  color: var(--whiteColor);
  background-color: var(--PrimaryColor);
  font-weight: 600;
}
.jeeta-app .testEdit .testSectionTabsContainer .left-btn,
.jeeta-app .testEdit .testSectionTabsContainer .right-btn,
.jeeta-app .testEdit .testSectionCreateTabsContainer .left-btn,
.jeeta-app .testEdit .testSectionCreateTabsContainer .right-btn,
.jeeta-app * .testEdit .testSectionTabsContainer .left-btn,
.jeeta-app * .testEdit .testSectionTabsContainer .right-btn,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .left-btn,
.jeeta-app * .testEdit .testSectionCreateTabsContainer .right-btn,
.customModal .testEdit .testSectionTabsContainer .left-btn,
.customModal .testEdit .testSectionTabsContainer .right-btn,
.customModal .testEdit .testSectionCreateTabsContainer .left-btn,
.customModal .testEdit .testSectionCreateTabsContainer .right-btn, .customModal * .testEdit .testSectionTabsContainer .left-btn,
.customModal * .testEdit .testSectionTabsContainer .right-btn,
.customModal * .testEdit .testSectionCreateTabsContainer .left-btn,
.customModal * .testEdit .testSectionCreateTabsContainer .right-btn {
  padding: 0.3rem 0.5rem 1.5rem 0.5rem;
}
.jeeta-app .testEdit .testSectionCreateTabsContainer,
.jeeta-app * .testEdit .testSectionCreateTabsContainer,
.customModal .testEdit .testSectionCreateTabsContainer, .customModal * .testEdit .testSectionCreateTabsContainer {
  transform: translateY(0);
  margin-top: 1rem;
}
.jeeta-app .testEdit .testSubjectContentsContainer,
.jeeta-app .testEdit .testSubjectCreateContentsContainer,
.jeeta-app * .testEdit .testSubjectContentsContainer,
.jeeta-app * .testEdit .testSubjectCreateContentsContainer,
.customModal .testEdit .testSubjectContentsContainer,
.customModal .testEdit .testSubjectCreateContentsContainer, .customModal * .testEdit .testSubjectContentsContainer,
.customModal * .testEdit .testSubjectCreateContentsContainer {
  position: relative;
  transform: translateY(-1.25rem);
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--whiteColorDeam);
  border-top: 8px solid var(--SecondaryColor);
  border-radius: 0.5rem;
  padding: 0 0.5rem;
}
.jeeta-app .testEdit .testSubjectCreateContentsContainer,
.jeeta-app * .testEdit .testSubjectCreateContentsContainer,
.customModal .testEdit .testSubjectCreateContentsContainer, .customModal * .testEdit .testSubjectCreateContentsContainer {
  background-color: var(--cardBG);
}
.jeeta-app .testEdit .testSectionContentsContainer,
.jeeta-app .testEdit .testSectionCreateContentsContainer,
.jeeta-app * .testEdit .testSectionContentsContainer,
.jeeta-app * .testEdit .testSectionCreateContentsContainer,
.customModal .testEdit .testSectionContentsContainer,
.customModal .testEdit .testSectionCreateContentsContainer, .customModal * .testEdit .testSectionContentsContainer,
.customModal * .testEdit .testSectionCreateContentsContainer {
  position: relative;
  transform: translateY(-1.25rem);
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: var(--whiteColor);
  border-top: 8px solid var(--PrimaryColor);
  border-radius: 0.5rem;
  padding: 0;
}
.jeeta-app .testEdit .testSectionCreateContentsContainer,
.jeeta-app * .testEdit .testSectionCreateContentsContainer,
.customModal .testEdit .testSectionCreateContentsContainer, .customModal * .testEdit .testSectionCreateContentsContainer {
  gap: 1rem;
  padding: 1rem;
}
.jeeta-app .test-timer,
.jeeta-app * .test-timer,
.customModal .test-timer, .customModal * .test-timer {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.jeeta-app .test-timer .timerTitle,
.jeeta-app * .test-timer .timerTitle,
.customModal .test-timer .timerTitle, .customModal * .test-timer .timerTitle {
  align-items: center;
  color: var(--PrimaryColor);
  gap: 0.3rem;
}
.jeeta-app .test-timer .timerTitle .icon,
.jeeta-app * .test-timer .timerTitle .icon,
.customModal .test-timer .timerTitle .icon, .customModal * .test-timer .timerTitle .icon {
  font-size: 2rem;
}
.jeeta-app .test-timer .timeDisplay,
.jeeta-app * .test-timer .timeDisplay,
.customModal .test-timer .timeDisplay, .customModal * .test-timer .timeDisplay {
  background-color: var(--SecondaryBkg);
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  color: var(--midnight-blue);
  justify-content: center;
  align-items: center;
}
.jeeta-app .test-timer .timeDisplay span,
.jeeta-app * .test-timer .timeDisplay span,
.customModal .test-timer .timeDisplay span, .customModal * .test-timer .timeDisplay span {
  text-align: center;
  font-size: 2rem;
}
.jeeta-app .test-timer .timeDisplayLimit,
.jeeta-app * .test-timer .timeDisplayLimit,
.customModal .test-timer .timeDisplayLimit, .customModal * .test-timer .timeDisplayLimit {
  background-color: transparent;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  color: red;
  justify-content: center;
  align-items: center;
}
.jeeta-app .test-timer .timeDisplayLimit span,
.jeeta-app * .test-timer .timeDisplayLimit span,
.customModal .test-timer .timeDisplayLimit span, .customModal * .test-timer .timeDisplayLimit span {
  text-align: center;
  font-size: 2rem;
}
.jeeta-app .test-timer #startTest,
.jeeta-app * .test-timer #startTest,
.customModal .test-timer #startTest, .customModal * .test-timer #startTest {
  font-size: 1.1rem;
}
.jeeta-app .test-timer .startTestDuration,
.jeeta-app * .test-timer .startTestDuration,
.customModal .test-timer .startTestDuration, .customModal * .test-timer .startTestDuration {
  width: 20%;
}
.jeeta-app .testModal,
.jeeta-app * .testModal,
.customModal .testModal, .customModal * .testModal {
  max-width: 80vw;
  max-height: 80vh;
  overflow-y: auto;
  background-color: var(--whiteColorDeam);
  padding: 1.5rem 1rem;
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
@media all and (max-width: 860px) {
  .jeeta-app .tests,
  .jeeta-app * .tests,
  .customModal .tests, .customModal * .tests {
    gap: 0.5rem;
  }
}
@media all and (max-width: 800px) {
  .jeeta-app .tests,
  .jeeta-app * .tests,
  .customModal .tests, .customModal * .tests {
    padding: 0.5rem;
  }
  .jeeta-app .tests-header,
  .jeeta-app * .tests-header,
  .customModal .tests-header, .customModal * .tests-header {
    margin: 0.5rem 0;
    padding: 0.5rem 0;
  }
}
@media all and (max-width: 720px) {
  .jeeta-app .tests,
  .jeeta-app * .tests,
  .customModal .tests, .customModal * .tests {
    gap: 0.5rem;
  }
  .jeeta-app .tests #createNew,
  .jeeta-app * .tests #createNew,
  .customModal .tests #createNew, .customModal * .tests #createNew {
    margin: 0 0 0.5rem 0;
    transform: scale(1.1);
  }
  .jeeta-app .testPage,
  .jeeta-app * .testPage,
  .customModal .testPage, .customModal * .testPage {
    width: 95%;
    padding: 1.5rem 0.5rem 0.5rem 0.5rem;
    gap: 0.5rem;
  }
  .jeeta-app .tests-glass,
  .jeeta-app * .tests-glass,
  .customModal .tests-glass, .customModal * .tests-glass {
    padding: 1rem 0.5rem;
  }
  .jeeta-app .testReport-btn .report-btn,
  .jeeta-app * .testReport-btn .report-btn,
  .customModal .testReport-btn .report-btn, .customModal * .testReport-btn .report-btn {
    border-radius: 0.8rem;
    font-size: 0.75rem;
    padding: 0.2rem 0.8rem;
    gap: 0.2rem;
  }
  .jeeta-app .testReport-btn .report-btn .icon,
  .jeeta-app * .testReport-btn .report-btn .icon,
  .customModal .testReport-btn .report-btn .icon, .customModal * .testReport-btn .report-btn .icon {
    font-size: 0.94rem;
  }
  .jeeta-app .test-status .msg,
  .jeeta-app .test-refresh .msg,
  .jeeta-app .testInfo-edit-btn .msg,
  .jeeta-app * .test-status .msg,
  .jeeta-app * .test-refresh .msg,
  .jeeta-app * .testInfo-edit-btn .msg,
  .customModal .test-status .msg,
  .customModal .test-refresh .msg,
  .customModal .testInfo-edit-btn .msg, .customModal * .test-status .msg,
  .customModal * .test-refresh .msg,
  .customModal * .testInfo-edit-btn .msg {
    border-radius: 0.8rem;
    font-size: 0.8rem;
    padding: 0.2rem 0.8rem;
    gap: 0.2rem;
  }
  .jeeta-app .test-status .msg .icon,
  .jeeta-app .test-refresh .msg .icon,
  .jeeta-app .testInfo-edit-btn .msg .icon,
  .jeeta-app * .test-status .msg .icon,
  .jeeta-app * .test-refresh .msg .icon,
  .jeeta-app * .testInfo-edit-btn .msg .icon,
  .customModal .test-status .msg .icon,
  .customModal .test-refresh .msg .icon,
  .customModal .testInfo-edit-btn .msg .icon, .customModal * .test-status .msg .icon,
  .customModal * .test-refresh .msg .icon,
  .customModal * .testInfo-edit-btn .msg .icon {
    font-size: 0.95rem;
  }
  .jeeta-app .testEdit,
  .jeeta-app * .testEdit,
  .customModal .testEdit, .customModal * .testEdit {
    padding: 0.5rem 0;
    gap: 0.5rem;
  }
  .jeeta-app .testEdit .test-add-btn,
  .jeeta-app * .testEdit .test-add-btn,
  .customModal .testEdit .test-add-btn, .customModal * .testEdit .test-add-btn {
    font-size: 0.94rem;
    padding: 0.7rem;
  }
  .jeeta-app .testEdit .testSubjectContentsContainer,
  .jeeta-app * .testEdit .testSubjectContentsContainer,
  .customModal .testEdit .testSubjectContentsContainer, .customModal * .testEdit .testSubjectContentsContainer {
    padding: 0;
  }
  .jeeta-app .tests-filter .filters,
  .jeeta-app * .tests-filter .filters,
  .customModal .tests-filter .filters, .customModal * .tests-filter .filters {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 0.5rem;
  }
  .jeeta-app .tests-filter .filters .filterSelect,
  .jeeta-app * .tests-filter .filters .filterSelect,
  .customModal .tests-filter .filters .filterSelect, .customModal * .tests-filter .filters .filterSelect {
    width: 100%;
  }
  .jeeta-app .test-timer,
  .jeeta-app * .test-timer,
  .customModal .test-timer, .customModal * .test-timer {
    flex-direction: row;
    gap: 0.5rem;
  }
  .jeeta-app .test-timer .timerTitle,
  .jeeta-app * .test-timer .timerTitle,
  .customModal .test-timer .timerTitle, .customModal * .test-timer .timerTitle {
    margin-top: 0rem;
    font-size: 0.83rem;
  }
  .jeeta-app .test-timer .timeDisplay,
  .jeeta-app .test-timer .timeDisplayLimit,
  .jeeta-app * .test-timer .timeDisplay,
  .jeeta-app * .test-timer .timeDisplayLimit,
  .customModal .test-timer .timeDisplay,
  .customModal .test-timer .timeDisplayLimit, .customModal * .test-timer .timeDisplay,
  .customModal * .test-timer .timeDisplayLimit {
    border-radius: 0.5rem;
    padding: 0.2rem 0.5rem;
  }
  .jeeta-app .test-timer .timeDisplay span,
  .jeeta-app .test-timer .timeDisplayLimit span,
  .jeeta-app * .test-timer .timeDisplay span,
  .jeeta-app * .test-timer .timeDisplayLimit span,
  .customModal .test-timer .timeDisplay span,
  .customModal .test-timer .timeDisplayLimit span, .customModal * .test-timer .timeDisplay span,
  .customModal * .test-timer .timeDisplayLimit span {
    font-size: 28px;
  }
}
@media all and (max-width: 550px) {
  .jeeta-app .tests-header,
  .jeeta-app * .tests-header,
  .customModal .tests-header, .customModal * .tests-header {
    margin: 0;
    padding: 0.5rem;
    font-size: 0.9rem;
  }
  .jeeta-app .testPage,
  .jeeta-app * .testPage,
  .customModal .testPage, .customModal * .testPage {
    padding: 2rem 0.5rem 0.5rem 0.5rem;
  }
  .jeeta-app .testPage .testEdit .test-add-btn,
  .jeeta-app * .testPage .testEdit .test-add-btn,
  .customModal .testPage .testEdit .test-add-btn, .customModal * .testPage .testEdit .test-add-btn {
    font-size: 0.87rem;
  }
  .jeeta-app .tests-filter .filters,
  .jeeta-app * .tests-filter .filters,
  .customModal .tests-filter .filters, .customModal * .tests-filter .filters {
    width: 90%;
  }
  .jeeta-app .tests-glass,
  .jeeta-app * .tests-glass,
  .customModal .tests-glass, .customModal * .tests-glass {
    padding: 0.5rem 0.1rem;
  }
  .jeeta-app .tests-glass .bkgImg,
  .jeeta-app * .tests-glass .bkgImg,
  .customModal .tests-glass .bkgImg, .customModal * .tests-glass .bkgImg {
    width: 90%;
  }
  .jeeta-app .test-timer,
  .jeeta-app * .test-timer,
  .customModal .test-timer, .customModal * .test-timer {
    gap: 0.5rem;
  }
  .jeeta-app .test-timer .timerTitle,
  .jeeta-app * .test-timer .timerTitle,
  .customModal .test-timer .timerTitle, .customModal * .test-timer .timerTitle {
    font-size: 0.75rem;
  }
  .jeeta-app .test-timer .timerTitle .icon,
  .jeeta-app * .test-timer .timerTitle .icon,
  .customModal .test-timer .timerTitle .icon, .customModal * .test-timer .timerTitle .icon {
    font-size: 1.38rem;
  }
  .jeeta-app .test-timer .timeDisplay span,
  .jeeta-app .test-timer .timeDisplayLimit span,
  .jeeta-app * .test-timer .timeDisplay span,
  .jeeta-app * .test-timer .timeDisplayLimit span,
  .customModal .test-timer .timeDisplay span,
  .customModal .test-timer .timeDisplayLimit span, .customModal * .test-timer .timeDisplay span,
  .customModal * .test-timer .timeDisplayLimit span {
    font-size: 1.38rem;
  }
  .jeeta-app .test-status .msg,
  .jeeta-app .test-refresh .msg,
  .jeeta-app .testInfo-edit-btn .msg,
  .jeeta-app * .test-status .msg,
  .jeeta-app * .test-refresh .msg,
  .jeeta-app * .testInfo-edit-btn .msg,
  .customModal .test-status .msg,
  .customModal .test-refresh .msg,
  .customModal .testInfo-edit-btn .msg, .customModal * .test-status .msg,
  .customModal * .test-refresh .msg,
  .customModal * .testInfo-edit-btn .msg {
    font-size: 0.75rem;
    padding: 0.2rem 0.5rem;
  }
  .jeeta-app .test-status .msg .icon,
  .jeeta-app .test-refresh .msg .icon,
  .jeeta-app .testInfo-edit-btn .msg .icon,
  .jeeta-app * .test-status .msg .icon,
  .jeeta-app * .test-refresh .msg .icon,
  .jeeta-app * .testInfo-edit-btn .msg .icon,
  .customModal .test-status .msg .icon,
  .customModal .test-refresh .msg .icon,
  .customModal .testInfo-edit-btn .msg .icon, .customModal * .test-status .msg .icon,
  .customModal * .test-refresh .msg .icon,
  .customModal * .testInfo-edit-btn .msg .icon {
    font-size: 0.9rem;
  }
}/*# sourceMappingURL=Test.css.map */