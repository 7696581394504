.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.members {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    color: var(--textColor);
    animation: fadeUp 1.2s ease-out forwards; /* Example duration and timing */
}

.members-header {
    position: relative;
    width: 100%;
    padding: .5rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background: var(--gradientBkg);
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

    .title {
        color: var(--whiteColor);
        text-shadow: 0 2px 4px rgba(140, 140, 141, 0.3);
        text-align: center;
    }
}    

.members-glass {
    width: 100%;
    min-height: 50vh;
    flex-direction: column;
    // justify-content: flex-start;
    align-items: center;
    // margin-top: 1rem;
    padding: 2rem .5rem 1rem .5rem;
    background: var(--whiteColor);
    border-radius: 2rem;
    gap: .5rem;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        color: var(--textColor);
        
        .memberTypeSelectionDropdown {

            .css-1dimb5e-singleValue {
                font-size: 1.5rem;
                font-weight: 600;
                color: var(--textColor);
            }
        }
    }
}

.memberList {
    width: 100%;
    max-height: 80vh;
    color: var(--textColor);
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    margin-top: 1rem;
}

.membersFilter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .subjectSelectionDropdown {
        width: 14rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .5rem;

        .title {
            font-size: .87rem;
            font-weight: 600;
        }

        .selectionDropdown,
        .selectionDropdownMultiple {
            flex-grow: 1;
            font-size: .83rem;

            .css-wsp0cs-MultiValueGeneric {
                max-width: 180px;
                overflow: hidden;
            }
        }
    }

    .create-btn {
        border: none;
        color: var(--PrimaryColor);
        background: transparent;

        &:hover {
            cursor: pointer;
        }

        .icon {
            align-self: center;
            font-size: 1.94rem;
        }
    }
}

.membersTable {
    position: relative;
    width: 95%;

    $table-header-bg: var(--SecondaryBkg);
    overflow-x: auto;
    overflow-y: auto;
    max-height: 100vh;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
}

.table-style {
    width: 100%;
    // border-collapse: collapse;
    font-size: .85rem;
    box-shadow: 0 0 1.11rem rgba(0, 0, 0, 0.15);

    thead tr {
        background-color: var(--SecondaryBkg);
        color: var(--textColor);
        text-align: center;
        font-size: .9rem;
        /* Horizontally center the text. */
        vertical-align: middle;
        /* Vertically center the text. */
        // padding: 10px 0;
    }

    th,
    td {
        padding: 10px;
        border-bottom: 1px solid var(--cardBG);
    }

    tbody tr {
        &:hover {
            font-weight: 600;
            background-color: var(--PrimaryBkg);
        }

        &:nth-of-type(even) {
            background-color: var(--whiteColorDeam);

            &:hover {
                font-weight: 600;
                background-color: var(--PrimaryBkg);
            }
        }
    }

    tbody td {
        white-space: nowrap;
        input {
            width: 100%;
            padding: .3rem .5rem;
            border: none;
            border-radius: .3rem;
        }

        .batchSelectionDropdown,
        .subjectSelectionDropdown {
            width: 100%;
        }
    }

    tbody tr:last-of-type {
        td {
            border-bottom: 0;
        }
    }

    td:nth-child(2),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7) {
        .icon {
            padding-top: 3px;
            font-size: 1rem;
        }

        text-align: center;
        /* Horizontally center the text. */
        vertical-align: middle;
        /* Vertically center the text. */
    }
}


.memberRegisterModal {
    max-height: 80vh;
    overflow-y: auto;
    overflow-y: auto;
    background-color: var(--whiteColorDeam);
    padding: 2rem 1rem;
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

    #close {
        position: absolute;
        top: 5px;
        right: 5px;
        color: var(--PrimaryColor);
        align-self: center;
        font-size: 1.66rem;
    }

    .register-container {
        width: 100%;
        padding: 1rem;
        color: var(--textColor);
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-size: 1rem;

        .registerTitle {
            margin-bottom: 1.5rem;
            font-size: 1.55rem;
        }

        .btn {
            margin-top: 1rem;
            color: var(--whiteColor);
            font-weight: 600;
            justify-content: space-between;
            gap: .5rem;

            .icon {
                align-self: center;
                font-size: 1rem;
            }
        }

        .registerInfoInput {
            flex-direction: column;
            justify-content: center;
            gap: 1.5rem;

            .contactInput {
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: .5rem;

                input {
                    width: 350px;
                    // font-size: .78rem;
                    border: none;
                    border-radius: .3rem;
                    // padding: 2px 5px 2px 5px;
                    padding: .5rem;
                    // margin-bottom: 1rem;
                    // border-radius: .5rem;
                    // border: 1px solid var(--PrimaryColor);
                    font-size: .87rem;
                }

                .icon {
                    color: var(--PrimaryColor);
                    font-size: 1.38rem;
                    font-weight: 600;
                    margin: 0 10px 0 10px;
                }
            }

            // .batchGradeSelectDropdown {
            //     width: 100%;
            //     display: flex;
            //     flex-direction: column;
            //     justify-content: center;
            //     gap: 1rem;

            //     .gradeSelectionDropdown,
            //     .batchSelectionDropdown {
            //         width: 100%;
            //         display: flex;
            //         flex-direction: row;
            //         align-items: center;
            //         gap: .5rem;

            //         .title {
            //             width: 60px;
            //             font-size: .87rem;
            //             font-weight: 600;
            //         }

            //         .selectionDropdown,
            //         .selectionDropdownMultiple {
            //             flex-grow: 1;
            //             font-size: .83rem;

            //             .css-wsp0cs-MultiValueGeneric {
            //                 max-width: 180px;
            //                 overflow: hidden;
            //             }
            //         }
            //     }
            // }

            .subjectSelectionDropdown {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .5rem;

                .title {
                    font-size: .87rem;
                    font-weight: 600;
                }

                .selectionDropdown,
                .selectionDropdownMultiple {
                    flex-grow: 1;
                    font-size: .83rem;

                    .css-wsp0cs-MultiValueGeneric {
                        max-width: 180px;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}

// .userProfile {
//     padding: 2rem 0 2rem 0;
//     flex-direction: column;
//     justify-content: space-between;
//     text-align: left;
//     gap: 1rem;
// }

//MEDIA QUERIES ==================================================>
@media screen and (min-width: 1400px) {}

@media all and (max-width:860px) {
    .members {
        gap: .5rem;
    }

    .membersFilter {
        flex-direction: column;
        gap: .5rem;
        margin-bottom: .5rem;
    }
}

@media all and (max-width:800px) {
    .members {
        padding: .5rem;
    }

    .members-header {
        margin: .5rem 0;
        padding: .5rem 0;
    }
}

@media all and (max-width:550px) {

    .members-header{
        margin: 0;
        padding: .5rem;
        font-size: .9rem;
    }

    .members-glass{
        margin-top: 0;
        font-size: 1rem;
        padding: 1rem 0;

        .title {
        
            .memberTypeSelectionDropdown {

                .css-1dimb5e-singleValue {
                    font-size: 1.05rem;
                }
            }
        }
    }
}
}