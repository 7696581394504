.jeeta-app .loading-container,
.jeeta-app * .loading-container,
.customModal .loading-container,
.customModal * .loading-container {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.jeeta-app .loading-icon,
.jeeta-app * .loading-icon,
.customModal .loading-icon,
.customModal * .loading-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid var(--SecondaryBkg);
  border-top-color: var(--SecondaryColor);
  animation: spin 1s linear infinite;
}
.jeeta-app .loading-icon .icon,
.jeeta-app * .loading-icon .icon,
.customModal .loading-icon .icon,
.customModal * .loading-icon .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--SecondaryColor);
  transform-origin: center;
  animation: spinReverse 1s linear infinite;
}/*# sourceMappingURL=LoadingPage.css.map */