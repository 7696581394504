.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.recommendedQuestionSlider{
    position: relative;
    width: 95%;
    min-height: 13rem;
    background: var(--cardBG);
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(140, 140, 141, .549);
    padding: 0 .5rem;
}

@media all and (max-width: 720){
    .recommendedQuestionSlider{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        padding: 0;
    }
}
}