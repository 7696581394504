.jeeta-app, .jeeta-app * {
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    padding: 1rem 2rem;
    background: var(--whiteColorDeam);
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.242);
    z-index: 1000;

    .navBtns {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 97%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .leftBtns {
            align-items: center;

            .logoDiv {
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: .5rem;
                color: var(--PrimaryColor);
                font-weight: 500;
                // margin-left: 1rem;

                &:hover {
                    cursor: pointer;
                }

                .companyLogo {
                    width: 35px;
                    height: 35px;
                    transform: translateY(-2px);
                }

                .companyTitle{
                    font-size: 1.5rem;
                    font-weight: 700;
                }
            }
        }

        .rightBtns {
            align-items: center;

            .icon {
                font-size: 30px;
                color: var(--PrimaryColor);
                text-decoration: none;

                &:hover {
                    cursor: pointer;
                    color: var(--SecondaryColor);
                }
            }

            #toggleMenu {
                display: none;
            }
        }
    }

    .menu {
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
        font-size: 15px;
        color: var(--textColor);
        z-index: 1001;

        .navList {
            flex-direction: row;
            justify-content: center;
            gap: 1rem;

            .navItem,
            .navItem-selected {
                padding: 0 .5rem;

                .navLink {
                    text-decoration: none;
                    color: var(--textColor);
                    font-weight: 500;

                    &:hover {
                        color: var(--PrimaryColor);
                        cursor: pointer;
                    }
                }

                #selected {
                    color: var(--PrimaryColor);
                }
            }

            // .closeNavbar {
            //     display: none;
            // }
        }
    }

}

//MEDIA QUERIES ==================================================>
@media screen and (min-width: 1400px) {
    // .toggleNavbar, .closeNavbar{
    //     display: none;
    // }
}

@media all and (max-width:860px) {
    .navbar {
        padding: .5rem 2rem;

        .menu-iconVisible {
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            font-size: 15px;
            color: var(--textColor);
            z-index: 1001;

            .navList {
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 1rem;

                .navItem {
                    padding: 0 .5rem;

                    .navLink {
                        // width: 1.5rem;
                        // height: 2.5rem;
                        display: flex;
                        justify-content: center;
                        text-decoration: none;
                        color: var(--textColor);
                        font-weight: 500;

                        &:hover {
                            color: var(--PrimaryColor);
                            cursor: pointer;
                        }

                        .navIcon {
                            transform: translateY(.15rem);
                            flex-direction: column;
                            justify-content: flex-end;
                            align-items: center;

                            .icon {
                                width: 1.5rem;
                                height: 1.5rem;
                            }

                            span {
                                font-size: .7rem;
                            }

                            &:hover {
                                color: var(--PrimaryColor);
                            }
                        }
                    }

                    #selected {
                        color: var(--PrimaryColor);

                        .navIcon {
                            color: var(--PrimaryColor);
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width:500px) {

    .navbar{

        .menu-iconVisible {
            .navList {
                gap: .5rem;

                .navItem{
                    padding: 0;
                    transform: scale(.95);
                }
            }
        }
    }
}
}