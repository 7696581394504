.jeeta-app,
.jeeta-app *,
.customModal,
.customModal *  {
    .videoInputContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .5rem;

    }

    .videoInput {
        width: 100%;
        justify-content: center;
        align-items: center;

        .dropzone {
            width: 100%;
            height: 6rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: .5rem;
            border: 2px dashed var(--PrimaryBkg);
            border-radius: .25rem;
            padding: 1rem;
            text-align: center;
            cursor: pointer;
            transition: border 0.2s ease-in-out;

            .drag-n-drop-msg {
                font-size: .95rem;
                color: var(--PrimaryColor);
                white-space: auto;
                font-weight: 600;
            }
        }

        .dropzone.active {
            border-color: var(--PrimaryColor);
        }

        .upload-video-number {
            width: 100%;
            padding: .3rem 1rem;
            text-align: center;
            font-weight: 600;
            background-color: var(--SecondaryColor);
            color: var(--whiteColor);
            font-size: 0.83rem;
        }

        #error {
            background-color: var(--tiger-lilly);
        }
    }


    .previewVideosContainer {
        flex-direction: row;
        .previewVideos {
            position: relative;
            justify-content: center;
            padding: .5rem;
            box-shadow: 2px 2px var(--greyText);
            background-color: var(--whiteColor);
            /* Ensure container takes up full column width */
            max-height: 40vh;
            min-height: 2rem;
            /* Fixed height for all containers */
            /* To handle larger videos */

            .previewVideo {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }

            .delete-btn {
                width: max-content;
                height: max-content;
                border: none;
                color: white;
                background-color: var(--SecondaryColor);
                display: flex;
                justify-content: center;
                padding: .3rem;

                .icon {
                    font-size: 1rem;
                }

                &:hover {
                    cursor: pointer;
                    background-color: var(--tiger-lilly);
                }
            }

            p {
                border: none;
                color: white;
                background-color: var(--PrimaryColor);
                padding: 0 .5rem;
            }
        }
    }

    .videoViewer {
        padding: .5rem;
        position: relative;
        width: 95%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .videoTitle {
                margin: 1rem 0;
                font-size: 1.38rem;
                text-align: center;
            }

        .videoPlayer {
            position: relative;
            width: 100%;
            height: 100%;
            // min-height: 25rem;
            // border: 2px solid var(--PrimaryBkg);
            background-color: var(--whiteColorDeam);
            border-radius: 1rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            overflow: hidden;
            // padding: .5rem;
        }
    }
.chapters-table-wrapper {
    width: 100%;
    font-size: .85rem;
    overflow-x: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
}

.chapters-table {
    width: 100%;
    border-collapse: collapse;

    thead th {
        background-color: transparent;
        color: var(--SecondaryColor);
        font-size: 1rem;
        text-align: center;
        vertical-align: middle;
        border: none;
        padding: 0 .5rem;

        .chapter-pin {
            font-size: 1.2rem;
            color: var(--PrimaryColor);
        }

        &:hover {
            cursor: pointer;

            .chapter-pin {
                color: var(--tiger-lilly);
            }
        }
    }

    tbody td {
        // max-width: 25rem;
        text-align: center;
        vertical-align: top;
        max-width: 20rem;
        overflow: word-wrap;
        border: none;
        padding: 0 .5rem .5rem .5rem;

        &:hover {
            font-weight: 600;
            cursor: pointer;
        }
    }
}

.chapters-table-vertical {
    width: 100%;
    border-collapse: collapse;

    thead th {
        background-color: transparent;
        color: var(--SecondaryColor);
        font-size: 1rem;
        text-align: center;
        vertical-align: middle;
        border: none;
        padding: 0 .5rem;


    }

    tbody td {
        // max-width: 25rem;
        // text-align: center;
        vertical-align: top;
        // max-width: 20rem;
        border: none;
        // padding: 0 .5rem .5rem .5rem;

        .start-time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: .2rem;
            white-space: nowrap;
            background-color: transparent;
            color: var(--SecondaryColor);
            font-size: 1rem;
            text-align: center;
            vertical-align: middle;
            border: none;
            padding: 0 .5rem;
        }

        .segment-title {
            overflow: word-wrap;

        }

        .chapter-pin {
            font-size: 1.2rem;
            color: var(--PrimaryColor);
        }

        &:hover {
            font-weight: 600;
            cursor: pointer;

            .chapter-pin {
                color: var(--tiger-lilly);
            }
        }
    }
}



    @media all and (min-width: 1800px) {}

    @media all and (max-width: 860px) {

        .videoInputContainer {

            .videoInput {

                .video-add-btn {
                    font-size: 0.89rem;

                    .upload-video-number {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    @media all and (max-width: 500px) {

        .videoInputContainer {
            .videoInput {
                .dropzone {
                    height: 7rem;
                }
            }
        }

    }
}