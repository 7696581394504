.home {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    // min-height: 100vh;
    background-color: var(--whiteColor);
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    // overflow: auto;

    .profileSelect {
        // position: absolute;
        // top: 60vh;
        // left: 50vw;
        // transform: translate(-50%, -50%);
        width: 450px;
        padding: .5rem;
        margin-bottom: 1rem;

        input {
            caret-color: transparent;
        }

        ::placeholder {
            font-size: 15px;
            color: var(--textColor);
        }

        span {
            font-size: 16px;
        }

        .react-dropdown-select-item {
            font-size: 15px;
        }
    }
}

//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {

    .home {

        .profileSelect {
            width: 30vw;
            padding: .8rem .5rem;

            ::placeholder {
                font-size: 18px;
            }

            span {
                font-size: 18px;
            }

            .react-dropdown-select-item {
                font-size: 17px;
            }
        }
    }
}

@media all and (max-width: 860px) {

    .home {

        .profileSelect {
            width: 50vw;
        }
    }
}

@media all and (max-width: 500px) {

    .home {

        .profileSelect {
            // display: none;
            width: 300px;

            ::placeholder {
                font-size: 13.5px;
            }

            span {
                font-size: 14px;
            }

            .react-dropdown-select-item {
                font-size: 13.5px;
            }
        }
    }
}