.customTable{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;


    .title{
        padding: .5rem;
        color: var(--textColor);
    }
}

.MuiTableRow-root>* {
    padding: .6rem;
}

.MuiTableRow-root.MuiTableRow-head>* {
    font-weight: bold !important;
}

.customTable td,
th {
    border: none !important;
}

.customTable :first-child {
    border-radius: 0.7rem !important;
}

@media screen and (max-width: 1200px) {
    .customTable  {
        // width: 170%;
        // margin-top: 2rem;
    }
}

@media all and (max-width: 500px) {
    // .customTable  {
    //     position: relative;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     width: 23rem;
    // }
}