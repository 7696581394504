.jeeta-app,
.jeeta-app *,
.customModal, .customModal * {
.testModal {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    /* necessary adjustments for centering */
    z-index: 1000;
    /* high value to ensure modal is on top */
    width: 80vw;
    // max-width: 800px;
    /* or your desired width */
    overflow-y: auto;
    /* display scrollbar only when necessary */
    background-color: var(--whiteColorDeam);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: .5rem;
    padding: 1rem 3rem;
    color: var(--textColor);
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .modalTitle {
        margin: 1rem 0;
        padding: 1rem 0;
        font-size: 1.4rem;
    }

    .testContent {
        position: relative;
        width: 100%;
        flex-direction: column;
        gap: 1.5rem;
        padding: 0 1rem;

        .testInfoInput,
        .testSelectionDropdown,
        .testSelectionDropdownMultiple {
            // width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .5rem;

            .title {
                padding: .35rem .5rem;
                font-size: .87rem;
                font-weight: 600;
            }

            .selectionDropdown,
            .selectionDropdownMultiple {
                flex-grow: 1;
                font-size: .83rem;
            }

            .text,
            .number,
            .date {
                flex-grow: 1;
                font-size: .83rem;
                padding: .5rem;
                color: var(--textColor);
                border: hsl(0, 0%, 80%) solid 1px;
                border-radius: .3rem;
                // line-height: 1.3;
            }

            input[type="range"]::-webkit-slider-runnable-track {
            cursor: pointer;
            animate: 0.2s;
            // background: var(--PrimaryColor);
            border-radius: .5rem;
        }

            .sliderValue{
                font-size: .83rem;
                background-color: var(--whiteColor);
                padding: .2rem .5rem;
                color: var(--textColor);
                border: hsl(0, 0%, 80%) solid 1px;
                border-radius: .3rem;
            }

            .sliderRange{
                font-size: .8rem;
                color: var(--textColor);
            }
        }

        .batchGradeSelectDropdown {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            // grid-auto-rows: minmax(400px, auto);
            gap: 1rem;

            .gradeSelectionDropdown,
            .batchSelectionDropdown {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .5rem;

                .title {
                    font-size: .87rem;
                    font-weight: 600;
                }

                .selectionDropdown,
                .selectionDropdownMultiple {
                    width: 100%;
                    font-size: .83rem;
                }
            }
        }
    }

    .btn {
        margin-top: 1rem;
        color: var(--whiteColor);
        font-weight: 600;
        justify-content: center;
        gap: .5rem;

        .icon {
            align-self: center;
            font-size: 1rem;
        }
    }

    #submit {
        margin-bottom: 1rem;
    }

    #close {
        position: absolute;
        top: .3rem;
        right: .3rem;
        color: var(--PrimaryColor);
        align-self: center;
        font-size: 1.8rem;

        &:hover {
            color: var(--tiger-lilly);
        }
    }
}

.autoTestSectionCreate {
    z-index: 1001;
    min-width: 800px;
    background-color: rgba(65, 137, 230, 0.3);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    transform: scale(0.9);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.automatedTestCheck{
    position: relative;
    padding: 3rem 1rem 0rem 1rem;
    border: none;
    border-radius: .5rem;
    background-color: var(--glass);
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    font-size: .87rem !important;
    z-index: 1000;
    
    .automatedTestCheckHeader{
        position: absolute;
        width: 100%;
        height: 2.2rem;
        top: 0;
        left: 0;
        overflow: hidden;

        .checkBox{
            // position: absolute;
            width: 100%;
            // top: 0;
            // left: 0;
            padding: .5rem 1rem 1rem 1rem;
            border-radius: .5rem;
            background-color: var(--blackColor);
            color: var(--whiteColor);
            width: 100%;
            align-items: center;
            gap: .5rem;

            .title{
                font-weight: 600;
            }

            input[type="checkbox"] {
                appearance: none; /* Remove default checkbox styling */
                -webkit-appearance: none; /* For Safari */
                cursor: pointer;
                width: 1rem;
                height: 1rem;
                background-color: white; /* Initial background color */
                border: 2px solid var(--orange); /* Border color */
                border-radius: 4px; /* Optional: rounded corners */
                position: relative;
            }

            input[type="checkbox"]:checked {
                background-color: var(--orange); /* Background color when checked */
                border: 2px solid var(--orange); /* Border color when checked */
            }

            input[type="checkbox"]:checked::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 10px;
                height: 10px;
                background-color: white;
                transform: translate(-50%, -50%);
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 20%, 80% 0%, 45% 75%);
            }
        }
    }

    .syllabusCheck,
    .syllabusFilter,
    .testInfoInput {
        padding: 1rem;
        border: none;
        border-radius: .5rem;
        background-color: var(--whiteColor);
        align-items: center;
        // justify-content: center;
        gap: .5rem;

        .title{
            font-weight: 600;
        }

        input{
            cursor: pointer;
        }
    }

    .syllabusFilter{
        padding: .5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        background-color: var(--PrimaryBkg);
        transform: scale(.9);
        z-index: 1001;

        .syllabus-filter{

            .filter{
                .filter-label{
                    font-size: .87rem;
                }
            }
        }
    }

}



//MEDIA QUERIES ==================================================>
@media all and (min-width: 1800px) {}

@media all and (max-width: 860px) {}

@media all and (max-width: 720px) {

    .testModal {
        width: 90vw;
        padding: .5rem;
    }
}

@media all and (max-width: 550px) {
    .testModal {
        width: 95vw;
        // padding: .5rem;
    }
}
}